module MapDrawing {
    import ObservableValue = spt.Utils.ObservableValue;

    export class ViewModel {

        constructor(mapDrawer: MapDrawer, view: Element, editorMode: number) {

            ko.track(this);

            this.init(mapDrawer, view, editorMode);

            this.UseImperialSystem = window.UseImperialSystem;
            this.usemeteronroof = window.usemeteronroof;

            this.valueAdjustOpts = {
                notImperial: true,
                precision: window.UseImperialSystem || window.usemeteronroof ? 3 : 0,
                from: "mm",
                to: window.UseImperialSystem ? "ft" : (window.usemeteronroof ? "m" : "mm")
            };

            this.slopeAdjustOpts = {
                notImperial: true,
                precision: 3
            };

            this.scaleAdjustOpts = {
                notImperial: true,
                precision: 2,
                scale: 100
            };

            this.rotateAdjustOpts = {
                notImperial: true,
                precision: 2
            };

            ko.defineProperty(this, 'detailsIconOffset', () => {
                return !this.detailsViewVisible ? `-${1 * 20}px -${2 * 20}px` : `-${0 * 20}px -${2 * 20}px`;
            });

            ko.defineProperty(this, 'currentLayer',
                {
                    get: () => {
                        return this.layer.typeName[this.selectedLayerTypeName] || this.layer.referenceLayer;
                    },
                    set: (p: PolygonLayer) => {
                        this.selectedLayerTypeName = p ? p.typeName : null;
                    }
                });

            ko.defineProperty(this, 'currentSubViewModel',
                {
                    get: () => {
                        return this._currentSubView && this.subViewModels[this._currentSubView] ? this.subViewModels[this._currentSubView] : null;
                    },
                    set: (p: ISubModelView) => {
                        this._currentSubView = p ? p.name : null;
                    }
                });

            ko.defineProperty(this, "bounds", () => {
                var layers = this.layer.all;
                var bounds: BoundsLatLng = null;
                layers.forEach(layer => {
                    var bd = layer.bounds;
                    if (bd) {
                        if (bounds)
                            bounds.Expand(bd);
                        else
                            bounds = new BoundsLatLng(bd.Min, bd.Max);
                    }
                });
                return bounds || new BoundsLatLng();
            });

            ko.defineProperty(this, "currentImportedImage", () => {
                if (!this.currentImportedImageId)
                    return this.importedImages.length ? this.importedImages[0] : null;
                for (var i = 0, j = this.importedImages.length; i < j; i++) {
                    var img = this.importedImages[i];
                    if (img._id === this.currentImportedImageId)
                        return img;
                }
                return null;
            });

            ko.defineProperty(this, "referenceLatLng", () => {
                var layer = this.layer.referenceLayer;
                var bd = layer.bounds;
                if (layer.polygons.length) {
                    var data = layer.polygons[0].userData as ShapeData;
                    if (data && data.southMarker && data.southMarker.orientation)
                        return LatLng.FromPoint2D(data.getOrientedOrigin());
                }
                if (bd && bd.Initialized)
                    return layer.bounds.Min;
                return this.mapDrawer.center;
            });

            ko.defineProperty(this, "referenceOrientation", () => {
                var layer = this.layer.referenceLayer;
                if (layer.polygons.length) {
                    var data = layer.polygons[0].userData as ShapeData;
                    if (data && data.southMarker && data.southMarker.orientation)
                        return data.southMarker.orientation;
                }
                return 0;
            });

            ko.defineProperty(this, "referenceMarker", () => {
                var latLng = this.referenceLatLng,
                    orientation = this.referenceOrientation,
                    refMarker = this._referenceMarker,
                    mapDrawer = this.mapDrawer;

                if (!mapDrawer.map || !latLng)
                    return null;

                var vx = Point2D.FromOrientation(-orientation).mul(20),
                    vy = vx.rot90(),
                    r = 14,
                    r2 = r * 2,
                    ps = `M${-r},0 a${r},${r} 0 0,0 ${r2},0 a${r},${r} 0 0,0 ${-r2},0 M${-vx.x},${-vx.y} L${vx.x},${vx.y} M${-vy.x},${-vy.y} L${vy.x},${vy.y}`,
                    opts: google.maps.MarkerOptions = {
                        position: latLng.ToGMLatLng(),
                        icon: {
                            path: ps,
                            strokeWeight: 1,
                            strokeColor: "rgb(179,255,255)",
                            strokeOpacity: 0.7
                        }
                    };

                if (!refMarker) {
                    refMarker = this._referenceMarker = new google.maps.Marker($.extend({
                        clickable: false,
                        draggable: false,
                        map: mapDrawer.map,
                        zIndex: 0,
                        visible: true
                    }, opts));
                } else {
                    refMarker.setOptions(opts);
                }

                return refMarker;
            });

            ko.getObservable(this, "referenceMarker").subscribe((v) => {
                //this function is needed! Even when it does nothing, we need a subscriber so knockout updates the referenceMarker when it changes.
            });

            ko.defineProperty(this, "referenceAxis", () => {
                return Point2D.FromOrientation(this.referenceOrientation);
            });

            ko.defineProperty(this, "saveDisabled", () => {
                return this.overlayTemplate != null || (this._currentSubView != null && this._currentSubView !== "referenceLength") || this.selectedToolName === "ImportImageHelperTool";
            });

            ko.defineProperty(this, "uploadDisabled", () => {
                return this.overlayTemplate != null || (this._currentSubView != null && this._currentSubView !== "referenceLength") || this.selectedToolName === "ImportImageHelperTool";
            });

            ko.getObservable(this, "selection").subscribe(this.onSelectionChanged.bind(this), null, "arrayChange");

            ko.getObservable(this, "showCoords").subscribe((v) => {
                if (this.layer.building)
                    this.layer.building.forceUpdateAllPolygons();
                if (this.layer.interference)
                    this.layer.interference.forceUpdateAllPolygons();
                if (this.layer.freeArea)
                    this.layer.freeArea.forceUpdateAllPolygons();
            });

            ko.getObservable(this, "showLengths").subscribe((v) => {
                if (this.layer.building)
                    this.layer.building.forceUpdateAllPolygons();
                if (this.layer.interference)
                    this.layer.interference.forceUpdateAllPolygons();
                if (this.layer.freeArea)
                    this.layer.freeArea.forceUpdateAllPolygons();
            });

            ko.getObservable(this, "showCoordNumbers").subscribe((v) => {
                if (this.layer.building)
                    this.layer.building.forceUpdateAllPolygons();
                if (this.layer.interference)
                    this.layer.interference.forceUpdateAllPolygons();
                if (this.layer.freeArea)
                    this.layer.freeArea.forceUpdateAllPolygons();
            });

            ko.getObservable(this, "showAngle").subscribe((v) => {
                if (this.layer.building)
                    this.layer.building.forceUpdateAllPolygons();
                if (this.layer.interference)
                    this.layer.interference.forceUpdateAllPolygons();
                if (this.layer.freeArea)
                    this.layer.freeArea.forceUpdateAllPolygons();
            });

            ko.defineProperty(this, 'toolsDisabled',
                {
                    get: () => {
                        return this.allTools.every(t => t.disabled);
                    },
                    set: (b: boolean) => {
                        this.allTools.forEach(t => { t.disabled = !!b; });
                    }
                });

            this.keyBoardSteps = new ObservableValue(100, { internalUnit: "mm", viewUnit: UseImperialSystem ? "in" : "mm", precision: 2 });
            this.interferenceLineThickness = new ObservableValue(250, { internalUnit: "mm", viewUnit: UseImperialSystem ? "in" : "mm", precision: 2 });

            if ($(".mdDetailsContent").length) {
                var mdDetailsContent = $(".mdDetailsContent").get(0) as HTMLDivElement;

                document.addEventListener("mousedown", (ev) => {
                    this.detailsContentHasFocus = false;
                }, true);

                mdDetailsContent.addEventListener("mousedown", (ev) => {
                    this.detailsContentHasFocus = true;
                }, true);
            }
        }

        clear() {
            this.layer.all.forEach(layer => {
                this.mapDrawer.deleteSelectables(layer.polygons.map(poly => poly.userData as ShapeData));
            });
            this.helperLines.removeAll().forEach(helperLine => {
                helperLine.dispose();
            });
            this.importedImages.removeAll().forEach(im => {
                im.setMap(null);
            });
            if (this._referenceMarker) {
                this._referenceMarker.setMap(null);
                this._referenceMarker = null;
            }
        }

        init(mapDrawer: MapDrawer, view: Element, editorMode: number) {
            this.mapDrawer = mapDrawer;
            this.view = view;
            this.editorMode = editorMode || 0;

            if (this._referenceMarker) {
                this._referenceMarker.setMap(null);
                this._referenceMarker = null;
            }

            if (this.multiShapeData === undefined) {
                var multiShapeData: MultiShapeData = null;
                Object.defineProperty(this, 'multiShapeData',
                    {
                        configurable: true,
                        enumerable: true,
                        get: () => {
                            if (!multiShapeData)
                                multiShapeData = new MultiShapeData(this.mapDrawer);
                            return multiShapeData;
                        }
                    });
            }

            this.layer = new LayerHolder(mapDrawer, this.editorMode);

            if (this.subViewModels) {
                Object.keys(this.subViewModels).forEach(name => {
                    ko.untrack(this.subViewModels[name]);
                });
            }

            this.subViewModels = {
                importLayer: new ImportLayerModel(mapDrawer),
                backgroundSetting: new ImportImageModel(mapDrawer),
                referenceLength: new ReferenceLengthModel(mapDrawer),
                multipleRidges: new MultipleRidgesModel(mapDrawer)
            };

            Object.keys(this.subViewModels).forEach(name => {
                (<ISubModelView>this.subViewModels[name]).name = name;
            });

            this.toolIcons = {};
            this.allTools.removeAll();

            //tools
            Object.keys(MapDrawing).filter((n) => typeof MapDrawing[n] === "function" && (<any>MapDrawing[n]).prototype instanceof BaseToolIcon).forEach(n => {
                this.allTools.push(this.toolIcons[n] = new MapDrawing[n](mapDrawer, n) as BaseToolIcon);
            });

            if (this.selection.length)
                this.selection.removeAll();
            if (this.importedImages.length)
                this.importedImages.removeAll();

            this.selectedLayerTypeName = null;
            this.isReadOnly = mapDrawer.options.isReadOnly;
            this.selectedToolName = this.isReadOnly ? 'HandTool' : 'SelectTool';
            this.slideOutVisible = false;
            this.viewWidth = 0;
            this.viewHeight = 0;
            this.currentImportedImageId = null;
            this._currentSubView = null;
            this.detailsViewVisible = false;
            this.overlayTemplate = null;
            this.cadPreviewSrc = null;
            this.cadImportEnabled = false;

            //mapDrawer.selectToolClick(this.selectedToolName);
            //mapDrawer.selectTool(this.selectedToolName);
        }

        get currentTool(): BaseToolIcon {
            return this.toolIcons[this.selectedToolName || 'SelectTool'];
        }

        set currentTool(v: BaseToolIcon) {
            this.selectedToolName = (v ? v.name : 'SelectTool') || 'SelectTool';
        }

        editorMode: number = 0;
        layer: LayerHolder = null;
        instances: { [id: string]: ISelectableItem } = {};
        selection: ISelectableItem[] = [];
        multiShapeData: MultiShapeData;
        currentLayer: PolygonLayer;
        selectedLayerTypeName: string = null;
        toolIcons: { [name: string]: BaseToolIcon };
        allTools: BaseToolIcon[] = [];
        isReadOnly: boolean = false;
        selectedToolName: string = 'SelectTool';
        isDragging: boolean = false;
        detailsContentHasFocus: boolean = false;
        mapDrawer: MapDrawer;
        view: Element;
        slideOutVisible = false;
        viewWidth = 0;
        viewHeight = 0;
        importedImages: ImageMapOverlay[] = [];
        currentImportedImageId: string = null;
        currentImportedImage: ImageMapOverlay;
        referenceLatLng: LatLng;
        referenceAxis: Point2D;
        referenceOrientation: number;
        private _referenceMarker: google.maps.Marker;
        referenceMarker: google.maps.Marker;
        bounds: BoundsLatLng;
        helperLines: Helperline[] = [];
        readonly keyBoardSteps: ObservableValue;
        readonly interferenceLineThickness: ObservableValue;
        hoveredId: string = null;
        toolsDisabled: boolean;
        dragInfo: {
            id: string;
            start: Point2D;
            lastLatLng: LatLng;
            firstLatLng: LatLng;
            active: boolean;
        } = null;
        cmdInputVal = "";
        cmdEdgeLength = 0;
        showCoords = false;
        showAngle = false;
        showAngleWhileDrawing = true;
        showLengths = true;
        showCoordNumbers = false;
        coordsRelative = false;
        enableDepthCalculation = false;
        isActiveCorrectionRightAngle = true;

        mapDrawerRenderCallback: () => void;

        afterMapDrawerRender() {
            if (this.mapDrawerRenderCallback)
                this.mapDrawerRenderCallback();
        }

        toggleOsmTool(b) {
            this.mapDrawer.selectTool(b ? "OsmBuildingsTool" : null);
        }

        setEdgeLength(v: number | string) {
            var l = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    precision: 0,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.valueAdjustOpts.to,
                    to: "mm"
                });

            this.cmdEdgeLength = l;
        }

        onCommandEnter(input: HTMLInputElement) {
            var $input = $(input);

            var cmd = "" + $input.val();

            if (this.selectedToolName === 'DrawTool')
                this.currentTool.onCommandText(this.mapDrawer, cmd);

            setTimeout(() => {
                $input.val("");
                this.cmdInputVal = "";
            }, 0);

            $input.focus();
        }

        //setKeyBoardSteps(v: string | number) {
        //    this.keyBoardSteps = spt.Utils.GetFloatFromInputValue("" + v,
        //        {
        //            min: 0,
        //            max: 1000000,
        //            applyArithmetic: true,
        //            isFeet: window.UseImperialSystem,
        //            notImperial: !window.UseImperialSystem,
        //            from: this.valueAdjustOpts.to,
        //            to: "mm"
        //        });
        //}

        setInstance(id: string, item: ISelectableItem) {
            this.instances[id] = item;
            ko.valueHasMutated(this, 'instances');
        }

        hasInstance(id: string) {
            return !!this.instances[id];
        }

        removeInstance(id: string) {
            if (this.instances[id]) {
                delete this.instances[id];
                ko.valueHasMutated(this, 'instances');
            }
        }

        foreachSelected(fn: (s: ISelectableItem) => void, excludeId?: string, includeLocked?: boolean): void {
            this.selection.forEach(sel => {
                if (!sel._isDisposed && sel.id !== excludeId && (!sel.locked || includeLocked))
                    fn(sel);
            });
        }

        foreachItem(fn: (s: ISelectableItem) => void, excludeId?: string, includeLocked?: boolean): void {
            var layers = this.layer.all;

            for (var i = 0, j = layers.length; i < j; i++) {
                var layer = layers[i];
                layer.polygons.forEach(sel => {
                    if (!sel._isDisposed && sel.userData && sel.userData.id !== excludeId && (!sel.userData.locked || includeLocked))
                        fn(sel.userData as ShapeData);
                });
            }

            this.helperLines.forEach(sel => {
                if (!sel._isDisposed && sel.id !== excludeId)
                    fn(sel);
            });
        }

        _currentSubView: string = null;
        currentSubViewModel: ISubModelView;
        subViewModels: {
            importLayer: ImportLayerModel;
            backgroundSetting: ImportImageModel;
            referenceLength: ReferenceLengthModel;
            multipleRidges: MultipleRidgesModel;
        };

        saveDisabled: boolean;
        uploadDisabled: boolean;

        detailsViewVisible: boolean = false;
        detailsIconOffset: string;
        showOverlay = false;
        overlayTemplate: string = null;

        cadImportImageNeedsUpdate: boolean;
        isAlreadyLoadingCadImage: boolean;
        cadPreviewSrc: string = null;
        cadImportEnabled: boolean = false;

        UseImperialSystem: boolean;
        usemeteronroof: boolean;

        slopeAdjustOpts: spt.Utils.IFloatFromInputOptions;
        valueAdjustOpts: spt.Utils.IFloatFromInputOptions;
        scaleAdjustOpts: spt.Utils.IFloatFromInputOptions;
        rotateAdjustOpts: spt.Utils.IFloatFromInputOptions;

        updateCadImportImage() {
            this.cadImportImageNeedsUpdate = true;
            this.checkCadImportImage();
        }

        editBackground(img: ImageMapOverlay) {
            this.currentImportedImageId = img._id;
            this.mapDrawer.selectTool("ImportImageHelperTool");
        }

        private checkCadImportImage() {
            if (this.cadImportImageNeedsUpdate && !this.isAlreadyLoadingCadImage) {
                LoaderManager.addLoadingJob();

                this.cadImportImageNeedsUpdate = false;
                this.isAlreadyLoadingCadImage = true;

                if (!this.cadImportEnabled) {
                    this.isAlreadyLoadingCadImage = false;
                    this.cadPreviewSrc = null;
                    LoaderManager.finishLoadingJob();
                }

                setTimeout(() => {
                    var layer: string[] = [];
                    var frozen: boolean[] = [];
                    this.subViewModels.importLayer.layers.forEach(l => {
                        layer.push(l.name);
                        frozen.push(!l.isActive);
                    });
                    if (!$('.md-html-overlay-view', this.mapDrawer.view).length) {
                        this.isAlreadyLoadingCadImage = false;
                        this.cadPreviewSrc = null;
                        LoaderManager.finishLoadingJob();
                        this.checkCadImportImage();
                        return;
                    }
                    AManager.AjaxWithLoading('AppServices.asmx', 'SetMultipleCadImportLayerActiveDeactive', { layers: layer, frozen: frozen }, () => {
                        if (!$('.md-html-overlay-view', this.mapDrawer.view).length) {
                            this.isAlreadyLoadingCadImage = false;
                            this.cadPreviewSrc = null;
                            LoaderManager.finishLoadingJob();
                            this.checkCadImportImage();
                            return;
                        }

                        var cr = $('.md-html-overlay-view', this.mapDrawer.view).get(0).getBoundingClientRect();
                        var w = Math.round(+cr.width) - 20;
                        var h = Math.round(+cr.height) - 20;

                        spt.Utils.LoadImage(`/handler/ImageHandler.ashx?ID=CADImportPreview&size=${w}&height=${h}&Guid=${spt.Utils.GenerateGuid()}`,
                            (img) => {
                                this.isAlreadyLoadingCadImage = false;
                                this.cadPreviewSrc = img.src;
                                LoaderManager.finishLoadingJob();
                                this.checkCadImportImage();

                                //DynToolTip.showHowToInfo('_HowToImportDWG', 'howTo_mapDrawerViewWrapper', 'mapDrawerImportCad');
                            });

                    }, () => {
                        this.isAlreadyLoadingCadImage = false;
                        this.cadPreviewSrc = null;
                        LoaderManager.finishLoadingJob();
                        this.checkCadImportImage();
                    });
                }, 100);
            }
        }

        toggleSlideOut() {
            this.slideOutVisible = !this.slideOutVisible;
        }

        setSlideOutVisible(v) {
            this.slideOutVisible = !!v;
        }

        selectLayer(layer: PolygonLayer) {
            this.selectedLayerTypeName = layer ? (layer.typeName || null) : null;
            this.mapDrawer.updateDrawingManagerLayerStyles();
        }

        selectLayerByTypeName(layerTypeName: string) {
            this.selectedLayerTypeName = layerTypeName || null;
            this.mapDrawer.updateDrawingManagerLayerStyles();
        }

        viewLayer(layer: PolygonLayer) {
            this.mapDrawer.fitBounds(layer.polygons);
        }

        toggleDetailsPane() {
            this.detailsViewVisible = !this.detailsViewVisible;
        }

        beginFileUpload(data: any, ev: Event) {
            var input = (ev.target || ev.srcElement) as HTMLInputElement;

            if (!input || !input.value)
                return;

            this.mapDrawer.selectTool("SelectTool");

            AManager.UploadFile(input, "Dach", "UploadCADOrImageFileForDrawing", { paramName: 'fileBase' }, (result: IImportCadResult) => {

                if (!result || result.ErrorCode !== CadImportErrorCodeEnum.Success) {
                    DManager.showErrorMessage("ErrorCode: " + result.ErrorCode + " - " + spt.Utils.ConvertCamelCase(CadImportErrorCodeEnum[result.ErrorCode]));
                    return;
                }

                this.mapDrawer.setMapTypeId(MapDrawer.EmptyCanvas);

                if (result.IsCADImport) {

                    var importLayerModel = this.subViewModels.importLayer;

                    importLayerModel.importAsImage = false;
                    importLayerModel.isMetric = true;
                    importLayerModel.scale = 1;
                    importLayerModel.realWidth = +result.RealWidth;
                    importLayerModel.realHeight = +result.RealHeight;
                    importLayerModel.layers.removeAll();

                    var cadLayerOptions = result.CadLayerSelection.map((ln, idx) => new NameValue(ln, idx));

                    result.CadLayers.forEach(cl => importLayerModel.layers.push(new NameSelect(cl, cadLayerOptions)));

                    importLayerModel.layers.forEach(ns => {
                        ko.getObservable(ns, 'isActive').subscribe(() => { this.updateCadImportImage(); });
                    });

                    this.currentSubViewModel = this.subViewModels.importLayer;
                    this.detailsViewVisible = true;

                    this.cadPreviewSrc = null;
                    this.cadImportEnabled = true;
                    this.toolsDisabled = true;
                    this.updateCadImportImage();
                    this.showOverlay = true;
                    this.overlayTemplate = 'cadpreview-md-template';

                    DynToolTip.showHowToInfo('_HowToImportDWG', 'howTo_mapDrawerViewWrapper', 'mapDrawerImportCad');
                } else
                    this.mapDrawer.load(() => {
                        var self = this;
                        ko.tasks.runEarly();
                        //setTimeout(() => { this.mapDrawer.selectTool('ImportImageHelperTool'); }, 0);
                        setTimeout(() => { DynToolTip.showHowToInfo('_HowToImportImage', 'howTo_mapDrawerViewWrapper', 'mapDrawerImportImage', function () { self.mapDrawer.selectTool('ImportImageHelperTool'); }); }, 0);
                    });

                input.value = null;
            });

        }

        finishImportCad() {
            this.cadPreviewSrc = null;
            this.cadImportEnabled = false;
            this.toolsDisabled = false;
            //this.updateCadImportImage();
            this.showOverlay = false;
            this.overlayTemplate = null;
            this.currentSubViewModel = null;
            this.detailsViewVisible = false;

            this.mapDrawer.load();
        }

        readImportCad() {
            var importLayerModel = this.subViewModels.importLayer;
            var layerValues: number[] = [];
            var metric = !!importLayerModel.isMetric;
            var wcs = !!importLayerModel.iswcs;
            var roofFormId = $('input[name="Roof.RoofForm_Id"]').filter(':checked').val() || null;
            var scale = spt.Utils.GetFloatFromInputValue("" + importLayerModel.scale,
                {
                    min: 0,
                    isFeet: false,
                    notImperial: true
                });
            var roofHeight = 0;
            if ($("#Roof_RoofHeight").length) {
                roofHeight = spt.Utils.GetFloatFromInput("#Roof_RoofHeight",
                    {
                        min: 0,
                        max: 1000000,
                        applyArithmetic: false,
                        isFeet: window.UseImperialSystem,
                        notImperial: !window.UseImperialSystem,
                        from: this.valueAdjustOpts.to,
                        to: "mm"
                    }) || 0;
            }

            if (scale <= 0) {
                DManager.showErrorMessage("Scale is too small.");
                return;
            }

            importLayerModel.layers.forEach(l => {
                if (l.isActive)
                    layerValues.push(parseInt(l.selected.value));
            });

            AManager.AjaxWithLoading('AppServices.asmx', 'ReadCadImport', { layerValues: layerValues, metric: metric, scale: scale, wcs: wcs, roofFormIdStr: roofFormId, roofHeight: roofHeight }, this.onReadImportCadFinished.bind(this), this.onReadImportCadFinished.bind(this));
        }

        private onReadImportCadFinished(result: { Data: { Error: boolean, Message: string } }) {
            if (result && result.Data && result.Data.Error)
                DManager.showErrorMessage(result.Data.Message);
            else if (result && result.Data && result.Data.Message)
                console.log(result.Data.Message);

            this.finishImportCad();
        }

        onSelectionChanged(changes: { value: ISelectableItem, status: string }[]) {
            var multiShapeData = this.multiShapeData;

            changes.forEach((change) => {
                var item = change.value;
                if (item && !item._isDisposed && item instanceof ShapeData) {

                    switch (change.status) {
                        case "deleted":
                            multiShapeData.shapeDatas.remove(im => im.id === item.id);
                            break;
                        case "added":
                            multiShapeData.shapeDatas.push(item);
                            break;
                    }
                }
            });

            multiShapeData.detailsViewVisible = false;

            multiShapeData.updateStyle();
        }

        onSelectableClick(selectable: MapDrawing.ISelectableItem, ev: google.maps.MouseEvent) {
            var mapDrawer = this.mapDrawer;

            if (this.isDragging)
                return;

            if (mapDrawer.viewModel.selectedToolName === 'SelectTool' && selectable && !selectable.locked) {
                this.hoveredId = selectable.id;
                selectable.hovered = true;
                mapDrawer.doSelectSelectable(selectable);
                if (selectable instanceof ShapeData && selectable.children.length && ev.latLng)
                    selectable.selectChildren(LatLng.FromGMLatLng(ev.latLng));
            }
            else if (mapDrawer.viewModel.selectedToolName === 'EraserTool' && selectable && !selectable.locked)
                mapDrawer.deleteSelectable(selectable);

        }

        cleanUpDragInfo() {
            if (this.dragInfo)
                this.dragInfo = null;
        }

        onSelectableMouseover(selectable: MapDrawing.ISelectableItem) {
            if (selectable) {
                this.hoveredId = selectable.id;
                if (this.selectedToolName === 'SelectTool' && selectable && !selectable.locked && !this.isDragging)
                    selectable.hovered = true;
            }

        }

        onSelectableMouseout(selectable: MapDrawing.ISelectableItem) {
            if (selectable) {
                if (this.hoveredId === selectable.id)
                    this.hoveredId = null;
                if (this.selectedToolName === 'SelectTool' && selectable && !selectable.locked && !this.isDragging)
                    selectable.hovered = false;
            }
        }

        onPolygonMousedown(poly: IGoogleMapsPolygon, ev: MouseEvent) {
            var dragInfo = this.dragInfo;
            //console.log('onPolygonMousedown');

            if (dragInfo) {
                let shapeData = (<ShapeData>this.instances[dragInfo.id]);

                if (dragInfo.active && !shapeData._isDisposed)
                    this.onPolygonDragEnd(shapeData.poly);

                this.cleanUpDragInfo();
            }

            if (poly && !poly._isDisposed && this.selectedToolName === 'SelectTool' && poly.userData) {

                let data = poly.userData as ShapeData,
                    latLng = LatLng.FromGMLatLng(this.mapDrawer.GetLatLngFromMouseEvent(ev)),
                    start = latLng.ToPoint2D();

                if (data instanceof ShapeData && data.selected && !data.isOnEdgePoint(start)) {

                    this.dragInfo = {
                        id: poly.userData.id,
                        start: start,
                        lastLatLng: latLng,
                        firstLatLng: latLng,
                        active: false
                    };

                    ev.preventDefault();
                    ev.stopPropagation();
                }
            }

            //console.log(dragInfo);
        }


        onMousemove(ev: MouseEvent) {
            var dragInfo = this.dragInfo;
            if (dragInfo) {
                let mapDrawer = this.mapDrawer,
                    shapeData = (<ShapeData>this.instances[dragInfo.id]);

                if (!dragInfo.active && mapDrawer.GetPoint2DFromMouseEvent(ev).sub(dragInfo.start).mul(mapDrawer.getPixelFactor()).GetLengthSquared() > 100) {
                    dragInfo.active = true;
                    this.onPolygonDragStart(shapeData.poly);
                }
                if (dragInfo.active && !shapeData._isDisposed) {
                    var curLatLng = LatLng.FromGMLatLng(this.mapDrawer.GetLatLngFromMouseEvent(ev));

                    if (mapDrawer.shiftDown) {
                        var snap = Helperline.GetSnapByHelperlines(null, dragInfo.firstLatLng, curLatLng, mapDrawer, true, false);
                        curLatLng = snap.result;
                    }

                    shapeData.moveByLatLng(curLatLng.sub(dragInfo.lastLatLng));

                    this.onPolygonDragged(shapeData.poly);

                    dragInfo.lastLatLng = curLatLng;
                }
            }
        }

        /*
         onMousemove(ev: MouseEvent) {
	        var dragInfo = this.dragInfo;
	        if (dragInfo) {
		        let mapDrawer = this.mapDrawer,
			        shapeData = (<ShapeData>this.instances[dragInfo.id]);
		        console.log('onMousemove draginfo?');
		        //  && mapDrawer.GetPoint2DFromMouseEvent(ev).sub(dragInfo.start).mul(mapDrawer.getPixelFactor()).GetLengthSquared() > 100
		        if (!dragInfo.active) {
			        dragInfo.active = true;
			        console.log("onPolygonDragStart");
			        console.log(ev);
			        this.onPolygonDragStart(shapeData.poly);
		        }
		        if (dragInfo.active && !shapeData._isDisposed) {
			        console.log("onPolygonDragged");
			        var curLatLng = LatLng.FromGMLatLng(this.mapDrawer.GetLatLngFromMouseEvent(ev));

			        if (mapDrawer.shiftDown) {
				        var snap = Helperline.GetSnapByHelperlines(null, dragInfo.firstLatLng, curLatLng, mapDrawer, true, false);
				        curLatLng = snap.result;
			        }

			        shapeData.moveByLatLng(curLatLng.sub(dragInfo.lastLatLng));
			        this.onPolygonDragged(shapeData.poly);
			        dragInfo.lastLatLng = curLatLng;
		        }
	        }
        }
         */

        onMouseup(ev: MouseEvent) {
            var dragInfo = this.dragInfo;

            if (dragInfo) {
                let shapeData = (<ShapeData>this.instances[dragInfo.id]);

                if (dragInfo.active && !shapeData._isDisposed) {
                    this.onPolygonDragEnd(shapeData.poly);

                    ev.preventDefault();
                    ev.stopPropagation();
                }

                this.dragInfo = null;
            }
        }

        onPolygonDragStart(poly: IGoogleMapsPolygon) {
            this.isDragging = true;

            if (poly._isDisposed)
                return;

            poly.setOptions({
                editable: false,
                draggable: false
            });

            var data = poly.userData as ShapeData,
                paths = poly.getPaths();

            if (!data || !paths || !paths.getLength())
                return;

            var path = paths.getAt(0);

            if (path && path.getLength()) {
                data.dragPosition = LatLng.FromGMLatLng(path.getAt(0));

                if (data.typeName === window.BuildingTypeName)
                    this.foreachItem(sel => {
                        if (sel instanceof ShapeData)
                            sel.poly.setOptions({
                                editable: false,
                                draggable: false
                            });
                    }, data.id);
                else
                    this.foreachSelected(sel => {
                        if (sel instanceof ShapeData)
                            sel.poly.setOptions({
                                editable: false,
                                draggable: false
                            });
                    }, data.id);
            }

            //#region strg copy

            if (this.mapDrawer.ctrlDown && data instanceof ShapeData && data.selected) {

                var viewModel = this;
                var dataLayer = viewModel.layer.typeName[data.typeName];

                if (!dataLayer.controlledByParent && !dataLayer.locked) {
                    var tempData: PolyData[] = this.mapDrawer.tempPolyData = [PolyData.fromShapeData(data, spt.Utils.GenerateGuid())];

                    this.foreachSelected(sel => {
                        if (sel instanceof ShapeData) {
                            let layer = viewModel.layer.typeName[sel.typeName];
                            if (!layer.controlledByParent && !layer.locked) {
                                tempData.push(PolyData.fromShapeData(sel, spt.Utils.GenerateGuid()));
                            }
                        }
                    }, data.id);
                }

            }

            //#endregion
        }

        onPolygonDragged(poly: IGoogleMapsPolygon) {
            if (poly._isDisposed)
                return;

            var data = poly.userData as ShapeData,
                paths = poly.getPaths();

            if (!data || !paths || !paths.getLength())
                return;

            //#region strg copy
            console.log("dragging shape");
            if (this.mapDrawer.ctrlDown && data instanceof ShapeData && data.selected && this.mapDrawer.tempPolyData.length) {
                var viewModel = this;
                var dataLayer = viewModel.layer.typeName[data.typeName];

                if (!dataLayer.controlledByParent && !dataLayer.locked) {
                    var layerCounter: { [typeName: string]: number } = {};

                    viewModel.foreachSelected(sel => {
                        if (sel instanceof ShapeData) {
                            let layer = viewModel.layer.typeName[sel.typeName];
                            if (!layer.controlledByParent && !layer.locked) {
                                if (!layerCounter[sel.typeName])
                                    layerCounter[sel.typeName] = layer.polygons.length + 1;
                                sel.title = layer.getNewName(layerCounter[sel.typeName]++);
                            }
                        }
                    });

                    this.mapDrawer.tempPolyData.forEach(data => {
                        this.mapDrawer.addPolygonByData(data);
                    });
                }

                this.mapDrawer.tempPolyData = [];
            }

            //#endregion

            var path = paths.getAt(0);

            if (path && path.getLength() && data.dragPosition) {
                var curpos = LatLng.FromGMLatLng(path.getAt(0)),
                    delta = curpos.sub(data.dragPosition);

                if (data.typeName === window.BuildingTypeName)
                    this.foreachItem(sel => {
                        sel.moveByLatLng(delta);
                    }, data.id);
                else
                    this.foreachSelected(sel => {
                        sel.moveByLatLng(delta);
                    }, data.id);

                data.dragPosition = curpos;
            }
        }

        onPolygonDragEnd(poly: IGoogleMapsPolygon) {
            setTimeout(() => { this.isDragging = false; }, 0);
            this.mapDrawer.tempPolyData = [];

            if (poly._isDisposed) {
                if (poly.userData as ShapeData) {
                    poly.userData.update();
                    poly.userData.hovered = false;
                }
                return;
            }

            var data = poly.userData as ShapeData,
                paths = poly.getPaths();

            if (!data || !paths || !paths.getLength()) {
                if (poly.userData as ShapeData) {
                    poly.userData.update();
                    poly.userData.hovered = false;
                }
                return;
            }

            var path = paths.getAt(0);

            if (data && path && path.getLength() && data.dragPosition) {
                var curpos = LatLng.FromGMLatLng(path.getAt(0)),
                    delta = curpos.sub(data.dragPosition);

                if (data.typeName === window.BuildingTypeName)
                    this.foreachItem(sel => {
                        sel.moveByLatLng(delta);
                        if (sel instanceof ShapeData)
                            sel.updateStyle();
                        sel.update();
                    }, data.id);
                else
                    this.foreachSelected(sel => {
                        sel.moveByLatLng(delta);
                        if (sel instanceof ShapeData)
                            sel.updateStyle();
                        sel.update();
                    }, data.id);

                delete data.dragPosition;

                data.update();

                data.hovered = false;
                data.updateStyle();
            }


        }

    }

    export interface ISubModelView {
        template: string;
        name: string;
    }

    export class LayerHolder {
        constructor(mapDrawer: MapDrawer, editorMode: number) {

            switch (editorMode) {
                case MapDrawing.EditorModeEnum.Area:
                    {
                        this.all.push(this.area = this.referenceLayer = this.mainLayer = new PolygonLayer({
                            typeName: window.AreaTypeName,
                            layerName: window.AreaLayerName,
                            shapeStyle: { fillColor: "#a7ac00", strokeColor: "#2b4300" },
                            shapeHoveredStyle: { fillColor: "#a7ac00", strokeColor: "#2b4300" },
                            shapeSelectedStyle: { fillColor: "#486e00", strokeColor: "#2b4300" },
                            shapeSelectedHoveredStyle: { fillColor: "#486e00", strokeColor: "#2b4300" },
                            zIndex: 1,
                            maxCount: 1
                        }, mapDrawer));

                        this.all.push(this.interference = this.area.child = new PolygonLayer({
                            typeName: window.InterfernceTypeName,
                            layerName: window.InterfernceLayerName,
                            shapeStyle: { fillColor: "#07AC00", strokeColor: "#0B3818" },
                            shapeHoveredStyle: { fillColor: "#07AC00", strokeColor: "#0B3818" },
                            shapeSelectedStyle: { fillColor: "#135B27", strokeColor: "#0B3818" },
                            shapeSelectedHoveredStyle: { fillColor: "#135B27", strokeColor: "#0B3818" },
                            zIndex: 6,
                            parent: this.area
                        }, mapDrawer));
                    }
                    break;
                case MapDrawing.EditorModeEnum.FreeArea:
                    {
                        this.all.push(this.area = this.referenceLayer = new PolygonLayer({
                            typeName: window.AreaTypeName,
                            layerName: window.AreaLayerName,
                            shapeStyle: { fillColor: "#b5b5b5", strokeColor: "#9e0808", fillOpacity: 0.1 },
                            shapeHoveredStyle: { fillColor: "#b5b5b5", strokeColor: "#9e0808", fillOpacity: 0.1 },
                            shapeSelectedStyle: { fillColor: "#dbdbdb", strokeColor: "#9e0808", fillOpacity: 0.4 },
                            shapeSelectedHoveredStyle: { fillColor: "#dbdbdb", strokeColor: "#9e0808", fillOpacity: 0.4 },
                            zIndex: 1,
                            maxCount: 1,
                            isPassive: true,
                        }, mapDrawer));

                        this.all.push(this.freeArea = this.mainLayer = this.area.child = new PolygonLayer({
                            typeName: window.FreeAreaTypeName,
                            layerName: window.FreeAreaLayerName,
                            shapeStyle: { fillColor: "#00ac73", strokeColor: "#053133" },
                            shapeHoveredStyle: { fillColor: "#00ac73", strokeColor: "#053133" },
                            shapeSelectedStyle: { fillColor: "#32cf93", strokeColor: "#053133" },
                            shapeSelectedHoveredStyle: { fillColor: "#32cf93", strokeColor: "#053133" },
                            zIndex: 2,
                            parent: this.area
                        }, mapDrawer));

                        this.all.push(this.interference = this.freeArea.child = new PolygonLayer({
                            typeName: window.InterfernceTypeName,
                            layerName: window.InterfernceLayerName,
                            shapeStyle: { fillColor: "#314570", strokeColor: "#0b1221" },
                            shapeHoveredStyle: { fillColor: "#314570", strokeColor: "#0b1221" },
                            shapeSelectedStyle: { fillColor: "#133d9c", strokeColor: "#0b1221" },
                            shapeSelectedHoveredStyle: { fillColor: "#133d9c", strokeColor: "#0b1221" },
                            zIndex: 6,
                            parent: this.freeArea
                        }, mapDrawer));
                    }
                    break;
                default: //roof/building
                    {
                        this.all.push(this.building = this.referenceLayer = this.mainLayer = new PolygonLayer({
                            typeName: window.BuildingTypeName,
                            layerName: window.BuildingLayerName,
                            shapeStyle: { fillOpacity: 0 },
                            shapeHoveredStyle: { fillOpacity: 0 },
                            shapeSelectedStyle: { fillOpacity: 0 },
                            shapeSelectedHoveredStyle: { fillOpacity: 0 },
                            zIndex: 4,
                            maxCount: 1
                        }, mapDrawer));

                        this.all.push(this.roof = this.building.child = new PolygonLayer({
                            typeName: window.RoofTypeName,
                            layerName: window.RoofLayerName,
                            zIndex: 3,
                            parent: this.building,
                            controlledByParent: true
                        }, mapDrawer));

                        this.all.push(this.interference = this.roof.child = new PolygonLayer({
                            typeName: window.InterfernceTypeName,
                            layerName: window.InterfernceLayerName,
                            shapeStyle: { fillColor: "#07AC00", strokeColor: "#0B3818" },
                            shapeHoveredStyle: { fillColor: "#07AC00", strokeColor: "#0B3818" },
                            shapeSelectedStyle: { fillColor: "#135B27", strokeColor: "#0B3818" },
                            shapeSelectedHoveredStyle: { fillColor: "#135B27", strokeColor: "#0B3818" },
                            zIndex: 6,
                            parent: this.roof
                        }, mapDrawer));
                    }
                    break;
            }

            Object.keys(this).forEach(k => {
                if (k && this[k] && this[k] instanceof PolygonLayer) {
                    var l = this[k] as PolygonLayer;
                    this.typeName[l.typeName] = l;
                }
            });
        }

        referenceLayer: PolygonLayer = null;
        mainLayer: PolygonLayer = null;
        area: PolygonLayer = null;
        building: PolygonLayer = null;
        roof: PolygonLayer = null;
        roofArea: PolygonLayer = null;
        freeArea: PolygonLayer = null;
        interference: PolygonLayer = null;

        typeName: { [typeName: string]: PolygonLayer } = {};

        all: PolygonLayer[] = [];
    }

    export class NameValue {
        constructor(name: string, value?: any) {
            this.value = value !== undefined ? value : null;
            ko.track(this);

            this.name = name;
        }

        name: string;
        value: any;
    }

    export class NameSelect {
        constructor(name: string, options?: NameValue[], isActive?: boolean) {
            this.isActive = isActive === undefined ? true : !!isActive;
            this.options = options || [];
            this.selected = null;
            ko.track(this);

            this.name = name;
        }

        isActive: boolean;
        name: string;
        selected: NameValue;
        options: NameValue[];
    }

    export class ImportLayerModel implements ISubModelView {
        constructor(mapDrawer: MapDrawer) {
            ko.track(this);

            ko.defineProperty(this, 'allActive',
                {
                    get: () => {
                        return this.layers.every(l => l.isActive);
                    },
                    set: (b) => {
                        this.layers.forEach(l => { l.isActive = b; });
                    }
                });

            ko.defineProperty(this, 'scaledWidth', () => Math.round(this.realWidth * this.scale));
            ko.defineProperty(this, 'scaledHeight', () => Math.round(this.realHeight * this.scale));
        }

        name: string;
        importAsImage: boolean = false;
        allActive: boolean;
        template: string = 'importLayer-md-template';
        isMetric: boolean = true;
        scale: number = 1;
        iswcs: boolean = true;
        realWidth: number = 0;
        realHeight: number = 0;
        scaledWidth: number;
        scaledHeight: number;
        layers: NameSelect[] = [];
    }

    export class ImportImageModel implements ISubModelView {
        constructor(mapDrawer: MapDrawer) {
            ko.track(this);

            this.mapDrawer = mapDrawer;

            ko.defineProperty(this, 'pos',
                {
                    get: () => {
                        var img = this.mapDrawer.viewModel.currentImportedImage,
                            mapDrawer = this.mapDrawer;
                        if (!img || !mapDrawer)
                            return Point2D.Zero;
                        var refLatLng = mapDrawer.viewModel.referenceLatLng;
                        if (!refLatLng || !img._bounds)
                            return Point2D.Zero;
                        return img._bounds.Initialized ? refLatLng.DirectionTo(img._bounds.Min).mul(1000) : Point2D.Zero;
                    },
                    set: (p: Point2D) => {
                        var img = this.mapDrawer.viewModel.currentImportedImage,
                            mapDrawer = this.mapDrawer;
                        if (!img || !img._bounds || !mapDrawer)
                            return;

                        var t = p.sub(this.pos).mul(0.001);

                        img.setBounds(img._bounds.OffsetMeters(t.y, t.x));
                    }
                });

            ko.defineProperty(this, 'rotation',
                {
                    get: () => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        if (!img || !img._bounds)
                            return 0;
                        return -img._orientation;
                    },
                    set: (r: number) => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        if (!img || !img._bounds)
                            return;

                        img.setOrientation(-r);
                    }
                });

            ko.defineProperty(this, 'alpha',
                {
                    get: () => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        if (!img || !img._bounds)
                            return 0;
                        return img._alpha;
                    },
                    set: (r: number) => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        if (!img || !img._bounds)
                            return;

                        img.setAlpha(r);
                    }
                });

            ko.defineProperty(this, 'refLength',
                {
                    get: () => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        var imgTool = this.mapDrawer.viewModel.toolIcons["ImportImageHelperTool"] as ImportImageHelperTool;
                        if (!img || !img._bounds || !imgTool)
                            return 0;
                        return img._refLength;
                    },
                    set: (r: number) => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        var imgTool = this.mapDrawer.viewModel.toolIcons["ImportImageHelperTool"] as ImportImageHelperTool;
                        if (!img || !img._bounds || !imgTool)
                            return;

                        var rl = this.refLength;

                        if (r > 0 && rl > 0) {
                            var sc = r / rl;

                            var s = imgTool.Start.ToPoint2D();
                            var e = imgTool.End.ToPoint2D();

                            var c = e.add(s).mul(0.5);
                            //var t = e.sub(s);

                            var transform = Transformation3D.Translation(-c.x, -c.y).multiply(Transformation3D.Scale(sc, sc)).multiply(Transformation3D.Translation(c.x, c.y));

                            img.setBounds(new BoundsLatLng(LatLng.FromPoint2D(transform.transform(img._bounds.Min.ToPoint2D())), LatLng.FromPoint2D(transform.transform(img._bounds.Max.ToPoint2D()))));

                            imgTool.setStartEnd(LatLng.FromPoint2D(transform.transform(s)), LatLng.FromPoint2D(transform.transform(e)));
                        }
                    }
                });

            ko.defineProperty(this, 'scale',
                {
                    get: () => {
                        return this._scale;
                    },
                    set: (v: number) => {
                        var img = this.mapDrawer.viewModel.currentImportedImage;
                        if (!img || !img._bounds)
                            return 0;
                        var s = v / this._scale;
                        this._scale = v;
                        if (s != 1) {
                            img.setBounds(img._bounds.Scale(s));
                        }
                    }
                });
        }

        name: string;
        template: string = 'bg-details-md-template';

        mapDrawer: MapDrawer;
        refLength: number;
        rotation: number;
        alpha: number;
        pos: Point2D;
        private _scale: number = 1;
        scale: number;

        setRefLength(v: number | string) {
            var l = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.refLength = l;
        }

        setPosX(v: string | number) {
            var y = this.pos.y;
            var x = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.pos = new Point2D(x, y);
        }

        setPosY(v: string | number) {
            var x = this.pos.x;
            var y = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.pos = new Point2D(x, y);
        }

        setRotation(v: string | number) {
            var r = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -360,
                    max: 360,
                    applyArithmetic: true,
                    notImperial: true
                });
            this.rotation = r;
        }

        setAlpha(v: string | number) {
            var s = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 100,
                    applyArithmetic: true,
                    notImperial: true,
                    scale: 1 / 100
                });
            this.alpha = s;
        }

        setScale(v: string | number) {
            var s = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    applyArithmetic: true,
                    notImperial: true,
                    scale: 1 / 100
                });
            this.scale = s;
        }
    }

    export enum ReferenceLengthScaleMode {
        scale = 0,
        scaleAlongEdge = 1,
        stretch = 2,
        expandLeft = 3,
        expandRight = 4
    }

    export class ReferenceLengthModel implements ISubModelView {
        name: string;
        template: string = 'rf-len-md-template';

        orientation = 0;
        slope = 0;

        mapDrawer: MapDrawer;

        readonly refLength: number;
        newLength: number = 0;

        readonly refInclinedLength: number;
        newInclinedLength: number;

        scaleImage = true;
        hasImage = false;
        imageBounds: BoundsLatLng;
        scaleEdgeMode: number = 0;
        scaleEdgeModes: { name: string, val: number }[];
        private shapeBackups: SolarProTool.MapShape[] = [];
        private selectedPolyId: string = null;
        //private previousTransform: { oldLength: number, newLength: number, scaleAlongEdge: boolean };

        private GetReferenceLengthTool() {
            return this.mapDrawer.viewModel.toolIcons["ReferenceLengthTool"] as ReferenceLengthTool;
        }

        origStart: LatLng = new LatLng();
        origEnd: LatLng = new LatLng();
        Start: LatLng = new LatLng();
        End: LatLng = new LatLng();

        constructor(mapDrawer: MapDrawer) {
            ko.track(this);

            this.scaleEdgeModes = window.MdScaleEdgeModes;

            this.mapDrawer = mapDrawer;

            ko.defineProperty(this, 'refLength', () => {
                var s = this.Start,
                    e = this.End;

                return s.DistanceTo(e) * 1000;
            });

            ko.defineProperty(this, 'refInclinedLength', () => {

                var s = this.Start,
                    e = this.End,
                    slope = Math.abs(this.slope);

                if (slope <= 0 || slope > 89)
                    return s.DistanceTo(e) * 1000;

                var d = s.DirectionTo(e),
                    dir = Point2D.FromOrientation(this.orientation),
                    dx = Math.abs(dir.dot(d)),
                    dy = Math.abs(dir.LeftHandNormal().dot(d) / Math.cos(slope / 180 * Math.PI));

                return Math.sqrt(dx * dx + dy * dy) * 1000;
            });

            ko.defineProperty(this, 'newInclinedLength',
                {
                    get: () => {
                        return this.newLength * this.refInclinedLength / this.refLength;
                    },
                    set: (v: number) => {
                        this.newLength = v * this.refLength / this.refInclinedLength;
                    }
                });
        }

        setNewLength(v: number | string) {
            var l = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.newLength = l;
        }

        setNewInclinedLength(v: number | string) {
            var l = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.newInclinedLength = l;
        }

        revertPrevious() {
            if (this.shapeBackups.length)
                this.mapDrawer.importShapes(this.shapeBackups);
            if (this.imageBounds) {
                var img = this.mapDrawer.viewModel.currentImportedImage;
                if (img)
                    img.setBounds(this.imageBounds.Clone());
            }
            this.Start = this.origStart.Clone();
            this.End = this.origEnd.Clone();
        }

        initLengths(start: LatLng, end: LatLng, shapeDataId: string, orientation: number, slope: number) {
            var mapDrawer = this.mapDrawer;
            if (!mapDrawer.viewModel.hasInstance(shapeDataId))
                return;
            this.orientation = orientation;
            this.slope = slope;

            this.Start = start.Clone();
            this.End = end.Clone();
            this.origStart = start.Clone();
            this.origEnd = end.Clone();

            this.selectedPolyId = shapeDataId;
            var shapeData = mapDrawer.viewModel.instances[shapeDataId] as ShapeData;
            this.shapeBackups.removeAll();
            this.shapeBackups.push(mapDrawer.exportShape(shapeData));

            if (shapeData.typeName === window.BuildingTypeName && shapeData.mType === 'ShapeData') {
                //add interference fields
                mapDrawer.viewModel.foreachItem(sel => {
                    if (sel instanceof ShapeData && sel.typeName === window.InterfernceTypeName) {
                        this.shapeBackups.push(mapDrawer.exportShape(sel));
                    }
                }, shapeData.id);
            }

            this.newLength = this.refLength;
            var img = mapDrawer.viewModel.currentImportedImage;
            this.imageBounds = null;

            if (img && img._bounds)
                this.imageBounds = img.getBounds().Clone();

            this.hasImage = !!img && !!this.imageBounds;

            //this.previousTransform = null;
        }

        revertNewLength() {
            this.revertPrevious();
            this.GetReferenceLengthTool().updatePolyline(this.Start, this.End, this.mapDrawer);
            ko.tasks.runEarly();
            this.newLength = this.refLength;
        }

        applyNewLength() {
            setTimeout(() => {
                ko.tasks.runEarly();
                var oldLength = this.refLength,
                    newLength = this.newLength;

                if (oldLength > 0 && newLength > 0 && oldLength !== newLength) {
                    this.revertPrevious();
                    ko.tasks.runEarly();
                    //this.previousTransform = { oldLength: this.refLength, newLength: newLength, scaleAlongEdge: this.scaleEdgeMode === ReferenceLengthScaleMode.scaleAlongEdge };
                    this.applyTransform(this.Start, this.End, newLength, this.scaleEdgeMode);
                }
            }, 0);
        }

        applyTransform(start: LatLng, end: LatLng, newLength: number, mode: number) {
            var oldLength = start.DistanceTo(end) * 1000;

            if (oldLength > 0 && newLength > 0 && oldLength !== newLength && this.shapeBackups.length && this.shapeBackups[0].IdString === this.selectedPolyId && this.mapDrawer.viewModel.hasInstance(this.selectedPolyId)) {

                var s = start.ToPoint2D(),
                    e = end.ToPoint2D(),
                    sc = newLength / oldLength,
                    center = e.add(s).mul(0.5);

                var mapDrawer = this.mapDrawer,
                    refLenTool = this.GetReferenceLengthTool(),
                    shapeData = this.mapDrawer.viewModel.instances[this.selectedPolyId] as ShapeData;

                if (shapeData) {
                    if (shapeData.controlledByParent)
                        shapeData = shapeData.parent;

                    switch (mode) {
                        case ReferenceLengthScaleMode.scaleAlongEdge:
                        case ReferenceLengthScaleMode.scale:
                            {
                                var transform: Transformation3D;
                                if (mode === ReferenceLengthScaleMode.scaleAlongEdge) {
                                    var se = e.sub(s),
                                        rad = Math.atan2(se.y, se.x);
                                    transform = Transformation3D.Translation(-center.x, -center.y).multiply(Transformation3D.Rotation(-rad)).multiply(Transformation3D.Scale(sc, 1)).multiply(Transformation3D.Rotation(rad)).multiply(Transformation3D.Translation(center.x, center.y));
                                } else
                                    transform = Transformation3D.Translation(-center.x, -center.y).multiply(Transformation3D.Scale(sc, sc)).multiply(Transformation3D.Translation(center.x, center.y));

                                if (shapeData.typeName === window.BuildingTypeName && shapeData.mType === 'ShapeData') {
                                    //apply to interferences
                                    mapDrawer.viewModel.foreachItem(sel => {
                                        if (sel instanceof ShapeData && sel.typeName === window.InterfernceTypeName) {
                                            sel.applyTransform(transform, mapDrawer, null, true);
                                        }
                                    }, shapeData.id);
                                }

                                shapeData.applyTransform(transform, mapDrawer, null, true);

                                this.Start = LatLng.FromPoint2D(transform.transform(s));
                                this.End = LatLng.FromPoint2D(transform.transform(e));

                                var img = this.mapDrawer.viewModel.currentImportedImage;
                                if (img && this.hasImage && this.scaleImage && mode === ReferenceLengthScaleMode.scale)
                                    img.setBounds(new BoundsLatLng(LatLng.FromPoint2D(transform.transform(img._bounds.Min.ToPoint2D())), LatLng.FromPoint2D(transform.transform(img._bounds.Max.ToPoint2D()))));
                            }
                            break;
                        case ReferenceLengthScaleMode.stretch:
                            {
                                var d = e.sub(s),
                                    dLen = d.GetLength(),
                                    dir = d.divide(dLen),
                                    maxOff = Math.abs(dLen * sc - dLen) * 0.5;

                                var sdt = dir.dot(s.sub(center)),
                                    soff = sdt * sc - sdt,
                                    edt = dir.dot(e.sub(center)),
                                    eoff = edt * sc - edt;

                                this.Start = LatLng.FromPoint2D(s.add(dir.mul(Math.max(-maxOff, Math.min(maxOff, soff)))));
                                this.End = LatLng.FromPoint2D(e.add(dir.mul(Math.max(-maxOff, Math.min(maxOff, eoff)))));

                                shapeData.poly.setPaths(shapeData.getBorderPoints().map(ps => ps.map(p => {
                                    var dt = dir.dot(p.sub(center)),
                                        off = dt * sc - dt;
                                    return LatLng.Point2DToGMLatLng(p.add(dir.mul(Math.max(-maxOff, Math.min(maxOff, off)))));
                                })));

                                if (shapeData.separationPolylines && shapeData.separationPolylines.length) {
                                    shapeData.separationPolylines.forEach(sp => {
                                        sp.polyline.setPath(sp.polyline.getPath().getArray().map<google.maps.LatLng>(ll => {
                                            var p = LatLng.GMLatLngToPoint2D(ll),
                                                dt = dir.dot(p.sub(center)),
                                                off = dt * sc - dt;
                                            return LatLng.Point2DToGMLatLng(p.add(dir.mul(Math.max(-maxOff, Math.min(maxOff, off)))));
                                        }));
                                    });
                                }

                                if (shapeData.typeName === window.BuildingTypeName && shapeData.mType === 'ShapeData') {
                                    //apply to interferences
                                    mapDrawer.viewModel.foreachItem(sel => {
                                        if (sel instanceof ShapeData && sel.typeName === window.InterfernceTypeName) {

                                            sel.poly.setPaths(sel.getBorderPoints().map(ps => ps.map(p => {
                                                var dt = dir.dot(p.sub(center)),
                                                    off = dt * sc - dt;
                                                return LatLng.Point2DToGMLatLng(p.add(dir.mul(Math.max(-maxOff, Math.min(maxOff, off)))));
                                            })));

                                            sel.update();
                                        }
                                    }, shapeData.id);
                                }

                                shapeData.update();
                            }
                            break;
                        case ReferenceLengthScaleMode.expandRight:
                            {
                                let np = LatLng.FromPoint2D(e.add(s.sub(e).mul(sc)));
                                shapeData.movePoint(start, np, null);
                                shapeData.update();

                                this.Start = np;
                                this.End = end.Clone();
                            }
                            break;
                        case ReferenceLengthScaleMode.expandLeft:
                            {
                                let np = LatLng.FromPoint2D(s.add(e.sub(s).mul(sc)));
                                shapeData.movePoint(end, np, null);
                                shapeData.update();

                                this.Start = start.Clone();
                                this.End = np;
                            }
                            break;
                    }

                    ko.tasks.runEarly();

                    refLenTool.updatePolyline(this.Start, this.End, mapDrawer);
                }

            }
        }

    }

    export class MultipleRidgesModel implements ISubModelView {
        name: string;
        template: string = 'rf-mul-ridge-md-template';

        mapDrawer: MapDrawer;
        count: number = 3;

        constructor(mapDrawer: MapDrawer) {
            ko.track(this);

            this.mapDrawer = mapDrawer;
        }

        getCount(): number {
            return spt.Utils.GetFloatFromInputValue("" + this.count, { min: 2 });
        }

        incrementCount(val: number): void {
            this.count = Math.round(Math.max(2, this.getCount() + val));
        }
    }
}