module MapDrawing.Ajax {

	const cacheKeyMapDrawerData = 'MapDrawerData';

	var initialized = false;

	export function init() {
		if (initialized)
			return;
		initialized = true;

		var bindings: { [url: string]: (data?: any) => any } = {
			"/Anordnung3DService.asmx/GetOsmBuildingsBounds": null,
			"/WebServices/MapServices.asmx/DeleteImportedImages": null,
			"/WebServices/MapServices.asmx/EditImportedImage": null,
			"/WebServices/MapServices.asmx/GetDataForMapDrawer": GetDataForMapDrawer,
			"/WebServices/MapServices.asmx/GetImportedImagesForMapDrawer": null,
			"/WebServices/MapServices.asmx/SaveMapDrawerData": SaveMapDrawerData,
			"/WebServices/MapServices.asmx/TestMapping": null
		};

		AManager.RegisterAjaxBindings(bindings);
	}

	export async function GetDataForMapDrawer() {
		let defaultData: SolarProTool.MapDrawerData = {
			mapTypeId: null,
			Shapes: [],
			ImportedImages: [],
			ImageId: null,
			ImageWidth: 0,
			ImageHeight: 0,
			ImageLatitude: 0,
			ImageLongitude: 0,
			ImageOrientation: 0,
			GhostBorders: [],
			MapsImageData: null,
			MapsImageMinLatitude: 0,
			MapsImageMinLongitude: 0,
			MapsImageMaxLatitude: 0,
			MapsImageMaxLongitude: 0
		};

		var mapDrawerData = await localforage.getItem<SolarProTool.MapDrawerData>(cacheKeyMapDrawerData);

		return mapDrawerData || defaultData;
	};

	export async function SaveMapDrawerData(d: { data: SolarProTool.MapDrawerData }) {
		await localforage.setItem(cacheKeyMapDrawerData, d.data);
	}
}