module MapDrawing {
    export class SelectTool extends BaseToolIcon {
        iconX = 0;
        iconY = 0;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            //mapDrawer.map.setOptions({ draggable: true });
        }

        onDeselect(mapDrawer: MapDrawer) {
            mapDrawer.disableRectangleSelect();
            //mapDrawer.map.setOptions({ draggable: false });
        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            //mapDrawer.map.setOptions({ draggable: true });
            if (!mapDrawer.shiftDown && !mapDrawer.ctrlDown)
                mapDrawer.clearSelection();
        }
    }

    export class HandTool extends BaseToolIcon {
        iconX = 7;
        iconY = 0;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            //mapDrawer.map.setOptions({ draggable: true });
        }

        onDeselect(mapDrawer: MapDrawer) {
            mapDrawer.disableRectangleSelect();
            //mapDrawer.map.setOptions({ draggable: false });
        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            //mapDrawer.map.setOptions({ draggable: true });
        }
    }

    export class SelectRectTool extends BaseToolIcon {
        iconX = 12;
        iconY = 4;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            mapDrawer.enableRectangleSelect();
        }

        onDeselect(mapDrawer: MapDrawer) {
            mapDrawer.disableRectangleSelect();
        }
    }

    export class DrawTool extends BaseToolIcon {
        constructor(mapDrawer: MapDrawer, name: string) {
            super(mapDrawer, name);
            //this.Cursor = 'crosshair';
            this.Cursor = `url("${mapDrawer.options.pathToIcons}/cursor/edit.cur"), default`;
        }

        static angleStep = Math.PI / 12;

        iconX = 13;
        iconY = 4;
        subMenu = [
            //new BaseSubToolIcon('Polygon', 9, 4),
            //new BaseSubToolIcon('Rectangle', 10, 4),
            //new BaseSubToolIcon('Circle', 11, 4),
            //new BaseSubToolIcon('Line', 1, 7)
            //new BaseSubToolIconNew('Polygon', 'ToolbarIcon-DrawPloy', 2), //'ToolbarIcon-DrawPloy' // default
            //new BaseSubToolIconNew('Rectangle', 'ToolbarIcon-DrawRect', 2), //ToolbarIcon-DrawRect
            //new BaseSubToolIconNew('Circle', 'ToolbarIcon-DrawCircle',2), //ToolbarIcon-DrawCircle
            //new BaseSubToolIconNew('Line', 'ToolbarIcon-MultiTraufe',2) //ToolbarIcon-MultiTraufe
        ];

        step: number = 0;

        polyline: google.maps.Polygon | google.maps.Polyline = null;
        startMarker: google.maps.Marker = null;
        hoverStartMarker: boolean = false;
        snapMarker: google.maps.Marker = null;
        snapped: boolean = false;
        measureTexts: OverlayText[] = [];
        angleTexts: OverlayText[] = [];
        coordsTexts: OverlayText[] = [];
        coordsNumberTexts: OverlayText[] = [];

        isMouseMoved = false;
        isMouseDown = false;
        mouseDownPoint: google.maps.LatLng = null;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            //mapDrawer.map.setOptions({ draggable: true });

            this.curSubmenu = subMenu = subMenu || 'Polygon';

            var layer = mapDrawer.viewModel.currentLayer;
            if (!layer || layer.locked) {
                mapDrawer.selectTool("SelectTool");
                return;
            }

            mapDrawer.setSeparationLinesFrozen(true);

            this.step = 0;
            this.hoverStartMarker = false;
            this.measureTexts = [];
            mapDrawer.viewModel.cmdInputVal = "";

            mapDrawer.map.setOptions({ draggableCursor: this.Cursor });

            mapDrawer.setDrawingMode(true);

            var polyLineOpts: google.maps.PolylineOptions = {
                map: mapDrawer.map,
                draggable: false,
                editable: false,
                clickable: false,
            };

            switch (subMenu) {
                case 'Rectangle':
                    this.polyline = new google.maps.Polygon($.extend(polyLineOpts, layer.shapeSelectedStyle) as google.maps.PolygonOptions);
                    this.measureTexts = [mapDrawer.createOverlayText(), mapDrawer.createOverlayText()];
                    break;
                case 'Circle':
                    this.polyline = new google.maps.Polyline($.extend(polyLineOpts, layer.shapeSelectedStyle) as google.maps.PolygonOptions);
                    this.measureTexts = [mapDrawer.createOverlayText()];
                    break;
                default: // 'Line', 'Polygon'
                    this.polyline = new google.maps.Polyline($.extend(polyLineOpts, layer.shapeSelectedStyle) as google.maps.PolylineOptions);
                    break;
            }

            this.createSnapMarker(mapDrawer);

            if (mapDrawer.viewModel.cmdEdgeLength <= 0)
                $('#md-input-EdgeLength').val("");
        }

        onDeselect(mapDrawer: MapDrawer) {
            if (this.startMarker) {
                google.maps.event.clearListeners(this.startMarker, 'click');
                this.startMarker.setMap(null);
                this.startMarker = null;
            }
            if (this.snapMarker) {
                google.maps.event.clearListeners(this.snapMarker, 'click');
                this.snapMarker.setMap(null);
                this.snapMarker = null;
            }
            if (this.polyline) {
                this.polyline.setMap(null);
                this.polyline = null;
            }
            if (this.measureTexts.length) {
                this.measureTexts.forEach(ot => { ot.dispose(); });
                this.measureTexts = [];
            }
            if (this.angleTexts.length) {
                this.angleTexts.forEach(ot => { ot.dispose(); });
                this.angleTexts = [];
            }
            if (this.coordsTexts.length) {
                this.coordsTexts.forEach(ot => { ot.dispose(); });
                this.coordsTexts = [];
            }
            if (this.coordsNumberTexts.length) {
                this.coordsNumberTexts.forEach(ot => { ot.dispose(); });
                this.coordsNumberTexts = [];
            }

            mapDrawer.setSeparationLinesFrozen(false);

            this.hoverStartMarker = false;
            mapDrawer.viewModel.cmdInputVal = "";

            mapDrawer.map.setOptions({ draggableCursor: null });

            mapDrawer.setDrawingMode(false);
        }

        onstartMarkerMouseover(mapDrawer: MapDrawer) {
            this.hoverStartMarker = true;
        }

        onstartMarkerMouseout(mapDrawer: MapDrawer) {
            this.hoverStartMarker = false;
        }

        isSnapping(mapDrawer, first: google.maps.LatLng, second: google.maps.LatLng, pixelDistance: number): boolean {
            // checks in touch-device if snapping on start
            let start = mapDrawer.fromLatLngToPixel(first);
            let startPoint = new Point2D(start.x, start.y);

            let current = mapDrawer.fromLatLngToPixel(second);
            let currentPoint = new Point2D(current.x, current.y);

            let distance = startPoint.DistanceTo(currentPoint);
            let isSnapping = distance <= pixelDistance;
            return isSnapping;
        }

        // onMouseDown
        onClick(ev: { latLng: google.maps.LatLng }, mapDrawer: MapDrawer) {
            this.isMouseDown = true;
            this.isMouseMoved = false;
            this.mouseDownPoint = ev.latLng;

            if ($.isTouchCapable()) {
                if (this.polyline && ev.latLng) {
                    if (this.curSubmenu === 'Rectangle' || this.curSubmenu === 'Circle')
                        mapDrawer.map.setOptions({ draggable: false })

                    let path = this.polyline.getPath();
                    if (this.curSubmenu === 'Rectangle') {
                        let gmevLatLng = this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng();
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                        this.step = 1;
                    }
                    else if (this.curSubmenu === 'Circle') {
                        let gmevLatLng = this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng();
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                        this.step = 0; //1
                    }
                }
            }
        }

        onMousemove(ev: { latLng: google.maps.LatLng }, mapDrawer: MapDrawer, ignoreTouchSnapping?: boolean) {
            //INFO ev.isDragging: boolean

            //if (!ignoreTouchSnapping && $.isTouchCapable()) {
            //    let isSnapping = this.isSnapping(mapDrawer, this.mouseDownPoint, ev.latLng, 6);
            //    //if (isSnapping)
            //    //    this.isMouseMoved = false;
            //    //return;
            //}

            if ($.isTouchCapable() && (curSubmenu === 'Rectangle' || curSubmenu === 'Circle') && this.isMouseDown && !this.isMouseMoved) {
                if (mapDrawer.viewModel.showLengths)
                    this.measureTexts.push(mapDrawer.createOverlayText());

                let path = this.polyline.getPath();
                path.push(ev.latLng);

                if (mapDrawer.viewModel.showCoords)
                    this.coordsTexts.push(mapDrawer.createOverlayText());
                if (mapDrawer.viewModel.showCoordNumbers) {
                    var coorNumText = mapDrawer.createOverlayText();
                    mapDrawer.setCoordNumberText(coorNumText, new LatLng(), path.getLength(), true);
                    this.coordsNumberTexts.push(coorNumText);
                }
            }

            var polyLine = this.polyline,
                curSubmenu = this.curSubmenu;
            if (polyLine && ev.latLng) {
                var path = this.polyline.getPath();
                if (curSubmenu === 'Rectangle') {
                    //rectangle
                    if (path.getLength() <= 0) {
                        if (this.snapMarker)
                            this.snapMarker.setPosition(this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng());
                    } else {
                        let evLatLng = this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer);
                        if (this.snapMarker)
                            this.snapMarker.setPosition(evLatLng.ToGMLatLng());
                        this.makeRectangle(path, evLatLng, mapDrawer);
                    }
                }
                else if (curSubmenu === 'Circle') {
                    //circle
                    if (path.getLength() <= 0) {
                        if (this.snapMarker)
                            this.snapMarker.setPosition(this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng());
                    } else {
                        var ll0 = path.getLength() > 0 ? LatLng.FromGMLatLng(path.getAt(0)) : null;
                        let evLatLng = this.getNextLatLng(null, ll0, LatLng.FromGMLatLng(ev.latLng), mapDrawer);
                        if (this.snapMarker)
                            this.snapMarker.setPosition(evLatLng.ToGMLatLng());
                        this.makeCircle(path, evLatLng, mapDrawer);
                    }
                }
                else {
                    //Line or Polygon
                    var len = path.getLength();
                    if (len) {
                        let prevPrev = len > 2 ? LatLng.FromGMLatLng(path.getAt(len - 3) as google.maps.LatLng) : null;
                        let llprev = len > 1 ? LatLng.FromGMLatLng(path.getAt(len - 2) as google.maps.LatLng) : null;
                        let evLatLng = this.getNextLatLng(prevPrev, llprev, LatLng.FromGMLatLng(ev.latLng), mapDrawer);

                        if (curSubmenu !== 'Line' && this.hoverStartMarker && this.startMarker && len > 2)
                            evLatLng = LatLng.FromGMLatLng(this.startMarker.getPosition());

                        if (this.snapMarker)
                            this.snapMarker.setPosition(evLatLng.ToGMLatLng());

                        if (ignoreTouchSnapping || !$.isTouchCapable()) {
                            path.setAt(len - 1, evLatLng.ToGMLatLng());

                            if (llprev && evLatLng && this.measureTexts.length)
                                mapDrawer.setMeasureText(this.measureTexts[this.measureTexts.length - 1], llprev, evLatLng);

                            if (len > 2 && this.angleTexts.length)
                                mapDrawer.setAngleText(this.angleTexts[this.angleTexts.length - 1], LatLng.FromGMLatLng(path.getAt(len - 3) as google.maps.LatLng), llprev, evLatLng);

                            if (this.coordsTexts.length)
                                mapDrawer.setCoordsText(this.coordsTexts[this.coordsTexts.length - 1], evLatLng);

                            if (this.coordsNumberTexts.length)
                                this.coordsNumberTexts[this.coordsNumberTexts.length - 1].set({
                                    latLng: evLatLng,
                                    visible: true
                                });
                        }


                    } else if (this.snapMarker)
                        this.snapMarker.setPosition(this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng());
                }
            } else if (this.snapMarker)
                this.snapMarker.setPosition(this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng());

            if (this.snapMarker && this.snapped !== this.snapMarker.getVisible())
                this.snapMarker.setVisible(this.snapped);

            this.isMouseMoved = true;
        }

        onMouseUp(ev: { latLng: google.maps.LatLng }, mapDrawer: MapDrawer) {
            mapDrawer.map.setOptions({ draggable: true })

            if (!$.isTouchCapable() && (this.curSubmenu === 'Rectangle' || this.curSubmenu === 'Circle') && (this.isMouseMoved || this.isDragging))
                return;

            if (this.curSubmenu !== 'Rectangle' && this.curSubmenu !== 'Circle' && this.isMouseDown && (this.isMouseMoved || this.isDragging))
                return;

            if ($.isTouchCapable() && (this.curSubmenu === 'Rectangle' || this.curSubmenu === 'Circle') && this.isMouseDown && !this.isMouseMoved && !this.isDragging) {
                // if circle or rectangle is not dragged on mobile then reset tue current path points!
                // todo show toast message: please press and drag the finger to draw!
                let path = this.polyline.getPath();
                path.clear();
                return;
            }


            if ($.isTouchCapable())
                this.onMousemove(ev, mapDrawer, true);

            var polyLine = this.polyline;
            if (polyLine && ev.latLng) {
                //var latLng = LatLng.FromGMLatLng(ev.latLng);
                let path = this.polyline.getPath();

                if (this.curSubmenu === 'Rectangle') {
                    //rectangle
                    if (path.getLength() <= 0) {
                        let gmevLatLng = this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng();
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                    }
                    else {
                        this.makeRectangle(path, this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer), mapDrawer);
                        var w = LatLng.FromGMLatLng(path.getAt(0)).DistanceTo(LatLng.FromGMLatLng(path.getAt(1)));
                        var h = LatLng.FromGMLatLng(path.getAt(1)).DistanceTo(LatLng.FromGMLatLng(path.getAt(2)));
                        if (w <= 0 || h <= 0)
                            this.polyline = null;
                        this.onPolylineClosed(mapDrawer);
                    }

                }
                else if (this.curSubmenu === 'Circle') {
                    //circle
                    if (path.getLength() <= 0) {
                        //this.polyline = null;
                        let gmevLatLng = this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer).ToGMLatLng();
                        path.push(gmevLatLng);
                        path.push(gmevLatLng);
                    }
                    else {
                        //if (path.getLength() <= 0)
                        //    this.polyline = null;
                        //else {
                        var ll0 = LatLng.FromGMLatLng(path.getAt(0));
                        this.makeCircle(path, this.getNextLatLng(null, ll0, LatLng.FromGMLatLng(ev.latLng), mapDrawer), mapDrawer);
                        var w = ll0.DistanceTo(LatLng.FromGMLatLng(path.getAt(1)));
                        if (w <= 0)
                            this.polyline = null;
                    }
                    this.onPolylineClosed(mapDrawer);
                    //}
                }
                else {
                    //Line or Polygon
                    if (!path.getLength()) {

                        let evLatLng = this.getNextLatLng(null, null, LatLng.FromGMLatLng(ev.latLng), mapDrawer);

                        var icon: google.maps.Symbol = {
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 8,
                            strokeWeight: 4,
                            strokeOpacity: 0,
                            fillOpacity: 1,
                            fillColor: '#fff'
                        };

                        var layer = mapDrawer.viewModel.currentLayer;
                        if (layer) {
                            icon.strokeColor = layer.shapeSelectedStyle.strokeColor;
                            icon.strokeOpacity = layer.shapeSelectedStyle.strokeOpacity;
                        }

                        this.startMarker = new google.maps.Marker({
                            map: mapDrawer.map,
                            clickable: true,
                            draggable: false,
                            position: evLatLng.ToGMLatLng(),
                            icon: icon,
                            zIndex: 10
                        });

                        this.snapMarker.setPosition(evLatLng.ToGMLatLng());

                        this.startMarker.addListener('click', this.onPolylineClosed.bind(this, mapDrawer));
                        this.startMarker.addListener('mouseover', this.onstartMarkerMouseover.bind(this, mapDrawer));
                        this.startMarker.addListener('mouseout', this.onstartMarkerMouseout.bind(this, mapDrawer));

                        path.push(evLatLng.ToGMLatLng());

                        if (mapDrawer.viewModel.showCoords) {
                            var coordsText = mapDrawer.createOverlayText();
                            this.coordsTexts.push(coordsText);
                            mapDrawer.setCoordsText(coordsText, evLatLng);
                        }
                        if (mapDrawer.viewModel.showCoordNumbers) {
                            var coorNumText = mapDrawer.createOverlayText();
                            mapDrawer.setCoordNumberText(coorNumText, evLatLng, path.getLength());
                            this.coordsNumberTexts.push(coorNumText);
                        }
                    } else {
                        if (mapDrawer.viewModel.showAngleWhileDrawing)
                            this.angleTexts.push(mapDrawer.createOverlayText());
                        if (this.startMarker && this.curSubmenu === 'Line') {
                            this.startMarker.setPosition(path.getAt(path.getLength() - 1));
                            this.onstartMarkerMouseover(mapDrawer);
                        }
                    }

                    if (mapDrawer.viewModel.showLengths)
                        this.measureTexts.push(mapDrawer.createOverlayText());

                    path.push(ev.latLng);

                    if (mapDrawer.viewModel.showCoords)
                        this.coordsTexts.push(mapDrawer.createOverlayText());
                    if (mapDrawer.viewModel.showCoordNumbers) {
                        var coorNumText = mapDrawer.createOverlayText();
                        mapDrawer.setCoordNumberText(coorNumText, new LatLng(), path.getLength(), true);
                        this.coordsNumberTexts.push(coorNumText);
                    }
                }
            }

            //reset bool events
            this.isMouseDown = false;
            this.isMouseMoved = false;
        }

        createSnapMarker(mapDrawer: MapDrawer) {
            if (!this.snapMarker) {
                var iconSnap: google.maps.Symbol = {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 3,
                    strokeWeight: 1,
                    strokeOpacity: 0,
                    fillOpacity: 1
                };

                var layer = mapDrawer.viewModel.currentLayer;

                if (layer) {
                    iconSnap.fillColor = layer.shapeSelectedStyle.strokeColor;
                    iconSnap.fillOpacity = layer.shapeSelectedStyle.strokeOpacity;
                    iconSnap.scale = layer.shapeSelectedStyle.strokeWeight * 3;
                }

                var b = mapDrawer.map.getBounds();

                this.snapMarker = new google.maps.Marker({
                    map: mapDrawer.map,
                    clickable: false,
                    draggable: false,
                    position: b ? b.getSouthWest() : mapDrawer.map.getCenter(),
                    icon: iconSnap
                });

                this.snapMarker.setVisible(false);
            }
        }

        makeRectangle(path: google.maps.MVCArray<google.maps.LatLng>, latlng: LatLng, mapDrawer: MapDrawer): void {
            var helperLines = mapDrawer.viewModel.helperLines;

            if (!latlng)
                latlng = LatLng.FromGMLatLng(path.getAt(2));

            var ll0 = LatLng.FromGMLatLng(path.getAt(0)),
                p0 = ll0.ToPoint2D(),
                p2 = latlng.ToPoint2D(),
                pDelta = p2.sub(p0);

            var dx = Point2D.XAxis;
            var dy = Point2D.YAxis;

            if (helperLines.length && mapDrawer.shiftDown) {
                var helperLine = helperLines[0];
                dx = helperLine.dirs[0];
                dy = helperLine.dirs[1];
            }

            var lx = dx.dot(pDelta);
            var ly = dy.dot(pDelta);

            var ll1 = LatLng.FromPoint2D(p0.add(dx.mul(lx)));
            var ll2 = latlng;
            var ll3 = LatLng.FromPoint2D(p0.add(dy.mul(ly)));
            var kayBoardSteps = mapDrawer.viewModel.keyBoardSteps.internalValue;

            if (mapDrawer.viewModel.cmdEdgeLength > 0 || (mapDrawer.ctrlDown && kayBoardSteps > 0)) {
                var realLenX = ll0.DistanceTo(ll1) * 1000;
                var realLenY = ll0.DistanceTo(ll3) * 1000;

                var targetLenX = realLenX;
                var targetLenY = realLenY;

                if (mapDrawer.viewModel.cmdEdgeLength > 0) {
                    targetLenX = targetLenY = mapDrawer.viewModel.cmdEdgeLength;
                } else {
                    targetLenX = Math.round(realLenX / kayBoardSteps) * kayBoardSteps;
                    targetLenY = Math.round(realLenY / kayBoardSteps) * kayBoardSteps;

                    if (targetLenX <= 0)
                        targetLenX = kayBoardSteps;
                    if (realLenX <= 0)
                        realLenX = targetLenX;

                    if (targetLenY <= 0)
                        targetLenY = kayBoardSteps;
                    if (realLenY <= 0)
                        realLenY = targetLenY;
                }

                lx *= targetLenX / realLenX;
                ly *= targetLenY / realLenY;
                ll1 = LatLng.FromPoint2D(p0.add(dx.mul(lx)));
                ll2 = LatLng.FromPoint2D(p0.add(dx.mul(lx)).add(dy.mul(ly)));
                ll3 = LatLng.FromPoint2D(p0.add(dy.mul(ly)));
            }

            path.setAt(1, ll1.ToGMLatLng());
            path.setAt(2, ll2.ToGMLatLng());
            path.setAt(3, ll3.ToGMLatLng());

            mapDrawer.setMeasureText(this.measureTexts[0], ll0, ll1);

            mapDrawer.setMeasureText(this.measureTexts[1], ll3, ll0);

        }

        makeCircle(path: google.maps.MVCArray<google.maps.LatLng>, ll1: LatLng, mapDrawer: MapDrawer): void {
            var helperLines = mapDrawer.viewModel.helperLines,
                result: LatLng[] = [];

            if (ll1)
                path.setAt(1, ll1.ToGMLatLng());
            else
                ll1 = LatLng.FromGMLatLng(path.getAt(1));

            var shiftDown = mapDrawer.shiftDown,
                ll0 = LatLng.FromGMLatLng(path.getAt(0)),
                radius = ll0.DistanceTo(ll1),
                segments = Math.round(Math.max(5, Math.min(64, (shiftDown ? radius : radius * 2) * Math.PI * 3))),
                p1 = ll1.ToPoint2D(),
                pivot = shiftDown ? ll0.ToPoint2D().add(p1).mul(0.5) : ll0.ToPoint2D(),
                count = path.getLength(),
                pi2 = LatLng.Pi2;

            for (var i = 1; i <= segments; i++) {
                var p = p1.RotateByPivot(pi2 * i / segments, pivot),
                    idx = i + 1;

                if (idx < count)
                    path.setAt(idx, LatLng.Point2DToGMLatLng(p));
                else
                    path.push(LatLng.Point2DToGMLatLng(p));
            }

            for (var j = segments + 2; count > j; j++) {
                path.removeAt(segments + 2);
            }

            mapDrawer.setMeasureText(this.measureTexts[0], ll0, ll1);

        }

        onKeyDown(keyCode: number, mapDrawer: MapDrawer) {
            if (keyCode === 16 || keyCode === 17) //shift, ctrl
                this.updateDrawing(mapDrawer);
        }

        onKeyUp(keyCode: number, mapDrawer: MapDrawer) {
            if (keyCode === 16 || keyCode === 17) //shift, ctrl
                this.updateDrawing(mapDrawer);
        }

        updateDrawing(mapDrawer: MapDrawer) {
            if (this.curSubmenu === 'Rectangle' && this.step === 1 && this.polyline)
                this.makeRectangle(this.polyline.getPath(), null, mapDrawer);
            else if (this.curSubmenu === 'Circle' && this.step === 1 && this.polyline)
                this.makeCircle(this.polyline.getPath(), null, mapDrawer);
        }

        getNextLatLng(prePrevLatLng: LatLng, prevLatLng: LatLng, curLatLng: LatLng, mapDrawer: MapDrawer): LatLng {

            var snap = Helperline.GetSnapByHelperlines(prePrevLatLng, prevLatLng, curLatLng, mapDrawer, mapDrawer.shiftDown, true);

            this.snapped = snap.snapped;

            if (prevLatLng && mapDrawer.viewModel.cmdEdgeLength > 0)
                snap.result = Helperline.SnapToFixedLength(prevLatLng, snap.result, mapDrawer.viewModel.cmdEdgeLength);
            if (mapDrawer.ctrlDown && prevLatLng && mapDrawer.viewModel.keyBoardSteps.internalValue > 0)
                snap.result = Helperline.SnapToNextIncrement(prevLatLng, snap.result, mapDrawer);

            if (!mapDrawer.shiftDown && mapDrawer.altDown && prevLatLng) {
                //15 degree
                let isActivePrevDirection = true;
                snap.result = this.snapOnAngleSteps(isActivePrevDirection, prePrevLatLng, prevLatLng, snap.result, DrawTool.angleStep);
            }
            else if (mapDrawer.viewModel.isActiveCorrectionRightAngle && prePrevLatLng && prevLatLng) {
                let angleStep = Math.PI / 2; //90�
                let toleranz = Math.PI / 18; // 10� => -5 und +5
                snap.result = this.snapOnAngleSteps(true, prePrevLatLng, prevLatLng, snap.result, angleStep, toleranz);
            }

            return snap.result;
        }

        snapOnAngleSteps(isActivePrevDirection: boolean, prePrevLatLng: LatLng, prevLatLng: LatLng, current: LatLng, angleStep: number, toleranz?: number) {

            // Plausibilit�tspr�fung f�r toleranz, ob toleranz nicht zu gro� gew�hlt worden ist, sodass sich die anglesteps und die tolernz �berschneiten w�rden
            if (!prevLatLng)
                return current;

            let diffPrevAngle = 0;
            if (isActivePrevDirection && prePrevLatLng) {
                let p = prePrevLatLng.ToPoint2D();
                let prevDir = prevLatLng.ToPoint2D().sub(p);
                let prevAngle = Math.atan2(prevDir.y, prevDir.x);
                diffPrevAngle = prevAngle % angleStep;
            }

            let halfToleranz = toleranz ? toleranz / 2 : 0;
            var p = prevLatLng.ToPoint2D();
            var t = current.ToPoint2D().sub(p);
            var angle = Math.atan2(t.y, t.x);

            let nAngle = 0;

            if (toleranz) {
                let foundSector = -1;
                let angleStepCount = Math.floor((Math.PI * 2) / angleStep); // => 360� / step
                for (let sector = 0; sector < angleStepCount; sector++) {
                    let sectorAngle = sector * angleStep;
                    let sectorAngleMax = sectorAngle + halfToleranz;
                    let sectorAngleMin = sectorAngle - halfToleranz;

                    let isInsideSectorZero = sectorAngleMin <= 0 && 0 <= sectorAngleMax;

                    let angleCorrected = angle - diffPrevAngle;
                    if (isInsideSectorZero)
                        angleCorrected = angle - diffPrevAngle;
                    else {
                        if (angleCorrected < 0)
                            angleCorrected = this.mod(angleCorrected, Math.PI * 2); // angleCorrected % (Math.PI * 2);
                    }

                    let isInsideSector = sectorAngleMin <= angleCorrected && angleCorrected <= sectorAngleMax;
                    if (isInsideSector) {
                        foundSector = sector;
                        break;
                    }
                }

                if (foundSector < 0)
                    return current; // nAngle = (Math.round((angle - diffPrevAngle) / angleStep) * angleStep);
                else
                    nAngle = foundSector * angleStep;
            }
            else
                nAngle = (Math.round((angle - diffPrevAngle) / angleStep) * angleStep);

            var tAngle = nAngle - angle;
            tAngle += diffPrevAngle;

            var cs = Math.cos(tAngle);
            var sn = Math.sin(tAngle);
            var res = new Point2D((t.x * cs) - (t.y * sn) + p.x, (t.x * sn) + (t.y * cs) + p.y);
            return LatLng.FromPoint2D(res);
        }

        mod(n, m) {
            // correct and fast mod version with negative n
            return ((n % m) + m) % m;
        }

        isEqualWithTolerance(a: number, b: number, tolerance: number) {
            return (Math.abs(a - b) < tolerance);
        }

        onPolylineClosed(mapDrawer: MapDrawer) {
            //console.log("close");
            //var i = 2;
            var polyLine = this.polyline;
            var layer = mapDrawer.viewModel.currentLayer;
            var curSubmenu = this.curSubmenu;
            if (polyLine && layer) {
                let path = this.polyline.getPath();
                if (path && (path.getLength() > 3 || (curSubmenu === 'Line' && path.getLength() > 2))) {
                    if (curSubmenu === 'Line') {
                        path.pop();

                        var lineWidth = Math.max(10, mapDrawer.viewModel.interferenceLineThickness.internalValue),
                            latLngs = LatLng.FromGMLatLngArray(path.getArray()),
                            latLngBounds = BoundsLatLng.FromLatLngs(latLngs),
                            origin = latLngBounds.Min,
                            pts = latLngs.map(ll => { var p = origin.DirectionTo(ll).mul(1000); return new THREE.Vector3(p.x, p.y, 0) });

                        path.clear();

                        var linePlys = spt.ThreeJs.utils.generateOffsetLinePolygons(pts, lineWidth).filter(ply => ply && ply.length > 2);
                        if (layer.count + linePlys.length <= (layer.maxCount || 2000)) {
                            linePlys.forEach(ply => {
                                var arr = ply.map(p => origin.Offset(p.y * 0.001, p.x * 0.001).ToGMLatLng());
                                var poly = new google.maps.Polygon({ paths: arr });
                                mapDrawer.onOverlaycomplete(poly);
                            });
                        }
                    }
                    else if (curSubmenu === 'Polygon') {
                        if (mapDrawer.shiftDown) {
                            let len = path.getLength(),
                                prevPrev = len > 2 ? LatLng.FromGMLatLng(path.getAt(len - 3) as google.maps.LatLng) : null,
                                llprev = LatLng.FromGMLatLng(path.getAt(len - 2) as google.maps.LatLng),
                                lllast = LatLng.FromGMLatLng(this.startMarker.getPosition()),
                                evLatLng = this.getNextLatLng(prevPrev, llprev, lllast, mapDrawer);

                            path.setAt(len - 2, (llprev.add(lllast.sub(evLatLng))).ToGMLatLng());
                        }

                        path.pop();

                        // correct last angle
                        if (mapDrawer.viewModel.isActiveCorrectionRightAngle && path.getLength() >= 4) {
                            var pathLength = path.getLength();
                            // start and prev coreners muste be in tolerance to 90�
                            var startPoint = LatLng.FromGMLatLng(path.getAt(0)).ToPoint2D();
                            var startNextPoint = LatLng.FromGMLatLng(path.getAt(1)).ToPoint2D();
                            var endPoint = LatLng.FromGMLatLng(path.getAt(pathLength - 1)).ToPoint2D();
                            var endPrevPoint = LatLng.FromGMLatLng(path.getAt(pathLength - 2)).ToPoint2D();

                            var endPrevVector = endPrevPoint.sub(endPoint);
                            var endVector = startPoint.sub(endPoint);

                            var lengthOfProjection = endPrevVector.GetNormalized().dot(endVector);
                            var newEndPoint = endPoint.add(endPrevVector.GetNormalized().mul(lengthOfProjection));

                            var startVector = startNextPoint.sub(startPoint)
                            var newEndVector = newEndPoint.sub(startPoint);
                            var startCorenerAngleNewEnd = Math.abs(Point2D.AngleFromVectors(newEndVector, startVector));

                            var endCorenerAngle = Math.abs(Point2D.AngleFromVectors(endPrevVector, endVector));
                            if (this.isEqualWithTolerance(endCorenerAngle, 90, 10)) {
                                if (this.isEqualWithTolerance(startCorenerAngleNewEnd, 90, 0.001)) {
                                    path.pop();
                                    path.push(LatLng.FromPoint2D(newEndPoint).ToGMLatLng());
                                }
                            }
                        }

                    } else if (curSubmenu === 'Circle') {
                        path.removeAt(0);
                        path.pop();
                    }
                    var pathArray = path.getArray();
                    if (pathArray.length > 2) {
                        var poly = new google.maps.Polygon({
                            paths: pathArray
                        });

                        poly.setOptions({});
                        mapDrawer.onOverlaycomplete(poly);

                        if (layer.typeName === "Building")
                            lsGoogleMaps.refreshStepNumber(2);
                    }
                    mapDrawer.selectTool(this.name, curSubmenu);
                }
            }
        }

        onCommandText(mapDrawer: MapDrawer, cmd: string) {
            if (cmd && typeof cmd === "string" && cmd.length) {
                var inputSplit = cmd.split(",");
                if (inputSplit.length === 2) {
                    var xStr = inputSplit[0];
                    var yStr = inputSplit[1];

                    var valueAdjustOpts = mapDrawer.viewModel.valueAdjustOpts;
                    var imperial = window.UseImperialSystem;

                    var xVal = spt.Utils.GetFloatFromInputValue("" + xStr,
                        {
                            applyArithmetic: true,
                            isFeet: imperial,
                            notImperial: !imperial,
                            from: valueAdjustOpts.to,
                            to: "m"
                        });

                    var yVal = spt.Utils.GetFloatFromInputValue("" + yStr,
                        {
                            applyArithmetic: true,
                            isFeet: imperial,
                            notImperial: !imperial,
                            from: valueAdjustOpts.to,
                            to: "m"
                        });

                    if (xVal === null || isNaN(xVal) || !isFinite(xVal) || yVal === null || isNaN(yVal) || !isFinite(yVal))
                        return;

                    var rp = mapDrawer.viewModel.referenceLatLng;

                    var path = this.polyline ? this.polyline.getPath() : null,
                        pathLength = path ? path.getLength() : null;

                    if (!path)
                        return;

                    if (mapDrawer.viewModel.coordsRelative) {
                        if (pathLength)
                            rp = LatLng.FromGMLatLng(path.getAt(pathLength - 1));
                    }

                    var latLng = rp.Offset(+yVal, +xVal);

                    if (pathLength && this.curSubmenu === 'Polygon') {
                        var fp = LatLng.FromGMLatLng(path.getAt(0));
                        if (fp.Equals(latLng)) {
                            this.onPolylineClosed(mapDrawer);
                            return;
                        }
                        var lp = LatLng.FromGMLatLng(path.getAt(pathLength - 1));
                        if (lp.Equals(latLng))
                            return;
                    }

                    this.onMousemove({ latLng: latLng.ToGMLatLng() }, mapDrawer);
                    this.onMouseUp({ latLng: latLng.ToGMLatLng() }, mapDrawer); // onClick changed adrian

                    path = this.polyline ? this.polyline.getPath() : null;
                    pathLength = path ? path.getLength() : null;

                    var bds = BoundsLatLng.FromGmLatLngs(path);
                    if (bds.Width < 50 || bds.Height < 50)
                        bds = BoundsLatLng.FromCenterWidthHeight(bds.Center, Math.max(50, bds.Width), Math.max(50, bds.Height));

                    mapDrawer.map.fitBounds(bds.ToGMLatLngBounds());
                }
            }
        }
    }

    export class HelperLinesTool extends BaseToolIcon {
        constructor(mapDrawer: MapDrawer, name: string) {
            super(mapDrawer, name);
            this.Cursor = 'crosshair';
        }

        iconX = 3;
        iconY = 4;

        helperLine: Helperline = null;
        snapMarker: google.maps.Marker = null;
        step: number = 0;
        snapped: boolean = false;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            mapDrawer.map.setOptions({ draggableCursor: this.Cursor });

            mapDrawer.setSeparationLinesFrozen(true);

            mapDrawer.clearSelection();

            var c = LatLng.FromGMLatLng(mapDrawer.map.getCenter());

            this.step = 0;

            this.helperLine = this.createHelperLine(c, mapDrawer);

            this.snapMarker = new google.maps.Marker({
                map: mapDrawer.map,
                clickable: false,
                draggable: false,
                visible: false,
                position: c.ToGMLatLng(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: Helperline.shapeStyle.strokeWeight * 3,
                    fillColor: Helperline.shapeStyle.strokeColor,
                    fillOpacity: Helperline.shapeStyle.strokeOpacity,
                    strokeOpacity: 0
                },
                zIndex: 0
            });

        }

        onDeselect(mapDrawer: MapDrawer) {

            mapDrawer.setSeparationLinesFrozen(false);

            if (this.helperLine) {
                this.helperLine.setMap(null);
                this.helperLine = null;
            }
            if (this.snapMarker) {
                this.snapMarker.setMap(null);
                this.snapMarker = null;
            }

            mapDrawer.map.setOptions({ draggableCursor: null });

        }

        private createHelperLine(c: LatLng, mapDrawer: MapDrawer): Helperline {
            var helperLine = new Helperline(mapDrawer, c, c);
            helperLine.locked = true;
            //helperLine.isVisible = false;
            return helperLine;
        }

        onMouseUp(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            if (this.step === 1) {
                let curll = LatLng.FromGMLatLng(ev.latLng),
                    ll = HelperLinesTool.getSnapPosition(this.helperLine.Start, curll, mapDrawer);
                mapDrawer.viewModel.helperLines.push(this.helperLine);
                this.helperLine = this.createHelperLine(ll, mapDrawer);
                this.step = 0;
            }
            else
                this.step++;
        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            var helperLine = this.helperLine,
                snapMarker = this.snapMarker;
            if (helperLine && ev.latLng) {

                switch (this.step) {
                    case 0:
                        {
                            let curll = LatLng.FromGMLatLng(ev.latLng),
                                ll = HelperLinesTool.getSnapPosition(null, curll, mapDrawer);
                            if (!ll) {
                                ll = curll;
                                this.snapped = false;
                            } else
                                this.snapped = true;
                            //helperLine.End = ll;
                            helperLine.setStartEnd(ll, ll);
                            snapMarker.setPosition(ll.ToGMLatLng());
                            this.helperLine.isVisible = true;
                            //this.step = 1;
                        }
                        break;
                    case 1:
                        {
                            let curll = LatLng.FromGMLatLng(ev.latLng),
                                ll = HelperLinesTool.getSnapPosition(helperLine.Start, curll, mapDrawer);
                            if (!ll) {
                                ll = curll;
                                this.snapped = false;
                            } else
                                this.snapped = true;
                            helperLine.End = ll;
                            //snapMarker.setPosition(ll.ToGMLatLng());
                            this.helperLine.frozen = true;
                            this.helperLine.locked = false;
                        }
                        break;
                }
                snapMarker.setVisible(false);
            }
        }

        onMousemove(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {

            var helperLine = this.helperLine,
                snapMarker = this.snapMarker;
            if (helperLine && ev.latLng) {

                switch (this.step) {
                    case 0:
                        {
                            let curll = LatLng.FromGMLatLng(ev.latLng),
                                ll = HelperLinesTool.getSnapPosition(null, curll, mapDrawer);
                            if (!ll) {
                                ll = curll;
                                this.snapped = false;
                            } else
                                this.snapped = true;
                            helperLine.setStartEnd(ll, ll);
                            snapMarker.setPosition(ll.ToGMLatLng());
                        }
                        break;
                    case 1:
                        {
                            let curll = LatLng.FromGMLatLng(ev.latLng),
                                ll = HelperLinesTool.getSnapPosition(helperLine.Start, curll, mapDrawer);
                            if (!ll) {
                                ll = curll;
                                this.snapped = false;
                            } else
                                this.snapped = true;
                            helperLine.End = ll;
                            snapMarker.setPosition(ll.ToGMLatLng());
                        }
                        break;
                }
                if (this.snapped !== snapMarker.getVisible())
                    snapMarker.setVisible(this.snapped);
            }
        }

        static getSnapPosition(prevLatLng: LatLng, curLatLng: LatLng, mapDrawer: MapDrawer, layers?: PolygonLayer[]): LatLng {
            let result: LatLng = null,
                clickTolerance = Helperline.clickTolerance;

            let pt = mapDrawer.getPointByPoint(curLatLng, clickTolerance, true, true, layers),
                seg = !pt ? mapDrawer.getSegmentByPoint(curLatLng, clickTolerance, layers) : null;

            if (prevLatLng && mapDrawer.shiftDown) {
                let helperlinesResult = Helperline.GetSnapByHelperlines(null, prevLatLng, LatLng.FromPoint2D(pt) || LatLng.FromPoint2D(seg && seg.getClosestPoint(curLatLng.ToPoint2D())) || curLatLng, mapDrawer, true, false);
                if (curLatLng.DistanceTo(helperlinesResult.result) > 0.01) {
                    if (pt)
                        result = helperlinesResult.result;
                    else if (seg && prevLatLng.DistanceTo(helperlinesResult.result) > 0.01) {
                        var inter = seg.getIntersection(new Segment2D(prevLatLng.ToPoint2D(), helperlinesResult.result.ToPoint2D(), true));
                        if (inter) {
                            var pxFactor = Math.pow(2, mapDrawer.map.getZoom()),
                                dist = inter.DistanceTo(helperlinesResult.result.ToPoint2D()) * pxFactor;
                            if (dist <= clickTolerance)
                                result = LatLng.FromPoint2D(inter);
                        }
                    }
                    if (!result)
                        result = helperlinesResult.result;
                }
            }

            if (!result) {
                if (pt) {
                    result = LatLng.FromPoint2D(pt);
                } else if (seg) {
                    var sp = seg.getClosestPoint(curLatLng.ToPoint2D());
                    if (sp) {
                        result = LatLng.FromPoint2D(sp);
                    }
                }
            }

            //if (!result && prevLatLng && mapDrawer.shiftDown) {
            //    let helperlinesResult = Helperline.GetSnapByHelperlines(prevLatLng, curLatLng, mapDrawer, true, false).result;
            //    if (curLatLng.DistanceTo(helperlinesResult) > 0.01)
            //        result = helperlinesResult;
            //}

            return result;
        }

    }

    export class SouthMarkerTool extends BaseToolIcon {
        constructor(mapDrawer: MapDrawer, name: string) {
            super(mapDrawer, name);
            // this.Cursor = `url("${mapDrawer.options.pathToIcons}/cursor/south.cur"), default`;
        }

        iconX = 15;
        iconY = 4;

        segments: { [id: string]: Segment2D[] } = null;
        polyline: google.maps.Polyline = null;

        southMarker: SouthMarker = null;
        currentPolyId: string = null;
        southMarkerHovered: boolean = false;

        static clickTolerance = 25;

        static highlightStyle: IShapeStyle = {
            strokeWeight: 3,
            strokeColor: '#FFFFFF',
            strokeOpacity: 0.4
        };

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var viewmodel = mapDrawer.viewModel,
                layer = viewmodel.layer.mainLayer,
                polys = layer.polygons;

            if (!polys.length) {
                mapDrawer.selectTool("SelectTool");
                return;
            }

            this.segments = {};
            this.currentPolyId = null;
            this.southMarkerHovered = false;

            polys.forEach(poly => {
                var data = poly.userData as ShapeData;
                if (data && !data._isDisposed) {
                    var segments = data.getSegments();
                    if (segments && segments.length) {
                        this.segments[data.id] = segments;
                    }
                    if (data.southMarker) {
                        var southMarker = data.southMarker;
                        southMarker.clickable = true;
                        southMarker.hovered = false;
                    }
                }
            });
            //mapDrawer.map.setOptions({ draggableCursor: this.Cursor });
            mapDrawer.map.setOptions({ draggableCursor: null });
            var polyLineOpts: google.maps.PolylineOptions = {
                map: mapDrawer.map,
                draggable: false,
                editable: false,
                clickable: false,
                visible: false,
                zIndex: 12,
                strokeWeight: layer.shapeSelectedHoveredStyle.strokeWeight + 2,
                strokeColor: layer.shapeSelectedHoveredStyle.strokeColor,
                strokeOpacity: 0.5,
            };

            this.polyline = new google.maps.Polyline(polyLineOpts);

            this.southMarker = this.createSouthMarker(mapDrawer);
        }

        onDeselect(mapDrawer: MapDrawer) {
            var viewmodel = mapDrawer.viewModel,
                layer = viewmodel.layer.mainLayer,
                polys = layer.polygons;

            this.segments = null;
            this.currentPolyId = null;
            if (this.southMarker) {
                this.southMarker.dispose();
                this.southMarker = null;
            }
            if (this.polyline) {
                this.polyline.setMap(null);
                this.polyline = null;
            }
            this.southMarkerHovered = false;
            if (polys) {
                polys.forEach(poly => {
                    var data = poly.userData as ShapeData;
                    if (data && !data._isDisposed && data.southMarker) {
                        var southMarker = data.southMarker;
                        southMarker.clickable = false;
                        southMarker.hovered = false;
                    }
                });
            }
            mapDrawer.map.setOptions({ draggableCursor: null });
        }

        createSouthMarker(mapDrawer: MapDrawer): SouthMarker {
            return new SouthMarker(null, 0, false, mapDrawer);
        }

        onMouseUp(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            if ($.isTouchCapable()) {
                if (this.isDragging)
                    return;
                else
                    this.onMousemove(ev, mapDrawer);
            }

            if (this.southMarker && this.southMarker.visible && this.currentPolyId) {

                var viewmodel = mapDrawer.viewModel,
                    layer = viewmodel.layer.mainLayer,
                    poly = layer.getPolygonById(this.currentPolyId);

                if (poly) {
                    var data = poly.userData as ShapeData;
                    this.removeSouthmarker(data);
                    if (data) {
                        data.southMarker = this.southMarker;
                        this.southMarker = null;
                        data.update();
                        data.regenerate();
                        lsGoogleMaps.refreshStepNumber(3);
                        if (!$.isTouchCapable()) {
                            if (layer.polygons.length > 1)
                                mapDrawer.selectTool("SouthMarkerTool");
                            else
                                mapDrawer.selectTool("SelectTool");
                        }
                        else {
                            mapDrawer.selectTool("SouthMarkerTool");
                        }

                    }
                }
            }
        }

        // onMouseDown
        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {

        }

        onMousemove(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            var polyLine = this.polyline,
                found = false,
                southMarkerHovered = this.southMarkerHovered;

            if (polyLine && ev.latLng && !southMarkerHovered) {
                var latLng = LatLng.FromGMLatLng(ev.latLng),
                    p = latLng.ToPoint2D();
                var s = this.tryGetSegment(p, mapDrawer);
                if (s) {

                    var seg = s.segment,
                        center = seg.getCenter(),
                        orientation = seg.getOrientation(),
                        normal = seg.Normal;

                    if (normal.dot(p.sub(seg.Start)) > 0) {
                        orientation += Math.PI;
                    }

                    this.currentPolyId = s.id;

                    this.southMarker.position = LatLng.FromPoint2D(center);
                    this.southMarker.orientation = orientation;
                    this.southMarker.visible = true;

                    this.polyline.setPath([LatLng.Point2DToGMLatLng(seg.Start), LatLng.Point2DToGMLatLng(seg.End)]);
                    this.polyline.setVisible(true);

                    mapDrawer.map.setOptions({ draggableCursor: 'pointer' });
                    //mapDrawer.map.setOptions({ draggableCursor: this.Cursor });

                    found = true;
                }
            }

            if (!found) {
                this.currentPolyId = null;
                this.southMarker.visible = false;
                this.polyline.setVisible(false);
                mapDrawer.map.setOptions({ draggableCursor: null });
                //mapDrawer.map.setOptions({ draggableCursor: this.Cursor });
            }
        }

        tryGetSegment(p: Point2D, mapDrawer: MapDrawer): { id: string, segment: Segment2D } {
            var result: { id: string, segment: Segment2D } = null;
            if (this.segments) {
                var minDist = Number.MAX_VALUE,
                    clickTolerance = SouthMarkerTool.clickTolerance,
                    pxFactor = mapDrawer.getPixelFactor(),
                    foundSeg: Segment2D = null,
                    foundId: string = null;

                Object.keys(this.segments).forEach(k => {
                    this.segments[k].forEach(seg => {
                        var dist = seg.distanceToPoint(p) * pxFactor;
                        if (dist <= clickTolerance && dist < minDist) {
                            minDist = dist;
                            foundSeg = seg;
                            foundId = k;
                        }
                    });
                });

                if (foundSeg && foundId) {
                    result = {
                        segment: foundSeg,
                        id: foundId
                    };
                }
            }
            //mapDrawer.map.setOptions({ draggableCursor: this.Cursor });
            return result;
        }

        onMouseoverSouthMarker(southMarker: SouthMarker, mapDrawer: MapDrawer) {
            this.southMarkerHovered = true;
            southMarker.hovered = true;
        }

        onMouseoutSouthMarker(southMarker: SouthMarker, mapDrawer: MapDrawer) {
            this.southMarkerHovered = false;
            southMarker.hovered = false;
        }

        onClickSouthMarker(southMarker: SouthMarker, mapDrawer: MapDrawer) {
            var viewmodel = mapDrawer.viewModel,
                layer = viewmodel.layer.mainLayer,
                polys = layer.polygons;

            if (southMarker && polys.length) {
                polys.filter(poly => poly && poly.userData && (poly.userData as ShapeData).southMarker && (poly.userData as ShapeData).southMarker.id === southMarker.id).forEach(poly => {
                    this.removeSouthmarker(poly.userData as ShapeData);
                });
            }

            this.southMarkerHovered = false;
        }

        removeSouthmarker(shape: ShapeData) {
            if (shape && shape.southMarker) {
                shape.southMarker.dispose();
                shape.southMarker = null;
            }
        }
    }

    export class EraserTool extends BaseToolIcon {
        constructor(mapDrawer: MapDrawer, name: string) {
            super(mapDrawer, name);
            this.Cursor = '';//`url("${mapDrawer.options.pathToIcons}/cursor/eraser.cur"), default`;
        }
        iconX = 5;
        iconY = 1;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var viewModel = mapDrawer.viewModel;
            if (viewModel.selection.length) {
                mapDrawer.deleteSelection();
                mapDrawer.eraseMode = false;
                mapDrawer.selectTool(prevTool.name !== 'EraserTool' ? prevTool.name : null);
                return;
            }
            if (!mapDrawer.eraseMode) {
                mapDrawer.eraseMode = true;
                mapDrawer.map.setOptions({ draggableCursor: this.Cursor });
            }
        }

        onDeselect(mapDrawer: MapDrawer) {
            if (mapDrawer.eraseMode) {
                mapDrawer.eraseMode = false;
                mapDrawer.map.setOptions({ draggableCursor: null });
            }
        }
    }

    export class ImportImageHelperTool extends BaseToolIcon {

        _Start: LatLng = new LatLng();
        _End: LatLng = new LatLng();

        get Start(): LatLng {
            return this._Start;
        }

        set Start(value: LatLng) {
            this._Start = value;
            this.updateMarker();
        }

        get End(): LatLng {
            return this._End;
        }

        set End(value: LatLng) {
            this._End = value;
            this.updateMarker();
        }

        get Enabled(): boolean {
            return !!this.startMarker;
        }

        setStartEnd(s: LatLng, e: LatLng) {
            this._Start = s;
            this._End = e;
            this.updateMarker();
        }

        private startMarker: google.maps.Marker = null;
        private endMarker: google.maps.Marker = null;
        private visibleMarkers: google.maps.Marker[] = null;
        private polyLine: google.maps.Polyline = null;
        rect: google.maps.Rectangle = null;

        static strokeColor: string = "rgb(240,0,0)";
        static strokeWeight: number = 2;
        static strokeOpacity: number = 0.9;

        static rectStrokeColor: string = "rgb(0,155,240)";
        static rectStrokeWeight: number = 1;
        static rectStrokeOpacity: number = 0.9;

        importedImage: ImageMapOverlay = null;

        onStartDrag() {
            var importedImage = this.importedImage;
            if (!this.Enabled || !importedImage)
                return;

            var s = this._Start = LatLng.FromGMLatLng(this.startMarker.getPosition());
            var e = this.End;

            this.polyLine.setPath([s.ToGMLatLng(), e.ToGMLatLng()]);
            importedImage._refLength = s.DistanceTo(e) * 1000;
        }

        onEndDrag() {
            var importedImage = this.importedImage;
            if (!this.Enabled || !importedImage)
                return;

            var s = this.Start;
            var e = this._End = LatLng.FromGMLatLng(this.endMarker.getPosition());

            this.polyLine.setPath([s.ToGMLatLng(), e.ToGMLatLng()]);
            importedImage._refLength = s.DistanceTo(e) * 1000;
        }

        onBoundsChanged() {

            if (!this.importedImage || !this.rect)
                return;

            var importedImage = this.importedImage;
            var rectBounds = BoundsLatLng.FromGMLatLngBounds(this.rect.getBounds());
            var rectSize = rectBounds.Size;
            var rectCenter = rectBounds.Center;

            var imgBounds = importedImage._bounds;
            var imgSize = imgBounds.Size;

            if (rectBounds.Equals(imgBounds))
                return;

            var f = Math.min(rectSize.x / imgSize.x, rectSize.y / imgSize.y);

            var newBounds = BoundsLatLng.FromCenterWidthHeight(rectCenter, imgSize.x * f, imgSize.y * f);

            if (imgBounds.Equals(newBounds))
                return;

            importedImage.setBounds(newBounds, true);

        }

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var map = mapDrawer.map,
                viewModel = mapDrawer.viewModel,
                importedImage = viewModel.currentImportedImage;

            if (!importedImage || viewModel.currentSubViewModel != null) {
                mapDrawer.selectTool("SelectTool");
                return;
            }

            viewModel.allTools.forEach(tool => {
                if (tool.name !== this.name && tool.name !== 'SelectTool')
                    tool.disabled = true;
            });

            viewModel.currentSubViewModel = viewModel.subViewModels.backgroundSetting;

            this.importedImage = importedImage;

            var imgBounds = importedImage._bounds;
            if (!this.visibleMarkers)
                this.visibleMarkers = [];

            mapDrawer.map.fitBounds(importedImage._bounds.ToGMLatLngBounds());

            var s = this.Start = imgBounds.Corners[0];
            var e = this.End = imgBounds.Corners[1];

            importedImage._refLength = s.DistanceTo(e) * 1000;

            var is = 3;
            var il = 21;

            var lpath = `M${is},0.5 h${il} M-${is},0.5 h-${il} M0.5,${is} v${il} M0.5,-${is} v-${il}`;

            this.startMarker = new google.maps.Marker({
                position: s.ToGMLatLng(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 28,
                    strokeWeight: 2,
                    strokeColor: ImportImageHelperTool.strokeColor,
                    strokeOpacity: ImportImageHelperTool.strokeOpacity
                },
                draggable: true,
                opacity: 0,
                map: map,
                zIndex: 4
            });

            var s1 = new google.maps.Marker({
                position: s.ToGMLatLng(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 28,
                    strokeWeight: 2,
                    strokeColor: ImportImageHelperTool.strokeColor,
                    strokeOpacity: ImportImageHelperTool.strokeOpacity
                },
                draggable: false,
                clickable: false,
                map: map,
                zIndex: 4
            });
            s1.bindTo('position', this.startMarker, 'position');

            var s2 = new google.maps.Marker({
                position: s.ToGMLatLng(),
                icon: {
                    path: lpath,
                    //scale: 1,
                    //strokeWeight: 0.5,
                    strokeColor: ImportImageHelperTool.strokeColor
                    //strokeOpacity: ImportImageHelperTool.strokeOpacity
                },
                draggable: false,
                clickable: false,
                map: map,
                zIndex: 4
            });
            s2.bindTo('position', this.startMarker, 'position');

            this.startMarker.addListener("drag", this.onStartDrag.bind(this));
            this.startMarker.addListener("dragend", this.onStartDrag.bind(this));

            this.endMarker = new google.maps.Marker({
                position: e.ToGMLatLng(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 28,
                    strokeWeight: 2,
                    strokeColor: ImportImageHelperTool.strokeColor,
                    strokeOpacity: ImportImageHelperTool.strokeOpacity
                },
                draggable: true,
                opacity: 0,
                map: map,
                zIndex: 4
            });

            var e1 = new google.maps.Marker({
                position: e.ToGMLatLng(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 28,
                    strokeWeight: 2,
                    strokeColor: ImportImageHelperTool.strokeColor,
                    strokeOpacity: ImportImageHelperTool.strokeOpacity
                },
                draggable: false,
                clickable: false,
                map: map,
                zIndex: 4
            });
            e1.bindTo('position', this.endMarker, 'position');

            var e2 = new google.maps.Marker({
                position: e.ToGMLatLng(),
                icon: {
                    path: lpath,
                    //scale: 1,
                    //strokeWeight: 0.5,
                    strokeColor: ImportImageHelperTool.strokeColor
                    //strokeOpacity: ImportImageHelperTool.strokeOpacity
                },
                draggable: false,
                clickable: false,
                map: map,
                zIndex: 4
            });
            e2.bindTo('position', this.endMarker, 'position');

            this.endMarker.addListener("drag", this.onEndDrag.bind(this));
            this.endMarker.addListener("dragend", this.onEndDrag.bind(this));

            this.visibleMarkers.push(s1, s2, e1, e2);

            this.polyLine = new google.maps.Polyline({
                path: [s.ToGMLatLng(), e.ToGMLatLng()],
                strokeWeight: ImportImageHelperTool.strokeWeight,
                strokeColor: ImportImageHelperTool.strokeColor,
                strokeOpacity: ImportImageHelperTool.strokeOpacity * 0.7,
                map: map,
                editable: false,
                draggable: false,
                clickable: false,
                zIndex: 5
            });

            this.rect = new google.maps.Rectangle({
                draggable: true,
                bounds: importedImage._bounds.ToGMLatLngBounds(),
                editable: true,
                zIndex: 1,
                map: map,
                strokeWeight: ImportImageHelperTool.rectStrokeWeight,
                strokeColor: ImportImageHelperTool.rectStrokeColor,
                strokeOpacity: ImportImageHelperTool.rectStrokeOpacity,
                fillOpacity: 0.2,
                fillColor: '#8bd6ff'
            });

            this.rect.addListener('bounds_changed', this.onBoundsChanged.bind(this));

            this.importedImage.boundsChanged = () => {
                this.rect.setBounds(this.importedImage._bounds.ToGMLatLngBounds());
            };
        }

        onDeselect(mapDrawer: MapDrawer) {
            if (!this.Enabled)
                return;

            var viewModel = mapDrawer.viewModel;

            viewModel.allTools.forEach(tool => {
                if (tool.name !== this.name && tool.name !== 'SelectTool')
                    tool.disabled = false;
            });

            viewModel.currentSubViewModel = null;
            viewModel.currentImportedImageId = null;

            google.maps.event.clearListeners(this.startMarker, 'drag');
            google.maps.event.clearListeners(this.startMarker, 'dragend');
            google.maps.event.clearListeners(this.endMarker, 'drag');
            google.maps.event.clearListeners(this.endMarker, 'dragend');
            google.maps.event.clearListeners(this.rect, 'bounds_changed');

            this.visibleMarkers.forEach(m => {
                m.unbindAll();
                m.setMap(null);
            });

            this.startMarker.setMap(null);
            this.endMarker.setMap(null);
            this.polyLine.setMap(null);
            this.rect.setMap(null);

            this.startMarker = null;
            this.endMarker = null;
            this.polyLine = null;
            this.visibleMarkers = null;
            this.rect = null;

            this.importedImage.boundsChanged = null;
            this.importedImage = null;

            //mapDrawer.load();
        }

        updateMarker() {
            var importedImage = this.importedImage;
            if (!this.Enabled || !importedImage)
                return;

            var s = this.Start;
            var e = this.End;

            this.startMarker.setPosition(s.ToGMLatLng());
            this.endMarker.setPosition(e.ToGMLatLng());
            this.polyLine.setPath([s.ToGMLatLng(), e.ToGMLatLng()]);
            importedImage._refLength = s.DistanceTo(e) * 1000;
        }
    }

    export class OsmBuildingsTool extends BaseToolIcon {
        buildingClicked(mapDrawer: MapDrawer, poly: google.maps.Polygon) {
            var layer = mapDrawer.viewModel.layer.building;
            if (poly && layer) {
                var path = poly.getPath();
                if (path && path.getLength() > 3) {

                    if (layer.polygons.length)
                        mapDrawer.deleteSelectables(layer.polygons.map(rp => rp.userData as ShapeData).filter(d => !!d));

                    mapDrawer.viewModel.currentLayer = layer;

                    mapDrawer.onOverlaycomplete(new google.maps.Polygon({ paths: path.getArray() }), true);
                }
            }
            mapDrawer.selectTool("SelectTool");
        }

        onSelect(mapDrawer: MapDrawer) {
            mapDrawer.osmBuildings.start(mapDrawer, this.buildingClicked.bind(this, mapDrawer));
        }

        onDeselect(mapDrawer: MapDrawer) {
            mapDrawer.osmBuildings.stop();
        }
    }

    export class ReferenceLengthTool extends BaseToolIcon {
        iconX = 16;
        iconY = 4;

        segments: { [id: string]: Segment2D[] } = null;
        polyline: google.maps.Polyline = null;
        selectedPolyline: google.maps.Polyline = null;

        currentPolyId: string = null;
        selectedPolyId: string = null;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var viewmodel = mapDrawer.viewModel,
                layer = viewmodel.layer.mainLayer;

            if (!layer || viewmodel.currentSubViewModel != null || !layer.polygons.length) {
                mapDrawer.selectTool("SelectTool");
                return;
            }

            mapDrawer.setSeparationLinesFrozen(true);

            var refLengthModel = viewmodel.currentSubViewModel = viewmodel.subViewModels.referenceLength;

            this.currentPolyId = null;
            this.selectedPolyId = null;

            this.updateSegments(mapDrawer);

            mapDrawer.map.setOptions({ draggableCursor: null });

            var polyLineOpts: google.maps.PolylineOptions = {
                map: mapDrawer.map,
                draggable: false,
                editable: false,
                clickable: false,
                visible: false,
                zIndex: 12,
                strokeWeight: layer.shapeSelectedHoveredStyle.strokeWeight + 2,
                strokeColor: layer.shapeSelectedHoveredStyle.strokeColor,
                strokeOpacity: 0.5
            };

            this.polyline = new google.maps.Polyline(polyLineOpts);

            polyLineOpts.strokeColor = "rgb(255,149,0)";
            polyLineOpts.strokeOpacity = 0.9;
            polyLineOpts.strokeWeight += 2;

            this.selectedPolyline = new google.maps.Polyline(polyLineOpts);

            refLengthModel.Start = new LatLng();
            refLengthModel.End = new LatLng();
        }

        onDeselect(mapDrawer: MapDrawer) {
            var viewModel = mapDrawer.viewModel;

            viewModel.currentSubViewModel = null;

            mapDrawer.setSeparationLinesFrozen(false);

            this.segments = null;

            if (this.polyline) {
                this.polyline.setMap(null);
                this.polyline = null;
            }

            if (this.selectedPolyline) {
                this.selectedPolyline.setMap(null);
                this.selectedPolyline = null;
            }

            this.currentPolyId = null;
            this.selectedPolyId = null;

            mapDrawer.map.setOptions({ draggableCursor: null });
        }

        tryGetSegment(p: Point2D, mapDrawer: MapDrawer): { id: string, segment: Segment2D } {
            return SouthMarkerTool.prototype.tryGetSegment.call(this, p, mapDrawer);
        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            if (this.currentPolyId && mapDrawer.viewModel.hasInstance(this.currentPolyId)) {

                let viewmodel = mapDrawer.viewModel,
                    refLengthModel = viewmodel.currentSubViewModel as ReferenceLengthModel,
                    polyLine = this.polyline,
                    selectedPolyline = this.selectedPolyline,
                    path = polyLine.getPath().getArray().map(ll => LatLng.FromGMLatLng(ll));

                this.selectedPolyId = this.currentPolyId;

                selectedPolyline.setPath(path.map<google.maps.LatLng>(ll => ll.ToGMLatLng()));
                selectedPolyline.setVisible(true);

                this.currentPolyId = null;
                polyLine.setVisible(false);

                var selectedData = viewmodel.instances[this.selectedPolyId] as ShapeData;

                var orientation = 0;
                var slope = 0;
                if (selectedData) {
                    if (selectedData.typeName === window.BuildingTypeName && selectedData.children && selectedData.children.length) {
                        for (var i = 0, l = selectedData.children.length; i < l; i++) {
                            var child = selectedData.children[i];

                            if (child.hasSegment(path[0], path[1])) {
                                selectedData = child;
                                break;
                            }
                        }
                    }

                    if (selectedData.southMarker && selectedData.slope) {
                        orientation = selectedData.southMarker.orientation;
                        slope = selectedData.slope;
                    }
                }

                refLengthModel.initLengths(path[0], path[1], this.selectedPolyId, orientation, slope);

                mapDrawer.map.setOptions({ draggableCursor: null });

            }
        }

        onMousemove(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            var polyLine = this.polyline,
                found = false;

            if (polyLine && ev.latLng) {
                var latLng = LatLng.FromGMLatLng(ev.latLng),
                    p = latLng.ToPoint2D();

                var s = this.tryGetSegment(p, mapDrawer);
                if (s) {

                    var seg = s.segment;

                    found = true;

                    if (this.selectedPolyId && this.selectedPolyId === s.id) {
                        var spath = this.selectedPolyline.getPath().getArray().map(ll => LatLng.GMLatLngToPoint2D(ll));
                        if (spath[0].Equals(seg.Start) && spath[1].Equals(seg.End))
                            found = false;
                    }

                    if (found) {
                        var path = [LatLng.Point2DToGMLatLng(seg.Start), LatLng.Point2DToGMLatLng(seg.End)];

                        this.currentPolyId = s.id;

                        this.polyline.setPath(path);
                        this.polyline.setVisible(true);

                        mapDrawer.map.setOptions({ draggableCursor: 'pointer' });
                    }
                }
            }

            if (!found) {
                this.currentPolyId = null;
                this.polyline.setVisible(false);
                mapDrawer.map.setOptions({ draggableCursor: null });
            }
        }

        updatePolyline(start: LatLng, end: LatLng, mapDrawer: MapDrawer) {
            var polyLine = this.polyline,
                selectedPolyline = this.selectedPolyline;

            if (polyLine && polyLine.getVisible()) {
                polyLine.setPath([start.ToGMLatLng(), end.ToGMLatLng()]);
                //polyLine.setPath(polyLine.getPath().getArray().map<google.maps.LatLng>(ll => LatLng.FromPoint2D(transform.transform(LatLng.FromGMLatLng(ll).ToPoint2D())).ToGMLatLng()));
            }

            if (selectedPolyline && selectedPolyline.getVisible()) {
                selectedPolyline.setPath([start.ToGMLatLng(), end.ToGMLatLng()]);
                //selectedPolyline.setPath(selectedPolyline.getPath().getArray().map<google.maps.LatLng>(ll => LatLng.FromPoint2D(transform.transform(LatLng.FromGMLatLng(ll).ToPoint2D())).ToGMLatLng()));
            }

            ko.tasks.runEarly();

            mapDrawer.setSeparationLinesFrozen(true);

            this.updateSegments(mapDrawer);

            mapDrawer.map.setOptions({ draggableCursor: null });
        }

        private updateSegments(mapDrawer: MapDrawer) {
            var viewmodel = mapDrawer.viewModel,
                layer = viewmodel.layer.mainLayer,
                polys = layer.polygons;

            this.segments = {};

            polys.forEach(poly => {
                var data = poly.userData as ShapeData;

                if (data && !data._isDisposed) {
                    var segments = data.getSegments(true);
                    if (segments && segments.length) {
                        this.segments[data.id] = segments;
                    }
                }
            });

            this.currentPolyId = null;
        }
    }

    export class RidgeTool extends BaseToolIcon {
        constructor(mapDrawer: MapDrawer, name: string) {
            super(mapDrawer, name);
            this.Cursor = 'crosshair';
            this.isCutting = false;
        }

        iconX = 16;
        iconY = 1;

        subMenu = [
            new BaseSubToolIcon('Draw', 1, 7),
            new BaseSubToolIcon('Multiple', 0, 7)
        ];

        target: ShapeData = null;
        separationPolyline: SeparationPolyline = null;
        private polyLines: google.maps.Polyline[] = null;
        private snapMarker: google.maps.Marker = null;
        snapped: boolean = false;
        isCutting: boolean = false;
        step: number = 0;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var viewmodel = mapDrawer.viewModel,
                buildingLayer = viewmodel.layer.building,
                buildingPolys = buildingLayer ? buildingLayer.polygons : null;

            this.curSubmenu = subMenu = subMenu || 'Draw';

            if (!buildingLayer || !buildingPolys.length) {
                mapDrawer.selectTool("SelectTool");
                return;
            }

            if (subMenu === 'Multiple') {
                if (viewmodel.currentSubViewModel != null) {
                    mapDrawer.selectTool("SelectTool");
                    return;
                }
                viewmodel.currentSubViewModel = viewmodel.subViewModels.multipleRidges;
            }

            mapDrawer.clearSelection();

            buildingPolys.forEach(poly => {
                (poly.userData as ShapeData).separationPolylines.forEach(sp => {
                    sp.frozen = true;
                });
            });

            //var target = this.target = viewmodel.selection[0] as ShapeData;

            this.target = buildingPolys[0].userData as ShapeData;

            var c = LatLng.FromGMLatLng(mapDrawer.map.getCenter());

            var shapeStyle = $.extend({}, SeparationPolyline.shapeStyle);
            if (this.isCutting)
                shapeStyle.strokeColor = '#bc0000';

            this.polyLines = [new google.maps.Polyline($.extend({
                map: mapDrawer.map,
                draggable: false,
                editable: false,
                clickable: false,
                visible: false,
                zIndex: 0,
                path: [c.ToGMLatLng(), c.ToGMLatLng()]
            }, shapeStyle) as google.maps.PolylineOptions)];

            this.step = 0;

            this.snapMarker = new google.maps.Marker({
                map: mapDrawer.map,
                clickable: false,
                draggable: false,
                visible: false,
                position: c.ToGMLatLng(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: shapeStyle.strokeWeight * 3,
                    fillColor: shapeStyle.strokeColor,
                    fillOpacity: shapeStyle.strokeOpacity,
                    strokeOpacity: 0
                },
                zIndex: 0
            });

            mapDrawer.setDrawingMode(true);

            mapDrawer.map.setOptions({ draggableCursor: this.Cursor });
        }

        onDeselect(mapDrawer: MapDrawer) {
            var viewmodel = mapDrawer.viewModel,
                layer = viewmodel.layer.building,
                polys = layer ? layer.polygons : null;

            viewmodel.currentSubViewModel = null;

            polys.forEach(poly => {
                (poly.userData as ShapeData).separationPolylines.forEach(sp => {
                    sp.frozen = false;
                });
            });

            if (this.snapMarker) {
                this.snapMarker.setMap(null);
                this.snapMarker = null;
            }
            if (this.polyLines && this.polyLines.length) {
                this.polyLines.forEach(polyLine => {
                    polyLine.setMap(null);
                });
                this.polyLines = null;
            }

            if (this.separationPolyline &&
                this.separationPolyline.isCutting &&
                this.separationPolyline.latLngs &&
                this.separationPolyline.latLngs.length <= 1) {
                this.separationPolyline.remove();
            }

            this.target = null;
            this.separationPolyline = null;

            mapDrawer.setDrawingMode(false);

            mapDrawer.map.setOptions({ draggableCursor: null });
        }

        onMousemove(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {

            let snapMarker = this.snapMarker,
                polyLine = this.polyLines[0],
                ll: LatLng;

            if (this.curSubmenu === "Multiple") {
                let lastll = this.step === 0 && polyLine.getVisible() ? LatLng.FromGMLatLng(polyLine.getPath().getAt(0)) : null;

                let curll = LatLng.FromGMLatLng(ev.latLng);

                ll = HelperLinesTool.getSnapPosition(lastll, curll, mapDrawer, [mapDrawer.viewModel.layer.building]);

                if (!ll) {
                    ll = curll;
                    this.snapped = false;
                } else
                    this.snapped = true;

                if (this.step === 0) {
                    if (polyLine.getVisible())
                        polyLine.getPath().setAt(1, ll.ToGMLatLng());
                }
                else
                    this.makeMultiple(ll, mapDrawer);

            } else {
                let lastll = polyLine.getVisible() ? LatLng.FromGMLatLng(polyLine.getPath().getAt(0)) : null;

                let curll = LatLng.FromGMLatLng(ev.latLng);

                ll = HelperLinesTool.getSnapPosition(lastll, curll, mapDrawer, [mapDrawer.viewModel.layer.building]);

                if (!ll) {
                    ll = curll;
                    this.snapped = false;
                } else
                    this.snapped = true;

                if (polyLine.getVisible()) {
                    let polyLinePath = polyLine.getPath();
                    polyLinePath.setAt(1, ll.ToGMLatLng());
                }
            }

            snapMarker.setPosition(ll.ToGMLatLng());


            if (this.snapped !== snapMarker.getVisible())
                snapMarker.setVisible(this.snapped);

        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer): void {
            if (this.target) {

                let curll = LatLng.FromGMLatLng(ev.latLng),
                    lastll: LatLng,
                    polyLine = this.polyLines[0];

                if (this.curSubmenu === "Multiple") {
                    lastll = this.step === 0 && polyLine.getVisible() ? LatLng.FromGMLatLng(polyLine.getPath().getAt(0)) : null;
                    let ll = HelperLinesTool.getSnapPosition(lastll, curll, mapDrawer, [mapDrawer.viewModel.layer.building]);
                    if (!ll) {
                        ll = curll;
                        this.snapped = false;
                    } else
                        this.snapped = true;

                    if (this.step === 0) {
                        if (polyLine.getVisible()) {
                            let polyLinePath = polyLine.getPath();
                            polyLinePath.setAt(1, ll.ToGMLatLng());
                            if (LatLng.FromGMLatLng(polyLinePath.getAt(0)).DistanceTo(ll) > 0.001)
                                this.step = 1;
                        } else {
                            polyLine.setVisible(true);
                            polyLine.setPath([ll.ToGMLatLng(), ll.ToGMLatLng()]);
                        }
                    } else {
                        this.makeMultiple(ll, mapDrawer);

                        this.polyLines.forEach(pl => {
                            let sp = new SeparationPolyline(mapDrawer, this.target, undefined, pl.getPath().getArray().map(gmll => new google.maps.LatLng(gmll.lat(), gmll.lng())), !!this.isCutting);
                        });

                        this.target.update();

                        mapDrawer.selectTool(this.name, this.curSubmenu);
                    }

                    return;
                }

                if (this.target.separationPolylines.some(sp => sp.hovered))
                    return;

                let separationPolyline = this.separationPolyline;
                lastll = polyLine.getVisible() ? LatLng.FromGMLatLng(polyLine.getPath().getAt(0)) : null;

                let ll = HelperLinesTool.getSnapPosition(lastll, curll, mapDrawer, [mapDrawer.viewModel.layer.building]);
                if (!ll) {
                    ll = curll;
                    this.snapped = false;
                } else
                    this.snapped = true;

                if (this.snapped || google.maps.geometry.poly.containsLocation(ll.ToGMLatLng(), this.target.poly as any)) {

                    if (!separationPolyline)
                        separationPolyline = this.separationPolyline = new SeparationPolyline(mapDrawer, this.target, undefined, undefined, !!this.isCutting);

                    separationPolyline.polyline.getPath().push(ll.ToGMLatLng());

                    if (!polyLine.getVisible())
                        polyLine.setVisible(true);

                    polyLine.setPath([ll.ToGMLatLng(), ll.ToGMLatLng()]);

                    this.target.update();
                }
            }

        }

        onKeyDown(keyCode: number, mapDrawer: MapDrawer) {
            if (keyCode === 68 && this.target) //d
                this.target.regenerate(true);
            else if (keyCode === 107) //add 
            {
                let multipleRidgesModel = mapDrawer.viewModel.currentSubViewModel as MultipleRidgesModel;
                if (multipleRidgesModel) {
                    multipleRidgesModel.incrementCount(+1);
                    this.makeMultiple(null, mapDrawer);
                }
            }
            else if (keyCode === 109) //subtract
            {
                let multipleRidgesModel = mapDrawer.viewModel.currentSubViewModel as MultipleRidgesModel;
                if (multipleRidgesModel) {
                    multipleRidgesModel.incrementCount(-1);
                    this.makeMultiple(null, mapDrawer);
                }
            }
        }

        makeMultiple(ll: LatLng, mapDrawer: MapDrawer) {
            if (!this.polyLines || !this.polyLines.length || !this.polyLines[0] || this.step === 0)
                return;

            let polyLines = this.polyLines,
                polyLine = polyLines[0],
                polyLinePath = polyLine.getPath();

            if (!ll)
                ll = LatLng.FromGMLatLng(polyLines[polyLines.length - 1].getPath().getAt(1));

            let multipleRidgesModel = mapDrawer.viewModel.currentSubViewModel as MultipleRidgesModel,
                p1 = LatLng.GMLatLngToPoint2D(polyLinePath.getAt(0)),
                p2 = LatLng.GMLatLngToPoint2D(polyLinePath.getAt(1)),
                p3 = ll.ToPoint2D(),
                count = +multipleRidgesModel.getCount(),
                dir = p1.sub(p2);

            if (mapDrawer.shiftDown) {
                let n = dir.GetNormalized().LeftHandNormal();
                p3 = p2.add(n.mul(n.dot(p3.sub(p2))));
            }

            if (polyLines.length !== count) {
                for (let j = 1, c = polyLines.length; j < c; j++) {
                    polyLines[j].setMap(null);
                    polyLines[j] = null;
                }

                polyLines = this.polyLines = [this.polyLines[0]];

                for (let j = 1, c = count; j < c; j++) {
                    polyLines.push(new google.maps.Polyline($.extend({
                        map: mapDrawer.map,
                        draggable: false,
                        editable: false,
                        clickable: false,
                        visible: true,
                        zIndex: 0,
                        path: [LatLng.Point2DToGMLatLng(p1), LatLng.Point2DToGMLatLng(p1)]
                    }, SeparationPolyline.shapeStyle) as google.maps.PolylineOptions));
                }
            }

            for (var i = 1, l = count - 1; i <= l; i++) {
                let t = i / l,
                    s = p2.mul(1 - t).add(p3.mul(t));

                let polyPath = polyLines[i].getPath();

                polyPath.setAt(1, LatLng.Point2DToGMLatLng(s));
                polyPath.setAt(0, LatLng.Point2DToGMLatLng(s.add(dir)));
            }
        }
    }

    export class CutTool extends RidgeTool {

        iconX = 15;
        iconY = 6;

        constructor(mapDrawer: MapDrawer, name: string) {
            super(mapDrawer, name);
            this.isCutting = true;
        }
    }

    export class DeactivateTool extends BaseToolIcon {

        iconX = 1;
        iconY = 4;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var viewModel = mapDrawer.viewModel;

        }

        onDeselect(mapDrawer: MapDrawer) {
            var viewModel = mapDrawer.viewModel;

        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer): void {
            var polys = mapDrawer.getPolygonsByPoint(ev.latLng).filter(poly => poly instanceof google.maps.Polygon && poly.userData && poly.userData instanceof MapDrawing.ShapeData && poly.userData.typeName === window.RoofTypeName);
            if (polys.length) {
                var sh = polys[0].userData as ShapeData;
                sh.isActive = !sh.isActive;
            }
        }
    }

    export class InfoButton extends BaseToolIcon {
        iconX = 16;
        iconY = 0;

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var viewmodel = mapDrawer.viewModel;
            DynToolTip.showHowToInfo('_HowToMapDrawer', 'howTo_mapDrawerViewWrapper', 'mapDrawerStart', null, true);

            if (viewmodel.currentSubViewModel != null) {
                mapDrawer.selectTool("SelectTool");
                return;
            }
        }

        onDeselect(mapDrawer: MapDrawer) {
            var viewModel = mapDrawer.viewModel;
            viewModel.currentSubViewModel = null;
        }
    }

    export class MappingTool extends BaseToolIcon {
        iconX = 7;
        iconY = 0;

        rectanglePoly: google.maps.Polygon = null;

        mappingPolygons: google.maps.Polygon[] = [];

        mappingOpts: google.maps.PolygonOptions = {
            draggable: false,
            editable: false,
            clickable: false,
            strokeWeight: 3,
            fillColor: '#f44b42',
            fillOpacity: 0.5,
            strokeColor: '#f44b42',
            strokeOpacity: 0.8,
        }

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            var center = mapDrawer.center;

            var rectangleOpts: google.maps.PolygonOptions = {
                map: mapDrawer.map,
                draggable: false,
                editable: false,
                clickable: false,
                strokeWeight: 1.5,
                fillColor: '#252525',
                fillOpacity: 0.2,
                strokeColor: '#252525',
                strokeOpacity: 0.8,
                paths: [new google.maps.LatLng(0, 0), new google.maps.LatLng(0, 0), new google.maps.LatLng(0, 0), new google.maps.LatLng(0, 0)]
            };

            this.mappingPolygons = [];
            this.rectanglePoly = new google.maps.Polygon(rectangleOpts);

            this.makeRectangle(this.rectanglePoly.getPath(), mapDrawer);
        }

        onDeselect(mapDrawer: MapDrawer) {
            if (this.rectanglePoly) {
                this.rectanglePoly.setMap(null);
                this.rectanglePoly = null;
            }
            if (this.mappingPolygons && this.mappingPolygons.length) {
                this.mappingPolygons.forEach(poly => {
                    poly.setMap(null);
                });
            }
            this.mappingPolygons = [];
        }

        onMousemove(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) {
            if (LoaderManager.isLoading())
                return;

            this.makeRectangle(this.rectanglePoly.getPath(), mapDrawer, LatLng.FromGMLatLng(ev.latLng));
        }

        showImg(lat: number, lng: number) {
            var src = "../handler/StaticImagesHandler.ashx?ID=Experiment&center=" + lat + "," + lng + "&zoom=18&size=300x300&scale=1";

            if (!$('#mt-test_img').length) {
                $('<div id="mt-test_img" style="width: auto; height: auto; padding: 0px; display: none;"></div>').appendTo(document.body);
                DManager.init('mt-test_img', 'auto', 'auto', SetWidthDialogTitleBarWidth, SetPaddingBack);
            } else {
                $('#mt-test_img').empty();
            }

            var img = new Image();

            img.width = 300;
            img.height = 300;

            $('#mt-test_img').get(0).appendChild(img);

            LoaderManager.addLoadingJob();

            img.onload = () => {

                LoaderManager.finishLoadingJob();

                img.onload = null;

                DManager.show('mt-test_img');
            };

            img.src = src;

            return true;
        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer): void {
            if (LoaderManager.isLoading())
                return;

            var centerLatLng = LatLng.FromGMLatLng(ev.latLng);

            if (this.showImg(centerLatLng.Latitude, centerLatLng.Longitude))
                return;

            this.makeRectangle(this.rectanglePoly.getPath(), mapDrawer, centerLatLng);

            SolarProTool.Ajax("WebServices/MapServices.asmx").Call("TestMapping").Data({ lat: centerLatLng.Latitude, lng: centerLatLng.Longitude }).CallBack(res => {
                var mappingPolygons = this.mappingPolygons;
                if (mappingPolygons && mappingPolygons.length) {
                    mappingPolygons.forEach(poly => {
                        poly.setMap(null);
                    });
                }
                mappingPolygons = [];
                if (res && res.length) {
                    var mappingOpts = this.mappingOpts;

                    res.forEach(pr => {
                        mappingPolygons.push(new google.maps.Polygon($.extend({ map: mapDrawer.map, paths: pr.map(ll => new google.maps.LatLng(ll.Latitude, ll.Longitude)) }, mappingOpts) as google.maps.PolygonOptions));
                    });
                }
            });

        }

        makeRectangle(path: google.maps.MVCArray<google.maps.LatLng>, mapDrawer: MapDrawer, latlng?: LatLng): void {

            if (!latlng)
                latlng = LatLng.FromGMLatLng(mapDrawer.map.getCenter());

            var latLngBds = BoundsLatLng.FromCenterAndZoom(latlng, 18, 300, 300);

            var corners = latLngBds.Corners;

            path.setAt(0, corners[0].ToGMLatLng());
            path.setAt(1, corners[1].ToGMLatLng());
            path.setAt(2, corners[2].ToGMLatLng());
            path.setAt(3, corners[3].ToGMLatLng());

        }
    }
}