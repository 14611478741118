module MapDrawing {

    export enum ElementPositionType {
        None = 0, // 0000
        Top = 1 << 0, // 0001 -- the bitshift is unnecessary, but done for consistency
        Right = 1 << 1,     // 0010
        Bottom = 1 << 2,    // 0100
        Left = 1 << 3,    // 1000
    }

    export interface IShapeStyle { //SolarProTool.WebServices.ProjectServices.ShapeStyle
        fillColor?: string; // System.String
        fillOpacity?: number; // System.Double?
        strokeColor?: string; // System.String
        strokeOpacity?: number; // System.Double?
        strokeWeight?: number; // System.Double?
        zIndex?: number;
        clickable?: boolean;
        editable?: boolean;
        draggable?: boolean;
        visible?: boolean;
    }

    export interface IPolygonLayer { //SolarProTool.WebServices.ProjectServices.PolygonLayer
        count?: number; // System.Int32
        maxCount?: number; // System.Int32?
        typeName: string; // System.String
        layerName: string; // System.String
        locked?: boolean; // System.Boolean
        shapeSelectedHoveredStyle?: IShapeStyle; // SolarProTool.WebServices.ProjectServices.ShapeStyle
        shapeSelectedStyle?: IShapeStyle; // SolarProTool.WebServices.ProjectServices.ShapeStyle
        shapeHoveredStyle?: IShapeStyle; // SolarProTool.WebServices.ProjectServices.ShapeStyle
        shapeStyle?: IShapeStyle; // SolarProTool.WebServices.ProjectServices.ShapeStyle
        zIndex?: number;
        parent?: IPolygonLayer;
        child?: IPolygonLayer;
        controlledByParent?: boolean;
        isPassive?: boolean;
    }

    export interface ISelectableItem {
        id: string;
        typeName: string;
        locked: boolean;
        frozen: boolean;
        selected: boolean;
        hovered: boolean;
        _isDisposed: boolean;
        pos: Point2D;
        rotation: number;
        dispose();
        remove(mapDrawer: MapDrawer);
        moveByLatLng(latLng: LatLng);
        moveByOffset(offset: Point2D);
        updateStyle();
        getMap(): google.maps.Map;
        getSegments(): Segment2D[];
        getPoints(): Point2D[][];
        update(): void;
    }

    export interface IImportedImageFileInfo {
        ProjectId: string;
        RoofId: string;
        Id: string;
        Latitude: number;
        Longitude: number;
        X: number;
        Y: number;
        RealWidth: number;
        RealHeight: number;
        Orientation: number;
    }

    export enum EditorModeEnum {
        Building = 0, //roof
        Area = 1, //carport area
        FreeArea = 2 //freearea
    }

    //SPTEnums/RoofEnum.cs
    export enum CadImportErrorCodeEnum {
        Success = 0,
        FileToBig,
        InvalidFile,
        InvalidFileName,
        InvalidFileExtension,
        InvalidImage,
        FileIsEmpty
    }

    //SPTEnums/SeparationPolylinesTypeEnum.cs
    export enum SeparationPolylinesTypeEnum {
        Ridge = 0,
        Eave
    }

    export interface IImportCadResult {
        ErrorCode: CadImportErrorCodeEnum;
        IsCADImport: boolean;
        CadLayers: string[];
        CadLayerSelection: string[];
        ImageWidth: number;
        ImageHeight: number;
        RealWidth: number;
        RealHeight: number;
        ImageInfo: IImportedImageFileInfo;
    }

    export interface ISubToolIcon {
        selected?: boolean;
        visible?: boolean;
        name: string;
        iconsetOffset?: string;
        iconX?: number;
        iconY?: number;
    }

    export class BaseSubToolIcon implements ISubToolIcon {
        constructor(name: string, iconX?: number, iconY?: number) {
            this.name = name;
            this.iconX = iconX || 0;
            this.iconY = iconY || 0;

            ko.track(this);

            ko.defineProperty(this, "iconsetOffset", () => {
                return `-${this.iconX * 30}px -${this.iconY * 30}px`;
            });
        }
        iconX: number;
        iconY: number;
        name: string;
        iconsetOffset: string;
        visible = true;
    }

    export class BaseSubToolIconNew implements ISubToolIcon {
        constructor(name: string, className: string, pathCount: number) {
            this.name = name;
            this.className = className;
            this.pathCount = pathCount;

            ko.track(this);
        }
        className: string;
        name: string;
        pathCount: number;
        visible = true;
    }

    export class BaseToolIcon {
        constructor(mapDrawer: MapDrawer, name: string) {
            ko.track(this);

            this.name = name;

            this.iconX = this.iconX || 0;
            this.iconY = this.iconY || 0;

            Object.defineProperty(this, "iconsetOffset",
                {
                    enumerable: true,
                    configurable: true,
                    get: () => {
                        return `-${this.iconX * 30}px -${this.iconY * 30}px`;
                    }
                });
        }

        subMenu: ISubToolIcon[] = [];
        curSubmenu: string = null;
        iconX: number;
        iconY: number;
        Cursor: string;
        name: string;
        iconsetOffset: string;
        visible = true;
        disabled = false;
        isDragging = false;

        getSubTool(name: string): ISubToolIcon {
            if (!name)
                return null;
            var submenu = this.subMenu;
            for (var i = 0, j = submenu.length; i < j; i++) {
                var m = submenu[i];
                if (m.name === name)
                    return m;
            }
            return null;
        }

        select(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {
            this.curSubmenu = subMenu || null;
            this.onSelect(mapDrawer, prevTool, subMenu);
        }

        deselect(mapDrawer: MapDrawer) {
            this.onDeselect(mapDrawer);
        }

        onSelect(mapDrawer: MapDrawer, prevTool: BaseToolIcon, subMenu: string) {

        }

        onDeselect(mapDrawer: MapDrawer) {

        }

        onClick(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) { } // mousedown
        onMouseUp(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) { }

        onMousemove(ev: google.maps.MouseEvent, mapDrawer: MapDrawer) { }
        onKeyDown(keyCode: number, mapDrawer: MapDrawer) { }
        onKeyUp(keyCode: number, mapDrawer: MapDrawer) { }
        onCommandText(mapDrawer: MapDrawer, cmd: string) { }
    }

    export class PolyData {
        constructor(id: string, latLngs: LatLng[][], typeName: string, title: string, height: number) {
            this.latLngs = latLngs;
            this.typeName = typeName;
            this.title = title;
            this.height = height;
            this.id = id || spt.Utils.GenerateGuid();
        }

        static fromShapeData(shapeData: ShapeData, id?: string): PolyData {
            return new PolyData(id || shapeData.id, shapeData.latLngs.map(lls => lls.slice()), shapeData.typeName, shapeData.title, shapeData.height);
        }

        latLngs: LatLng[][];
        typeName: string;
        title: string;
        height: number;
        id: string;

    }

    export class ShapeData implements IGmUserData {
        constructor(mapDrawer: MapDrawer, poly: IGoogleMapsPolygon, id?: string, title?: string, typeName?: string) {
            id = id || spt.Utils.GenerateGuid();
            this.title = title || "Form";
            this.typeName = typeName || null;
            this.height = 0;
            this._slope = 0;
            this.poly = poly;
            this.mapDrawer = mapDrawer;

            if (typeName === window.BuildingTypeName) {
                if ($("#Roof_Angle").length) {
                    this._slope = spt.Utils.GetFloatFromInput("#Roof_Angle",
                        {
                            min: 0,
                            max: 89,
                            precision: 2,
                            notImperial: true,
                            isFeet: false
                        }) || 0;
                }

                if ($("#Roof_RoofHeight").length) {
                    this.height = spt.Utils.GetFloatFromInput("#Roof_RoofHeight",
                        {
                            min: 0,
                            max: 1000000,
                            applyArithmetic: false,
                            isFeet: window.UseImperialSystem,
                            notImperial: !window.UseImperialSystem,
                            from: mapDrawer.viewModel.valueAdjustOpts.to,
                            to: "mm"
                        }) || 10000;
                }
            }

            ko.track(this);

            poly.userData = this;

            this.mType = 'ShapeData';
            this.subsciptions = [];

            mapDrawer.viewModel.setInstance(id, this);
            Object.defineProperty(this, "id",
                {
                    configurable: true,
                    enumerable: true,
                    get: () => {
                        return id;
                    },
                    set: (v) => {
                        if (id && mapDrawer.viewModel.hasInstance(id))
                            mapDrawer.viewModel.removeInstance(id);
                        id = v;
                        if (id && !this._isDisposed)
                            mapDrawer.viewModel.setInstance(id, this);
                    }
                });

            ko.defineProperty(this, "frozen",
                {
                    get: () => {
                        return this._frozen || this.controlledByParent;
                    },
                    set: (v: boolean) => {
                        this._frozen = v;
                    }
                });

            ko.defineProperty(this, "locked",
                {
                    get: () => {
                        return this._locked || (this.controlledByParent && this.parent && this.parent.locked);
                    },
                    set: (v: boolean) => {
                        this._locked = v;
                    }
                });

            ko.defineProperty(this, "latLngs",
                {
                    get: () => {
                        var poly = this.poly;
                        if (!poly)
                            return [];
                        var paths = poly.getPaths();
                        if (paths)
                            return paths.getArray().map(path => path.getArray().map((ll: google.maps.LatLng) => LatLng.FromGMLatLng(ll)));
                        return [];
                    },
                    set: (latLngs: LatLng[][]) => {
                        if (this._isDisposed)
                            return;

                        var poly = this.poly;

                        if (poly && latLngs)
                            poly.setPaths(latLngs.map(lls => lls.map(ll => ll.ToGMLatLng())));
                    }
                });

            ko.defineProperty(this, "points", () => {
                if (!this.poly || !this.mapDrawer)
                    return [];
                var viewModel = this.mapDrawer.viewModel,
                    axis = viewModel.referenceAxis,
                    latLngs = this.latLngs,
                    refLatLng = viewModel.referenceLatLng,
                    height = this.height;
                return latLngs.map(lls => lls.map(ll => refLatLng.DirectionTo(ll).mul(1000).Project(axis).to3D(height)));
            });

            ko.defineProperty(this, "labelPosition", () => {
                if (!this.poly || !this.mapDrawer)
                    return new Circle2D();

                let viewModel = this.mapDrawer.viewModel,
                    latLngs = this.latLngs;

                if (!latLngs || !latLngs.length)
                    return new Circle2D();

                let lls = latLngs[0],
                    refLatLng = viewModel.referenceLatLng;

                if (!lls || !lls.length)
                    return new Circle2D();

                if (lls.length < 3)
                    return new Circle2D(lls[0].ToPoint2D(), 0);

                return MapDrawing.biggestCircleInLatLngPolygon(lls, refLatLng);
            });

            ko.defineProperty(this, "bounds", () => {
                return BoundsLatLng.FromLatLngs(this.latLngs);
            });

            ko.defineProperty(this, "pos",
                {
                    get: () => {
                        if (!this.poly || !this.mapDrawer)
                            return Point2D.Zero;
                        var viewModel = this.mapDrawer.viewModel,
                            refLatLng = viewModel.referenceLatLng,
                            axis = viewModel.referenceAxis,
                            origin = this.originLatLng;
                        return refLatLng.DirectionTo(origin).mul(1000).Project(axis);
                    },
                    set: (p: Point2D) => {
                        if (!this.poly)
                            return;
                        var viewModel = this.mapDrawer.viewModel,
                            axis = viewModel.referenceAxis;

                        let t = p.sub(this.pos).mul(0.001).Unproject(axis),
                            center = BoundsLatLng.FromLatLngs(this.latLngs).Center,
                            transform = Transformation3D.Translation(center.Offset(t.y, t.x).ToPoint2D().sub(center.ToPoint2D()));

                        this.applyTransform(transform, mapDrawer);

                        //let latLngs = this.latLngs.map(lls => lls.map(ll => ll.Offset(t.y, t.x).ToGMLatLng()));

                        //if (latLngs.length) {
                        //    this.poly.setPaths(latLngs);
                        //    this.update();

                        //    if (this.typeName === window.RoofTypeName && this.mType === 'ShapeData') {
                        //        //move all others
                        //        mapDrawer.viewModel.foreachItem(sel => {
                        //            if (sel instanceof ShapeData) {
                        //                let latLngs = sel.latLngs,
                        //                    newLatLngs = latLngs.map(lls => lls.map(ll => ll.Offset(t.y, t.x).ToGMLatLng()));
                        //                if (newLatLngs.length) {
                        //                    sel.poly.setPaths(newLatLngs);
                        //                    sel.update();
                        //                }
                        //            }
                        //        }, this.id);
                        //    }
                        //}
                    }
                });

            ko.defineProperty(this, "rotation",
                {
                    get: () => {
                        return this._rot;
                    },
                    set: (r: number) => {
                        if (!this.poly)
                            return;
                        var tr = r - this._rot;
                        this._rot = r;
                        if (tr != 0) {
                            let pts = this.getBorderPoints(),
                                centroid = Point2D.computeCenter(pts);
                            if (centroid) {
                                let transform = Transformation3D.Rotation(LatLng.Grad2Rad(tr), centroid);
                                this.applyTransform(transform, mapDrawer, pts);

                                //var newLatLngs = pts.map(ps => ps.map(p => LatLng.FromPoint2D(transform.transform(p)).ToGMLatLng()));
                                //if (newLatLngs.some(nlls => nlls.length > 0)) {
                                //    this.poly.setPaths(newLatLngs);
                                //    this.update();

                                //    if (this.typeName === window.RoofTypeName && this.mType === 'ShapeData') {
                                //        //move all others
                                //        mapDrawer.viewModel.foreachItem(sel => {
                                //            if (sel instanceof ShapeData) {
                                //                let pts = sel.getBorderPoints(),
                                //                    newLatLngs = pts.map(ps => ps.map(p => LatLng.FromPoint2D(transform.transform(p)).ToGMLatLng()));
                                //                if (newLatLngs.length) {
                                //                    sel.poly.setPaths(newLatLngs);
                                //                    sel.update();
                                //                }
                                //            }
                                //        }, this.id);
                                //    }
                                //}
                            }
                        }

                    }
                });

            ko.defineProperty(this, "scale",
                {
                    get: () => {
                        return this._scale;
                    },
                    set: (v: number) => {
                        if (!this.poly)
                            return;
                        let s = v / this._scale;
                        this._scale = v;
                        if (s != 1) {
                            let pts = this.getBorderPoints(),
                                centroid = Point2D.computeCenter(pts);
                            if (centroid) {
                                let transform = Transformation3D.Translation(-centroid.x, -centroid.y).multiply(Transformation3D.Scale(s, s)).multiply(Transformation3D.Translation(centroid.x, centroid.y));
                                this.applyTransform(transform, mapDrawer, pts);

                                //var newLatLngs = pts.map(ps => ps.map(p => LatLng.FromPoint2D(transform.transform(p)).ToGMLatLng()));
                                //if (newLatLngs.some(nlls => nlls.length > 0)) {
                                //    this.poly.setPaths(newLatLngs);
                                //    this.update();

                                //    if (this.typeName === window.RoofTypeName && this.mType === 'ShapeData') {
                                //        //move all others
                                //        mapDrawer.viewModel.foreachItem(sel => {
                                //            if (sel instanceof ShapeData) {
                                //                let pts = sel.getBorderPoints(),
                                //                    newLatLngs = pts.map(ps => ps.map(p => LatLng.FromPoint2D(transform.transform(p)).ToGMLatLng()));
                                //                if (newLatLngs.length) {
                                //                    sel.poly.setPaths(newLatLngs);
                                //                    sel.update();
                                //                }
                                //            }
                                //        }, this.id);
                                //    }
                                //}
                            }
                        }

                    }
                });

            ko.defineProperty(this, "originLatLng", () => {
                if (this.typeName === window.BuildingTypeName)
                    return LatLng.FromPoint2D(this.getOrientedOrigin());

                var viewModel = this.mapDrawer.viewModel;
                return LatLng.FromPoint2D(this.getOrientedOrigin(viewModel.referenceOrientation));
            });

            ko.defineProperty(this, "slope",
                {
                    get: () => {
                        return this.slopeOverride ? this.customSlope : this._slope;
                    },
                    set: (v: number) => {
                        this._slope = v || 0;
                        if (!this.slopeOverride)
                            this.customSlope = v || 0;
                    }
                });

            ko.defineProperty(this, "ridgeLines", () => {
                if (this.separationPolylines && this.separationPolylines.length)
                    return this.separationPolylines.filter(sp => !sp.isCutting);
                return [] as SeparationPolyline[];
            });

            ko.defineProperty(this, "eavesLines", () => {
                if (this.separationPolylines && this.separationPolylines.length)
                    return this.separationPolylines.filter(sp => sp.isCutting);
                return [] as SeparationPolyline[];
            });

            //this.subsciptions.push(ko.getObservable(this, 'poly').subscribe(this.onPolygonChangedIntern.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'isActive').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'hidden').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'selected').subscribe(this.updateSelected.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'hovered').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'locked').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'frozen').subscribe(this.updateStyle.bind(this)));

            //this.onPolygonChangedIntern();
        }

        subsciptions: KnockoutSubscription[];
        mapDrawer: MapDrawer;
        mType: string;
        poly: IGoogleMapsPolygon;
        id: string;
        title: string;
        isParallel: boolean = true;
        typeName: string;
        height: number;
        //slope in degree
        slope: number;
        private _slope: number = 0;
        slopeOverride: boolean = false;
        customSlope: number = 0;
        useProjection: boolean = true;
        isActive: boolean = true;
        //private _isActive: boolean = true;
        private _active: boolean = true;
        latLngs: LatLng[][];
        points: IPoint3D[][];
        readonly bounds: BoundsLatLng;
        labelPosition: Circle2D;

        _locked: boolean = false;
        locked: boolean;
        _frozen: boolean = false;
        frozen: boolean;
        controlledByParent: boolean = false;
        hidden: boolean = false;
        pos: Point2D;
        private _rot: number = 0;
        rotation: number;
        private _scale: number = 1;
        scale: number;
        _isDisposed: boolean;
        selected: boolean = false;
        hovered: boolean = false;
        southMarker: SouthMarker = null;
        dragPosition: LatLng;
        currentStyle: keyof PolygonLayer;
        isFrozen: boolean;
        isLocked: boolean;
        skipUpdate: boolean;
        originLatLng: LatLng;
        separationPolylines: SeparationPolyline[] = [];
        ridgeLines: SeparationPolyline[];
        eavesLines: SeparationPolyline[];
        parent: ShapeData = null;
        children: ShapeData[] = [];

        measureTexts: OverlayText[] = [];
        angleTexts: OverlayText[] = [];
        coordsTexts: OverlayText[] = [];
        coordsNumberTexts: OverlayText[] = [];

        clearTexts() {
            if (this.measureTexts.length) {
                this.measureTexts.forEach(ot => { ot.dispose(); });
                this.measureTexts = [];
            }
            if (this.angleTexts.length) {
                this.angleTexts.forEach(ot => { ot.dispose(); });
                this.angleTexts = [];
            }
            if (this.coordsTexts.length) {
                this.coordsTexts.forEach(ot => { ot.dispose(); });
                this.coordsTexts = [];
            }
            if (this.coordsNumberTexts.length) {
                this.coordsNumberTexts.forEach(ot => { ot.dispose(); });
                this.coordsNumberTexts = [];
            }
        }

        detailsViewVisible: boolean = false;

        setPaths(latLngs: google.maps.LatLng[][]) {
            this.poly.setPaths(latLngs);

            this.update();
        }

        applyTransform(transform: Transformation3D, mapDrawer: MapDrawer, pts?: Point2D[][], skipChildren?: boolean) {
            if (!pts)
                pts = this.getBorderPoints();

            var newLatLngs = pts.map(ps => ps.map(p => LatLng.FromPoint2D(transform.transform(p)).ToGMLatLng()));

            if (newLatLngs.some(nlls => nlls.length > 0)) {
                this.poly.setPaths(newLatLngs);

                this.separationPolylines.forEach(sp => {
                    sp.applyTransform(transform);
                });

                if (this.typeName === window.BuildingTypeName && this.mType === 'ShapeData' && !skipChildren) {
                    //move all others
                    mapDrawer.viewModel.foreachItem(sel => {
                        if (sel instanceof ShapeData) {
                            let pts = sel.getBorderPoints(),
                                newLatLngs = pts.map(ps => ps.map(p => LatLng.FromPoint2D(transform.transform(p)).ToGMLatLng()));
                            if (newLatLngs.length) {
                                sel.poly.setPaths(newLatLngs);
                                sel.update();
                            }
                        }
                    }, this.id);

                    mapDrawer.viewModel.importedImages.forEach(img => { img.applyTransform(transform, mapDrawer); });

                    if (this.southMarker)
                        this.southMarker.applyTransform(transform, mapDrawer);
                }

                this.update();
            }
        }

        toggleDetailsViewVisible(b) {
            this.detailsViewVisible = !!b;
            if (b && !this.mapDrawer.viewModel.showCoordNumbers)
                this.mapDrawer.viewModel.showCoordNumbers = true;
        }

        toggleLocked(b) {
            this.locked = !!b;
        }

        moveByLatLng(latLng: LatLng) {
            if (this._isDisposed)
                return;

            var poly = this.poly,
                paths = poly ? poly.getPaths() : null;

            if (paths)
                poly.setPaths(paths.getArray().map(path => path.getArray().map(ll => new google.maps.LatLng(ll.lat() + latLng.Latitude, ll.lng() + latLng.Longitude))));

            this.separationPolylines.forEach(sp => {
                sp.moveByLatLng(latLng);
            });

            if (this.southMarker)
                this.southMarker.moveByLatLng(latLng);
        }

        moveByOffset(offset: Point2D) {
            if (this._isDisposed)
                return;

            var poly = this.poly,
                paths = poly ? poly.getPaths() : null;

            if (paths)
                poly.setPaths(paths.getArray().map(path => path.getArray().map(ll => LatLng.FromGMLatLng(ll).Offset(offset.y, offset.x).ToGMLatLng())));

            this.separationPolylines.forEach(sp => {
                sp.moveByOffset(offset);
            });

            if (this.southMarker)
                this.southMarker.moveByOffset(offset);
        }

        //tolerance in meters
        hasSegment(llStart: LatLng, llEnd: LatLng, tolerance = 0.001) {
            if (this._isDisposed || !this.poly)
                return false;
            var latLngs = this.latLngs;

            for (var j = 0, llen = latLngs.length; j < llen; j++) {
                var lls = latLngs[j],
                    len = lls.length;

                if (len <= 2)
                    continue;

                for (var i = 0; i < len; i++) {
                    var llst = lls[i],
                        lle = lls[(i + 1) % len];
                    if ((llst.DistanceTo(llStart) <= tolerance && lle.DistanceTo(llEnd) <= tolerance) ||
                        (llst.DistanceTo(llEnd) <= tolerance && lle.DistanceTo(llStart) <= tolerance))
                        return true;
                }
            }

            return false;
        }

        getSegments(checkOrientation?: boolean): Segment2D[] {
            if (this._isDisposed || !this.poly)
                return [];
            var latLngs = this.latLngs;

            var result: Segment2D[] = [];

            latLngs.forEach(lls => {
                var points = lls.map(ll => ll.ToPoint2D());

                var len = points.length;

                if (len <= 2)
                    return;

                if (checkOrientation && Point2D.isClockwise(points))
                    points.reverse();

                for (var i = 0; i < len; i++) {
                    var p1 = points[i],
                        p2 = points[(i + 1) % len];

                    result.push(new Segment2D(p1, p2));
                }
            });

            if (this.separationPolylines && this.separationPolylines.length)
                this.separationPolylines.forEach(spl => {
                    result.push.apply(result, spl.getSegments());
                });

            return result;
        }

        getBorderPoints(): Point2D[][] {
            if (this._isDisposed || !this.poly)
                return [];

            return this.latLngs.map(lls => lls.map(ll => ll.ToPoint2D()));
        }

        getPoints(): Point2D[][] {
            if (this._isDisposed || !this.poly)
                return [];

            var result = this.getBorderPoints();

            if (this.separationPolylines && this.separationPolylines.length)
                this.separationPolylines.forEach(spl => {
                    result.push.apply(result, spl.getPoints());
                });

            return result;
        }

        isClockwise(): boolean {
            return Point2D.isClockwise(this.points as Point3D[][]);
        }

        getOrientedOrigin(orientation?: number): Point2D {

            if (orientation == undefined)
                orientation = this.southMarker ? this.southMarker.orientation : 0;

            var points = this.getBorderPoints(),
                xAxis = Point2D.FromOrientation(orientation),
                yAxis = xAxis.rot90(),
                bounds = Bounds2D.FromPoints(points),
                center = bounds.Center;

            if (bounds.IsEmpty || orientation == 0)
                return bounds.Min;

            bounds = Bounds2D.FromPoints(points.map<Point2D[]>(ps => ps.map(p => new Point2D(xAxis.dot(p.sub(center)), yAxis.dot(p.sub(center))))));

            return center.add(xAxis.mul(bounds.Min.x)).add(yAxis.mul(bounds.Min.y));
        }

        isOnEdgePoint(p: Point2D): boolean {
            var latLngs = this.latLngs,
                pxFactor = this.mapDrawer.getPixelFactor();

            if (!latLngs || !latLngs.length)
                return false;

            for (var i = 0, j = latLngs.length; i < j; i++) {
                let lls = latLngs[i];
                for (let k = 0, l = lls.length; k < l; k++) {
                    let latLng = lls[k],
                        nextLatLng = lls[(k + 1) % l];

                    if (p.sub(latLng.ToPoint2D()).mul(pxFactor).GetLengthSquared() <= 42.25) { // 6,5 px
                        return true;
                    }

                    if (p.sub(latLng.add(nextLatLng).mul(0.5).ToPoint2D()).mul(pxFactor).GetLengthSquared() <= 42.25) { // 6,5 px
                        return true;
                    }
                }
            }

            for (var m = 0, n = this.separationPolylines.length; m < n; m++) {
                let lls = this.separationPolylines[m].polyline.getPath().getArray().map(ll => LatLng.FromGMLatLng(ll));

                for (let k = 0, l = lls.length; k < l; k++) {
                    let latLng = lls[k];

                    if (p.sub(latLng.ToPoint2D()).mul(pxFactor).GetLengthSquared() <= 42.25) { // 6,5 px
                        return true;
                    }
                }

            }

            return false;
        }

        updateSelected() {

            if (this.children.length) {
                setTimeout(() => {
                    if (!this._isDisposed && !this.selected && this.children.length) {
                        var selChildren = this.children.filter(ch => !ch._isDisposed && ch.selected);
                        if (selChildren.length)
                            selChildren.forEach(s => this.mapDrawer.removeItemFromSelection(s));
                    }
                }, 0);
            }

            this.updateStyle();
        }

        selectChildren(latLng: LatLng) {
            var ll = latLng.ToGMLatLng();
            this.selectChildrenByIds(this.children.filter(ch => !ch._isDisposed && google.maps.geometry.poly.containsLocation(ll, ch.poly as any)).map(ch => ch.id));
        }

        selectChildrenByIds(childIds: string[]) {

            var mapDrawer = this.mapDrawer,
                forceSelection = !mapDrawer.ctrlDown && !mapDrawer.shiftDown,
                addSelection = mapDrawer.ctrlDown !== mapDrawer.shiftDown,
                removeSelection = mapDrawer.ctrlDown && mapDrawer.shiftDown;

            //setTimeout(() => {
            if (!this._isDisposed && this.selected && this.children.length) {
                if (forceSelection) {
                    var selChildren = this.children.filter(ch => !ch._isDisposed && ch.selected);
                    if (selChildren.length)
                        selChildren.forEach(s => mapDrawer.removeItemFromSelection(s));
                }
                if (forceSelection || addSelection) {
                    this.children.forEach(ch => {
                        if (childIds.indexOf(ch.id) !== -1)
                            mapDrawer.addItemToSelection(ch);
                    });
                } else if (removeSelection) {
                    this.children.forEach(ch => {
                        if (childIds.indexOf(ch.id) !== -1)
                            mapDrawer.removeItemFromSelection(ch);
                    });
                }
            }
            //}, 0);
        }

        updateStyle() {
            if (!this.mapDrawer || !this.poly)
                return;
            var poly = this.poly,
                layer = this.mapDrawer.viewModel.layer.typeName[this.typeName],
                hovered = this.hovered,
                selected = this.selected,
                locked = this.locked,
                clickable = !this.frozen && !locked,
                hidden = this.hidden,
                isActive = this.isActive,
                controlledByParent = this.controlledByParent;

            if (!layer)
                return;

            //if (!clickable && this.selected) {
            //    this.mapDrawer.selectShape(this, false, false, true);
            //    return;
            //}

            //this.separationPolylines.forEach(spolyline => {
            //    spolyline.selected = selected;
            //});

            let newStyle: keyof PolygonLayer = hidden ? null : (selected ? (hovered ? 'shapeSelectedHoveredStyle' : 'shapeSelectedStyle') : (hovered ? 'shapeHoveredStyle' : 'shapeStyle'));

            var opts: google.maps.PolygonOptions = <google.maps.PolygonOptions>null;

            if (this.currentStyle !== newStyle || this._active !== isActive) {
                this._active = isActive;
                opts = <google.maps.PolygonOptions>(newStyle ? $.extend({}, layer[newStyle]) : {});
                if (!isActive)
                    opts.fillOpacity = 0;
                this.currentStyle = newStyle;
            }

            if (this.isLocked !== locked) {
                this.isLocked = locked;
                if (!opts)
                    opts = <google.maps.PolygonOptions>(newStyle ? $.extend({}, layer[newStyle]) : {});

                opts.clickable = clickable;
            }

            if (this.isFrozen !== this.frozen) {
                this.isFrozen = this.frozen;
                if (!opts)
                    opts = {};
                opts.clickable = clickable;
            }

            if (!controlledByParent && poly.getEditable() != selected) {
                if (!opts)
                    opts = {};
                opts.editable = !!selected;
                opts.draggable = false;

                //this.separationPolylines.forEach(spolyline => {
                //    spolyline.visible = opts.editable;
                //});
            }

            if (opts) {
                if (opts.visible === undefined)
                    opts.visible = !hidden;
                opts.strokeWeight = 2.5;

                if (locked) {
                    if (opts.fillColor)
                        opts.fillColor = (new Color().setStyle(opts.fillColor).getGrayscale()).toString();
                    if (opts.strokeColor)
                        opts.strokeColor = (new Color().setStyle(opts.strokeColor).getGrayscale()).toString();
                    if (this.detailsViewVisible)
                        this.detailsViewVisible = false;
                }

                poly.setOptions(opts);
            }

        }

        setHeight(v: string | number) {
            this.height = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });

            this.regenerate();
        }

        refreshRoofSettings() {
            var newHeight = $("#detailsRoofHeight").val();
            var newSlope = $('#detailsRoofAngle').val();
            lsGoogleMaps.saveRoofSettings(newHeight, newSlope);
        }

        setSlope(v: string | number) {
            this.slope = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -89,
                    max: 89,
                    applyArithmetic: true,
                    isFeet: false,
                    notImperial: true
                });

            this.regenerate();
        }

        setSlopeOverride(v?: boolean) {
            this.slopeOverride = !!v;

            if (this.parent)
                this.parent.regenerate();
        }

        setCustomSlope(v: string | number) {
            this.customSlope = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 89,
                    applyArithmetic: true,
                    isFeet: false,
                    notImperial: true
                });

            if (this.parent)
                this.parent.regenerate();
        }

        setPosX(v: string | number) {
            var y = this.pos.y;
            var x = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.pos = new Point2D(x, y);
        }

        setPosY(v: string | number) {
            var x = this.pos.x;
            var y = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.pos = new Point2D(x, y);
        }

        setPointX(pathIndex: number, index: number, viewModel: ViewModel, v: string | number) {
            var poly = this.poly,
                paths = poly ? poly.getPaths() : null;

            if (!paths || paths.getLength() <= pathIndex)
                return;

            var path = paths.getAt(pathIndex),
                pts = this.points[pathIndex],
                axis = viewModel.referenceAxis;

            if (index >= 0 && path && path.getLength() > index && pts && pts.length > index) {
                var p = pts[index],
                    latLng = this.latLngs[pathIndex][index];

                var dx = spt.Utils.GetFloatFromInputValue("" + v,
                    {
                        min: -1000000,
                        max: 1000000,
                        applyArithmetic: true,
                        isFeet: window.UseImperialSystem,
                        notImperial: !window.UseImperialSystem,
                        from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                        to: "mm"
                    }) - p.x;

                if (dx) {
                    var t = new Point2D(dx * 0.001, 0).Unproject(axis);

                    path.setAt(index, latLng.Offset(t.y, t.x).ToGMLatLng());
                    this.update();
                }
            }

        }

        setPointY(pathIndex: number, index: number, viewModel: ViewModel, v: string | number) {
            var poly = this.poly,
                paths = poly ? poly.getPaths() : null;

            if (!paths || paths.getLength() <= pathIndex)
                return;

            var path = paths.getAt(pathIndex),
                pts = this.points[pathIndex],
                axis = viewModel.referenceAxis;

            if (index >= 0 && path && path.getLength() > index && pts && pts.length > index) {
                var p = pts[index],
                    latLng = this.latLngs[pathIndex][index];

                var dy = spt.Utils.GetFloatFromInputValue("" + v,
                    {
                        min: -1000000,
                        max: 1000000,
                        applyArithmetic: true,
                        isFeet: window.UseImperialSystem,
                        notImperial: !window.UseImperialSystem,
                        from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                        to: "mm"
                    }) - p.y;

                if (dy) {
                    var t = new Point2D(0, dy * 0.001).Unproject(axis);

                    path.setAt(index, latLng.Offset(t.y, t.x).ToGMLatLng());
                    this.update();
                }
            }
        }

        setPointZ(pathIndex: number, index: number, viewModel: ViewModel, v: string | number) {
            var z = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });

            this.height = z;
        }

        deltePoint(pathIndex: number, index: number) {
            var poly = this.poly,
                paths = poly ? poly.getPaths() : null;

            if (!paths || paths.getLength() <= pathIndex)
                return;

            var path = paths.getAt(pathIndex);

            if (index >= 0 && path && path.getLength() > index) {
                if (path.getLength() > 3) {
                    path.removeAt(index);
                    this.update();
                } else {
                    DManager.showErrorMessage("Couldn't delete point. Polygons with less than 3 points aren't supported!");
                }
            }
        }

        setRotation(v: string | number) {
            var r = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -360,
                    max: 360,
                    applyArithmetic: true,
                    notImperial: true
                });
            this.rotation = r;
        }

        setScale(v: string | number) {
            var s = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0.01,
                    max: 1000000,
                    applyArithmetic: true,
                    notImperial: true,
                    scale: 1 / 100
                });
            this.scale = s;
        }

        getChildByLatLng(latLng: LatLng): ShapeData {
            if (!this.children.length)
                return null;

            let gmLatLng = latLng.ToGMLatLng();

            var childs = this.children.filter(ch => google.maps.geometry.poly.containsLocation(gmLatLng, ch.poly as any));

            if (!childs.length)
                return null;
            else if (childs.length === 1)
                return childs[0];

            let child: ShapeData = null,
                dist = Number.MAX_VALUE;

            childs.forEach(ch => {
                var d = latLng.DistanceTo(ch.bounds.Center);
                if (d < dist) {
                    dist = d;
                    child = ch;
                }
            });

            return child;
        }

        movePoint(from: LatLng, to: LatLng, sourceId: string) {
            if (this._isDisposed || !this.poly || this.skipUpdate)
                return;
            this.skipUpdate = true;
            if (this.id !== sourceId) {
                this.poly.getPaths().forEach(path => {
                    var len = path.getLength();
                    for (var i = len - 1; i >= 0; i--) {
                        if (LatLng.FromGMLatLng(path.getAt(i)).DistanceTo(from) <= 0.01)
                            path.setAt(i, to.ToGMLatLng());
                    }
                });
            }

            this.separationPolylines.forEach(separationPolyline => {
                if (separationPolyline.id !== sourceId)
                    separationPolyline.movePoint(from, to);
            });

            this.skipUpdate = false;
        }

        private static trianglesAdjacent(tr1: number[], tr2: number[], ignoredEdges?: [number, number][]): boolean {
            var e = tr1.filter((oidx) => tr2.some(idx => oidx === idx));
            return e.length === 2 && (!ignoredEdges || !ignoredEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0])));
        }

        private static trianglesCollectionAdjacent(tr1s: number[][], tr2s: number[][], ignoredEdges?: [number, number][]): boolean {
            return tr1s.some(tr1 => tr2s.some(tr2 => ShapeData.trianglesAdjacent(tr1, tr2, ignoredEdges)));
        }

        updateOverlayTexts(mapDrawer: MapDrawer) {
            var viewModel = mapDrawer.viewModel,
                poly = this.poly,
                paths = poly.getPaths(),
                layer = this.mapDrawer.viewModel.layer.typeName[this.typeName],
                measureTexts = this.measureTexts,
                angleTexts = this.angleTexts,
                coordsTexts = this.coordsTexts,
                coordsNumberTexts = this.coordsNumberTexts,
                countMeasureTexts = 0,
                countAngleTexts = 0,
                countCoordsTexts = 0,
                countCoordsNumberTexts = 0;

            if (!layer)
                return;

            paths.forEach(path => {
                var length = path.getLength();

                if (length > 0) {
                    var showCoordNumbers = viewModel.showCoordNumbers,
                        showCoords = !showCoordNumbers && viewModel.showCoords,
                        showAngle = viewModel.showAngle && length > 2,
                        showLengths = viewModel.showLengths && length > 1,
                        latLngs = path.getArray().map(ll => LatLng.FromGMLatLng(ll));

                    for (var cur = 0, len = length - 1; cur <= len; cur++) {
                        var prev = cur === 0 ? len : cur - 1;
                        var next = cur === len ? 0 : cur + 1;

                        if (showCoords) {
                            if (coordsTexts.length <= countCoordsTexts)
                                coordsTexts.push(mapDrawer.createOverlayText(layer));
                            mapDrawer.setCoordsText(coordsTexts[countCoordsTexts], latLngs[cur]);
                            countCoordsTexts++;
                        }
                        if (showAngle) {
                            if (angleTexts.length <= countAngleTexts)
                                angleTexts.push(mapDrawer.createOverlayText(layer));
                            mapDrawer.setAngleText(angleTexts[countAngleTexts], latLngs[prev], latLngs[cur], latLngs[next]);
                            countAngleTexts++;
                        }
                        if (showLengths) {
                            if (measureTexts.length <= countMeasureTexts)
                                measureTexts.push(mapDrawer.createOverlayText(layer));
                            mapDrawer.setMeasureText(measureTexts[countMeasureTexts], latLngs[cur], latLngs[next]);
                            countMeasureTexts++;
                        }
                        if (showCoordNumbers) {
                            if (coordsNumberTexts.length <= countCoordsNumberTexts)
                                coordsNumberTexts.push(mapDrawer.createOverlayText(layer));
                            mapDrawer.setCoordNumberText(coordsNumberTexts[countCoordsNumberTexts], latLngs[cur], cur + 1);
                            countCoordsNumberTexts++;
                        }
                    }
                }
            });

            if (coordsNumberTexts.length > countCoordsNumberTexts) {
                let delCount = coordsNumberTexts.length - countCoordsNumberTexts;
                coordsNumberTexts.splice(-delCount, delCount).forEach(ot => { ot.dispose(); });
            }
            if (coordsTexts.length > countCoordsTexts) {
                let delCount = coordsTexts.length - countCoordsTexts;
                coordsTexts.splice(-delCount, delCount).forEach(ot => { ot.dispose(); });
            }
            if (angleTexts.length > countAngleTexts) {
                let delCount = angleTexts.length - countAngleTexts;
                angleTexts.splice(-delCount, delCount).forEach(ot => { ot.dispose(); });
            }
            if (measureTexts.length > countMeasureTexts) {
                let delCount = measureTexts.length - countMeasureTexts;
                measureTexts.splice(-delCount, delCount).forEach(ot => { ot.dispose(); });
            }
        }

        computeArea() {
            var poly = this.poly,
                paths = poly.getPaths().getArray().map(path => path.getArray()).filter(path => path.length > 2);
            return !paths.length ? 0 : Math.abs(paths.reduce((prev, cur) => prev + google.maps.geometry.spherical.computeSignedArea(cur), 0));
        }

        approximateParameters() {
            if (this._isDisposed || LoaderManager.isLoading())
                return;
            
            if (this.typeName !== window.BuildingTypeName && (this.typeName !== window.RoofTypeName || !this.slopeOverride))
                return;

            var area = this.computeArea();

            if (area < 1)
                return;
            
            var mapDrawer = this.mapDrawer,
                children = this.children,
                child = this.typeName !== window.BuildingTypeName || (!children || children.length < 2) ? null : children.map(child => { return { child: child, area: child.computeArea() }; })
                    .reduce((prev, cur) => prev.area > cur.area ? prev : cur).child;

            if (!child || child.computeArea() <= 0) {

                this.getApproximatedRoofPlane((plane, minDepth, maxDepth) => {
                    if (!plane)
                        return;

                    var slope = +(plane.normal.angleTo(new THREE.Vector3(0, 0, 1)) / Math.PI * 180).toFixed(2);

                    if (this.typeName === window.RoofTypeName) {
                        this.setCustomSlope(slope);
                        return;
                    }

                    var height = Math.round(maxDepth - minDepth) * 1000;

                    this.slope = slope;
                    this.height = height;

                    this.regenerate();
                }, mapDrawer);

                return;
            }

            child.getApproximatedRoofPlane((plane, minDepth, maxDepth) => {
                if (!plane)
                    return;

                var slope = +(plane.normal.angleTo(new THREE.Vector3(0, 0, 1)) / Math.PI * 180).toFixed(2);
                var height = Math.round(maxDepth - minDepth) * 1000;

                this.slope = slope;
                this.height = height;

                this.regenerate();
            }, mapDrawer);
        }

        regenerate(debugOutput?: boolean) {
            if (this._isDisposed)
                return;

            if (this.typeName === window.InterfernceTypeName) {
                this.updateOverlayTexts(this.mapDrawer);
                return;
            }

            this.updateOverlayTexts(this.mapDrawer);

            if (this.typeName !== window.BuildingTypeName)
                return;

            var mapDrawer = this.mapDrawer,
                viewModel = mapDrawer.viewModel,
                children = this.children,
                layer = this.mapDrawer.viewModel.layer.typeName[this.typeName],
                childLayer = layer.child as PolygonLayer,
                refLatLng = viewModel.referenceLatLng,
                separationPolylines = this.separationPolylines.filter(sp => !sp.isCutting);

            if (!this.poly) {
                this.clearTexts();
                return;
            }

            let childShapesArray: { paths: google.maps.LatLng[][]; pos: LatLng; southMarkerPos: LatLng; orientation: number; slope: number; }[] = [];

            this.getCuttingSeparatedPolygons().forEach(border => {
                childShapesArray.push.apply(childShapesArray, this.calculateSeparatedChildShapes([border], separationPolylines, refLatLng, debugOutput));
            });

            if (!childShapesArray || !childShapesArray.length) {

                let paths = this.poly.getPaths().getArray().map((path) => path.getArray().slice()).slice();

                childShapesArray = [
                    {
                        paths: paths,
                        southMarkerPos: this.southMarker ? this.southMarker.position.Clone() : null,
                        orientation: this.southMarker ? this.southMarker.orientation : 0,
                        slope: this.slope,
                        pos: BoundsLatLng.FromGmLatLngs(paths).Center
                    }];
            } else if (childShapesArray.length == 1) {
                childShapesArray[0].slope = this.slope;
            }

            var childCount = childShapesArray.length;

            if (children.length > childCount)
                children.splice(childCount, children.length - childCount).forEach(sh => sh.remove());

            var childHash: { [id: string]: boolean } = {};

            var childSouthMarkerVisible = false;

            for (var i = 0; i < childCount; i++) {
                let childShapes = childShapesArray[i],
                    child: ShapeData = null,
                    dist = Number.MAX_VALUE;

                children.forEach(ch => {
                    if (!childHash[ch.id]) {
                        var d = childShapes.pos.DistanceTo(ch.bounds.Center);
                        if (d < dist) {
                            dist = d;
                            child = ch;
                        }
                    }
                });

                if (child)
                    child.poly.setPaths(childShapes.paths);

                if (!child) {
                    let poly = new google.maps.Polygon({ paths: childShapes.paths });
                    (poly as any).userData = child = new ShapeData(mapDrawer, poly as any, spt.Utils.GenerateGuid(), childLayer.getNewName(), childLayer.typeName);
                    mapDrawer.addPolygon(poly as any);
                    child.parent = this;
                    children.push(child);
                }

                childHash[child.id] = true;

                if (childShapes.southMarkerPos) {
                    if (!child.southMarker) {
                        child.southMarker = new SouthMarker(childShapes.southMarkerPos.Clone(), childShapes.orientation, true, mapDrawer);
                    } else {
                        child.southMarker.visible = true;
                        child.southMarker.orientation = childShapes.orientation;
                        child.southMarker.position = childShapes.southMarkerPos.Clone();
                    }
                } else if (child.southMarker) {
                    child.southMarker.dispose();
                    child.southMarker = null;
                }
                child.slope = childShapes.slope;

                if (child.southMarker && child.southMarker.visible)
                    childSouthMarkerVisible = true;

                child.update();
            }

            this.calculateChildSlopes();

            if (this.southMarker)
                this.southMarker.visible = !childSouthMarkerVisible;
        }

        private edgeInList(edge: [number, number], list: [number, number][]): boolean {
            return edge && list.some(e => (e[0] === edge[0] && e[1] === edge[1]) || (e[1] === edge[0] && e[0] === edge[1]));
        }

        private triangleEdgeConnected(a: [number, number, number], b: [number, number, number]): [number, number] {
            let res = a.filter(idx => b.some(ix => idx === ix));
            return (res.length === 2 ? res : null) as [number, number];
        }

        private calculatePolygonsFromTriangles(pts: Point3D[], edges: [number, number][], triangles: [number, number, number][]): Point3D[][] {

            let result: Point3D[][] = [],
                joinedTriangles: number[][] = [],
                remainingTriangles: number[] = [],
                connectedTriangles = triangles.map((tr, idx) => {
                    remainingTriangles.push(idx);
                    let otherTriangles: number[] = [];
                    triangles.forEach((otr, oidx) => {
                        if (idx != oidx) {
                            let commonEdge = this.triangleEdgeConnected(tr, otr);
                            if (commonEdge && !this.edgeInList(commonEdge, edges))
                                otherTriangles.push(oidx);
                        }
                    });
                    return otherTriangles;
                });

            while (remainingTriangles.length) {
                let work: number[] = remainingTriangles.splice(0, 1),
                    joined: number[] = [];

                while (work.length) {
                    var cur = work.splice(0, 1)[0];
                    joined.push(cur);
                    connectedTriangles[cur].forEach(idx => {
                        if (joined.every(i => idx !== i) && work.every(i => idx !== i))
                            work.push(idx);
                    });
                }

                remainingTriangles = remainingTriangles.filter(ridx => joined.every(i => ridx !== i));
                joinedTriangles.push(joined);
            }

            joinedTriangles.forEach(tris => {
                let polys = new ClipperLib.Paths(),
                    resultPolys: ClipperLib.Paths;

                tris.map(trIdx => triangles[trIdx]).forEach(tr => {
                    var poly = new ClipperLib.Path();

                    tr.forEach(idx => {
                        var p = pts[idx];

                        poly.push(new ClipperLib.IntPoint(p.X, p.Y, p.Z));
                    });

                    polys.push(poly);
                });

                if (polys.length > 1) {
                    var c = new ClipperLib.Clipper();
                    c.AddPaths(polys, ClipperLib.PolyType.ptSubject, true);
                    resultPolys = new ClipperLib.Paths();
                    c.Execute(ClipperLib.ClipType.ctUnion, resultPolys, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);
                } else {
                    resultPolys = polys;
                }

                if (resultPolys.map(rp => ClipperLib.Clipper.Area(rp)).reduce((a, b) => a + b, 0) < 0)
                    ClipperLib.Clipper.ReversePaths(resultPolys);

                resultPolys.forEach(rpoly => {
                    result.push(rpoly.map(rp => new Point3D(rp.X, rp.Y, rp.Z)));
                });
            });

            return result;

        }

        private getBordersAsEdges(borders: LatLng[][], refLatLng: LatLng, outPts: Point3D[], outLatLngs: LatLng[], outEdges: [number, number][], offsetIndex: number) {

            borders.forEach(border => {
                //cleanup clockwise border

                let borderPts = border.map((ll: LatLng) => {
                    return { ll: ll, pt: refLatLng.DirectionTo(ll).mul(1000).round().to3D(0) };
                });

                let cleanedBorderPts: { ll: LatLng; pt: Point3D; }[] = [];

                for (var k = 0, p = borderPts.length; k < p; k++) {
                    let bp1 = borderPts[k],
                        bp2 = borderPts[(k + 1) % p];

                    if (bp2.pt.sub(bp1.pt).GetLengthSquared() > 100)
                        cleanedBorderPts.push(bp2);
                }

                borderPts = cleanedBorderPts;

                if (borderPts.length > 2) {
                    if (Point2D.isClockwise(borderPts.map(bp => bp.pt)))
                        borderPts.reverse();

                    for (let idx = 0, j = borderPts.length; idx < j; idx++) {
                        outLatLngs.push(borderPts[idx].ll);
                        outPts.push(borderPts[idx].pt);
                        outEdges.push([offsetIndex + idx, offsetIndex + idx + 1]);
                    }

                    outEdges[outEdges.length - 1][1] = offsetIndex;

                    offsetIndex += border.length;
                }

            });

        }

        private calculateSeparatedChildShapes(borders: LatLng[][], separationPolylines: SeparationPolyline[], refLatLng: LatLng, debugOutput?: boolean): { paths: google.maps.LatLng[][]; pos: LatLng; southMarkerPos: LatLng; orientation: number; slope: number; }[] {

            var childShapesArray: { paths: google.maps.LatLng[][]; pos: LatLng; southMarkerPos: LatLng; orientation: number; slope: number; }[] = [];

            if (!separationPolylines.length) {
                childShapesArray.push(
                    {
                        paths: borders.map(b => b.map(ll => ll.ToGMLatLng())),
                        southMarkerPos: this.southMarker ? this.southMarker.position.Clone() : null,
                        orientation: this.southMarker ? this.southMarker.orientation : 0,
                        slope: this.slope,
                        pos: BoundsLatLng.FromLatLngs(borders).Center
                    });
                return childShapesArray;
            }

            var pts: Point3D[] = [];
            var latLngs: LatLng[] = [];

            var borderEdges: [number, number][] = [];
            var separationEdges: [number, number][] = [];
            var additionalEdges: [number, number][] = [];

            var checkForRemove: number[] = [];

            let borderPoly = new google.maps.Polygon({ paths: borders[0].map(ll => ll.ToGMLatLng()) });

            var separationOffsetIndex = 0;

            //special case: add inbetween points of separation edges to border points if they are on border lines
            var inbetweenSeparationPoints: Point2D[] = [],
                seperationHeadsTails = [].concat.apply([], separationPolylines.map(sp => sp.polyline.getPath().getArray().map((gmll: google.maps.LatLng) => refLatLng.DirectionTo(LatLng.FromGMLatLng(gmll)).mul(1000).round())).filter(ispts => {
                    if (ispts.length > 2)
                        ispts.splice(1, ispts.length - 2).forEach(sp => {
                            if (!inbetweenSeparationPoints.some(p => p.sub(sp).GetLengthSquared() <= 100))
                                inbetweenSeparationPoints.push(sp);
                        });
                    return ispts.length > 1;
                })) as Point2D[];

            seperationHeadsTails.forEach((sp, spIdx) => {
                if (!inbetweenSeparationPoints.some(p => p.sub(sp).GetLengthSquared() <= 100) && seperationHeadsTails.some((p, idx) => spIdx != idx && p.sub(sp).GetLengthSquared() <= 100))
                    inbetweenSeparationPoints.push(sp);
            });

            borders.forEach(border => {
                if (border.length > 2) {
                    var newLLs: LatLng[] = [];
                    ArrayHelper.forEachPairClosed(border, (ll1, ll2) => {
                        newLLs.push(ll1);

                        var s = refLatLng.DirectionTo(ll1).mul(1000).round(),
                            e = refLatLng.DirectionTo(ll2).mul(1000).round(),
                            delta = e.sub(s),
                            lenSq = delta.GetLengthSquared();

                        if (lenSq < 100)
                            return;

                        var len = Math.sqrt(lenSq),
                            lenEnd = len - 10,
                            dir = delta.divide(len),
                            norm = dir.rot90(),
                            newPts: Point2D[] = [];

                        inbetweenSeparationPoints.forEach(p => {
                            var d = p.sub(s),
                                t = dir.dot(d);

                            if (t > 10 && t < lenEnd && Math.abs(norm.dot(d)) < 10) {
                                newPts.push(s.add(dir.mul(t)));
                            }
                        });

                        if (newPts.length) {
                            newPts.sort((p1, p2) => dir.dot(p1) - dir.dot(p2));
                            newLLs.push.apply(newLLs, newPts.map(pt => refLatLng.OffsetVector(pt.divide(1000))));
                        }

                    });

                    if (newLLs.length > border.length)
                        border.splice.apply(border, ([0, border.length] as any[]).concat(newLLs));
                }
            });

            //add borders
            this.getBordersAsEdges(borders, refLatLng, pts, latLngs, borderEdges, 0);

            var idxOff = latLngs.length;

            var bds = Bounds2D.FromPoints(pts);
            if (bds.Delta.GetLengthSquared() > 100) {

                separationOffsetIndex = idxOff;

                var spPts: [number, number][] = [];

                separationPolylines.forEach(sp => {
                    var cspPts = sp.polyline.getPath().getArray().map((gmll: google.maps.LatLng) => refLatLng.DirectionTo(LatLng.FromGMLatLng(gmll)).mul(1000).round()),
                        spIndices: number[] = [];

                    //split separation edge on border points
                    for (var j = 1, k = cspPts.length - 1; j <= k; j++) {
                        let sp1 = cspPts[j - 1],
                            sp2 = cspPts[j],
                            delta = sp2.sub(sp1),
                            lenSq = delta.GetLengthSquared();

                        if (lenSq <= 100)
                            continue;

                        spIndices.push(spPts.length);
                        spPts.push([sp1.X, sp1.Y] as [number, number]);

                        let len = Math.sqrt(lenSq),
                            dir = delta.divide(len),
                            n = dir.LeftHandNormal(),
                            apts = pts.map(bept3D => {
                                var bept = bept3D.getXY(),
                                    beDelta = bept.sub(sp1);
                                if (Math.abs(n.dot(beDelta)) <= 10)
                                    return dir.dot(beDelta);
                                return 0;
                            }).filter(t => t > 10 && t < len - 10);

                        if (apts.length) {
                            apts.sort((a, b) => a - b);

                            apts.forEach(t => {
                                var tpt = sp1.add(dir.mul(t));
                                spIndices.push(spPts.length);
                                spPts.push([tpt.X, tpt.Y] as [number, number]);
                            });
                        }
                    }

                    var lastcspPt = cspPts[cspPts.length - 1];

                    spIndices.push(spPts.length);
                    spPts.push([lastcspPt.X, lastcspPt.Y] as [number, number]);

                    for (var j = 1, k = spIndices.length - 1; j <= k; j++) {
                        let sp1 = spIndices[j - 1],
                            sp2 = spIndices[j];
                        if (sp1 !== sp2)
                            separationEdges.push([sp1, sp2]);
                    }
                });

                cleanPslg(spPts, separationEdges);

                var separationEdgesIndices: number[] = [];

                spPts.forEach(spPt => {
                    var pt = new Point3D(spPt[0], spPt[1], 0),
                        llIndex = ArrayHelper.searchIndex(pts, p => p.sub(pt).GetLengthSquared() <= 100);
                    if (llIndex < 0) {
                        llIndex = pts.length;
                        latLngs.push(refLatLng.Offset(pt.Y * 0.001, pt.X * 0.001));
                        pts.push(pt);
                    }
                    separationEdgesIndices.push(llIndex);
                });

                separationEdges = separationEdges.filter(se => {
                    se[0] = separationEdgesIndices[se[0]];
                    se[1] = separationEdgesIndices[se[1]];
                    return se[0] !== se[1];
                });

                //replace borderegeds with separationedges if needed
                //borderEdges = borderEdges.filter(e => !separationEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0])));

                //cut separation edges on borders and filter
                separationEdges = separationEdges.filter(separationEdge => {

                    let changed = true, sp1: Point3D, sp2: Point3D;

                    //cut on border edges
                    while (changed) {
                        changed = false;

                        sp1 = pts[separationEdge[0]];
                        sp2 = pts[separationEdge[1]];

                        for (var j = 0, k = borderEdges.length; j < k; j++) {
                            var borderEdge = borderEdges[j];

                            let ep1 = pts[borderEdge[0]],
                                ep2 = pts[borderEdge[1]],
                                delta = ep2.sub(ep1),
                                dir = delta.GetNormalized(),
                                n = dir.LeftHandNormal(),
                                dp1 = sp1.sub(ep1),
                                n1Dot = n.dot(dp1),
                                dp2 = sp2.sub(ep1),
                                n2Dot = n.dot(dp2);

                            if (n1Dot > 0 !== n2Dot > 0 && Math.abs(n1Dot) > 10 && Math.abs(n2Dot) > 10) {

                                var inter = Segment2D.GetIntersection(sp1, sp2, ep1, ep2);

                                if (inter) {
                                    var sidx = 0;
                                    if (n.dot(dp1) < 0)
                                        sidx = 1;

                                    pts[separationEdge[sidx]] = inter.to3D(0);
                                    latLngs[separationEdge[sidx]] = refLatLng.Offset(inter.y * 0.001, inter.x * 0.001);

                                    changed = true;
                                    break;
                                }
                            }
                        }
                    }

                    //filter edges
                    sp1 = pts[separationEdge[0]];
                    sp2 = pts[separationEdge[1]];
                    //let detla = sp2.sub(sp1);

                    //if (detla.GetLengthSquared() <= 100)
                    //    return false;

                    for (var si = 0; si < 2; si++) {
                        var sp = pts[separationEdge[si]];

                        var bidx = -1,
                            bDist = Number.MAX_VALUE;

                        for (var bi = 0; bi < separationOffsetIndex; bi++) {
                            var bPt = pts[bi];
                            var bd = bPt.sub(sp).GetLengthSquared();
                            if (bd <= 100 && bd < bDist) {
                                bDist = bd;
                                bidx = bi;
                            }
                        }

                        if (bidx >= 0) {
                            var oSidx = separationEdge[si];
                            separationEdge[si] = bidx;
                            if (checkForRemove.indexOf(oSidx) === -1)
                                checkForRemove.push(oSidx);
                        }
                    }

                    if (separationEdge[0] < separationOffsetIndex || separationEdge[1] < separationOffsetIndex) {

                        //add to additional
                        if (separationEdge[0] < separationOffsetIndex !== separationEdge[1] < separationOffsetIndex) {
                            additionalEdges.push(separationEdge);
                            return false;
                        }
                        //return false;
                    }

                    return true;
                });

                //if borderedges are marked as separationEdges, put them to additionalEdges
                //var elevatedIndizesHash: { [idx: number]: boolean } = {};
                //borderEdges.filter(e => separationEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0]))).forEach(e => {
                //    elevatedIndizesHash[e[0]] = true;
                //    elevatedIndizesHash[e[1]] = true;
                //});
                //var elevatedIndizes = Object.keys(elevatedIndizesHash).map(k => +k);
                //additionalEdges.push.apply(additionalEdges, borderEdges.filter(e => (elevatedIndizes.indexOf(e[0]) !== -1 || elevatedIndizes.indexOf(e[1]) !== -1) && !separationEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0]))));

                //split border edges
                borderEdges = borderEdges.filter(e => {

                    let ep1 = pts[e[0]],
                        ep2 = pts[e[1]],
                        delta = ep2.sub(ep1),
                        len = delta.GetLength(),
                        dir = delta.mul(1 / len),
                        n = dir.LeftHandNormal(),
                        sPts: number[] = [];

                    for (var si = separationOffsetIndex, j = pts.length; si < j; si++) {
                        let pt = pts[si],
                            dp = pt.sub(ep1),
                            dDot = dir.dot(dp),
                            nDot = Math.abs(n.dot(dp));

                        if (dDot > 0 && dDot < len && nDot <= 10) {
                            sPts.push(si);
                        }
                    }

                    if (sPts.length) {

                        sPts.sort((i1, i2) => dir.dot(pts[i1]) - dir.dot(pts[i2]));
                        sPts.unshift(e[0]);
                        sPts.push(e[1]);

                        for (var k = 0, p = sPts.length - 1; k < p; k++) {
                            additionalEdges.push([sPts[k], sPts[k + 1]]);
                        }

                        return false;
                    }
                    //return elevatedIndizes.indexOf(e[0]) === -1 && elevatedIndizes.indexOf(e[1]) === -1;
                    return !separationEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0]));
                });

                var allEdges = borderEdges.concat(additionalEdges).concat(separationEdges);

                //remove some points
                if (checkForRemove.length) {
                    checkForRemove.sort((a, b) => { return b - a });
                    checkForRemove.forEach(chckr => {
                        if (!allEdges.some(e => e[0] === chckr || e[1] === chckr)) {
                            pts.splice(chckr, 1);
                            latLngs.splice(chckr, 1);
                            allEdges.forEach(e => {
                                if (e[0] > chckr)
                                    e[0]--;
                                if (e[1] > chckr)
                                    e[1]--;
                            });
                            if (separationOffsetIndex > chckr)
                                separationOffsetIndex--;
                        }
                    });
                }

                try {
                    //constrained delaunay triangulation
                    var triangles = cdt2d(pts.map(pt => [pt.X, pt.Y] as [number, number]), allEdges,
                        {
                            delaunay: true,
                            interior: true,
                            exterior: false,
                            infinity: false
                        });

                    //edges <-> triangle relationship
                    var edgeHash: { [key: string]: { edge: [number, number], triangles: number[] } } = {};

                    //filter too small triangles and make all counter clockwise
                    triangles = triangles.filter(tr => {
                        let area = Point2D.computeArea((<number[]>tr).map(ci => pts[ci]));
                        if (Math.abs(area) > 100 && google.maps.geometry.poly.containsLocation(refLatLng.OffsetVector(pts[tr[0]].add(pts[tr[1]].add(pts[tr[2]])).getXY().divide(3000)).ToGMLatLng(), borderPoly)) {
                            if ((area > 0)) {
                                //isClockwise
                                let swap = tr[1];
                                tr[1] = tr[2];
                                tr[2] = swap;
                            }

                            return true;
                        }
                        return false;
                    });

                    //build edgeHash. Edges spanning between seperation edges
                    triangles.forEach((tr, idx) => {
                        [
                            [tr[0], tr[1]],
                            [tr[1], tr[2]],
                            [tr[2], tr[0]]
                        ].filter(e => e[0] >= separationOffsetIndex && e[1] >= separationOffsetIndex && !allEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0])))
                            .forEach((e: [number, number]) => {
                                var k = `${Math.min(e[0], e[1])}_${Math.max(e[0], e[1])}`;
                                if (!edgeHash[k])
                                    edgeHash[k] = { edge: e, triangles: [idx] };
                                else
                                    edgeHash[k].triangles.push(idx);
                            });
                    });

                    //swap triangles so that at best all seperation edges points are connected to the edge with a straight line
                    Object.keys(edgeHash).map(k => edgeHash[k]).filter(trInd => trInd.triangles.length === 2).forEach(trInd => {
                        var e = trInd.edge;
                        var tr1 = triangles[trInd.triangles[0]] as number[];
                        var tr2 = triangles[trInd.triangles[1]] as number[];
                        var idxOff = tr1.concat(tr2).filter(idx => idx !== e[0] && idx !== e[1]);
                        if (idxOff.length === 2 && ((idxOff[0] < separationOffsetIndex) !== (idxOff[1] < separationOffsetIndex))) {

                            var idxEdge = idxOff[0] < separationOffsetIndex ? idxOff[0] : idxOff[1];
                            var idxSep = idxOff[0] < separationOffsetIndex ? idxOff[1] : idxOff[0];

                            let edgeSeg = new Segment2D(pts[e[0]].getXY(), pts[e[1]].getXY()),
                                crossSeg = new Segment2D(pts[idxEdge].getXY(), pts[idxSep].getXY()),
                                intersecParams = edgeSeg.getIntersectionParams(crossSeg);

                            if (intersecParams.p && intersecParams.t1 > 0 && intersecParams.t1 < 1 && intersecParams.t2 > 0 && intersecParams.t2 < 1) {
                                triangles[trInd.triangles[0]] = [idxEdge, e[0], idxSep];
                                triangles[trInd.triangles[1]] = [idxEdge, e[1], idxSep];
                            }
                        }

                    });

                    //set z for all points connected to edges
                    pts.forEach((pt, idx) => {
                        if (idx < separationOffsetIndex)
                            return;

                        let h = 0;

                        triangles.filter(tr => tr.some(ind => ind === idx)).forEach(tr => {
                            let cBorderEdge = 0,
                                cSeparationEdge = 0;

                            let trEdges = [
                                [tr[0], tr[1]],
                                [tr[1], tr[2]],
                                [tr[2], tr[0]]
                            ].map(e => {
                                let isBorderEdge = borderEdges.some(be => (be[0] === e[0] && be[1] === e[1]) || (be[0] === e[1] && be[1] === e[0])),
                                    isSeparationEdge = !isBorderEdge && separationEdges.some(se => (se[0] === e[0] && se[1] === e[1]) || (se[0] === e[1] && se[1] === e[0]));
                                if (isBorderEdge)
                                    cBorderEdge++;
                                if (isSeparationEdge)
                                    cSeparationEdge++;
                                return {
                                    isBorderEdge: isBorderEdge,
                                    isSeparationEdge: isSeparationEdge,
                                    edge: e as [number, number],
                                    ptIdx: e.indexOf(idx)
                                };
                            });

                            if ((cBorderEdge + cSeparationEdge) === 1) {
                                if (cBorderEdge) {
                                    let tE = trEdges.filter(tre => tre.isBorderEdge)[0];
                                    if (tE.ptIdx === -1) {
                                        let ePts = tE.edge.map(ei => pts[ei]),
                                            z = Math.abs(ePts[1].sub(ePts[0]).GetNormalized().LeftHandNormal().dot(pt.sub(ePts[0])));// * hFactor;
                                        if (h < z)
                                            h = z;
                                    }
                                } else {
                                    let tE = trEdges.filter(tre => tre.isSeparationEdge)[0];
                                    if (tE.ptIdx !== -1) {
                                        let ePts = tE.edge.map(ei => pts[ei]),
                                            z = Math.abs(ePts[1].sub(ePts[0]).GetNormalized().LeftHandNormal().dot(pts[tr.filter(tri => !tE.edge.some(tEi => tri === tEi))[0]].sub(ePts[0])));//  * hFactor;
                                        if (h < z)
                                            h = z;
                                    }
                                }
                            }

                        });

                        pt.setZ(h);
                    });

                    //set z for every other point
                    triangles.filter(tr => tr.every(idx => idx >= separationOffsetIndex)).forEach(tr => {
                        var cpts = (<number[]>tr).map(ci => pts[ci]);
                        var z = cpts.reduce((a, b) => { return (a.z < b.z && a.z > 0) || b.z <= 0 ? a : b; }).z;
                        if (z > 0) {
                            cpts.filter(pt => pt.z <= 0).forEach(pt => {
                                pt.setZ(z);
                            });
                        }
                    });

                    //build a helper structure
                    var triangleDefinitons = triangles.map((tr, idx) => {
                        let ctr = (<number[]>tr).slice();
                        let cpts = ctr.map(ci => pts[ci]);

                        let trEdges = [[tr[0], tr[1]], [tr[1], tr[2]], [tr[2], tr[0]]],
                            borderRefEdges = trEdges.filter(trEdge => {
                                return borderEdges.some(bEdgeRef => (bEdgeRef[0] === trEdge[0] && bEdgeRef[1] === trEdge[1]) || (bEdgeRef[0] === trEdge[1] && bEdgeRef[1] === trEdge[0]));
                            }),
                            cEdge: number[] = null;

                        if (borderRefEdges.length) {
                            if (borderRefEdges.length === 1) {
                                cEdge = borderRefEdges[0];
                            } else {
                                let cEdgeLength = 0;

                                borderRefEdges.forEach(edgeRef => {
                                    var edgeRefLength = pts[edgeRef[1]].sub(pts[edgeRef[0]]).GetLengthSquared();
                                    if (edgeRefLength > cEdgeLength) {
                                        cEdgeLength = edgeRefLength;
                                        cEdge = edgeRef;
                                    }
                                });
                            }
                        }

                        let n = Point3D.getUpwardsNormalFromPoints(pts[tr[0]], pts[tr[1]], pts[tr[2]]);
                        return {
                            idx: idx,
                            tr: [ctr],
                            //pts: cpts,
                            n: n,
                            edge: cEdge ? new Segment2D(pts[cEdge[0]].getXY(), pts[cEdge[1]].getXY()) : null, //border edge
                            nearestNeighboorIdx: -1,
                            faceUpwards: cpts.every(pt => pt.Z === cpts[0].Z),
                            slope: (Point3D.ZAxis.angleTo(n) * 180 / Math.PI), // / 45 * targetSlope,
                            collectedIdx: []
                        } as ITriangleDefinition;
                    });

                    //define nearest likely neighboor (a neighboor is the most likely adjacent triangle that defines the same surface area)
                    triangleDefinitons.forEach(triangleDefiniton => {
                        if (!triangleDefiniton.edge) {
                            //var neighboors = triangleDefinitons.filter(other => other.tr.filter((oidx) => triangleDefiniton.tr.some(idx => oidx === idx)).length >= 2);
                            var neighboors = triangleDefinitons.filter(other => other.idx !== triangleDefiniton.idx && other.faceUpwards === triangleDefiniton.faceUpwards && ShapeData.trianglesAdjacent(other.tr[0], triangleDefiniton.tr[0], allEdges));

                            var edgeNeighboors = neighboors.filter(neighboor => !!neighboor.edge);

                            if (edgeNeighboors.length === 1) {
                                triangleDefiniton.nearestNeighboorIdx = edgeNeighboors[0].idx;
                            } else {
                                let nearestNeighboorIdx = -1,
                                    nearestNeighboorDist = Number.MAX_VALUE;

                                neighboors.forEach((neighboor) => {
                                    var dist = Math.abs(triangleDefiniton.n.dot(neighboor.n) - 1);
                                    if (dist < nearestNeighboorDist) {
                                        nearestNeighboorIdx = neighboor.idx;
                                        nearestNeighboorDist = dist;
                                    }
                                });

                                triangleDefiniton.nearestNeighboorIdx = nearestNeighboorIdx;
                            }
                        }

                    });

                    //if (debugOutput) {
                    //    triangleDefinitons.forEach(trd => {
                    //        console.log(JSON.stringify(trd));
                    //    });
                    //}

                    var edgeTriangles = triangleDefinitons.filter(trd => !!trd.edge && !trd.faceUpwards),
                        edgeTrianglesFaceUpwards = triangleDefinitons.filter(trd => !!trd.edge && !!trd.faceUpwards);
                    triangleDefinitons = triangleDefinitons.filter(trd => !trd.edge);

                    //join triangles together that belong most likely to the same surface areas
                    edgeTriangles.forEach(triangleDefiniton => {
                        var collectedIdx = triangleDefiniton.collectedIdx = [triangleDefiniton.idx];

                        if (triangleDefinitons.length) {
                            var changed = true;

                            while (changed) {
                                changed = false;

                                for (var j = 0, k = triangleDefinitons.length; j < k; j++) {
                                    var other = triangleDefinitons[j];

                                    if (collectedIdx.indexOf(other.idx) === -1 && collectedIdx.indexOf(other.nearestNeighboorIdx) !== -1) {
                                        //join

                                        collectedIdx.push(other.idx);

                                        triangleDefiniton.tr.push.apply(triangleDefiniton.tr, other.tr);

                                        triangleDefinitons.splice(j, 1);

                                        if (!triangleDefiniton.edge || (other.edge && triangleDefiniton.edge.Length < other.edge.Length))
                                            triangleDefiniton.edge = other.edge;

                                        changed = true;

                                        break;

                                        //var indices = [
                                        //    triangleDefiniton.tr.indexOf(other.tr[0]),
                                        //    triangleDefiniton.tr.indexOf(other.tr[1]),
                                        //    triangleDefiniton.tr.indexOf(other.tr[2])
                                        //];

                                        //var commonIdx = indices.filter(idx => idx == -1).length;

                                        //if (commonIdx === 1) {
                                        //    changed = true;

                                        //    collectedIdx.push(other.idx);

                                        //    var minIdx = Math.min.apply(Math, indices.filter(idx => idx !== -1));
                                        //    var maxIdx = Math.max.apply(Math, indices.filter(idx => idx !== -1));

                                        //    if (minIdx === 0 && maxIdx === triangleDefiniton.tr.length - 1)
                                        //        triangleDefiniton.tr.push(other.tr[indices.indexOf(-1)]);
                                        //    else
                                        //        triangleDefiniton.tr.splice(minIdx + 1, 0, other.tr[indices.indexOf(-1)]);

                                        //    if (!triangleDefiniton.edge)
                                        //        triangleDefiniton.edge = other.edge;

                                        //    triangleDefinitons.splice(j, 1);

                                        //    break;
                                        //} else if (commonIdx === 3) {

                                        //}
                                    }
                                }
                            }
                        }

                    });

                    //join upwards facing edge triangles
                    if (edgeTrianglesFaceUpwards.length) {
                        edgeTriangles.forEach(triangleDefiniton => {
                            var collectedIdx = triangleDefiniton.collectedIdx;

                            if (edgeTrianglesFaceUpwards.length) {
                                var changed = true;

                                while (changed) {
                                    changed = false;

                                    for (var j = 0, k = edgeTrianglesFaceUpwards.length; j < k; j++) {
                                        var other = edgeTrianglesFaceUpwards[j];

                                        if (collectedIdx.indexOf(other.idx) === -1) {

                                            for (var p = 0, q = triangleDefiniton.tr.length; p < q; p++) {
                                                var triangleDefinitonTr = triangleDefiniton.tr[p];

                                                if (ShapeData.trianglesAdjacent(triangleDefinitonTr, other.tr[0], allEdges)) {

                                                    //join

                                                    collectedIdx.push(other.idx);

                                                    triangleDefiniton.tr.push.apply(triangleDefiniton.tr, other.tr);

                                                    edgeTrianglesFaceUpwards.splice(j, 1);

                                                    if (!triangleDefiniton.edge || (other.edge && triangleDefiniton.edge.Length < other.edge.Length))
                                                        triangleDefiniton.edge = other.edge;

                                                    changed = true;

                                                    break;
                                                }
                                            }

                                            if (changed)
                                                break;
                                        }
                                    }
                                }
                            }
                        });
                    }

                    //all edge triangles
                    var allEdgeTriangles = edgeTriangles.concat(edgeTrianglesFaceUpwards);

                    //join non edge triangles
                    if (triangleDefinitons.length) {

                        let changed = true;

                        while (changed) {
                            changed = false;

                            let len = triangleDefinitons.length;

                            for (let l = 0; l < len; l++) {
                                let triangleDefiniton = triangleDefinitons[l];

                                for (let n = l + 1; n < len; n++) {
                                    let other = triangleDefinitons[n];

                                    if (triangleDefiniton.idx !== other.idx && other.faceUpwards === triangleDefiniton.faceUpwards) {

                                        for (let r = 0, trLen = triangleDefiniton.tr.length; r < trLen; r++) {
                                            let triangleDefinitonTr = triangleDefiniton.tr[r];

                                            for (let t = 0, oTrLen = other.tr.length; t < oTrLen; t++) {
                                                let otherTr = other.tr[t];

                                                if (ShapeData.trianglesAdjacent(triangleDefinitonTr, otherTr, allEdges)) {
                                                    //join

                                                    triangleDefiniton.tr.push.apply(triangleDefiniton.tr, other.tr);

                                                    triangleDefinitons.splice(n, 1);

                                                    if (!triangleDefiniton.edge)
                                                        triangleDefiniton.edge = other.edge;

                                                    changed = true;

                                                    break;
                                                }
                                            }

                                            if (changed)
                                                break;
                                        }
                                    }

                                    if (changed)
                                        break;
                                }

                                if (changed)
                                    break;
                            }
                        }

                        //find edge triangles
                        let idxConts: { edgeTr: ITriangleDefinition, trIdx: number }[] = [];

                        for (let l = 0, len = triangleDefinitons.length; l < len; l++) {
                            let triangleDefiniton = triangleDefinitons[l];

                            if (!triangleDefiniton.edge) {

                                var adjacentEdgeTriangles = allEdgeTriangles.filter(edgetrs => edgetrs.edge && ShapeData.trianglesCollectionAdjacent(edgetrs.tr, triangleDefiniton.tr, allEdges));

                                if (adjacentEdgeTriangles.length === 1)
                                    idxConts.push({ edgeTr: adjacentEdgeTriangles[0], trIdx: l });
                                else if (adjacentEdgeTriangles.length > 1) {
                                    let nearestNeighboor: ITriangleDefinition = null,
                                        nearestNeighboorDist = Number.MAX_VALUE;

                                    adjacentEdgeTriangles.forEach((neighboor) => {
                                        var dist = Math.abs(triangleDefiniton.n.dot(neighboor.n) - 1);
                                        if (dist < nearestNeighboorDist) {
                                            nearestNeighboor = neighboor;
                                            nearestNeighboorDist = dist;
                                        }
                                    });

                                    idxConts.push({ edgeTr: nearestNeighboor, trIdx: l });
                                }
                            }
                        }

                        idxConts.sort((a, b) => { return b.trIdx - a.trIdx });

                        idxConts.forEach(idxCont => {
                            let adjacentEdgeTriangle = idxCont.edgeTr,
                                l = idxCont.trIdx;

                            adjacentEdgeTriangle.tr.push.apply(adjacentEdgeTriangle.tr, triangleDefinitons[l].tr);

                            triangleDefinitons.splice(l, 1);
                        });

                    }

                    //set edges on triangles
                    if (triangleDefinitons.length) {

                        var borderSegments: Segment2D[] = [];

                        borders.forEach(lls => {
                            for (var j = 0, k = lls.length; j < k; j++) {
                                var ll1 = lls[j],
                                    ll2 = lls[(j + 1) % k];

                                borderSegments.push(new Segment2D(refLatLng.DirectionTo(ll1).mul(1000).round(), refLatLng.DirectionTo(ll2).mul(1000).round()));
                            }
                        });

                        triangleDefinitons.forEach(triangleDefiniton => {
                            if (!triangleDefiniton.edge) {

                                let foundEdge: [number, number] = null;

                                triangleDefiniton.tr.forEach(trtr => {

                                    for (var j = 0, k = trtr.length; j < k; j++) {
                                        var tidx1 = trtr[j],
                                            tidx2 = trtr[(j + 1) % k],
                                            seg = new Segment2D(pts[tidx1].getXY(), pts[tidx2].getXY());

                                        if (borderSegments.some(bs => bs.isOnSameStraightLine(seg, 1000))) {
                                            if (!foundEdge || seg.Length > pts[foundEdge[0]].getXY().sub(pts[foundEdge[1]].getXY()).GetLength())
                                                foundEdge = [tidx1, tidx2] as [number, number];
                                        }
                                    }
                                });

                                if (foundEdge)
                                    triangleDefiniton.edge = new Segment2D(pts[foundEdge[0]].getXY(), pts[foundEdge[1]].getXY());
                            }
                        });
                    }

                    //try to join edge triangles
                    if (allEdgeTriangles.length) {
                        let changed = true;

                        while (changed) {
                            changed = false;

                            for (let l = 0, len = allEdgeTriangles.length; l < len; l++) {
                                let edgeTriangle = allEdgeTriangles[l];

                                if (edgeTriangle.edge) {

                                    var adjacentEdgeTriangles = allEdgeTriangles.filter(edgetrs => edgetrs.edge && edgetrs.idx !== edgeTriangle.idx && edgetrs.edge.isOnSameStraightLine(edgeTriangle.edge, 1000) && ShapeData.trianglesCollectionAdjacent(edgetrs.tr, edgeTriangle.tr, allEdges));

                                    if (adjacentEdgeTriangles.length) {
                                        var otherEdgeTriangle = adjacentEdgeTriangles[0];

                                        otherEdgeTriangle.tr.push.apply(otherEdgeTriangle.tr, edgeTriangle.tr);

                                        allEdgeTriangles.splice(l, 1);

                                        changed = true;

                                        break;
                                    }
                                }
                            }
                        }
                    }

                    //debug output to wavefront obj
                    //if (debugOutput) {
                    //    var off = new Point3D((bds.Delta.x + 5000), 0, 0).mul(0.0001);
                    //    var objHolder = spt.Utils.SerializeTrianglesToObj(triangles.map(trd => (<number[]>trd).map(idx => pts[idx].mul(0.0001).add(off))));

                    //    var rtr: Point3D[][] = [];

                    //    edgeTriangles.forEach(trd => {
                    //        var rPts: Point3D[] = trd.tr.map(idx => pts[idx].mul(0.0001));
                    //        if (trd.tr.length <= 3)
                    //            rtr.push(rPts);
                    //        else {
                    //            var ridx = rPts.map((p, i) => [i, i + 1] as [number, number]);
                    //            ridx[ridx.length - 1][1] = 0;

                    //            var rtrs = cdt2d(rPts.map(pt => [pt.X, pt.Y] as [number, number]), ridx,
                    //                {
                    //                    delaunay: false,
                    //                    interior: true,
                    //                    exterior: false,
                    //                    infinity: false
                    //                });

                    //            rtrs.forEach(rtri => {
                    //                rtr.push((<number[]>rtri).map(ri => rPts[ri]));
                    //            });
                    //        }
                    //    });

                    //    objHolder = spt.Utils.SerializeTrianglesToObj(rtr, null, objHolder);
                    //    spt.Utils.saveTextAsFile("Scene.obj", objHolder.output);
                    //}

                    var allTriangles = allEdgeTriangles.concat(triangleDefinitons);

                    //join triangles that are adjacent, face the same direction and have similiar slopes
                    if (allTriangles.length) {
                        var minDirAngle = Math.cos(1 / 180 * Math.PI);

                        var changed = true;
                        while (changed) {
                            changed = false;

                            for (var i = 0, l = allTriangles.length - 1; i < l; i++) {
                                var curTrd = allTriangles[i];
                                if (!curTrd.edge)
                                    continue;

                                var curEdgeDir = curTrd.edge.Direction;

                                for (var j = i + 1; j <= l; j++) {
                                    var otherTrd = allTriangles[j];
                                    if (!otherTrd.edge)
                                        continue;
                                    var otherEdgeDir = otherTrd.edge.Direction;

                                    if (Math.abs(curTrd.slope - otherTrd.slope) < 1 && curEdgeDir.dot(otherEdgeDir) >= minDirAngle && ShapeData.trianglesCollectionAdjacent(curTrd.tr, otherTrd.tr, allEdges)) {
                                        curTrd.tr = curTrd.tr.concat(otherTrd.tr);
                                        allTriangles.splice(j, 1);
                                        changed = true;
                                        break;
                                    }
                                }
                                if (changed)
                                    break;
                            }
                        }
                    }

                    childShapesArray = allTriangles.map(trd => {

                        let paths: google.maps.LatLng[][];

                        if (debugOutput) {
                            paths = trd.tr.map(trs => trs.map(idx => latLngs[idx].ToGMLatLng()));
                        } else {
                            let polys = new ClipperLib.Paths(),
                                resultPolys = new ClipperLib.Paths(),
                                c = new ClipperLib.Clipper();

                            trd.tr.forEach(tr => {
                                var poly = new ClipperLib.Path();

                                tr.forEach(idx => {
                                    var p = pts[idx];

                                    poly.push(new ClipperLib.IntPoint(p.X, p.Y, p.Z));
                                });

                                polys.push(poly);
                            });

                            if (polys.length > 1) {
                                c.AddPaths(polys, ClipperLib.PolyType.ptSubject, true);
                                c.Execute(ClipperLib.ClipType.ctUnion, resultPolys, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);
                            } else {
                                resultPolys = polys;
                            }

                            if (resultPolys.map(rp => ClipperLib.Clipper.Area(rp)).reduce((a, b) => a + b, 0) < 0)
                                ClipperLib.Clipper.ReversePaths(resultPolys);
                            paths = resultPolys.map(rpoly => rpoly.map(rp => refLatLng.Offset(rp.Y * 0.001, rp.X * 0.001).ToGMLatLng()));
                        }

                        //let paths = [trd.tr.map(idx => latLngs[idx].ToGMLatLng())];
                        if (trd.edge) {
                            return {
                                paths: paths,
                                southMarkerPos: refLatLng.Offset(trd.edge.Start.Y * 0.001, trd.edge.Start.X * 0.001).add(refLatLng.Offset(trd.edge.End.Y * 0.001, trd.edge.End.X * 0.001)).mul(0.5),
                                orientation: trd.edge.getOrientation() + Math.PI,
                                slope: trd.slope,
                                pos: BoundsLatLng.FromGmLatLngs(paths).Center
                            };
                        }
                        return {
                            paths: paths,
                            southMarkerPos: this.southMarker ? this.southMarker.position.Clone() : null,
                            orientation: this.southMarker ? this.southMarker.orientation : 0,
                            slope: trd.slope,
                            pos: BoundsLatLng.FromGmLatLngs(paths).Center
                        };
                    });
                } catch (e) {
                    console.log(e);
                }
            }

            return childShapesArray.filter(chsh => {
                return chsh.paths.reduce((pv, cv) => pv + Math.abs(google.maps.geometry.spherical.computeArea(cv)), 0) > 1;
            });
        }

        private getCuttingSeparatedPolygons(): LatLng[][] {
            var mapDrawer = this.mapDrawer,
                viewModel = mapDrawer.viewModel,
                refLatLng = viewModel.referenceLatLng,
                cuttingPolylines = this.separationPolylines.filter(sp => sp.isCutting && sp.polyline.getPath().getLength() > 1);

            if (!this.poly)
                return null;

            let borders = this.poly.getPaths().getArray().map(path => path.getArray().map((ll: google.maps.LatLng) => LatLng.FromGMLatLng(ll))),
                borderPoly = new google.maps.Polygon({ paths: borders[0].map(ll => ll.ToGMLatLng()) });

            if (!cuttingPolylines.length)
                return borders;

            let borderEdges: [number, number][] = [],
                edges: [number, number][] = [],
                pts: Point3D[] = [],
                latLngs: LatLng[] = [];

            //add borders
            this.getBordersAsEdges(borders, refLatLng, pts, latLngs, borderEdges, 0);

            var separationOffsetIndex = pts.length;

            //add cutting lines
            cuttingPolylines.forEach(sp => {
                var spLLs = sp.polyline.getPath().getArray().map((gmll: google.maps.LatLng) => {
                    var spll = LatLng.FromGMLatLng(gmll),
                        spPt = refLatLng.DirectionTo(spll).mul(1000).round().to3D(0),
                        llIndex = ArrayHelper.searchIndex(pts, (pt) => pt.getXY().DistanceSquaredTo(spPt.getXY()) < 100);

                    if (llIndex < 0) {
                        llIndex = pts.length;

                        pts.push(spPt);
                    }

                    return llIndex;
                });

                for (var j = 1, k = spLLs.length - 1; j <= k; j++) {
                    let sp1 = spLLs[j - 1],
                        sp2 = spLLs[j];
                    if (sp1 !== sp2)
                        edges.push([sp1, sp2]);
                }
            });

            //split border edges
            borderEdges = borderEdges.filter(borderEdge => {

                let ep1 = pts[borderEdge[0]],
                    ep2 = pts[borderEdge[1]],
                    delta = ep2.sub(ep1),
                    len = delta.GetLength(),
                    dir = delta.mul(1 / len),
                    n = dir.LeftHandNormal(),
                    sPts: number[] = [];

                for (var si = separationOffsetIndex, j = pts.length; si < j; si++) {
                    let pt = pts[si],
                        dp = pt.sub(ep1),
                        dDot = dir.dot(dp),
                        nDot = Math.abs(n.dot(dp));

                    if (dDot > 0 && dDot < len && nDot <= 10) {
                        sPts.push(si);
                    }
                }

                if (sPts.length) {

                    sPts.sort((i1, i2) => dir.dot(pts[i1]) - dir.dot(pts[i2]));
                    sPts.unshift(borderEdge[0]);
                    sPts.push(borderEdge[1]);

                    for (var k = 0, p = sPts.length - 1; k < p; k++) {
                        edges.push([sPts[k], sPts[k + 1]]);
                    }

                    return false;
                }

                return true;
            });

            edges = edges.concat(borderEdges);

            let ptsArray: [number, number][] = pts.map(p => [p.X, p.Y] as [number, number]);

            //clean line intersections
            cleanPslg(ptsArray, edges);

            pts = ptsArray.map(pt => new Point3D(pt[0], pt[1], 0));

            //constrained delaunay triangulation
            let triangles = cdt2d(ptsArray,
                edges,
                {
                    delaunay: true,
                    interior: true,
                    exterior: false,
                    infinity: false
                }).filter(tr => {
                    return !Point2D.pointsOnLine(pts[tr[0]].getXY(), pts[tr[1]].getXY(), pts[tr[2]].getXY()) && google.maps.geometry.poly.containsLocation(refLatLng.OffsetVector(pts[tr[0]].add(pts[tr[1]].add(pts[tr[2]])).getXY().divide(3000)).ToGMLatLng(), borderPoly);
                });
            //return triangles.map(tr => tr.map(idx => refLatLng.Offset(pts[idx].y * 0.001, pts[idx].x * 0.001)));
            return this.calculatePolygonsFromTriangles(pts, edges, triangles).map(poly => poly.map(pt => refLatLng.Offset(pt.Y * 0.001, pt.X * 0.001)));

        }

        calculateChildSlopes() {
            if (this._isDisposed || !this.children || this.children.length < 2) {
                if (!this._isDisposed && this.children && this.children.length) {
                    var h = this.height;
                    this.children.forEach(child => {
                        child.height = h;
                    });
                }

                return;
            }

            let mapDrawer = this.mapDrawer,
                viewModel = mapDrawer.viewModel,
                targetSlope = this.slope,
                targetHeight = this.height,
                refLatLng = viewModel.referenceLatLng,
                bOff = 0,
                isNegativeSlope = targetSlope < 0;

            if (isNegativeSlope)
                targetSlope = -targetSlope;

            this.getCuttingSeparatedPolygons().forEach(border => {

                let borderPoly = new google.maps.Polygon({ paths: border.map(ll => ll.ToGMLatLng()) }),
                    pts: Point3D[] = [],
                    isSet: { [idx: number]: boolean } = {},
                    children = this.children.filter(child => google.maps.geometry.poly.containsLocation(LatLng.Point2DToGMLatLng(child.labelPosition.Center), borderPoly)).map(child => {
                        let indices: number[] = [],
                            orientation = child.southMarker ? child.southMarker.orientation : 0,
                            up = Point2D.FromOrientation(orientation + LatLng.PiHalf);

                        child.poly.getPaths().getArray().forEach(path => {
                            path.getArray().forEach((ll: google.maps.LatLng) => {
                                let pt = refLatLng.DirectionTo(LatLng.FromGMLatLng(ll)).mul(1000).round().to3D(0),
                                    idx = ArrayHelper.searchIndex(pts, p => p.DistanceSquaredTo(pt) <= 1);
                                if (idx < 0) {
                                    idx = pts.length;
                                    pts.push(pt);
                                }
                                indices.push(idx);
                            });
                        });

                        let minDistance = Number.MAX_VALUE,
                            distances = indices.map(idx => {
                                let d = up.dot(pts[idx].getXY());
                                if (d < minDistance)
                                    minDistance = d;
                                return d;
                            }).map(d => d - minDistance),
                            ptsMeta = indices.map((idx, i) => {
                                return {
                                    pt: pts[idx],
                                    idx: idx,
                                    dist: distances[i]
                                } as { pt: Point3D; idx: number; dist: number; };
                            });

                        return {
                            shapeData: child,
                            slope: child.slopeOverride ? child.customSlope : -1,
                            orientation: orientation,
                            area: Math.abs(Point2D.computeArea(indices.map(idx => pts[idx]))),//google.maps.geometry.spherical.computeArea((<google.maps.Polygon>child.poly).getPath()),
                            pts: ptsMeta
                        };
                    });

                children.sort((a, b) => b.area - a.area);

                let toCheck = children.filter(c => c.pts.some(cpt => !isSet[cpt.idx])),
                    current = toCheck.length ? (ArrayHelper.firstOrNull(toCheck, c => c.slope >= 0) || toCheck[0]) : null;

                while (current) {

                    let currentPts = current.pts,
                        setPts = currentPts.filter(cpt => isSet[cpt.idx]),
                        plane: Plane3D = null;

                    //try to find the best fitting plane

                    if (setPts.length >= 3)
                        plane = Plane3D.fromPoints(setPts.map(cpt => cpt.pt));

                    if (!plane && setPts.length >= 2) {
                        let g = ArrayHelper.byMin(currentPts, cpt => cpt.dist),
                            p1 = ArrayHelper.firstOrNull(setPts, cpt => cpt.dist > g.dist);
                        if (p1) {
                            let p2 = ArrayHelper.firstOrNull(setPts, cpt => cpt.dist > g.dist && cpt !== p1 && !Point3D.pointsOnLine(g.pt, p1.pt, cpt.pt));
                            if (p2)
                                plane = Plane3D.fromThreePoints(g.pt, p1.pt, p2.pt);
                        }
                    }

                    if (!plane && setPts.length >= 1) {
                        let g = ArrayHelper.byMin(currentPts, cpt => cpt.dist),
                            p1 = g.pt.add(Point2D.FromOrientation(current.orientation).to3D(0)),
                            p2 = ArrayHelper.firstOrNull(setPts, cpt => cpt.dist > g.dist && !Point3D.pointsOnLine(g.pt, p1, cpt.pt));
                        if (p2)
                            plane = Plane3D.fromThreePoints(g.pt, p1, p2.pt);
                    }

                    if (!plane) {
                        let slope = current.slope;
                        if (slope < 0)
                            slope = current.slope = targetSlope;

                        let slopeRad = slope * Math.PI / 180;

                        let g = ArrayHelper.byMin(currentPts, cpt => cpt.dist),
                            p1 = g.pt.add(Point2D.FromOrientation(current.orientation).to3D(0)),
                            p2 = g.pt.add(Point2D.FromOrientation(current.orientation + LatLng.PiHalf).to3D(Math.tan(slopeRad)));
                        plane = Plane3D.fromThreePoints(g.pt, p1, p2);
                    }

                    if (plane.dir.Z < 0)
                        plane.dir = plane.dir.neg();

                    if (plane.dir.Z <= 0)
                        plane.dir = new Point3D(0, 0, 1);

                    let dir = plane.dir,
                        pos = plane.pos;

                    //use this plane to update z on every point

                    currentPts.filter(cpt => !isSet[cpt.idx]).forEach(cpt => {
                        let p = cpt.pt;
                        cpt.pt.setZ(((dir.x * (p.x - pos.x) + dir.y * (p.y - pos.y)) / -dir.z) + pos.z);
                        isSet[cpt.idx] = true;
                    });

                    toCheck = children.filter(c => c.pts.some(cpt => !isSet[cpt.idx]));
                    current = toCheck.length ? (ArrayHelper.firstOrNull(toCheck, c => c.slope >= 0) || ArrayHelper.byMax(toCheck, c => c.pts.filter(cpt => isSet[cpt.idx]).length)) : null;
                }

                var bounds = Bounds3D.FromPoints(pts),
                    cOff = -bounds.Min.Z,
                    curbOff = targetHeight - (bounds.Max.Z + cOff);
                if (bOff <= 0 || curbOff < bOff)
                    bOff = curbOff;
                //now set orientation and slope
                children.forEach(child => {
                    let childPts = child.pts.map(cpt => cpt.pt),
                        plane = Plane3D.fromPoints(childPts),
                        cHeight = Math.max.apply(Math, childPts.map(pt => pt.Z));

                    if (plane && plane.dir.Z < 0)
                        plane.dir = plane.dir.neg();

                    if (!plane || plane.dir.Z <= 0)
                        plane = new Plane3D(childPts[0], new Point3D(0, 0, 1));

                    let cSlope = (Point3D.ZAxis.angleTo(plane.dir) * 180 / Math.PI),
                        cDir = cSlope > 0.1 ? plane.dir.getXY().GetNormalized().LeftHandNormal() : Point2D.FromOrientation(child.orientation);

                    let cSeg = ArrayHelper.byMax(child.shapeData.getSegments(), seg => seg.Direction.dot(cDir));

                    if (child.shapeData.southMarker) {
                        child.shapeData.southMarker.orientation = cSeg.getOrientation();
                        child.shapeData.southMarker.position = LatLng.FromPoint2D(cSeg.getCenter());
                    } else {
                        child.shapeData.southMarker = new SouthMarker(LatLng.FromPoint2D(cSeg.getCenter()), cSeg.getOrientation(), true, mapDrawer);
                    }

                    child.shapeData.height = cHeight + cOff;
                    child.shapeData.slope = cSlope;

                });
            });

            if (isNegativeSlope) {
                this.children.forEach(child => {
                    child.height += bOff;

                    if (child.southMarker) {
                        var cDir = Point2D.FromOrientation(child.southMarker.orientation).LeftHandNormal(),
                            ptLens = (Array.prototype.concat.apply([], child.getBorderPoints()) as Point2D[]).map(pt => pt.dot(cDir)),
                            minLen = Math.min.apply(null, ptLens) as number,
                            maxLen = Math.max.apply(null, ptLens) as number,
                            delta = maxLen - minLen;

                        child.southMarker.orientation = (child.southMarker.orientation + Math.PI) % (Math.PI * 2);
                        child.southMarker.position = LatLng.FromPoint2D(child.southMarker.position.ToPoint2D().add(cDir.mul(delta)));
                    }
                });
            } else {
                this.children.forEach(child => {
                    child.height += bOff;
                });
            }
        }

        update() {
            this.mapDrawer.viewModel.layer.typeName[this.typeName].onPolygonChanged(this.poly);
        }

        getMap(): google.maps.Map {
            return this.poly ? this.poly.getMap() : null;
        }

        getApproximatedRoofPlane(callback: (plane: THREE.Plane, minDepth: number, maxDepth: number) => void, mapDrawer?: MapDrawer) {
            if (this._isDisposed)
                return;

            if (!mapDrawer)
                mapDrawer = this.mapDrawer;

            var bd = this.bounds.Extend(5),
                w = bd.Width,
                h = bd.Height;

            if (w <= 0 || w >= 1000 || h <= 0 || h >= 1000)
                return;

            var pxSize = 256,
                sizeFactor = Math.min(pxSize / w, pxSize / h),
                width = Math.round(w * sizeFactor),
                height = Math.round(h * sizeFactor),
                ocr = mapDrawer.ocrManager.ocr,
                zoom: number,
                maxSize = Math.max(w, h);

            if (maxSize > 500)
                zoom = 18;
            else if (maxSize > 250)
                zoom = 19;
            else
                zoom = 23;

            LoaderManager.addLoadingJob();

            ocr.request({
                bounds: bd,
                level: zoom,
                renderWidth: width,
                renderHeight: height,
                depthMaterial: true,
                drawFn: (canvas, gl, order) => {
                    if (this._isDisposed) {
                        callback(null, 0, 0);
                        return;
                    }

                    if (order.maxLevel <= 20) {
                        DManager.ShowSmallInfo(MDStrings.ApproxNotEnoughDepthError);
                        callback(null, 0, 0);
                        return;
                    }

                    var heightData = new Uint8Array(width * height * 4);
                    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, heightData);

                    var minZ = Number.MAX_VALUE,
                        maxZ = 0,
                        idx: number,
                        r: number,
                        g: number,
                        b: number,
                        wlen = width * 4,
                        positions: number[] = [],
                        poly = this.latLngs[0].map(ll => {
                            let v = bd.Min.DirectionTo(ll);
                            return [Math.round(v.x * width / w), Math.round(v.y * height / h)];
                        }).reverse();

                    GMapsUtils.triangulationUtils.pointsInPolygon([poly], (x, y) => {
                        if (x > 0 && x < width && y > 0 && y < height) {
                            idx = y * wlen + x * 4;
                            r = heightData[idx];
                            g = heightData[idx + 1];
                            b = heightData[idx + 2];
                            d = r / 256 + g + b * 256;
                            positions.push(x / width * w, y / height * h, d);
                            if (d < minZ)
                                minZ = d;
                            if (d > maxZ)
                                maxZ = d;
                        }
                    });

                    var y: number,
                        x: number,
                        d: number,
                        minDepth = Number.MAX_VALUE, //lowest point in the vicinity
                        maxDepth = maxZ; //heighest point on the roof

                    //calc min and max depth
                    for (y = 0; y < height; y++) {
                        for (x = 0; x < width; x++) {
                            idx = y * wlen + x * 4;
                            r = heightData[idx];
                            g = heightData[idx + 1];
                            b = heightData[idx + 2];

                            d = r / 256 + g + b * 256;

                            if (d < minDepth)
                                minDepth = d;
                            //if (d > maxDepth)
                            //    maxDepth = d;
                        }
                    }

                    callback(spt.ThreeJs.utils.approximatePlaneFromPositionArray(positions, minZ), minDepth, maxDepth);

                },
                callBack: (success) => {
                    LoaderManager.finishLoadingJob();
                }
            });
        }

        remove(mapDrawer?: MapDrawer) {
            if (!mapDrawer)
                mapDrawer = this.mapDrawer;
            var layer = mapDrawer && mapDrawer.viewModel.layer.typeName[this.typeName];
            if (layer)
                layer.removeById(this.id);
            if (!this._isDisposed)
                this.dispose();
        }

        dispose() {
            if (this._isDisposed)
                return;
            this.clearTexts();
            this._isDisposed = true;
            this.subsciptions.forEach(sp => { sp.dispose(); });
            this.subsciptions = [];
            this.children.splice(0, this.children.length).forEach((sh) => { sh.remove(); });
            if (this.parent) {
                this.parent.children.remove((sh => sh.id === this.id));
                this.parent = null;
            }
            this.separationPolylines.splice(0, this.separationPolylines.length).forEach((sh) => { sh.remove(); });
            ko.untrack(this);
            if (this.southMarker) {
                this.southMarker.dispose();
                this.southMarker = null;
            }
            this.poly.setMap(null);
            this.mapDrawer = null;
            this.poly = null;
        }
    }

    export class MultiPolygon implements IGoogleMapsPolygon {
        //getDraggable(): boolean {
        //    return this.polygons.every(p => p.getDraggable());
        //}

        getEditable(): boolean {
            return this.polygons.every(p => p.getEditable());
        }

        getMap(): google.maps.Map {
            var polygons = this.polygons;
            for (var i = 0, j = polygons.length; i < j; i++) {
                var polygon = polygons[i],
                    map = polygon.getMap();
                if (map)
                    return map;
            }
        }

        getPaths(): google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> {
            var arr: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> = new google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>();
            this.polygons.forEach(poly => {
                var paths = poly ? poly.getPaths() : null;
                if (paths)
                    arr.push(new google.maps.MVCArray(paths.getAt(0).getArray()));
            });
            return arr;
        }

        getVisible(): boolean {
            return this.polygons.every(p => p.getVisible());
        }

        //setDraggable(draggable: boolean): void {
        //    this.polygons.forEach(p => { p.setDraggable(draggable); });
        //}

        setEditable(editable: boolean): void {
            this.polygons.forEach(p => { p.setEditable(editable); });
        }

        setMap(map: google.maps.Map): void {
            this.polygons.forEach(p => { p.setMap(map); });
        }

        setOptions(options: google.maps.PolygonOptions): void {
            this.polygons.forEach(p => { p.setOptions(options); });
        }

        setPaths(paths: google.maps.LatLng[][]): void {
            var polygons = this.polygons;

            if (paths) {
                var len = paths.length;
                if (polygons.length !== len)
                    return;

                for (var i = 0; i < len; i++) {
                    var path = paths[i];
                    if (path && path.length)
                        polygons[i].setPaths([path]);
                }

            }
        }

        setVisible(visible: boolean): void {
            this.polygons.forEach(p => { p.setVisible(visible); });
        }

        get polygons(): IGoogleMapsPolygon[] {
            var data = this.userData as MultiShapeData;
            if (data)
                return data.shapeDatas.map(d => d.poly);
            return [];
        }

        userData: MultiShapeData;

        get _isDisposed(): boolean {
            return this.polygons.every(p => !!p._isDisposed);
        }

        set _isDisposed(disposed: boolean) {
            this.polygons.forEach(p => { p._isDisposed = !!disposed; });
        }
    }

    export class MultiShapeData extends ShapeData {
        constructor(mapDrawer: MapDrawer) {
            super(mapDrawer, new MultiPolygon(), null, null, null);

            ko.track(this, ['shapeDatas']);

            this.mType = 'MultiShapeData';

            this.subsciptions.forEach(sp => { sp.dispose(); });
            this.subsciptions = [];

            //this.subsciptions.push(ko.getObservable(this, 'shapeDatas').subscribe(this.onShapeDatasChanged.bind(this), null, "arrayChange"));

            //isParallel, detailsViewVisible, locked, frozen, _rot, _scale, selected, hovered, southMarker, id, title, typeName, height, latLngs, points, bounds, pos, rotation, scale
            var sharedBooleans: (keyof ShapeData)[] = ["isParallel", "locked", "frozen", "detailsViewVisible"];
            var sharedStrings: (keyof ShapeData)[] = ["id", "title", "typeName"];

            ko.untrack(this, ["height", "isParallel", "locked", "frozen", "id", "title", "typeName", "detailsViewVisible"]);

            delete this.height;

            ko.defineProperty(this, "height",
                {
                    get: () => {
                        var v0 = this.shapeDatas.length ? this.shapeDatas[0].height : 0;
                        return this.shapeDatas.length && this.shapeDatas.every(d => d.height === v0) ? v0 : 0;
                    },
                    set: (v: number) => {
                        this.shapeDatas.forEach(d => { d.height = v || 0; });
                    }
                });

            sharedBooleans.forEach(k => {

                delete this[k];

                ko.defineProperty(this as any, k,
                    {
                        get: () => {
                            return this.shapeDatas.every(d => !!d[k]);
                        },
                        set: (v: boolean) => {
                            this.shapeDatas.forEach(d => { d[k as any] = !!v; });
                        }
                    });
            });

            sharedStrings.forEach(k => {

                delete this[k];

                ko.defineProperty(this as any, k,
                    {
                        get: () => {
                            if (this.shapeDatas.length) {
                                var rd = this.shapeDatas[0][k];
                                if (this.shapeDatas.every(d => d[k] === rd))
                                    return rd;
                            }

                            return null as any;
                        },
                        set: (v: string) => {
                            this.shapeDatas.forEach(d => { d[k as any] = v; });
                        }
                    });
            });
        }

        shapeDatas: ShapeData[] = [];

        poly: MultiPolygon;

        deleteShapes() {
            if (this.shapeDatas.length && confirm("Delete selection?"))
                this.mapDrawer.deleteSelectables(this.shapeDatas.splice(0, this.shapeDatas.length));
        }

        update() {
            this.shapeDatas.forEach(shapeData => {
                if (!shapeData._isDisposed)
                    shapeData.mapDrawer.viewModel.layer.typeName[shapeData.typeName].onPolygonChanged(shapeData.poly);
            });
            ko.valueHasMutated(this, 'poly');
        }

        updateStyle() {
            this.shapeDatas.forEach(shapeData => {
                if (!shapeData._isDisposed)
                    shapeData.updateStyle();
            });
        }
    }

    export class PolygonLayer implements IPolygonLayer {
        constructor(options: IPolygonLayer, mapDrawer: MapDrawer) {
            if (options) {

                ['shapeSelectedHoveredStyle', 'shapeSelectedStyle', 'shapeHoveredStyle', 'shapeStyle']
                    .forEach((style: string) => {
                        var s = {};
                        Object.keys(mapDrawer[style]).forEach(k => { s[k] = mapDrawer[style][k]; });
                        if (options[style])
                            Object.keys(options[style]).filter(k => options[style][k] !== undefined && options[style][k] !== null).forEach(k => { s[k] = options[style][k]; });
                        options[style] = s;
                    });

                for (var key in options) {
                    switch (key) {
                        case 'polygons':
                        case 'count':
                            break;
                        case 'maxCount':
                            if (options[key] && options[key] > 0)
                                this[key] = options[key];
                            break;
                        default:
                            this[key] = options[key];
                            break;
                    }
                }
            }

            ko.track(this);

            this.lastClickedIndex = -1;
            this.mapDrawer = mapDrawer;

            if (this.locked === undefined)
                ko.defineProperty(this, "locked", () => { return this.controlledByParent || this.count >= (this.maxCount || 2000); });

            ko.getObservable(this, 'polygons').subscribe(this.onPolygonsChanged.bind(this), null, "arrayChange");

            ko.defineProperty(this, "count", () => {
                return this.polygons.length;
            });

            ko.defineProperty(this, "polygonsLocked",
                {
                    get: () => {
                        return this.polygons.every(d => d.userData.locked);
                    },
                    set: (v: boolean) => {
                        this.polygons.forEach(d => { d.userData.locked = v; });
                    }
                });

            //ko.defineProperty(this, "selectedShapeDataId", () => {
            //    var selected = this.mapDrawer.viewModel.selection;
            //    if (!selected.length)
            //        return null;

            //    var polygons = this.polygons,
            //        currentSelected = polygons.filter(poly => !poly._isDisposed && poly.userData && !poly.userData._isDisposed && poly.userData.selected);

            //    if (currentSelected.length === 1)
            //        return currentSelected[0].userData.id;
            //    return null;
            //});

            //ko.defineProperty(this, 'currentPolygon',
            //    {
            //        get: () => {
            //            return this.getPolygonById(this.currentPolygonId) || (this.polygons.length ? this.polygons[0] : null);
            //        },
            //        set: (p: IGoogleMapsPolygon) => {
            //            if (p && p.userData)
            //                this.currentPolygonId = p.userData.id;
            //            else
            //                this.currentPolygonId = null;
            //        }
            //    });

            ko.defineProperty(this, "bounds", () => {
                var latLngs: LatLng[] = [];
                var polys = this.polygons;
                polys.forEach(poly => {
                    var userData = poly.userData as ShapeData;
                    if (userData) {
                        var bd = userData.bounds;
                        if (bd) {
                            latLngs.push(bd.Min);
                            latLngs.push(bd.Max);
                        }
                    }
                });
                return BoundsLatLng.FromLatLngs(latLngs);
            });
        }

        mapDrawer: MapDrawer;
        polygons: IGoogleMapsPolygon[] = [];
        //currentPolygonId: string = null;
        //currentPolygon: IGoogleMapsPolygon;
        //selectedShapeDataId: string;
        readonly bounds: BoundsLatLng;

        count: number;
        maxCount: number = 0;
        zIndex: number;

        lastClickedIndex: number;

        shapeSelectedHoveredStyle: IShapeStyle;
        shapeSelectedStyle: IShapeStyle;
        shapeHoveredStyle: IShapeStyle;
        shapeStyle: IShapeStyle;

        typeName: string;
        layerName: string;

        locked: boolean;
        polygonsLocked: boolean;

        parent: IPolygonLayer;
        child: IPolygonLayer;
        controlledByParent: boolean;
        isPassive: boolean;

        togglePolygonsLocked(b: boolean) {
            this.polygonsLocked = !!b;
        }

        getNewName(count?: number): string {
            if (count === undefined)
                count = this.polygons.length;
            var newName = this.layerName;
            if (this.typeName === window.RoofTypeName && $("#Roof_Title[name='Roof.Title']").length) {
                var n = $("#Roof_Title[name='Roof.Title']").val();
                if (n && typeof n === "string" && n.length > 1)
                    newName = n;
            }
            if (this.typeName !== window.BuildingTypeName)
                newName += ' ' + (count > 99 ? ('' + count) : ('0' + count).slice(-2));
            return newName;
        }

        onPolyListClick(data: ShapeData, index: number) {
            var mapDrawer = this.mapDrawer,
                lastClickedIndex = this.lastClickedIndex,
                polys = this.polygons;

            mapDrawer.selectTool('SelectTool');

            if (data.parent && data.parent instanceof ShapeData) {
                var parent = data.parent,
                    childIds: string[] = [];
                mapDrawer.doSelectSelectable(parent);

                if (mapDrawer.shiftDown && index !== lastClickedIndex && lastClickedIndex >= 0 && index >= 0 && polys && polys.length > index && polys.length > lastClickedIndex) {
                    var step = index < lastClickedIndex ? 1 : -1;
                    for (var i = index; i !== lastClickedIndex; i += step) {
                        var poly = polys[i];
                        if (poly && !poly._isDisposed && poly.userData)
                            childIds.push(poly.userData.id);
                    }
                } else
                    childIds.push(data.id);

                parent.selectChildrenByIds(childIds);
            } else {
                if (mapDrawer.shiftDown && index !== lastClickedIndex && lastClickedIndex >= 0 && index >= 0 && polys && polys.length > index && polys.length > lastClickedIndex) {
                    var step = index < lastClickedIndex ? 1 : -1;
                    for (var i = index; i !== lastClickedIndex; i += step) {
                        var poly = polys[i];
                        if (poly && !poly._isDisposed && poly.userData)
                            mapDrawer.doSelectSelectable(poly.userData);
                    }
                } else
                    mapDrawer.doSelectSelectable(data);
            }

            this.lastClickedIndex = +index;

            return true; //false := preventDefault()
        }

        getPolygonById(id: string) {
            var polygons = this.polygons;
            if (!id || !polygons.length)
                return null;

            for (var i = 0, j = polygons.length; i < j; i++) {
                var poly = polygons[i];
                if (poly.userData.id === id)
                    return poly;
            }

            return null;
        }

        removeById(id: string) {
            var rem = this.polygons.remove(s => s._isDisposed || s.userData.id === id);
            rem.forEach(this.onPolygonsRemoved.bind(this));
        }

        addPolygon(poly: IGoogleMapsPolygon, mapDrawer: MapDrawer) {
            if (poly) {
                if (!this.maxCount || this.polygons.length < this.maxCount)
                    this.polygons.push(poly);
                else {
                    if (poly.userData && poly.userData.dispose)
                        poly.userData.dispose();
                    else
                        poly.setMap(null);
                }
            }
        }

        //onPolyPointInserted(poly: IGoogleMapsPolygon, path: google.maps.MVCArray<google.maps.LatLng>, index: number) {
        //    if (!poly || poly._isDisposed || !poly.userData || poly.userData.skipUpdate)
        //        return;
        //    //console.log("onPolyPointInserted " + index);
        //    this.onPolygonChanged(poly);
        //}

        //onPolyPointRemoved(poly: IGoogleMapsPolygon, path: google.maps.MVCArray<google.maps.LatLng>, index: number, prev: google.maps.LatLng) {
        //    if (!poly || poly._isDisposed || !poly.userData || poly.userData.skipUpdate)
        //        return;
        //    //console.log("onPolyPointRemoved " + index);
        //    this.onPolygonChanged(poly);
        //}

        forceUpdateAllPolygons() {
            if (this.polygons.length) {
                this.polygons.forEach(poly => {
                    this.onPolygonChanged(poly);
                });
            }
        }

        onPolyPointChanged(shapeData: ShapeData, path: google.maps.MVCArray<google.maps.LatLng>, index: number, prev: google.maps.LatLng) {
            if (!shapeData || shapeData._isDisposed || shapeData.skipUpdate)
                return;
            //var t = LatLng.FromGMLatLng(prev).DirectionTo(LatLng.FromGMLatLng(path.getAt(index)));
            //console.log(`onPolyPointChanged ${index} (${t.x},${t.y})`);
            shapeData.movePoint(LatLng.FromGMLatLng(prev), LatLng.FromGMLatLng(path.getAt(index)), shapeData.id);
            this.onPolygonChanged(shapeData.poly);
        }

        onPolygonChanged(poly: IGoogleMapsPolygon) {
            if (!poly || poly._isDisposed || !poly.userData || poly.userData.skipUpdate)
                return;
            if (poly.userData) {
                if (poly.userData.poly)
                    ko.valueHasMutated(poly.userData, 'poly');
                if (poly.userData.regenerate)
                    poly.userData.regenerate();
            }

            ko.valueHasMutated(this, 'polygons');
        }

        removeEvents(shapeData: ShapeData) {
            var poly = shapeData.poly;

            if ((<any>poly)._gm_events_added_ex) {
                (<any>poly)._gm_events_added_ex = false;

                var paths = poly.getPaths();

                paths.forEach(path => {
                    google.maps.event.clearListeners(path, 'insert_at');
                    google.maps.event.clearListeners(path, 'remove_at');
                    google.maps.event.clearListeners(path, 'set_at');
                });

                google.maps.event.clearListeners(poly, 'click');
                google.maps.event.clearListeners(poly, 'mouseover');
                google.maps.event.clearListeners(poly, 'mouseout');

                //google.maps.event.clearListeners(poly, 'dragstart');
                //google.maps.event.clearListeners(poly, 'drag');
                //google.maps.event.clearListeners(poly, 'dragend');
            }
        }

        addEvents(shapeData: ShapeData) {
            var poly = shapeData.poly,
                paths = poly.getPaths(),
                mapDrawer = this.mapDrawer,
                viewModel = mapDrawer.viewModel;

            if (!(<any>poly)._gm_events_added_ex) {
                (<any>poly)._gm_events_added_ex = true;

                google.maps.event.addListener(poly, 'click', viewModel.onSelectableClick.bind(viewModel, shapeData));
                google.maps.event.addListener(poly, 'mouseover', viewModel.onSelectableMouseover.bind(viewModel, shapeData));
                google.maps.event.addListener(poly, 'mouseout', viewModel.onSelectableMouseout.bind(viewModel, shapeData));

                paths.forEach(path => {
                    google.maps.event.addListener(path, "insert_at", this.onPolygonChanged.bind(this, poly));
                    google.maps.event.addListener(path, "remove_at", this.onPolygonChanged.bind(this, poly));
                    google.maps.event.addListener(path, "set_at", this.onPolyPointChanged.bind(this, poly.userData, path));
                });

                //google.maps.event.addListener(poly, 'dragstart', viewModel.onPolygonDragStart.bind(viewModel, poly));
                //google.maps.event.addListener(poly, 'drag', viewModel.onPolygonDragged.bind(viewModel, poly));
                //google.maps.event.addListener(poly, 'dragend', viewModel.onPolygonDragEnd.bind(viewModel, poly));

            }
        }

        onPolygonsChanged(changes: { value: IGoogleMapsPolygon, status: string }[]) {
            changes.forEach((change) => {
                switch (change.status) {
                    case "deleted":
                        this.onPolygonsRemoved(change.value);
                        break;
                    case "added":
                        this.onPolygonsAdded(change.value);
                        break;
                }
            });
        }

        onPolygonsRemoved(poly: IGoogleMapsPolygon) {
            if (!poly || poly._isDisposed)
                return;

            if (poly.userData)
                this.removeEvents(poly.userData as ShapeData);

            if (poly.userData && (<any>poly.userData).dispose)
                (<any>poly.userData).dispose();
            else
                poly.setMap(null);

            poly.userData = null;

            poly._isDisposed = true;

            lsGoogleMaps.checkRoofStepAfterRemove();

        }

        onPolygonsAdded(poly: IGoogleMapsPolygon) {
            if (!poly || poly._isDisposed)
                return;

            var mapDrawer = this.mapDrawer;

            if (!poly.userData)
                poly.userData = new ShapeData(mapDrawer, poly, spt.Utils.GenerateGuid(), this.getNewName(), this.typeName);

            if (poly instanceof google.maps.Polygon && !(<any>poly)._gm_setPaths_ex) {
                (<any>poly)._gm_setPaths_ex = true;
                poly.setPaths = (path: any) => {
                    if (poly._isDisposed || !poly.userData || poly.userData._isDisposed)
                        return google.maps.Polygon.prototype.setPaths.call(poly, path);

                    this.removeEvents(poly.userData as ShapeData);
                    var res = google.maps.Polygon.prototype.setPaths.call(poly, path);
                    this.addEvents(poly.userData as ShapeData);
                    return res;
                };
            }

            if (this.controlledByParent)
                poly.userData.controlledByParent = true;

            var clickable = !poly.userData.locked && !poly.userData.frozen;

            poly.setOptions({
                clickable: clickable,
                //draggable: clickable && poly.userData.selected,
                draggable: false,
                editable: clickable && poly.userData.selected && !poly.userData.controlledByParent,
                map: mapDrawer.map,
                zIndex: this.zIndex || 0
            });

            this.addEvents(poly.userData as ShapeData);

            poly.userData.typeName = this.typeName;
            //poly.userData.bounds = BoundsLatLng.FromGmLatLngs(paths);
            poly.userData.updateStyle();
            if (poly.userData.regenerate)
                poly.userData.regenerate();

            var toolName = mapDrawer.viewModel.selectedToolName;

            if (toolName === "ReferenceLengthTool")
                return;

            if (toolName === "SelectTool" || toolName === "SelectRectTool" || toolName === "RidgeTool") {
                //mapDrawer.selectShape(poly.userData as ShapeData);
                if (this.locked)
                    mapDrawer.autoSelectLayer();
            } else {
                if (this.locked) {
                    mapDrawer.selectTool("SelectTool");
                    mapDrawer.autoSelectLayer();
                } else
                    poly.userData.frozen = true;
            }
        }
    }

    export interface ISnapResult {
        snapped: boolean;
        aligned: boolean;
        result: LatLng;
    }

    export class Helperline implements ISelectableItem {
        constructor(mapDrawer: MapDrawer, start?: LatLng, end?: LatLng, zIndex?: number) {

            this.start = start || new LatLng();
            this.end = end || this.start;

            ko.track(this);

            var id = spt.Utils.GenerateGuid();
            this.typeName = "Helperline";

            mapDrawer.viewModel.setInstance(id, this);
            Object.defineProperty(this, "id",
                {
                    configurable: true,
                    enumerable: true,
                    get: () => {
                        return id;
                    },
                    set: (v) => {
                        if (id && mapDrawer.viewModel.hasInstance(id))
                            mapDrawer.viewModel.removeInstance(id);
                        id = v;
                        if (id && !this._isDisposed)
                            mapDrawer.viewModel.setInstance(id, this);
                    }
                });

            this.mapDrawer = mapDrawer;

            var polyLineOpts: google.maps.PolylineOptions = {
                map: mapDrawer.map,
                draggable: false,
                editable: false,
                clickable: false,
                zIndex: zIndex || 0,
                visible: this.isVisible
            };

            var polyLine = this.polyLine = new google.maps.Polyline($.extend(polyLineOpts, Helperline.shapeStyle) as google.maps.PolylineOptions);
            var polyLineOuter = this.polyLineOuter = new google.maps.Polyline($.extend(polyLineOpts, Helperline.shapeStyle) as google.maps.PolylineOptions);

            polyLineOuter.setPath(polyLine.getPath());

            var clickable = !this.locked && !this.frozen;

            polyLineOuter.setOptions({
                strokeWeight: Helperline.clickTolerance,
                clickable: clickable,
                editable: false,
                draggable: clickable && this.selected,
                strokeOpacity: 0,
                visible: this.isVisible
            });

            google.maps.event.addListener(polyLineOuter, 'click', mapDrawer.doSelectSelectable.bind(mapDrawer, this));
            google.maps.event.addListener(polyLineOuter, 'mouseover', this.onMouseover.bind(this, mapDrawer));
            google.maps.event.addListener(polyLineOuter, 'mouseout', this.onMouseout.bind(this, mapDrawer));
            google.maps.event.addListener(polyLineOuter, 'dragend', this.onDragged.bind(this, mapDrawer));
            //google.maps.event.addListener(polyLineOuter, 'drag', this.onDragged.bind(this, mapDrawer));

            this.update();

            ko.getObservable(this, 'selected').subscribe(this.onSelected.bind(this, mapDrawer));

            ko.getObservable(this, 'isVisible').subscribe(this.updateVisibility.bind(this));
            ko.getObservable(this, 'locked').subscribe(this.updateClickable.bind(this));
            ko.getObservable(this, 'frozen').subscribe(this.updateClickable.bind(this));

            ko.defineProperty(this, 'pos',
                {
                    get: () => {
                        if (!this.polyLine || !this.mapDrawer)
                            return Point2D.Zero;
                        var refLatLng = this.mapDrawer.viewModel.referenceLatLng;
                        return refLatLng.DirectionTo(this.start);
                    },
                    set: (p: Point2D) => {
                        if (!this.polyLine || !this.mapDrawer)
                            return;
                        var t = p.sub(this.pos).mul(0.001),
                            e = this.end,
                            s = this.start;
                        this.setStartEnd(s.Offset(t.y, t.x), e.Offset(t.y, t.x));
                    }
                });

            ko.defineProperty(this, 'rotation',
                {
                    get: () => {
                        if (!this.polyLine || !this.mapDrawer)
                            return 0;
                        var d = this.end.ToPoint2D().sub(this.start.ToPoint2D());
                        return Math.atan2(d.y, d.x) / Math.PI * 180;
                    },
                    set: (r: number) => {
                        if (!this.polyLine || !this.mapDrawer)
                            return;
                        var rad = r / 180 * Math.PI;
                        var d = new Point2D(Math.cos(rad), Math.sin(rad));
                        this.end = LatLng.FromPoint2D(this.start.ToPoint2D().add(d));
                    }
                });

        }

        private updateVisibility(newValue: boolean) {
            console.log(`updateVisibility: ${newValue}`);
            this.isVisible = newValue;
            if (!this.polyLine)
                return;
            this.polyLine.setOptions({
                visible: this.isVisible
            });
            this.polyLineOuter.setOptions({
                visible: this.isVisible
            });
        }

        private updateClickable() {
            if (!this.polyLine)
                return;

            var clickable = !this.locked && !this.frozen;

            this.polyLineOuter.setOptions({
                clickable: clickable
            });
            if (!clickable && this.selected)
                this.mapDrawer.doSelectSelectable(this, false, false, true);
        }

        static SnapDirection(n: Point2D, mapDrawer: MapDrawer): Point2D {
            var helperLines = mapDrawer.viewModel.helperLines;

            if (!helperLines || !helperLines.length)
                return n;

            let ndist = 1,
                normals: Point2D[] = helperLines.length ? [] : [Point2D.XAxis, Point2D.YAxis],
                foundNormal: Point2D = null;

            if (helperLines.length) {
                helperLines.forEach(helperLine => {
                    normals.push.apply(normals, helperLine.dirs);
                });
            }

            normals.forEach(normal => {
                let d = 1 - Math.abs(n.dot(normal));
                if (d < ndist) {
                    ndist = d;
                    foundNormal = normal;
                }
            });

            if (foundNormal && n.dot(foundNormal) < 0)
                foundNormal = foundNormal.neg();

            return foundNormal || n;
        }

        static GetSnapByHelperlines(prevPrevLatLng: LatLng, prevLatLng: LatLng, curLatLng: LatLng, mapDrawer: MapDrawer, align: boolean, snapClosest: boolean): ISnapResult {
            var result: LatLng = null;

            var curp = curLatLng.ToPoint2D(),
                helperLines = mapDrawer.viewModel.helperLines,
                clickTolerance = Helperline.clickTolerance,
                pxFactor = mapDrawer.getPixelFactor();

            var snapped = false;
            var aligned = false;

            if (align && prevLatLng) {

                let prev = prevLatLng.ToPoint2D(),
                    t = curp.sub(prev),
                    lensq = t.GetLengthSquared();

                if (lensq > 0) {
                    let len = Math.sqrt(lensq),
                        n = t.divide(len),
                        ndist = 1,
                        normals: Point2D[] = helperLines.length ? [] : [Point2D.XAxis, Point2D.YAxis],
                        foundNormal: Point2D = null;

                    // adrian
                    let isActivePrevDirection = true;
                    if (isActivePrevDirection) {
                        let prevNorm = prev.sub(prevPrevLatLng.ToPoint2D()).GetNormalized();
                        normals = helperLines.length ? [] : [prevNorm, prevNorm.RightHandNormal()]
                    }

                    if (helperLines.length) {
                        helperLines.forEach(helperLine => {
                            normals.push.apply(normals, helperLine.dirs);
                        });
                    }

                    normals.forEach(normal => {
                        let d = 1 - Math.abs(n.dot(normal));
                        if (d < ndist) {
                            ndist = d;
                            foundNormal = normal;
                        }
                    });

                    if (foundNormal) {
                        if (n.dot(foundNormal) < 0)
                            foundNormal = foundNormal.neg();

                        if (helperLines.length) {
                            let minDist = Number.MAX_VALUE,
                                nlen = foundNormal.dot(t),
                                seg = new Segment2D(prev, prev.add(foundNormal.mul(nlen)), true),
                                foundPoint: Point2D = null;

                            if (nlen !== 0) {

                                helperLines.forEach(helperLine => {
                                    if (snapClosest) {
                                        let intersection = helperLine.getIntersection(seg);
                                        if (intersection &&
                                            intersection.sub(prev).GetNormalized().dot(foundNormal) > 0) {
                                            let dist = intersection.DistanceTo(curp) * pxFactor;
                                            if (dist <= clickTolerance && dist < minDist) {
                                                minDist = dist;
                                                foundPoint = intersection;
                                            }
                                        }
                                    } else if (helperLine.distanceToPoint(seg.End) * pxFactor <= clickTolerance)
                                        snapped = true;
                                });
                            }

                            if (foundPoint) {
                                result = LatLng.FromPoint2D(foundPoint);
                                snapped = true;
                            } else {
                                result = LatLng.FromPoint2D(seg.End);
                                aligned = true;
                            }
                        } else {
                            result = LatLng.FromPoint2D(prev.add(foundNormal.mul(foundNormal.dot(t))));
                            snapped = true;
                        }
                    }
                }
            }

            if (!result && snapClosest && helperLines.length) {
                let minDist = Number.MAX_VALUE,
                    foundPoint: Point2D = null;

                let p = mapDrawer.getPointByPoint(LatLng.FromPoint2D(curp), clickTolerance, true, false);
                if (p) {
                    minDist = 0;
                    foundPoint = p;
                } else {
                    helperLines.forEach(helperLine => {
                        var dist = helperLine.distanceToPoint(curp) * pxFactor;
                        if (dist <= clickTolerance && dist < minDist) {
                            minDist = dist;
                            foundPoint = helperLine.getClosestPoint(curp);
                        }
                    });
                }

                if (foundPoint) {
                    result = LatLng.FromPoint2D(foundPoint);
                    snapped = true;
                }
            }

            return {
                snapped: snapped,
                aligned: aligned,
                result: result || curLatLng
            };
        }

        static SnapToNextIncrement(prevLatLng: LatLng, curLatLng: LatLng, mapDrawer: MapDrawer) {
            var p1 = prevLatLng.ToPoint2D();
            var p2 = curLatLng.ToPoint2D();
            var t = p2.sub(p1);

            var realLen = prevLatLng.DistanceTo(curLatLng) * 1000;
            var targetLen = Math.round(realLen / mapDrawer.viewModel.keyBoardSteps.internalValue) * mapDrawer.viewModel.keyBoardSteps.internalValue;
            if (targetLen <= 0)
                targetLen = mapDrawer.viewModel.keyBoardSteps.internalValue;
            if (realLen <= 0)
                realLen = targetLen;

            return LatLng.FromPoint2D(p1.add(t.mul(targetLen / realLen)));
        }

        static SnapToFixedLength(prevLatLng: LatLng, curLatLng: LatLng, targetLen: number) {
            var p1 = prevLatLng.ToPoint2D();
            var p2 = curLatLng.ToPoint2D();
            var t = p2.sub(p1);

            var realLen = prevLatLng.DistanceTo(curLatLng) * 1000;

            return LatLng.FromPoint2D(p1.add(t.mul(targetLen / realLen)));
        }

        getClosestPoint(p: Point2D): Point2D {
            if (!p || this._isDisposed)
                return null;

            return this.segment2D.getClosestPoint(p);
        }

        distanceToPoint(p: Point2D): number {
            if (!p || this._isDisposed)
                return Number.POSITIVE_INFINITY;

            return this.segment2D.distanceToPoint(p);
        }

        getSegments(): Segment2D[] {
            if (this._isDisposed)
                return [];
            return [this.segment2D];
        }

        getPoints(): Point2D[][] {
            return [[]];
        }

        getIntersection(seg: Segment2D) {
            return this.segment2D.getIntersection(seg);
        }

        updateStyle() {
            if (!this.mapDrawer || !this.polyLine)
                return;
            var poly = this.polyLine,
                hovered = this.hovered,
                selected = this.selected;
            if (selected)
                poly.setOptions(hovered ? Helperline.shapeSelectedHoveredStyle : Helperline.shapeSelectedStyle);
            else
                poly.setOptions(hovered ? Helperline.shapeHoveredStyle : Helperline.shapeStyle);
        }

        setPosX(v: string | number) {
            var y = this.pos.y;
            var x = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.pos = new Point2D(x, y);
        }

        setPosY(v: string | number) {
            var x = this.pos.x;
            var y = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                    to: "mm"
                });
            this.pos = new Point2D(x, y);
        }

        setRotation(v: string | number) {
            var r = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -360,
                    max: 360,
                    applyArithmetic: true,
                    notImperial: true
                });
            this.rotation = r;
        }

        onSelected(mapDrawer: MapDrawer, b: boolean) {
            if (!this.polyLine)
                return;
            this.polyLineOuter.setOptions({
                draggable: !!b
            });
            this.updateStyle();
        }

        onMouseover(mapDrawer: MapDrawer) {
            if (this.selectable) {
                this.hovered = true;
                this.updateStyle();
            }
        }

        onMouseout(mapDrawer: MapDrawer) {
            if (this.selectable) {
                this.hovered = false;
                this.updateStyle();
            }
        }

        onDragged(mapDrawer: MapDrawer) {
            var path = this.polyLine.getPath();
            if (path) {
                var start = this.start = LatLng.FromGMLatLng(path.getAt(0) as google.maps.LatLng),
                    end = this.end = LatLng.FromGMLatLng(path.getAt(1) as google.maps.LatLng);

                var s = start.ToPoint2D(),
                    e = end.ToPoint2D(),
                    dir = e.sub(s).GetNormalized();

                this.dirs = [dir, dir.rot90()];

                this.segment2D = new Segment2D(s, e, true);

            }
        }

        static clickTolerance = 15;

        static shapeStyle: IShapeStyle = {
            strokeWeight: 1.5,
            strokeColor: '#00d8ff',
            strokeOpacity: 0.7
        };

        static shapeHoveredStyle: IShapeStyle = {
            strokeWeight: 1.5,
            strokeColor: '#00d8ff',
            strokeOpacity: 0.9
        };

        static shapeSelectedStyle: IShapeStyle = {
            strokeWeight: 2,
            strokeColor: '#00a9e1',
            strokeOpacity: 1
        };

        static shapeSelectedHoveredStyle: IShapeStyle = {
            strokeWeight: 2,
            strokeColor: '#00c0ff',
            strokeOpacity: 1
        };

        id: string;
        typeName: string;
        selected: boolean = false;
        selectable: boolean = false;
        hovered: boolean = false;
        private start: LatLng;
        private end: LatLng;
        private delta: LatLng;
        private polyLine: google.maps.Polyline;
        private polyLineOuter: google.maps.Polyline;
        private segment2D: Segment2D;
        pos: Point2D;
        mapDrawer: MapDrawer;
        rotation: number;
        dirs: Point2D[] = [new Point2D(0, 1), new Point2D(1, 0)];
        locked: boolean = false;
        frozen: boolean = false;
        isVisible: boolean = false;
        _isDisposed: boolean;

        get Start(): LatLng {
            return this.start;
        }

        set Start(value: LatLng) {
            this.start = value;
            this.update();
        }

        get End(): LatLng {
            return this.end;
        }

        set End(value: LatLng) {
            this.end = value;
            this.update();
        }

        get Delta(): LatLng {
            return this.delta;
        }

        get Segment(): Segment2D {
            return this.segment2D;
        }

        setStartEnd(s: LatLng, e: LatLng) {
            this.start = s;
            this.end = e;
            this.update();
        }

        update() {
            var start = this.start,
                delta = this.delta = this.end.Equals(start) ? new LatLng(0.1, 0) : this.end.sub(start),
                end = start.add(delta),
                s = start.ToPoint2D(),
                e = end.ToPoint2D(),
                dir = e.sub(s).GetNormalized(),
                d = dir.mul(50);
            this.dirs = [dir, dir.rot90()];

            var path = this.polyLine.getPath();

            this.segment2D = new Segment2D(s, e, true);

            path.setAt(0, LatLng.Point2DToGMLatLng(s.sub(d)));
            path.setAt(1, LatLng.Point2DToGMLatLng(s.add(d)));
        }

        moveByLatLng(latLng: LatLng) {
            if (this._isDisposed)
                return;

            this.start = this.start.add(latLng);
            this.end = this.end.add(latLng);
            this.update();
        }

        moveByOffset(offset: Point2D) {
            if (this._isDisposed)
                return;

            this.start = this.start.Offset(offset.y, offset.x);
            this.end = this.end.Offset(offset.y, offset.x);
            this.update();
        }

        setMap(map: google.maps.Map) {
            this.polyLine.setMap(map);
        }

        getMap(): google.maps.Map {
            return this.polyLine.getMap();
        }

        remove(mapDrawer: MapDrawer) {
            this.dispose();
        }

        dispose() {
            if (this._isDisposed)
                return;

            if (this.id && this.mapDrawer)
                this.mapDrawer.viewModel.helperLines.remove(hl => hl.id === this.id);

            ko.untrack(this);
            var polyLine = this.polyLine,
                polyLineOuter = this.polyLineOuter;

            google.maps.event.clearListeners(polyLineOuter, 'click');
            google.maps.event.clearListeners(polyLineOuter, 'mouseover');
            google.maps.event.clearListeners(polyLineOuter, 'mouseout');
            //google.maps.event.clearListeners(polyLineOuter, 'drag');
            google.maps.event.clearListeners(polyLineOuter, 'dragend');

            polyLine.setMap(null);
            polyLineOuter.setMap(null);

            this.polyLine = null;
            this.polyLineOuter = null;
            this._isDisposed = true;
        }
    }

    export class SouthMarker {

        constructor(position: LatLng, orientation: number, visible: boolean, mapDrawer: MapDrawer, zIndex?: number) {
            this.position = position || LatLng.FromGMLatLng(mapDrawer.map.getCenter());
            this.orientation = orientation;
            this.visible = !!visible;

            ko.track(this);

            this.id = spt.Utils.GenerateGuid();

            var marker = this.marker = new google.maps.Marker({
                position: this.position.ToGMLatLng(),
                map: mapDrawer.map,
                zIndex: zIndex || 11,
                visible: this.visible,
                clickable: false,
                draggable: false
            });

            ko.getObservable(this, 'clickable').subscribe(this.updateClickable.bind(this));
            ko.getObservable(this, 'visible').subscribe(this.updateVisible.bind(this));
            ko.getObservable(this, 'hovered').subscribe(this.updateStyle.bind(this));
            ko.getObservable(this, 'orientation').subscribe(this.update.bind(this));
            ko.getObservable(this, 'position').subscribe(this.updatePosition.bind(this));

            google.maps.event.addListener(marker, 'click', this.onClick.bind(this, mapDrawer));
            google.maps.event.addListener(marker, 'mouseover', this.onMouseover.bind(this, mapDrawer));
            google.maps.event.addListener(marker, 'mouseout', this.onMouseout.bind(this, mapDrawer));

            this.update();
        }

        clone(mapDrawer: MapDrawer, zIndex?: number): SouthMarker {
            return new SouthMarker(this.position.Clone(), this.orientation, this.visible, mapDrawer, zIndex);
        }

        onClick(mapDrawer: MapDrawer) {
            if (!this._isDisposed && mapDrawer.viewModel.selectedToolName === 'SouthMarkerTool')
                (mapDrawer.viewModel.currentTool as SouthMarkerTool).onClickSouthMarker(this, mapDrawer);
        }

        onMouseover(mapDrawer: MapDrawer) {
            if (!this._isDisposed && mapDrawer.viewModel.selectedToolName === 'SouthMarkerTool')
                (mapDrawer.viewModel.currentTool as SouthMarkerTool).onMouseoverSouthMarker(this, mapDrawer);
        }

        onMouseout(mapDrawer: MapDrawer) {
            if (!this._isDisposed && mapDrawer.viewModel.selectedToolName === 'SouthMarkerTool')
                (mapDrawer.viewModel.currentTool as SouthMarkerTool).onMouseoutSouthMarker(this, mapDrawer);
        }

        updateVisible() {
            if (!this.marker)
                return;
            this.marker.setVisible(this.visible);
        }

        updateClickable() {
            if (!this.marker)
                return;
            this.marker.setClickable(this.clickable);
        }

        updateStyle() {
            if (!this.marker)
                return;
            this.marker.setOptions($.extend({
                position: this.position.ToGMLatLng(),
                visible: this.visible
            }, this.hovered ? SouthMarker.shapeHoveredStyle : SouthMarker.shapeStyle));
        }

        update() {
            if (!this.marker)
                return;
            this.marker.setOptions({
                position: this.position.ToGMLatLng(),
                icon: this.getSymbol()
            });
        }

        updatePosition() {
            if (!this.marker)
                return;
            this.marker.setPosition(this.position.ToGMLatLng());
        }

        marker: google.maps.Marker;
        position: LatLng;

        static size = 15;
        static dist = 8;
        hovered: boolean = false;
        visible: boolean = true;
        clickable: boolean = false;
        id: string;
        orientation: number;
        _isDisposed: boolean;

        static shapeStyle: IShapeStyle = {
            strokeColor: '#212121',
            fillColor: '#FFFFFF',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeOpacity: 0.7
        };

        static shapeHoveredStyle: IShapeStyle = {
            strokeColor: '#212121',
            fillColor: '#FFFFFF',
            fillOpacity: 0.6,
            strokeWeight: 2,
            strokeOpacity: 1
        };

        getSymbol(): google.maps.Symbol {

            var size = SouthMarker.size,
                dist = SouthMarker.dist,
                dirR = Point2D.FromOrientation(this.orientation),
                dirL = dirR.neg(),
                n = dirL.rot90(),
                nb = n.neg(),
                nl = n.add(dirL);

            var path = `M ${n.mul(dist)} l ${nl.mul(size * 1.5)} l ${dirR.mul(size)} l ${n.mul(size * 1.5)} l ${dirR.mul(size)} l ${nb.mul(size * 1.5)} l ${dirR.mul(size)} z M${n.mul(dist * 0.7).add(dirL.mul(size * 1.3))} l ${dirR.mul(size * 2.6)}`;

            var icon: google.maps.Symbol = {
                path: path,
                scale: 1
            };

            return $.extend(icon, this.hovered ? SouthMarker.shapeHoveredStyle : SouthMarker.shapeStyle) as google.maps.Symbol;
        }

        dispose() {
            ko.untrack(this);
            if (this.marker) {
                var marker = this.marker;

                google.maps.event.clearListeners(marker, 'click');
                google.maps.event.clearListeners(marker, 'mouseover');
                google.maps.event.clearListeners(marker, 'mouseout');

                marker.setMap(null);
                this.marker = null;
            }
            this._isDisposed = true;
        }

        getLatLngOrientation(): SolarProTool.LatLngOrientation {
            if (this._isDisposed)
                return null;

            var p = this.position;
            return {
                Latitude: p.Latitude,
                Longitude: p.Longitude,
                Orientation: this.orientation
            }
        }

        applyTransform(transformation: Transformation3D, mapDrawer: MapDrawer) {
            var dirR = Point2D.FromOrientation(this.orientation),
                p = this.position.ToPoint2D(),
                pts = [p.sub(dirR), p.add(dirR)].map(pt => transformation.transform(pt)),
                delta = pts[1].sub(pts[0]),
                orientation = Math.atan2(delta.y, delta.x) || 0;

            this.position = LatLng.FromPoint2D(pts[0].add(pts[1]).mul(0.5));
            this.orientation = orientation;
        }

        moveByLatLng(latLng: LatLng) {
            var pos = this.position = this.position.add(latLng);
            this.marker.setPosition(pos.ToGMLatLng());
        }

        moveByOffset(offset: Point2D) {
            var pos = this.position = this.position.Offset(offset.y, offset.x);
            this.marker.setPosition(pos.ToGMLatLng());
        }
    }

    export interface IOsmBuilding {
        Id: string;
        Nodes: { Latitude: number, Longitude: number }[];
    }

    export class OsmBuildings {
        private mapDrawer: MapDrawer = null;
        needsUpdate = false;
        intervalTime = 100;
        swLat = 0.0;
        swLong = 0.0;
        neLat = 0.0;
        neLong = 0.0;
        delayTime = 0;
        buildingPolys: { [id: string]: google.maps.Polygon } = {};
        buildingsVisible = false;
        boundsChangedListener: google.maps.MapsEventListener = null;
        buildingClickListener: ((polygon: google.maps.Polygon) => void) = null;
        time = 0;
        interval = 0;

        buildingStyle: IShapeStyle = {
            strokeWeight: 1,
            fillColor: "#81EB8A",
            fillOpacity: 0.3,
            strokeColor: "#12B012",
            strokeOpacity: 0.7,
            clickable: true,
            editable: false,
            draggable: false,
            visible: true
        };

        buildingHoverStyle: IShapeStyle = {
            strokeWeight: 1,
            fillColor: "#81EB8A",
            fillOpacity: 0.8,
            strokeColor: "#12B012",
            strokeOpacity: 0.9,
            clickable: true,
            editable: false,
            draggable: false,
            visible: true
        };

        start(mapDrawer: MapDrawer, clickListener: ((polygon: google.maps.Polygon) => void)) {
            this.mapDrawer = mapDrawer;
            this.buildingClickListener = clickListener;
            this.time = Date.now();
            this.buildingsVisible = true;
            this.boundsChangedListener = google.maps.event.addListener(mapDrawer.map, 'bounds_changed', this.mapBoundsChange.bind(this));
            this.interval = setInterval(this.update.bind(this), this.intervalTime) as any;
            this.mapBoundsChange();
        }

        stop() {
            this.buildingsVisible = false;
            this.clearBuildings();
            if (this.interval)
                clearInterval(this.interval);
            if (this.boundsChangedListener) {
                google.maps.event.removeListener(this.boundsChangedListener);
                this.boundsChangedListener = null;
            }
            this.buildingClickListener = null;
            if (this.mapDrawer) {
                this.mapDrawer.map.setOptions({ draggableCursor: null });
                this.mapDrawer = null;
            }
        }

        update() {
            var now = Date.now();
            var dt = now - this.time;
            this.time = now;
            if (this.needsUpdate) {
                this.delayTime -= dt;
                if (this.delayTime <= 0) {
                    if (this.buildingsVisible) {
                        AManager.Controller('StaticImages').Method('GetOsmBuildingsBounds').Data({ swLat: this.swLat, swLong: this.swLong, neLat: this.neLat, neLong: this.neLong }).withLoading(false).CallBack(this.onGetBuildings.bind(this));
                    }

                    //AManager.Ajax('Anordnung3DService.asmx', 'GetOsmBuildingsBounds', JSON.stringify({ swLat: this.swLat, swLong: this.swLong, neLat: this.neLat, neLong: this.neLong }), function (res) { self.onGetBuildings(res); });
                    this.delayTime = 1000;
                    this.needsUpdate = false;
                }
            }
        }

        mapBoundsChange() {
            if (this.buildingsVisible && this.mapDrawer && this.mapDrawer.map.getZoom() >= 17) {
                var bounds = this.mapDrawer.map.getBounds();
                var ne = bounds.getNorthEast();
                var sw = bounds.getSouthWest();
                this.swLat = sw.lat();
                this.swLong = sw.lng();
                this.neLat = ne.lat();
                this.neLong = ne.lng();
                this.delayTime = 1000;
                this.needsUpdate = true;
            } else
                this.clearBuildings();
        }

        clearBuildings() {
            for (let id in this.buildingPolys) {
                if (this.buildingPolys.hasOwnProperty(id) && this.buildingPolys[id]) {
                    google.maps.event.clearListeners(this.buildingPolys[id], 'click');
                    google.maps.event.clearListeners(this.buildingPolys[id], 'mouseover');
                    google.maps.event.clearListeners(this.buildingPolys[id], 'mouseout');
                    this.buildingPolys[id].setMap(null);
                    delete this.buildingPolys[id];
                }
            }
            this.buildingPolys = {};
        }

        onBuildingClicked(poly: google.maps.Polygon) {
            if (this.buildingsVisible && this.mapDrawer && this.buildingClickListener)
                this.buildingClickListener(poly);
        }

        onBuildingMouseover(poly: google.maps.Polygon) {
            if (this.buildingsVisible && this.mapDrawer) {
                poly.setOptions(this.buildingHoverStyle);
                this.mapDrawer.map.setOptions({ draggableCursor: 'pointer' });
            }
        }

        onBuildingMouseout(poly: google.maps.Polygon) {
            if (this.buildingsVisible && this.mapDrawer) {
                poly.setOptions(this.buildingStyle);
                this.mapDrawer.map.setOptions({ draggableCursor: null });
            }
        }

        onGetBuildings(buildings: IOsmBuilding[]) {
            if (buildings && this.mapDrawer && this.buildingsVisible) {
                var ids = [];
                for (var i = 0, l = buildings.length; i < l; i++) {
                    var building = buildings[i];
                    ids.push(building.Id);
                    if (this.buildingPolys[building.Id])
                        continue;
                    var path = new google.maps.MVCArray<google.maps.LatLng>();
                    for (var j = 0, l2 = building.Nodes.length - 1; j < l2; j++) {
                        var node = building.Nodes[j];
                        path.push(new google.maps.LatLng(node.Latitude, node.Longitude));
                    }
                    var polyOpts = $.extend({ map: this.mapDrawer.map, paths: path }, this.buildingStyle) as google.maps.PolygonOptions;
                    var poly = new google.maps.Polygon(polyOpts);
                    google.maps.event.addListener(poly, 'click', this.onBuildingClicked.bind(this, poly));
                    google.maps.event.addListener(poly, 'mouseover', this.onBuildingMouseover.bind(this, poly));
                    google.maps.event.addListener(poly, 'mouseout', this.onBuildingMouseout.bind(this, poly));
                    this.buildingPolys[building.Id] = poly;
                }
                for (let id in this.buildingPolys) {
                    if (ids.indexOf(parseInt(id)) === -1) {
                        google.maps.event.clearListeners(this.buildingPolys[id], 'click');
                        google.maps.event.clearListeners(this.buildingPolys[id], 'mouseover');
                        google.maps.event.clearListeners(this.buildingPolys[id], 'mouseout');
                        this.buildingPolys[id].setMap(null);
                        delete this.buildingPolys[id];
                    }
                }
            }
        }
    }

    export class SeparationPolyline implements MapDrawing.ISelectableItem {

        constructor(mapDrawer: MapDrawer, parent: ShapeData, id?: string, path?: google.maps.LatLng[], isCutting?: boolean) {

            id = "" + (id || spt.Utils.GenerateGuid());

            ko.track(this);

            var viewModel = mapDrawer.viewModel;

            viewModel.setInstance(id, this);
            Object.defineProperty(this, "id",
                {
                    configurable: true,
                    enumerable: true,
                    get: () => {
                        return id;
                    },
                    set: (v) => {
                        if (id && mapDrawer.viewModel.hasInstance(id))
                            mapDrawer.viewModel.removeInstance(id);
                        id = v;
                        if (id && !this._isDisposed)
                            mapDrawer.viewModel.setInstance(id, this);
                    }
                });

            this.subsciptions = [];
            this.typeName = 'SeparationPolyline';
            this.parent = parent;
            this.mapDrawer = mapDrawer;
            this.isCutting = !!isCutting;

            var opts: google.maps.PolylineOptions = $.extend({}, SeparationPolyline.shapeStyle,
                {
                    map: mapDrawer.map,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    zIndex: 9
                }) as google.maps.PolylineOptions;

            if (path)
                opts.path = path;

            var polyline = this.polyline = new google.maps.Polyline(opts);

            polyline.setPath = (path: any) => {
                if ((polyline as any)._isDisposed || this._isDisposed)
                    return google.maps.Polyline.prototype.setPath.call(polyline, path);

                SeparationPolyline.removeEvents(this);
                var res = google.maps.Polyline.prototype.setPath.call(polyline, path);
                if (this.hoveredPolyline) {
                    this.hoveredPolyline.setMap(null);
                    this.hoveredPolyline = null;
                }
                if (this.hoveredMarker) {
                    this.hoveredMarker.setMap(null);
                    this.hoveredMarker = null;
                }
                SeparationPolyline.addEvents(this);
                ko.valueHasMutated(this, "polyline");
                this.regenerate();

                return res;
            };

            if (!parent.separationPolylines.some(sp => sp.id === this.id))
                parent.separationPolylines.push(this);

            ko.defineProperty(this, "latLngs",
                {
                    get: () => {
                        var polyline = this.polyline;
                        if (!polyline)
                            return [];
                        var path = polyline.getPath();
                        if (path)
                            return path.getArray().map((ll: google.maps.LatLng) => LatLng.FromGMLatLng(ll));
                        return [];
                    },
                    set: (latLngs: LatLng[]) => {
                        if (this._isDisposed)
                            return;

                        var polyline = this.polyline;

                        if (polyline && latLngs)
                            polyline.setPath(latLngs.map<google.maps.LatLng>(ll => ll.ToGMLatLng()));
                    }
                });

            ko.defineProperty(this, 'pos',
                {
                    get: () => {
                        if (!this.polyline || this._isDisposed)
                            return Point2D.Zero;
                        var refLatLng = this.mapDrawer.viewModel.referenceLatLng;

                        var c = 0,
                            p = Point2D.Zero;
                        this.polyline.getPath().forEach(gmll => {
                            p = p.add(refLatLng.DirectionTo(LatLng.FromGMLatLng(gmll)));
                            c++;
                        });

                        return c > 0 ? p.mul(1000 / c) : Point2D.Zero;
                    },
                    set: (p: Point2D) => {
                        if (!this.polyline || this._isDisposed)
                            return;
                        var t = p.sub(this.pos).mul(0.001);
                        this.moveByOffset(t);
                    }
                });

            ko.defineProperty(this, "points", () => {
                if (!this.polyline || this._isDisposed)
                    return [];
                var viewModel = this.mapDrawer.viewModel,
                    axis = viewModel.referenceAxis,
                    latLngs = this.latLngs,
                    refLatLng = viewModel.referenceLatLng;
                return latLngs.map(ll => refLatLng.DirectionTo(ll).mul(1000).Project(axis));
            });

            ko.defineProperty(this, "rotation",
                {
                    get: () => {
                        return this._rot;
                    },
                    set: (r: number) => {
                        if (!this.polyline || this._isDisposed)
                            return;
                        var tr = r - this._rot;
                        this._rot = r;
                        if (tr != 0) {
                            let pts = this.getPoints(),
                                centroid = Point2D.computeCenter(pts);
                            if (centroid)
                                this.applyTransform(Transformation3D.Rotation(LatLng.Grad2Rad(tr), centroid));
                        }

                    }
                });

            SeparationPolyline.addEvents(this);

            this.subsciptions.push(ko.getObservable(this, 'selected').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'hovered').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'locked').subscribe(this.updateStyle.bind(this)));
            this.subsciptions.push(ko.getObservable(this, 'frozen').subscribe(this.updateStyle.bind(this)));

            if (path && path.length)
                this.regenerate();
            else
                this.updateStyle();
        }

        id: string;
        mapDrawer: MapDrawer;
        typeName: string;
        pos: Point2D;
        rotation: number;
        latLngs: LatLng[];
        subsciptions: KnockoutSubscription[];
        polyline: google.maps.Polyline = null;
        hoveredPolyline: google.maps.Polyline = null;
        hoveredMarker: google.maps.Marker = null;
        parent: ShapeData;
        _isDisposed: boolean;
        skipUpdate: boolean;
        private _rot = 0;
        hovered = false;
        private isHovered: boolean;
        selected = false;
        visible = true;
        private isVisible: boolean;
        frozen = false;
        private isFrozen: boolean;
        locked = false;
        private isLocked: boolean;
        private currentStyle: string;
        isCutting: boolean = false;
        points: IPoint2D[];

        setPointX(index: number, viewModel: ViewModel, v: string | number) {
            if (!this.polyline || this._isDisposed)
                return;
            var path = this.polyline.getPath();
            if (!path)
                return;

            var pts = this.points,
                axis = viewModel.referenceAxis;

            if (index >= 0 && path && path.getLength() > index && pts && pts.length > index) {
                var p = pts[index],
                    latLng = this.latLngs[index];

                var dx = spt.Utils.GetFloatFromInputValue("" + v,
                    {
                        min: -1000000,
                        max: 1000000,
                        applyArithmetic: true,
                        isFeet: window.UseImperialSystem,
                        notImperial: !window.UseImperialSystem,
                        from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                        to: "mm"
                    }) - p.x;

                if (dx) {
                    var t = new Point2D(dx * 0.001, 0).Unproject(axis);

                    path.setAt(index, latLng.Offset(t.y, t.x).ToGMLatLng());
                    this.update();
                }
            }
        }

        setPointY(index: number, viewModel: ViewModel, v: string | number) {
            if (!this.polyline || this._isDisposed)
                return;
            var path = this.polyline.getPath();
            if (!path)
                return;

            var pts = this.points,
                axis = viewModel.referenceAxis;

            if (index >= 0 && path && path.getLength() > index && pts && pts.length > index) {
                var p = pts[index],
                    latLng = this.latLngs[index];

                var dy = spt.Utils.GetFloatFromInputValue("" + v,
                    {
                        min: -1000000,
                        max: 1000000,
                        applyArithmetic: true,
                        isFeet: window.UseImperialSystem,
                        notImperial: !window.UseImperialSystem,
                        from: this.mapDrawer.viewModel.valueAdjustOpts.to,
                        to: "mm"
                    }) - p.y;

                if (dy) {
                    var t = new Point2D(0, dy * 0.001).Unproject(axis);

                    path.setAt(index, latLng.Offset(t.y, t.x).ToGMLatLng());
                    this.update();
                }
            }
        }

        deltePoint(index: number) {
            if (!this.polyline || this._isDisposed)
                return;
            var path = this.polyline.getPath();

            if (index >= 0 && path && path.getLength() > index) {
                if (path.getLength() > 1) {
                    path.removeAt(index);
                    this.update();
                } else {
                    this.mapDrawer.deleteSelectable(this);
                }
            }
        }

        onPolylineSelected() {
            if (this._isDisposed || !this.polyline)
                return;

            this.selected = true;
        }

        onPolylineMouseover() {
            if (this._isDisposed || !this.polyline)
                return;

            this.hovered = true;
        }

        onPolylineMouseout() {
            if (this._isDisposed || !this.polyline)
                return;

            this.hovered = false;
        }

        moveByLatLng(latLng: LatLng) {
            if (this._isDisposed)
                return;

            this.polyline.setPath(this.polyline.getPath().getArray().map<google.maps.LatLng>(ll => new google.maps.LatLng(ll.lat() + latLng.Latitude, ll.lng() + latLng.Longitude)));
            this.onPolylineChanged();
        }

        moveByOffset(offset: Point2D) {
            if (this._isDisposed)
                return;

            this.polyline.setPath(this.polyline.getPath().getArray().map<google.maps.LatLng>(ll => LatLng.FromGMLatLng(ll).Offset(offset.y, offset.x).ToGMLatLng()));
            this.onPolylineChanged();
        }

        applyTransform(transform: Transformation3D) {
            this.polyline.setPath(this.polyline.getPath().getArray().map<google.maps.LatLng>(ll => LatLng.FromPoint2D(transform.transform(LatLng.GMLatLngToPoint2D(ll))).ToGMLatLng()));
        }

        getSegments(): Segment2D[] {
            if (this._isDisposed || !this.polyline)
                return [];
            var latLngs = this.latLngs,
                result: Segment2D[] = [],
                points = latLngs.map(ll => ll.ToPoint2D()),
                len = points.length;

            for (var i = 1; i < len; i++) {
                var p1 = points[i - 1],
                    p2 = points[i];

                result.push(new Segment2D(p1, p2));
            }

            return result;
        }

        getPoints(): Point2D[][] {
            if (this._isDisposed || !this.polyline)
                return [];

            return [this.latLngs.map(ll => ll.ToPoint2D())];
        }

        getMap(): google.maps.Map {
            return this.polyline.getMap();
        }

        update(): void {
            this.updateStyle();
        }

        updateStyle() {
            if (this._isDisposed)
                return;
            var polyLine = this.polyline,
                hoveredPolyline = this.hoveredPolyline,
                hoveredMarker = this.hoveredMarker,
                hovered = this.hovered,
                selected = this.selected,
                visible = this.visible,
                locked = this.locked,
                frozen = this.frozen,
                clickable = !frozen && !locked,
                isCutting = this.isCutting;

            if (!polyLine || (polyLine as any)._isDisposed)
                return;

            let newStyle = (selected ? (hovered ? 'shapeSelectedHoveredStyle' : 'shapeSelectedStyle') : (hovered ? 'shapeHoveredStyle' : 'shapeStyle'));

            var opts: google.maps.PolygonOptions = <google.maps.PolygonOptions>null;
            var hoveredOpts: google.maps.PolygonOptions = <google.maps.PolygonOptions>null;

            if (this.currentStyle !== newStyle) {
                opts = $.extend({}, SeparationPolyline[newStyle]) as google.maps.PolygonOptions;
                this.currentStyle = newStyle;
                if (isCutting)
                    opts.strokeColor = '#bc0000';
            }

            if (this.isLocked !== locked) {
                this.isLocked = locked;
                if (!opts)
                    opts = { strokeColor: SeparationPolyline[newStyle].strokeColor };
                if (!hoveredOpts)
                    hoveredOpts = {};
                if (locked) {
                    if (opts.strokeColor)
                        opts.strokeColor = (new Color().setStyle(opts.strokeColor).getGrayscale()).toString();
                }
                hoveredOpts.clickable = clickable;
            }

            if (this.isFrozen !== this.frozen) {
                this.isFrozen = this.frozen;
                if (!hoveredOpts)
                    hoveredOpts = {};
                hoveredOpts.clickable = clickable;
                //hoveredOpts.visible = !this.frozen;
            }

            if (polyLine.getEditable() != selected) {
                if (!opts)
                    opts = {};
                opts.editable = !!selected;
                opts.draggable = false;
            }

            if (this.isVisible !== visible) {
                this.isVisible = visible;
                if (!opts)
                    opts = {};
                if (!hoveredOpts)
                    hoveredOpts = {};
                opts.visible = visible;
                hoveredOpts.visible = visible;
            }

            if (this.isHovered !== hovered) {
                this.isHovered = hovered;

                if (!hoveredOpts)
                    hoveredOpts = {};
                hoveredOpts.strokeOpacity = (hovered ? SeparationPolyline.shapeStyle.strokeOpacity * 0.75 : 0);
            }

            if (opts)
                polyLine.setOptions(opts);

            if (hoveredOpts) {
                if (hoveredMarker) {
                    var path = polyLine.getPath();
                    if (path.getLength()) {
                        hoveredMarker.setOptions({
                            position: path.getAt(0),
                            clickable: clickable,
                            visible: visible,
                            opacity: (hovered ? SeparationPolyline.shapeStyle.strokeOpacity * 0.75 : 0)
                        });
                    }
                } else if (hoveredPolyline)
                    hoveredPolyline.setOptions(hoveredOpts);
            }
        }

        onPolyPointChanged(path: google.maps.MVCArray<google.maps.LatLng>, index: number, prev: google.maps.LatLng) {
            if (this._isDisposed || this.skipUpdate)
                return;

            if (this.parent && !this.parent._isDisposed)
                this.parent.movePoint(LatLng.FromGMLatLng(prev), LatLng.FromGMLatLng(path.getAt(index)), this.id);
            if (path.getLength() === 1 && this.hoveredMarker)
                this.hoveredMarker.setPosition(path.getAt(0));
            this.onPolylineChanged(path, index);
        }

        static removeEvents(separationPolyline: SeparationPolyline) {
            if (!separationPolyline || separationPolyline._isDisposed)
                return;
            var polyline = separationPolyline.polyline,
                hoveredPolyline = separationPolyline.hoveredPolyline,
                hoveredMarker = separationPolyline.hoveredMarker;

            if ((<any>polyline)._gm_events_added_ex) {
                (<any>polyline)._gm_events_added_ex = false;

                var path = polyline.getPath();

                google.maps.event.clearListeners(path, 'insert_at');
                google.maps.event.clearListeners(path, 'remove_at');
                google.maps.event.clearListeners(path, 'set_at');

                if (hoveredPolyline) {
                    google.maps.event.clearListeners(hoveredPolyline, 'click');
                    google.maps.event.clearListeners(hoveredPolyline, 'mouseover');
                    google.maps.event.clearListeners(hoveredPolyline, 'mouseout');
                }

                if (hoveredMarker) {
                    google.maps.event.clearListeners(hoveredMarker, 'click');
                    google.maps.event.clearListeners(hoveredMarker, 'mouseover');
                    google.maps.event.clearListeners(hoveredMarker, 'mouseout');
                }
            }
        }

        static addEvents(separationPolyline: SeparationPolyline) {
            if (!separationPolyline || separationPolyline._isDisposed)
                return;
            var polyline = separationPolyline.polyline,
                hoveredPolyline = separationPolyline.hoveredPolyline,
                hoveredMarker = separationPolyline.hoveredMarker;

            if (!(<any>polyline)._gm_events_added_ex) {
                (<any>polyline)._gm_events_added_ex = true;

                var path = polyline.getPath();
                var viewModel = separationPolyline.mapDrawer.viewModel;

                google.maps.event.addListener(path, "insert_at", separationPolyline.onPolylineChanged.bind(separationPolyline, path));
                google.maps.event.addListener(path, "remove_at", separationPolyline.onPolylineChanged.bind(separationPolyline, path));
                google.maps.event.addListener(path, "set_at", separationPolyline.onPolyPointChanged.bind(separationPolyline, path));

                if (hoveredPolyline) {
                    google.maps.event.addListener(hoveredPolyline, 'click', viewModel.onSelectableClick.bind(viewModel, separationPolyline));
                    google.maps.event.addListener(hoveredPolyline, 'mouseover', viewModel.onSelectableMouseover.bind(viewModel, separationPolyline));
                    google.maps.event.addListener(hoveredPolyline, 'mouseout', viewModel.onSelectableMouseout.bind(viewModel, separationPolyline));
                }

                if (hoveredMarker) {
                    google.maps.event.addListener(hoveredMarker, 'click', viewModel.onSelectableClick.bind(viewModel, separationPolyline));
                    google.maps.event.addListener(hoveredMarker, 'mouseover', viewModel.onSelectableMouseover.bind(viewModel, separationPolyline));
                    google.maps.event.addListener(hoveredMarker, 'mouseout', viewModel.onSelectableMouseout.bind(viewModel, separationPolyline));
                }

            }
        }

        onPolylineChanged(path?: google.maps.MVCArray<google.maps.LatLng>, index?: number) {
            this.regenerate();
            ko.valueHasMutated(this, 'polyline');
            if (!this._isDisposed && this.parent && !this.parent._isDisposed && !this.skipUpdate)
                this.parent.update();
        }

        regenerate() {
            if (this._isDisposed || !this.parent || this.parent._isDisposed || this.skipUpdate || !this.mapDrawer)
                return;
            var polyline = this.polyline,
                path = polyline.getPath(),
                len = path.getLength(),
                changed = false,
                mapDrawer = this.mapDrawer,
                viewModel = mapDrawer.viewModel;
            if (len === 0) {
                if (this.hoveredPolyline) {
                    this.hoveredPolyline.setMap(null);
                    this.hoveredPolyline = null;
                    changed = true;
                }
                if (this.hoveredMarker) {
                    this.hoveredMarker.setMap(null);
                    this.hoveredMarker = null;
                    changed = true;
                }
            } else if (len === 1) {
                if (this.hoveredPolyline) {
                    this.hoveredPolyline.setMap(null);
                    this.hoveredPolyline = null;
                    changed = true;
                }
                if (!this.hoveredMarker) {
                    var hoveredMarker = this.hoveredMarker = new google.maps.Marker({
                        map: mapDrawer.map,
                        clickable: true,
                        draggable: false,
                        visible: true,
                        position: path.getAt(0),
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: SeparationPolyline.shapeStyle.strokeWeight * 4,
                            fillColor: this.isCutting ? '#bc0000' : SeparationPolyline.shapeStyle.strokeColor,
                            fillOpacity: 1,
                            strokeOpacity: 0
                        },
                        zIndex: <number>(polyline.get("zIndex") || 0) - 1,
                        opacity: SeparationPolyline.shapeStyle.strokeOpacity * 0.75
                    });

                    google.maps.event.addListener(hoveredMarker, 'click', viewModel.onSelectableClick.bind(viewModel, this));
                    google.maps.event.addListener(hoveredMarker, 'mouseover', viewModel.onSelectableMouseover.bind(viewModel, this));
                    google.maps.event.addListener(hoveredMarker, 'mouseout', viewModel.onSelectableMouseout.bind(viewModel, this));

                    changed = true;
                }
            } else {
                if (this.hoveredMarker) {
                    this.hoveredMarker.setMap(null);
                    this.hoveredMarker = null;
                    changed = true;
                }
                if (!this.hoveredPolyline) {
                    var hoveredPolyline = this.hoveredPolyline = new google.maps.Polyline({
                        map: mapDrawer.map,
                        clickable: true,
                        draggable: false,
                        editable: false,
                        visible: true,
                        strokeColor: this.isCutting ? '#bc0000' : SeparationPolyline.shapeStyle.strokeColor,
                        strokeWeight: SeparationPolyline.shapeStyle.strokeWeight * 4,
                        strokeOpacity: 0,
                        zIndex: <number>(polyline.get("zIndex") || 0) + 1
                    });

                    hoveredPolyline.setPath(polyline.getPath());

                    google.maps.event.addListener(hoveredPolyline, 'click', viewModel.onSelectableClick.bind(viewModel, this));
                    google.maps.event.addListener(hoveredPolyline, 'mouseover', viewModel.onSelectableMouseover.bind(viewModel, this));
                    google.maps.event.addListener(hoveredPolyline, 'mouseout', viewModel.onSelectableMouseout.bind(viewModel, this));

                    changed = true;
                }
            }
            if (changed)
                this.updateStyle();
        }

        remove() {
            if (this.parent) {
                this.parent.separationPolylines.remove((sp) => sp.id === this.id);
                this.parent.update();
            }
            this.dispose();
        }

        dispose() {
            if (this._isDisposed)
                return;
            ko.untrack(this);
            if (this.polyline) {
                this.polyline.unbindAll();
                this.polyline.setMap(null);
            }
            if (this.hoveredPolyline) {
                this.hoveredPolyline.unbindAll();
                this.hoveredPolyline.setMap(null);
                this.hoveredPolyline = null;
            }
            if (this.hoveredMarker) {
                this.hoveredMarker.unbindAll();
                this.hoveredMarker.setMap(null);
                this.hoveredMarker = null;
            }
            this.subsciptions.forEach(sp => { sp.dispose(); });
            this.mapDrawer = null;
            this.subsciptions = [];
            this.polyline = null;
            this._isDisposed = true;
        }

        movePoint(from: LatLng, to: LatLng) {
            if (this._isDisposed || !this.polyline || this.skipUpdate)
                return;
            this.skipUpdate = true;
            var path = this.polyline.getPath();
            var len = path.getLength();
            for (var i = len - 1; i >= 0; i--) {
                if (LatLng.FromGMLatLng(path.getAt(i)).DistanceTo(from) <= 0.01)
                    path.setAt(i, to.ToGMLatLng());
            }
            if (len === 1 && this.hoveredMarker)
                this.hoveredMarker.setPosition(path.getAt(0));
            this.skipUpdate = false;

            ko.valueHasMutated(this, 'polyline');
        }

        static shapeStyle: IShapeStyle = {
            strokeColor: '#2861ff',
            strokeWeight: 2,
            strokeOpacity: 0.5
        };

        static shapeHoveredStyle: IShapeStyle = {
            strokeColor: '#2861ff',
            strokeWeight: 2,
            strokeOpacity: 0.7
        };

        static shapeSelectedStyle: IShapeStyle = {
            strokeColor: '#2861ff',
            strokeWeight: 2,
            strokeOpacity: 0.8
        };

        static shapeSelectedHoveredStyle: IShapeStyle = {
            strokeColor: '#2861ff',
            strokeWeight: 2,
            strokeOpacity: 1
        };
    }
}

interface IMapDrawerOptions {
    center?: google.maps.LatLngLiteral;
    width?: number;
    height?: number;
    pathToIcons: string;
    pathToButtons: string;
    dialogId?: string;
    mapTypeId?: string;
    editorMode?: number;
    tileUrl?: string;
    isReadOnly: boolean;
}

interface JQuery {
    mapDrawer(settings: IMapDrawerOptions): JQuery;
    mapDrawer(methodName: string): void;
    mapDrawer(methodName: 'show'): JQuery;
    mapDrawer(methodName: 'clear'): JQuery;
    mapDrawer(methodName: 'mapDrawer'): MapDrawing.MapDrawer;
    mapDrawer(methodName: 'shapes', shapes?: SolarProTool.MapShape[]): void;
    mapDrawer(methodName: 'shapes'): SolarProTool.MapShape[];
}

(($) => {
    $.fn.mapDrawer = function (settings?: IMapDrawerOptions | string, param1?: any) {
        var result = null;
        var elems = this.each((idx: number, el: Element) => {
            if (settings && typeof settings === "string") {
                switch (settings) {
                    case "mapDrawer":
                        result = $(el).data("MapDrawing.MapDrawer") as MapDrawing.MapDrawer;
                        break;
                    case "shapes":
                        var mapDrawer = $(el).data("MapDrawing.MapDrawer") as MapDrawing.MapDrawer;
                        if (mapDrawer) {
                            if (param1)
                                mapDrawer.importShapes(param1);
                            else
                                result = mapDrawer.exportShapes();
                        }
                        break;
                    case "clear":
                        var mapDrawer = $(el).data("MapDrawing.MapDrawer") as MapDrawing.MapDrawer;
                        if (mapDrawer)
                            mapDrawer.clear();
                        break;
                    case "show":
                        var mapDrawer = $(el).data("MapDrawing.MapDrawer") as MapDrawing.MapDrawer;
                        if (mapDrawer && mapDrawer.dialog)
                            DManager.show(mapDrawer.dialog);
                        break;
                }
                return false;
            } else {
                var options = (settings || {}) as IMapDrawerOptions;
                if (!options.width || !options.height || options.width < 10 || options.height < 10) {
                    var cr = el.getBoundingClientRect();
                    options.width = Math.max(10, cr.right - cr.left);
                    options.height = Math.max(10, cr.bottom - cr.top);
                }
                var md = $(el).data("MapDrawing.MapDrawer") as MapDrawing.MapDrawer;
                if (md) {
                    md.init(options);
                } else {
                    $(el).data("MapDrawing.MapDrawer", new MapDrawing.MapDrawer(el, options));
                }
            }
            return true;
        });
        return result === null ? elems : result;
    };

})(jQuery);

//var $freeAreaMapDrawer = $('#freeAreaMapsDrawer'),
//    s = getSize(),
//    w = Math.max(s[0] - 360, 100),
//    h = Math.max(s[1] - 360, 100);



//$freeAreaMapDrawer.mapDrawer({
//    center: { lat: result.Data.Latitude, lng: result.Data.Longitude },
//    width: w,
//    height: h,
//    pathToIcons: cdnPath + "/APP_THEMES/SPTv2/icons/",
//    pathToButtons: cdnPath + "/APP_THEMES/" + currentButtonTheme + "/icons/",
//    LayerDefinitions: window.freeAreaLayerDefinitions
//});