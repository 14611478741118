module MapDrawing {
    export class MapDrawer implements IEventManager {

        constructor(view: Element, options?: IMapDrawerOptions) {

            if (!MapDrawer.staticInitialized) {
                MapDrawer.staticInitialized = true;

                ImageMapOverlay.initializePrototype();
                CanvasOverlay.initializePrototype();

            }

            this.view = view;

            //if (window["GMapsUtils"] && window["GMapsUtils"].OctantNodeRenderer && this.mapTypeIds && this.mapTypeIds.indexOf("Google HD") === -1)
            //    this.mapTypeIds.push("Google HD");

            //var mouseEventCreation = false;
            //try {
            //    var ev = new MouseEvent("mousemove", {
            //        screenX: 0,
            //        screenY: 0,
            //        clientX: 1,
            //        clientY: 1
            //    });
            //} catch (err) {
            //    mouseEventCreation = true;
            //}
            //this.mouseEventCreation = mouseEventCreation;
            this.init(options);
        }

        static staticInitialized: boolean;

        static EmptyCanvas: string = "Canvas";

        init(options?: IMapDrawerOptions, skipBindings?: boolean) {

            this.options = {
                center: { lat: 0, lng: 0 },
                width: 200,
                height: 200,
                pathToIcons: "~/",
                pathToButtons: "~/",
                dialogId: null,
                tileUrl: "../../handler/StaticImagesHandler.ashx",
                isReadOnly: false
            };

            if (options) {
                for (var key in options) {
                    if (options.hasOwnProperty(key))
                        this.options[key] = options[key];
                }
            }

            options = this.options;

            if (options.pathToIcons.slice(-1) !== "/")
                options.pathToIcons += "/";

            if (options.dialogId) {
                this.dialog = document.getElementById(options.dialogId);
                DManager.init(this.dialog, 'auto', 'auto', SetWidthDialogTitleBarWidth, this.onDialogClose.bind(this), { closeOnEscape: false });
            }

            var view = this.view;

            var viewModel = this.viewModel;

            if (!viewModel)
                this.viewModel = viewModel = new ViewModel(this, view, options.editorMode);
            else
                viewModel.init(this, view, options.editorMode);

            if ($(".mapDrawerViewWrapper", this.view).length === 0 && !skipBindings) {
                //div not yet available. try apply knockout first and init again later.

                viewModel.mapDrawerRenderCallback = this.init.bind(this, options, true);
                ko.applyBindings(viewModel, view);
                return;
            }

            viewModel.mapDrawerRenderCallback = null;

            var mapsViewWrapper = this.mapsViewWrapper = $(".mapDrawerViewWrapper", this.view).get(0);
            var mapsView = this.mapsView = $(".mapDrawerView", mapsViewWrapper).get(0);

            var map = this.map;
            var zoom = 21;
            let tilesize = LatLng.TILE_SIZE;
            var applyBindings = !skipBindings;
            var url = options.tileUrl;

            var mapTypeId = options.mapTypeId || MapDrawer.EmptyCanvas;

            this.center.set(options.center.lat, options.center.lng);

            mapsViewWrapper.style.width = mapsView.style.width = options.width + "px";
            mapsViewWrapper.style.height = mapsView.style.height = options.height + "px";
            viewModel.viewWidth = options.width;
            viewModel.viewHeight = options.height;

            if (map) {
                google.maps.event.trigger(map, "resize");

                map.setCenter(options.center);

                map.setZoom(zoom);

                map.setMapTypeId(mapTypeId);

                applyBindings = false;
            }
            else {
                map = new google.maps.Map(mapsView, {
                    zoom: zoom,
                    center: new google.maps.LatLng(options.center.lat, options.center.lng),
                    mapTypeId: google.maps.MapTypeId.SATELLITE,
                    heading: 0,
                    tilt: 0,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        mapTypeIds: this.mapTypeIds,
                        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
                    },
                    streetViewControl: false,
                    disableDoubleClickZoom: true,
                    overviewMapControl: false,
                    panControl: false,
                    rotateControl: false,
                    scaleControl: false,
                    scrollwheel: true,
                    zoomControl: false,
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                });


                let $controlCorrectionRightAngle = $('#roofMapControlAutoCorrectionRightAngle').clone();
                $controlCorrectionRightAngle.removeClass('d-none');
                let $buttonCorrection = $controlCorrectionRightAngle.find('.checkbox').first();
                if (this.viewModel.isActiveCorrectionRightAngle)
                    $buttonCorrection.checkbox('check');

                $buttonCorrection.checkbox({
                    onChange: () => {
                        this.viewModel.isActiveCorrectionRightAngle = !this.viewModel.isActiveCorrectionRightAngle;
                        //$buttonCorrection.checkbox('toggle');
                    }
                });

                map.controls[google.maps.ControlPosition.TOP_LEFT].push($controlCorrectionRightAngle.get(0));


                let $stepHelper = $('#showRoofHelperOnStep').clone();
                $stepHelper.removeClass('d-none');
                map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push($stepHelper.get(0))

                //var dragedMapListener = google.maps.event.addListener(this.marker, 'dragend', () => {
                //    this.geocodePosition(this.marker.getPosition());
                //});

                var mapMaxZoom = 25;

                //map.mapTypes.set("OSM", new google.maps.ImageMapType({
                //    getTileUrl: (coord: IPoint2D, zoom: number) => {
                //        return "http://tile.openstreetmap.org/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
                //    },
                //    tileSize: new google.maps.Size(Tilesize, Tilesize),
                //    name: "OpenStreetMap",
                //    maxZoom: mapMaxZoom
                //}));

                //map.mapTypes.set("Google", new google.maps.ImageMapType({
                //    getTileUrl: (coord, zoom) => {
                //        var scale = (1 << zoom) / Tilesize;
                //        var projection = map.getProjection();
                //        var latlng = projection.fromPointToLatLng(new google.maps.Point((coord.x + 0.5) / scale, (coord.y + 0.5) / scale), false);

                //        var url = url +"?ID=GoogleHybrid&center=" + latlng.lat() + "," + latlng.lng() + "&zoom=" + zoom + "&size=" + Tilesize + "x" + Tilesize + "&scale=1";

                //        return url;
                //    },
                //    tileSize: new google.maps.Size(Tilesize, Tilesize),
                //    name: "Google",
                //    maxZoom: mapMaxZoom,
                //    minZoom: 1
                //}));

                var tileManager = this.tileManager,
                    ocrManager = this.ocrManager;

                if (!tileManager) {
                    tileManager = this.tileManager = [
                        null
                        , new TileManager(this, url + "?ID=GoogleHybrid", this.mapTypeIds[1])
                        , new TileManager(this, url + "?ID=Bing", this.mapTypeIds[2])
                        //,null
                    ];
                }

                if (!ocrManager && this.mapTypeIds[3])
                    ocrManager = this.ocrManager = new OCRTileManager(this, url + "?ID=GoogleSatellite", this.mapTypeIds[3]);

                map.mapTypes.set(this.mapTypeIds[0],
                    {
                        getTile: (coord: google.maps.Point, zoom: number, ownerDocument: Document) => {

                            var div = ownerDocument.createElement('div');

                            div.className = "md-tile";
                            //div.innerHTML = coord.x + "_" + coord.y + "_" + zoom;

                            return div;
                        },
                        releaseTile: (tile: Element) => {
                            if (tile && tile.parentElement)
                                tile.parentElement.removeChild(tile);
                        },
                        tileSize: new google.maps.Size(tilesize, tilesize),
                        name: this.mapTypeIds[0],
                        maxZoom: mapMaxZoom,
                        minZoom: 0
                    });

                map.mapTypes.set(this.mapTypeIds[1],
                    {
                        getTile: tileManager[1].getTileDiv.bind(tileManager[1]),
                        releaseTile: tileManager[1].releaseTile.bind(tileManager[1]),
                        tileSize: new google.maps.Size(tilesize, tilesize),
                        name: this.mapTypeIds[1],
                        maxZoom: mapMaxZoom,
                        minZoom: 1
                    });

                map.mapTypes.set(this.mapTypeIds[2], {
                    getTile: tileManager[2].getTileDiv.bind(tileManager[2]),
                    releaseTile: tileManager[2].releaseTile.bind(tileManager[2]),
                    tileSize: new google.maps.Size(tilesize, tilesize),
                    name: this.mapTypeIds[2],
                    maxZoom: mapMaxZoom,
                    minZoom: 1
                });

                if (ocrManager) {
                    map.mapTypes.set(this.mapTypeIds[3], {
                        getTile: ocrManager.getTileDiv.bind(ocrManager),
                        releaseTile: ocrManager.releaseTile.bind(ocrManager),
                        tileSize: new google.maps.Size(tilesize, tilesize),
                        name: this.mapTypeIds[3],
                        maxZoom: mapMaxZoom,
                        minZoom: 1
                    });
                    viewModel.enableDepthCalculation = true;
                } else
                    viewModel.enableDepthCalculation = false;

                //map.mapTypes.set(this.mapTypeIds[1],
                //    {
                //        getTile: (coord: google.maps.Point, zoom: number, ownerDocument: Document) => {
                //            return MapDrawer.getTileDiv(map, coord, zoom, ownerDocument, url +"?ID=GoogleHybrid");
                //        },
                //        releaseTile: (tile: Element) => {
                //            if (tile && tile.parentElement)
                //                tile.parentElement.removeChild(tile);
                //        },
                //        tileSize: new google.maps.Size(tilesize, tilesize),
                //        name: this.mapTypeIds[1],
                //        maxZoom: mapMaxZoom,
                //        minZoom: 1
                //    });

                //map.mapTypes.set(this.mapTypeIds[2], new google.maps.ImageMapType({
                //    getTileUrl: (coord, zoom) => {
                //        var scale = (1 << zoom) / tilesize;
                //        var projection = map.getProjection();
                //        var latlng = projection.fromPointToLatLng(new google.maps.Point((coord.x + 0.5) / scale, (coord.y + 0.5) / scale), false);

                //        var url = url +"?ID=GoogleHybrid&center=" + latlng.lat() + "," + latlng.lng() + "&zoom=" + zoom + "&size=" + tilesize + "x" + tilesize + "&scale=1";

                //        return url;
                //    },
                //    tileSize: new google.maps.Size(tilesize, tilesize),
                //    name: this.mapTypeIds[2],
                //    maxZoom: mapMaxZoom,
                //    minZoom: 1
                //}));

                //map.mapTypes.set(this.mapTypeIds[2], {
                //    getTile: (coord: google.maps.Point, zoom: number, ownerDocument: Document) => {
                //        return MapDrawer.getTileDiv(map, coord, zoom, ownerDocument, url +"?ID=Bing");
                //    },
                //    releaseTile: (tile: Element) => {
                //        if (tile && tile.parentElement)
                //            tile.parentElement.removeChild(tile);
                //    },
                //    tileSize: new google.maps.Size(tilesize, tilesize),
                //    name: this.mapTypeIds[2],
                //    maxZoom: mapMaxZoom,
                //    minZoom: 1
                //});

                //map.mapTypes.set("BING", new google.maps.ImageMapType({
                //    getTileUrl: (coord: google.maps.Point, zoom: number) => {
                //        var scale = (1 << zoom) / tilesize;
                //        var projection = map.getProjection();
                //        var latlng = projection.fromPointToLatLng(new google.maps.Point((coord.x + 0.5) / scale, (coord.y + 0.5) / scale), false);

                //        var url = url +"?ID=Bing&center=" + latlng.lat() + "," + latlng.lng() + "&zoom=" + zoom + "&size=" + tilesize + "x" + tilesize + "&scale=1";

                //        return url;

                //        //var q = MapDrawer.getQuadtreeQuadrant(coord.x, coord.y, zoom);
                //        //var url = "http://t" + q[q.length - 1] + ".ssl.ak.tiles.virtualearth.net/tiles/a" + q.join("") + ".jpeg?g=3533&n=z";
                //        //return url;
                //    },
                //    tileSize: new google.maps.Size(tilesize, tilesize),
                //    name: "Bing",
                //    maxZoom: mapMaxZoom,
                //    minZoom: 1
                //}));

                map.setMapTypeId(mapTypeId);

                this.map = map;

                setTimeout(() => {
                    google.maps.event.trigger(map, "resize");
                }, 0);
            }

            if (!this.elevationService)
                this.elevationService = new google.maps.ElevationService();

            var drawingManager = this.drawingManager;

            if (!drawingManager) {
                drawingManager = this.drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: null,
                    drawingControl: false,
                    map: map
                });

                if (!options.isReadOnly)
                    this.bindEvents();
            } else
                drawingManager.setMap(map);

            if (!this.canvasOverlay)
                this.canvasOverlay = new CanvasOverlay(this);
            else {
                this.canvasOverlay.clear();
                this.canvasOverlay.init(this);
            }

            if (!this.osmBuildings) {
                this.osmBuildings = new OsmBuildings();
            }

            if (applyBindings)
                ko.applyBindings(this.viewModel, this.dialog || this.view);


            this.load();
        }

        onOverlaycomplete(newShape: google.maps.Polygon | google.maps.Circle | google.maps.Rectangle, ignoreDrawingMode = false) {
            if (newShape instanceof google.maps.Rectangle) {
                newShape.setMap(null);
                if (this.rectSelectMode) {
                    var rectBounds = (newShape as google.maps.Rectangle).getBounds();
                    this.selectShapes(this.getAllPolygons().filter(poly => {
                        if (poly && poly.userData) {
                            var sh = poly.userData as ShapeData;
                            if (sh.locked || (sh.controlledByParent && sh.parent && sh.parent.locked))
                                return false;
                        }
                        return rectBounds.intersects(this.getBoundsFromPolygon(poly));
                    }).map(poly => poly.userData as ShapeData), !this.ctrlDown && !this.shiftDown, !this.ctrlDown && this.shiftDown, this.ctrlDown && this.shiftDown);
                    return;
                }
                newShape = this.convertRectangleToPolygon(newShape as google.maps.Rectangle);
            } else if (newShape instanceof google.maps.Circle) {
                newShape.setMap(null);
                newShape = this.convertCircleToPolygon(newShape as google.maps.Circle);
            }

            if (!ignoreDrawingMode && !this.drawingMode) {
                newShape.setMap(null);
                return;
            }

            if (newShape instanceof google.maps.Polygon) {
                var paths = newShape.getPaths();
                var path = paths && paths.getLength() ? paths.getAt(0) : null;
                var area = path ? google.maps.geometry.spherical.computeSignedArea(path) : 0; //in m²
                var layer = this.viewModel.currentLayer;
                if (!path || path.getLength() < 3 || Math.abs(area) <= 0.01 || (layer && layer.locked)) {
                    newShape.setMap(null);
                    if (layer && layer.locked)
                        this.selectTool(null);
                } else {
                    this.addPolygon(newShape as any);
                }
            } else
                (<google.maps.Circle>newShape).setMap(null);
        }

        unBindEvents() {
            var view = this.view;
            //var drawingManager = this.drawingManager;
            //var map = this.map;

            this._mapEventListener.splice(0, this._mapEventListener.length).forEach(listener => {
                if (listener.remove)
                    listener.remove();
            });

            //google.maps.event.clearInstanceListeners(drawingManager);
            //google.maps.event.clearInstanceListeners(map);

            document.removeEventListener('keydown', this.keyDownHandler);
            document.removeEventListener('keyup', this.keyUpHandler);

            if (!$.isTouchCapable()) {
                document.removeEventListener('mousedown', this.mouseDownHandler, true);
                document.removeEventListener('mouseup', this.mouseUpHandler, true);
                view.removeEventListener('mousedown', this.viewMouseDownHandler, true);
                view.removeEventListener('mousemove', this.mouseMoveHandler, true);
            }
            else {
                view.removeEventListener('touchstart', this.viewMouseDownHandler, true);
                view.removeEventListener('touchmove', this.mouseMoveHandler, true);
                view.removeEventListener('touchend', this.mouseUpHandler, true);
            }


            this.keyDownHandler = null;
            this.keyUpHandler = null;;
            this.mouseDownHandler = null;;
            this.viewMouseDownHandler = null;;
        }

        mapMovePosition: ElementPositionType;

        // helper
        appendToTouchEventLatLngFromPixel(event: google.maps.MouseEvent, touch: JQueryTouchEvents.TouchEvent, map: google.maps.Map): google.maps.MouseEvent {

            let mapPadding = 50;
            if ($.isTouchCapable())
                mapPadding = 20;
            let mapElement = $(this.mapsView); //$(touch.target);
            let minVector = new THREE.Vector2(mapPadding, mapPadding);
            let maxVector = new THREE.Vector2(mapElement.width() - mapPadding, mapElement.height() - mapPadding);
            let mapBoxPadding = new THREE.Box2(minVector, maxVector); // map bounds

            var absoluteMapPoint = touch.position;
            var absoluteMapTargetOffset = mapElement.offset();
            var relativeMapPoint = new google.maps.Point(
                absoluteMapPoint.x - absoluteMapTargetOffset.left,
                absoluteMapPoint.y - absoluteMapTargetOffset.top);

            let latLng = this.fromPixelToLatLng(map, relativeMapPoint);
            let relativeMapVector = new THREE.Vector2(relativeMapPoint.x, relativeMapPoint.y);
            // out of bounds => will be negative values
            //console.log(map.getBounds().)
            if (!mapBoxPadding.containsPoint(relativeMapVector)) {
                let clampPoint = new THREE.Vector2();
                clampPoint = mapBoxPadding.clampPoint(relativeMapVector, clampPoint);

                this.mapMovePosition = ElementPositionType.None;
                if (mapBoxPadding.min.x === clampPoint.x)
                    this.mapMovePosition |= ElementPositionType.Left;
                if (mapBoxPadding.min.y === clampPoint.y)
                    this.mapMovePosition |= ElementPositionType.Top;
                if (mapBoxPadding.max.x === clampPoint.x)
                    this.mapMovePosition |= ElementPositionType.Right;
                if (mapBoxPadding.max.y === clampPoint.y)
                    this.mapMovePosition |= ElementPositionType.Bottom;

                //this.moveGoogleMapsCamera(this.mapMovePosition);
            }
            else
                this.mapMovePosition = ElementPositionType.None;

            event.latLng = latLng;
            return event;
        }

        moveGoogleMapsCamera(elementPosition: ElementPositionType, speedInPixel: number) {
            let isDraggable: boolean = this.map.get('draggable');
            if (elementPosition === ElementPositionType.None || isDraggable)
                return;

            let offsetPoint = new google.maps.Point(0, 0);
            if ((elementPosition & ElementPositionType.Left) === ElementPositionType.Left)
                offsetPoint.x = -speedInPixel;
            if ((elementPosition & ElementPositionType.Right) === ElementPositionType.Right)
                offsetPoint.x = speedInPixel;
            if ((elementPosition & ElementPositionType.Top) === ElementPositionType.Top)
                offsetPoint.y = -speedInPixel;
            if ((elementPosition & ElementPositionType.Bottom) === ElementPositionType.Bottom)
                offsetPoint.y = speedInPixel;

            //this.map.setOptions({ draggable: true });
            this.map.panBy(offsetPoint.x, offsetPoint.y);
            //this.map.setOptions({ draggable: false });
        }

        mapMoveTimerId: NodeJS.Timeout;

        createMapMove() {
            let interval = 120; // repeat with the interval of 100ms
            let speedInPixel = 20;
            if ($.isTouchCapable()) {
                //interval = 500;
                //speedInPixel = 10;
            }

            this.mapMoveTimerId = setInterval(() => this.moveGoogleMapsCamera(this.mapMovePosition, speedInPixel), interval);
        }

        removeMapMove() {
            clearInterval(this.mapMoveTimerId);
        }

        // helper
        fromPixelToLatLng(map: google.maps.Map, point: google.maps.Point): google.maps.LatLng {
            let projection = map.getProjection();
            let topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast());
            let bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest());
            let scale = 1 << map.getZoom(); // Math.pow(2, map.getZoom());
            return projection.fromPointToLatLng(new google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y));
        };

        fromLatLngToPixel(latLng: google.maps.LatLng): google.maps.Point {
            let projection = this.map.getProjection();
            let bounds = this.map.getBounds();
            let topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
            let bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
            let scale = Math.pow(2, this.map.getZoom());
            let worldPoint = projection.fromLatLngToPoint(latLng);
            let point = new google.maps.Point(
                Math.floor((worldPoint.x - bottomLeft.x) * scale),
                Math.floor((worldPoint.y - topRight.y) * scale));
            return point;
        }

        isViewMouseDown = false;
        isViewMouseMoving = false;
        isViewStartMoving = false;
        lastMouseDownEvent: google.maps.MouseEvent = null;
        isGoogleMapsMoved = false;
        viewMovedCount = 0;

        bindEvents() {
            var view = this.view;
            var drawingManager = this.drawingManager;
            var map = this.map;

            this._mapEventListener.push(google.maps.event.addListener(drawingManager, 'overlaycomplete', (e: google.maps.drawing.OverlayCompleteEvent) => {
                this.onOverlaycomplete(<google.maps.Polygon | google.maps.Circle | google.maps.Rectangle>e.overlay);
            }));

            //this._mapEventListener.push(google.maps.event.addListener(map, 'click', (ev: google.maps.MouseEvent) => {
            //    if ($.isTouchCapable())
            //        return;
            //    console.log('GM click event');
            //    this.viewModel.currentTool.onClick(ev, this);
            //}));

            this._mapEventListener.push(google.maps.event.addListener(map, 'mousedown', (event: google.maps.MouseEvent) => {
                this.viewModel.currentTool.isDragging = false;
                this.lastMouseDownEvent = event;
                //console.log('GM mousedown event');
                this.viewModel.currentTool.onClick(event, this);
            }));

            this._mapEventListener.push(google.maps.event.addListener(map, 'mouseup', (event: google.maps.MouseEvent) => {
                //console.log("GM mouseup");
                //this.viewModel.currentTool.onClick(event, this);
                this.viewModel.currentTool?.onMouseUp(event, this);
            }));

            this._mapEventListener.push(google.maps.event.addListener(map, 'mousemove', (event: google.maps.MouseEvent) => {
                //event = this.appendToTouchEventLatLngFromPixel(event, touch, map);
                //if (!event.latLng)
                //    return;
                this.isGoogleMapsMoved = true;
                //console.log("GM mousemove");
                //let ev: any = event;
                //this.mouseMoveHandler(ev);
                this.viewModel.currentTool.onMousemove(event, this);
            }));

            this._mapEventListener.push(google.maps.event.addListener(map, 'drag', (event: google.maps.MouseEvent) => {
                //console.log("GM drag");
                // drag event is undefined!

                //let lastMouseDownEvent: any = {};
                //lastMouseDownEvent.latLng = this.lastMouseDownEvent.latLng;
                //lastMouseDownEvent.isDragging = true;

                this.viewModel.currentTool.isDragging = true;
            }));

            //$(this.mapsView).on('tapmove', (event: google.maps.MouseEvent, touch) => {
            //    console.log('tapmove event');
            //    event = this.appendToTouchEventLatLngFromPixel(event, touch, map);
            //    if (!event.latLng)
            //        return;

            //    //let ev: any = event;
            //    //this.mouseMoveHandler(ev);
            //    this.viewModel.currentTool.onMousemove(event, this);
            //});

            this.keyDownHandler = (e: KeyboardEvent) => {
                var code = (e.keyCode ? e.keyCode : e.which) as number;
                switch (code) {
                    case 16: //shift
                        this.shiftDown = true;
                        break;
                    case 17: //ctrl
                        this.ctrlDown = true;
                        if (this.hasFocus)
                            e.preventDefault();
                        break;
                    case 18: //alt
                        this.altDown = true;
                        if (this.hasFocus)
                            e.preventDefault();
                        break;
                    case 32: //space
                        this.spaceDown = true;
                        break;
                    case 37:
                    case 38:
                    case 39:
                    case 40: //arrows
                        if (this.hasFocus) {

                            var dir: Point2D;

                            switch (code) {
                                case 37:
                                    dir = new Point2D(-1, 0);
                                    break;
                                case 38:
                                    dir = new Point2D(0, 1);
                                    break;
                                case 39:
                                    dir = new Point2D(1, 0);
                                    break;
                                case 40:
                                    dir = new Point2D(0, -1);
                                    break;
                            }

                            if (this.shiftDown)
                                dir = Helperline.SnapDirection(dir, this);

                            var off = dir.mul(this.viewModel.keyBoardSteps.internalValue * 0.001);

                            if (this.viewModel.selection.some(sel => sel.typeName === this.viewModel.layer.referenceLayer.typeName)) {
                                this.viewModel.foreachItem(sel => {
                                    if (sel instanceof ShapeData && !sel.controlledByParent) {
                                        sel.moveByOffset(off);
                                        sel.update();
                                    }
                                });
                            } else {
                                this.viewModel.foreachSelected(sel => {
                                    sel.moveByOffset(off);
                                    sel.update();
                                });
                            }

                            return;
                        }
                        break;
                }
                if (this.hasFocus)
                    this.viewModel.currentTool.onKeyDown(code, this);
            };

            this.keyUpHandler = (e: KeyboardEvent) => {
                var code = (e.keyCode ? e.keyCode : e.which) as number;
                switch (code) {
                    case 16: //shift
                        this.shiftDown = false;
                        break;
                    case 17: //ctrl
                        this.ctrlDown = false;
                        if (this.hasFocus)
                            e.preventDefault();
                        break;
                    case 18: //alt
                        this.altDown = false;
                        if (this.hasFocus)
                            e.preventDefault();
                        break;
                    case 32: //space
                        this.spaceDown = false;
                        break;
                    case 46: //delete
                        if (this.hasFocus)
                            this.deleteSelection();
                        return;
                    case 27: //escape
                        if (this.hasFocus)
                            this.selectTool(null);
                        return;
                }
                if (this.hasFocus)
                    this.viewModel.currentTool.onKeyUp(code, this);
            };

            this.mouseDownHandler = (e: MouseEvent) => {
                this.hasFocus = false;
                //console.log("mouseDownHandler hasFocus false");
            };

            this.mouseUpHandler = (e: any | MouseEvent | TouchEvent) => {
                this.isViewMouseDown = false;
                //console.log("mouseUpHandler");
                let firstTouchPoint = null;
                if (e.touches && e.touches[0])
                    firstTouchPoint = e.touches[0];
                if (firstTouchPoint == null && e.changedTouches && e.changedTouches[0])
                    firstTouchPoint = e.changedTouches[0];
                if (firstTouchPoint) {

                    e.clientX = firstTouchPoint.clientX
                    e.clientY = firstTouchPoint.clientY;
                    //e.force: 1
                    //e.identifier: 0
                    e.pageX = firstTouchPoint.pageX;
                    e.pageY = firstTouchPoint.pageY;
                    //e.radiusX: 14.935065269470215
                    //e.radiusY: 14.935065269470215
                    //e.rotationAngle: 0
                    e.screenX = firstTouchPoint.screenX;
                    e.screenY = firstTouchPoint.screenY;
                }
                this.viewModel.onMouseup(e);
            };

            this.mouseMoveHandler = (e: any | MouseEvent | TouchEvent) => {
                // bugfix for strange google maps move bug: if too many polygon points are clicked then the googleMapsMove event dont trigger
                if (this.viewMovedCount == 0)
                    this.isGoogleMapsMoved = false;
                this.viewMovedCount++;
                if (this.viewMovedCount > 2) {
                    this.viewMovedCount = 0;
                    if (!this.isGoogleMapsMoved && this.viewModel.selectedToolName === 'DrawTool' && !this.viewModel.currentTool.isDragging) {
                        this.map.setZoom(this.map.getZoom());
                    }
                }

                let firstTouchPoint = null;
                if (e.touches && e.touches[0])
                    firstTouchPoint = e.touches[0];
                if (firstTouchPoint == null && e.changedTouches && e.changedTouches[0])
                    firstTouchPoint = e.changedTouches[0];
                if (firstTouchPoint) {

                    e.clientX = firstTouchPoint.clientX
                    e.clientY = firstTouchPoint.clientY;
                    //e.force: 1
                    //e.identifier: 0
                    e.pageX = firstTouchPoint.pageX;
                    e.pageY = firstTouchPoint.pageY;
                    //e.radiusX: 14.935065269470215
                    //e.radiusY: 14.935065269470215
                    //e.rotationAngle: 0
                    e.screenX = firstTouchPoint.screenX;
                    e.screenY = firstTouchPoint.screenY;
                }

                this.viewModel.onMousemove(e);
                this.isViewMouseMoving = true;
            };

            this.viewMouseDownHandler = (e: any | MouseEvent | TouchEvent) => {
                //this.viewModel.currentTool.isDragging = false;
                this.isViewStartMoving = false;
                this.isViewMouseDown = true;
                this.isViewMouseMoving = false;

                let firstTouchPoint = null;
                if (e.touches && e.touches[0])
                    firstTouchPoint = e.touches[0];
                if (firstTouchPoint == null && e.changedTouches && e.changedTouches[0])
                    firstTouchPoint = e.changedTouches[0];
                if (firstTouchPoint) {

                    e.clientX = firstTouchPoint.clientX
                    e.clientY = firstTouchPoint.clientY;
                    //e.force: 1
                    //e.identifier: 0
                    e.pageX = firstTouchPoint.pageX;
                    e.pageY = firstTouchPoint.pageY;
                    //e.radiusX: 14.935065269470215
                    //e.radiusY: 14.935065269470215
                    //e.rotationAngle: 0
                    e.screenX = firstTouchPoint.screenX;
                    e.screenY = firstTouchPoint.screenY;
                }
                this.hasFocus = true;
                var viewModel = this.viewModel,
                    hoveredId = this.viewModel.hoveredId;

                //console.log("view mousedown");
                if ($.isTouchCapable()) {
                    // check hover id
                    //let touch = {} as JQueryTouchEvents.TouchEvent;
                    //touch.position = {} as JQueryTouchEvents.Point;
                    //touch.position.x = e.pageX;
                    //touch.position.y = e.pageY;
                    //let event = this.appendToTouchEventLatLngFromPixel(e, touch, map);
                    //let latLng = event.latLng;
                    let latLng = this.GetLatLngFromMouseEvent(e);
                    let polys = this.getPolygonsByPoint(latLng);
                    if (polys && polys.length) {
                        hoveredId = polys[0].userData.id;
                        this.viewModel.hoveredId = hoveredId;
                        //let test = viewModel.instances[hoveredId];
                        //if (test instanceof ShapeData) {
                        //    e.latLng = latLng;
                        //    this.viewModel.onSelectableClick(test, e);
                        //}

                        //polys[0].userData.hovered = true;
                    }
                    else {
                        if (hoveredId && viewModel.instances[hoveredId]) {
                            let data = viewModel.instances[hoveredId];
                            //data.hovered = false;
                            hoveredId = null;
                            this.viewModel.hoveredId = hoveredId;
                        }
                    }
                }

                //console.log("hoverid" + hoveredId);
                if (hoveredId && viewModel.instances[hoveredId]) {
                    var data = viewModel.instances[hoveredId];
                    if (data instanceof ShapeData && !data._isDisposed)
                        this.viewModel.onPolygonMousedown(data.poly, e);
                }

            };

            document.addEventListener('keydown', this.keyDownHandler);
            window.addEventListener('keyup', this.keyUpHandler, true);

            if (!$.isTouchCapable()) {
                document.addEventListener('mousedown', this.mouseDownHandler, true);
                view.addEventListener('mousedown', this.viewMouseDownHandler, true);
                view.addEventListener('mousemove', this.mouseMoveHandler, true);
                document.addEventListener('mouseup', this.mouseUpHandler, true); //view.addEventListener('mouseup', this.mouseUpHandler, true);
            }
            else {
                //let onlyMapView = $('#howTo_mapDrawerViewWrapper').get(0);
                //document.addEventListener('touchstart', this.mouseDownHandler, true);
                view.addEventListener('touchstart', this.viewMouseDownHandler, true);
                view.addEventListener('touchmove', this.mouseMoveHandler, true);
                view.addEventListener('touchend', this.mouseUpHandler, true); //view.addEventListener('mouseup', this.mouseUpHandler, true);
            }

            //$('body').on('tapstart', (e: any, touch) => {
            //    e = this.toMouseEventCheck(e, touch);
            //    this.mouseDownHandler(e);
            //});
            //$(this.mapsView).on('tapstart', (e: MouseEvent, touch) => {
            //    console.log('view mousedown event');
            //    e = this.toMouseEventCheck(e, touch);
            //    this.viewMouseDownHandler(e);
            //});
            //$(this.mapsView).on('tapmove', (e: any, touch) => {
            //    console.log("mapsView move");
            //    e = this.toMouseEventCheck(e, touch);
            //    this.mouseMoveHandler(e);
            //});
            //// $('body')
            //$(this.mapsView).on('tapend', (e: MouseEvent, touch) => {
            //    e = this.toMouseEventCheck(e, touch);
            //    this.mouseUpHandler(e);
            //});

        }

        toMouseEventCheck(sourceEvent: any, touch: JQueryTouchEvents.TouchEvent): MouseEvent {
            sourceEvent.pageX = touch.position.x;
            sourceEvent.pageY = touch.position.y;
            return sourceEvent;
        }

        enableRectangleSelect() {
            this.rectSelectMode = true;

            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);

            this.drawingManager.setOptions({
                rectangleOptions: this.rectSelectStyle
            });

        }

        disableRectangleSelect() {
            this.rectSelectMode = false;

            var layer = this.viewModel.currentLayer,
                shapeSelectedStyle = layer ? layer.shapeSelectedStyle : this.shapeSelectedStyle;

            this.drawingManager.setOptions({
                rectangleOptions: shapeSelectedStyle
            });

            this.drawingManager.setDrawingMode(null);
        }

        GetLatLngFromMouseEvent(e: MouseEvent): google.maps.LatLng {
            var map = this.map;
            var scale = Math.pow(2, map.getZoom());
            var projection = map.getProjection();
            var offset = map.getDiv().getBoundingClientRect();
            var mousePixels = new google.maps.Point(e.pageX - offset.left, e.pageY - offset.top);
            var mapBounds = map.getBounds();
            if (!mapBounds)
                return null;
            var mapNE = mapBounds.getNorthEast();
            var mapSW = mapBounds.getSouthWest();

            var mapNW = new google.maps.LatLng(mapNE.lat(), mapSW.lng());
            var pNW = projection.fromLatLngToPoint(mapNW);
            var point = new google.maps.Point(((pNW.x * scale) + mousePixels.x) / scale, ((pNW.y * scale) + mousePixels.y) / scale);

            return projection.fromPointToLatLng(point, false);
        }

        GetPoint2DFromMouseEvent(e: MouseEvent): Point2D {
            return LatLng.GMLatLngToPoint2D(this.GetLatLngFromMouseEvent(e));
        }

        getBoundsFromPolygons(polys: IGoogleMapsPolygon[]) {
            var bounds = new google.maps.LatLngBounds();
            polys.forEach(poly => {
                var paths = poly.getPaths();
                paths.forEach(path => {
                    path.forEach(latLng => {
                        bounds.extend(latLng);
                    });
                });
            });
            return bounds;
        }

        getBoundsFromPolygon(poly: IGoogleMapsPolygon) {
            var bounds = new google.maps.LatLngBounds();
            var paths = poly.getPaths();
            paths.forEach(path => {
                path.forEach(latLng => {
                    bounds.extend(latLng);
                });
            });
            return bounds;
        }

        convertCircleToPolygon(circle: google.maps.Circle) {
            var points: google.maps.LatLng[] = [],
                center = circle.getCenter(),
                radius = circle.getRadius(),
                numPoints = Math.floor(Math.min(32, Math.max(8, radius * 2))),
                degreeStep = 360 / numPoints;

            for (var i = 0; i < numPoints; i++) {
                points.push(google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i));
            }

            var layer = this.viewModel.currentLayer,
                shapeStyle = layer ? layer.shapeStyle : this.shapeStyle;

            return new google.maps.Polygon($.extend({}, {
                map: this.map,
                clickable: !this.zoomOnlyMode,
                draggable: false,
                editable: false,
                paths: points
            }, shapeStyle) as google.maps.PolygonOptions);
        }

        convertRectangleToPolygon(rectangle: google.maps.Rectangle) {
            var bounds = rectangle.getBounds();
            var sw = bounds.getSouthWest(),
                ne = bounds.getNorthEast();

            var layer = this.viewModel.currentLayer,
                shapeStyle = layer ? layer.shapeStyle : this.shapeStyle;

            return new google.maps.Polygon($.extend({}, {
                map: this.map,
                clickable: !this.zoomOnlyMode,
                draggable: false,
                editable: false,
                paths: [sw, new google.maps.LatLng(sw.lat(), ne.lng()), ne, new google.maps.LatLng(ne.lat(), sw.lng())]
            }, shapeStyle) as google.maps.PolygonOptions);
        }

        clearSelection() {
            var viewModel = this.viewModel;
            viewModel.selection.splice(0, viewModel.selection.length).forEach(item => {

                item.selected = false;

                //var data = shape.userData as ShapeData;
                //if (data) {
                //    var layer = viewModel.getLayerByTypeName(data.typeName);
                //    shape.setOptions(layer ? layer.shapeStyle : this.shapeStyle);
                //}

                //shape.setEditable(false);
                //shape.setDraggable(false);

            });
        }

        deleteAll() {
            var viewModel = this.viewModel;
            viewModel.layer.all.forEach(layer => {
                this.deleteSelectables(layer.polygons.map(poly => poly.userData as ShapeData));
            });
        }

        clear() {
            this.viewModel.clear();
        }

        deleteRoofClick(roof: ISelectableItem) {
            if (roof && confirm("Delete roof?"))
                this.deleteSelectable(roof);
        }

        deleteSelection() {
            var viewModel = this.viewModel;
            var items = viewModel.selection.splice(0, viewModel.selection.length);
            this.deleteSelectables(items);
        }

        deleteSelectables(items: ISelectableItem[]) {
            if (!items)
                return;

            this.viewModel.cleanUpDragInfo();

            items.forEach(item => {
                this.deleteSelectable(item);
            });

            if (this.viewModel.layer.all.every(layer => !layer.polygons.length))
                this.viewModel.detailsViewVisible = false;
        }

        deleteSelectableClick(item: ISelectableItem) {
            if (!item.locked)
                this.deleteSelectable(item);
        }

        deleteSelectable(item: ISelectableItem) {
            if (item) {
                this.removeItemFromSelection(item);

                item.remove(this);

                if (item.typeName !== window.InterfernceLayerName)
                    this.autoSelectLayer();
            }
        }

        exportShapes(): SolarProTool.MapShape[] {

            if (!this.viewModel.layer.referenceLayer.polygons.length)
                return [];

            var referenceLayer = this.viewModel.layer.referenceLayer,
                referencePoly = referenceLayer.polygons.length ? referenceLayer.polygons[0] : null;

            //var roofLayer = this.viewModel.layer.roof,
            //    roofPoly = roofLayer.polygons.length ? roofLayer.polygons[0] : null;

            if (!referencePoly || referencePoly._isDisposed || !referencePoly.userData)
                return null;

            let referenceData = referencePoly.userData as ShapeData;

            referenceData.regenerate();

            let referenceOrientation = referenceData.southMarker ? referenceData.southMarker.orientation : 0,
                referenceOrigin = LatLng.FromPoint2D(referenceData.getOrientedOrigin());

            var result = this.getAllPolygons().filter(poly => poly.userData && !poly._isDisposed).map(poly => {
                return this.exportShape(poly.userData as ShapeData, referenceData, referenceOrigin, referenceOrientation);

                //var polys: SolarProTool.MapPolygon[] = [];

                //var data = poly.userData as ShapeData;

                //data.regenerate();

                //var bd = new BoundsLatLng();

                //poly.getPaths().forEach((path) => {
                //    var lls = LatLng.FromGMLatLngArray(path);
                //    bd.ExpandByLatLngs(lls);
                //    //var poly = this.pathToMapPolygon(lls, data.southMarker ? data.southMarker.orientation : buildingOrientation, buildingOrigin, data.height, data.slope, data.useProjection);
                //    var poly = this.pathToMapPolygon(lls, data.southMarker ? data.southMarker.orientation : referenceOrientation, data.southMarker ? LatLng.FromPoint2D(data.getOrientedOrigin()) : referenceOrigin, data.height, data.slope, referenceData.useProjection);
                //    if (poly)
                //        polys.push(poly);
                //});

                //var spPolylines: SolarProTool.MapPolygon[] = [],
                //    cutPolylines: SolarProTool.MapPolygon[] = [];

                //if (data.separationPolylines && data.separationPolylines.length) {
                //    data.separationPolylines.forEach(sp => {
                //        var mp = this.pathToMapPolygon(
                //            LatLng.FromGMLatLngArray(sp.polyline.getPath()),
                //            data.southMarker ? data.southMarker.orientation : referenceOrientation,
                //            data.southMarker ? LatLng.FromPoint2D(data.getOrientedOrigin()) : referenceOrigin,
                //            referenceData.height,
                //            referenceData.slope,
                //            referenceData.useProjection,
                //            true);
                //        if (sp.isCutting)
                //            cutPolylines.push(mp);
                //        else
                //            spPolylines.push(mp);
                //    });
                //}

                //let result: SolarProTool.MapShape = {
                //        IdString: data.id,
                //        TypeName: data.typeName,
                //        Title: data.title,
                //        borders: polys,
                //        IsParallel: data.isParallel,
                //        mapSouthMarker: data.southMarker ? data.southMarker.getLatLngOrientation() : null,
                //        IsActive: data.isActive,
                //        separationPolylines: spPolylines,
                //        cuttingPolylines: cutPolylines,
                //        SlopeOverride: data.slopeOverride
                //    };
                //return result;
            });
            return result;
        }

        exportShape(data: ShapeData, referenceData?: ShapeData, referenceOrigin?: LatLng, referenceOrientation?: number): SolarProTool.MapShape {
            data.regenerate();

            if (!referenceData) {
                if (this.viewModel.layer.referenceLayer.typeName === data.typeName) {
                    referenceData = data;
                }
                else {
                    if (!this.viewModel.layer.referenceLayer.polygons.length)
                        return null;

                    var referenceLayer = this.viewModel.layer.referenceLayer,
                        referencePoly = referenceLayer.polygons.length ? referenceLayer.polygons[0] : null;

                    if (!referencePoly || referencePoly._isDisposed || !referencePoly.userData)
                        return null;

                    referenceData = referencePoly.userData as ShapeData;

                    referenceData.regenerate();
                }
            }

            if (!referenceOrigin) {
                referenceOrigin = LatLng.FromPoint2D(referenceData.getOrientedOrigin());
            }

            if (referenceOrientation === null || isNaN(referenceOrientation)) {
                referenceOrientation = referenceData.southMarker ? referenceData.southMarker.orientation : 0;
            }

            var polys: SolarProTool.MapPolygon[] = [],
                poly = data.poly;

            var bd = new BoundsLatLng();

            poly.getPaths().forEach((path) => {
                var lls = LatLng.FromGMLatLngArray(path);
                bd.ExpandByLatLngs(lls);
                //var poly = this.pathToMapPolygon(lls, data.southMarker ? data.southMarker.orientation : buildingOrientation, buildingOrigin, data.height, data.slope, data.useProjection);
                var poly = this.pathToMapPolygon(lls, data.southMarker ? data.southMarker.orientation : referenceOrientation, data.southMarker ? LatLng.FromPoint2D(data.getOrientedOrigin()) : referenceOrigin, data.height, data.slope, referenceData.useProjection);
                if (poly)
                    polys.push(poly);
            });

            var spPolylines: SolarProTool.MapPolygon[] = [],
                cutPolylines: SolarProTool.MapPolygon[] = [];

            if (data.separationPolylines && data.separationPolylines.length) {
                data.separationPolylines.forEach(sp => {
                    var mp = this.pathToMapPolygon(
                        LatLng.FromGMLatLngArray(sp.polyline.getPath()),
                        data.southMarker ? data.southMarker.orientation : referenceOrientation,
                        data.southMarker ? LatLng.FromPoint2D(data.getOrientedOrigin()) : referenceOrigin,
                        referenceData.height,
                        referenceData.slope,
                        referenceData.useProjection,
                        true);
                    if (sp.isCutting)
                        cutPolylines.push(mp);
                    else
                        spPolylines.push(mp);
                });
            }

            let result: SolarProTool.MapShape = {
                IdString: data.id,
                TypeName: data.typeName,
                Title: data.title,
                borders: polys,
                IsParallel: data.isParallel,
                mapSouthMarker: data.southMarker ? data.southMarker.getLatLngOrientation() : null,
                IsActive: data.isActive,
                separationPolylines: spPolylines,
                cuttingPolylines: cutPolylines,
                SlopeOverride: data.slopeOverride
            };
            return result;
        }

        importShape(shape: SolarProTool.MapShape, bounds?: google.maps.LatLngBounds): ShapeData {
            var paths: google.maps.LatLng[][] = [];

            if (!bounds)
                bounds = new google.maps.LatLngBounds();

            var h = 0;
            var slope = 0;
            var useProjection = true;

            shape.borders.forEach(poly => {
                paths.push(poly.points.map(p => {
                    var latLng = new google.maps.LatLng(p.latitude, p.longitude);
                    if (p.z > h)
                        h = p.z;
                    bounds.extend(latLng);
                    return latLng;
                }));
                slope = poly.slope || 0;
                useProjection = poly.useProjection === undefined ? true : !!poly.useProjection;
            });

            var layer = this.viewModel.layer.typeName[shape.TypeName],
                shapeStyle = layer ? layer.shapeStyle : this.shapeStyle;

            var gPoly = new google.maps.Polygon($.extend({}, {
                map: this.map,
                clickable: !this.zoomOnlyMode,
                draggable: false,
                editable: false,
                paths: paths
            }, shapeStyle) as google.maps.PolygonOptions);

            var isFrozen = false;

            if (shape.IdString && this.viewModel.hasInstance(shape.IdString)) {
                var prev = this.viewModel.instances[shape.IdString] as ShapeData;
                isFrozen = !prev.controlledByParent && prev.frozen;
                prev.remove(this);
                this.viewModel.removeInstance(shape.IdString);
            }

            var shapeData = new ShapeData(this, gPoly as any, shape.IdString, shape.Title, shape.TypeName);

            shapeData.slopeOverride = !!shape.SlopeOverride;
            shapeData.isActive = !!shape.IsActive;
            shapeData.slope = slope;
            shapeData.customSlope = slope;
            shapeData.useProjection = useProjection;
            shapeData.isParallel = !!shape.IsParallel;
            if (isFrozen)
                shapeData.frozen = true;

            (gPoly as any).userData = shapeData;

            if (shape.mapSouthMarker) {
                var pos = new LatLng(shape.mapSouthMarker.Latitude, shape.mapSouthMarker.Longitude);
                shapeData.southMarker = new SouthMarker(pos, shape.mapSouthMarker.Orientation, true, this);
            }

            if (shape.separationPolylines && shape.separationPolylines.length) {
                shape.separationPolylines.forEach(poly => {
                    var arr = poly.points.map(p => {
                        var latLng = new google.maps.LatLng(p.latitude, p.longitude);
                        if (p.z > h)
                            h = p.z;
                        bounds.extend(latLng);
                        return latLng;
                    });
                    var separationPolyline = new SeparationPolyline(this, shapeData, null, arr);
                });
            }

            if (shape.cuttingPolylines && shape.cuttingPolylines.length) {
                shape.cuttingPolylines.forEach(poly => {
                    var arr = poly.points.map(p => {
                        var latLng = new google.maps.LatLng(p.latitude, p.longitude);
                        if (p.z > h)
                            h = p.z;
                        bounds.extend(latLng);
                        return latLng;
                    });
                    if (arr.length > 1)
                        var cuttingPolyline = new SeparationPolyline(this, shapeData, null, arr, true);
                });
            }

            shapeData.height = h;

            this.addPolygon(gPoly as any);

            return shapeData;
        }

        importShapes(shapes: SolarProTool.MapShape[]): BoundsLatLng {
            if (shapes && shapes.length) {

                var bounds = new google.maps.LatLngBounds();

                var building: ShapeData = null;

                var buildingShape = shapes.filter(shape => shape.TypeName === window.BuildingTypeName);

                if (buildingShape.length)
                    building = this.importShape(buildingShape[0], bounds);

                shapes.filter(shape => shape.TypeName !== window.BuildingTypeName).forEach(shape => {
                    var shapeData = this.importShape(shape, bounds);

                    if (building && shape.TypeName === window.RoofTypeName) {
                        building.children.push(shapeData);
                        shapeData.parent = building;
                    }
                });

                if (building)
                    building.update();

                if (!bounds.isEmpty())
                    return BoundsLatLng.FromGMLatLngBounds(bounds);
            }
            return new BoundsLatLng();
        }

        addPolygonByData(data: PolyData) {
            var layer = this.viewModel.layer.typeName[data.typeName],
                shapeStyle = layer ? layer.shapeStyle : this.shapeStyle;

            var gPoly = new google.maps.Polygon($.extend({}, {
                map: this.map,
                clickable: !this.zoomOnlyMode,
                draggable: false,
                editable: false,
                paths: new google.maps.MVCArray(data.latLngs.map(lls => new google.maps.MVCArray(lls.map(ll => ll.ToGMLatLng()))))
            }, shapeStyle) as google.maps.PolygonOptions);

            var shapeData = new ShapeData(this, gPoly as any, data.id || spt.Utils.GenerateGuid(), data.title, data.typeName);

            shapeData.height = data.height;

            (gPoly as any).userData = shapeData;

            this.addPolygon(gPoly as any);
        }

        addPolygon(poly: IGoogleMapsPolygon): IGoogleMapsPolygon {
            var data = poly.userData as ShapeData;
            var layer = (data ? this.viewModel.layer.typeName[data.typeName] : null) || this.viewModel.currentLayer;

            if (layer) {
                layer.addPolygon(poly, this);
            } else {
                if (data)
                    data.dispose();
                else
                    poly.setMap(null);
                DManager.showErrorMessage("Can't add Polygon of unknown type.");
                return null;
            }

            return poly;
        }

        selectShapes(shapes: ISelectableItem[], forceSelection?: boolean, forceAdd?: boolean, forceRemove?: boolean) {

            var ctrlDown = this.ctrlDown;
            var shiftDown = this.shiftDown;

            if (((!forceAdd && !shiftDown && !ctrlDown) || forceSelection) && !forceRemove) {
                this.clearSelection();
            }

            shapes.forEach(s => this.doSelectSelectable(s, false, !forceRemove, forceRemove));
        }

        doSelectSelectable(item: ISelectableItem, forceSelection?: boolean, forceAdd?: boolean, forceRemove?: boolean) {
            if (!item || !item.getMap() || item.locked)
                return;
            var ctrlDown = this.ctrlDown,
                shiftDown = this.shiftDown;
            if (forceSelection) {
                ctrlDown = false;
                shiftDown = false;
            } else if (forceAdd) {
                ctrlDown = false;
                shiftDown = true;
            } else if (forceRemove) {
                ctrlDown = true;
                shiftDown = true;
            }
            //var data = shape.userData as ShapeData;
            //var layer = data ? viewModel.getLayerByTypeName(data.typeName) : null;
            //if (!layer)
            //    return;

            //var shapeHovered = true;

            if (this.eraseMode) {
                this.deleteSelectable(item);
                return;
            }

            var isSelected = item.selected;

            if (ctrlDown && !shiftDown) {
                //toogle selection
                if (isSelected) {
                    this.removeItemFromSelection(item);

                    //item.selected = false;
                    //shape.setEditable(false);
                    //shape.setDraggable(false);
                    //shape.setOptions(shapeHovered ? layer.shapeHoveredStyle : layer.shapeStyle);
                    //viewModel.selection.remove(s => s.id === item.id);
                } else {
                    this.addItemToSelection(item);

                    //item.selected = true;
                    //item.setEditable(true);
                    //item.setDraggable(true);
                    //item.setOptions(shapeHovered ? layer.shapeSelectedHoveredStyle : layer.shapeSelectedStyle);
                    //viewModel.selection.push(item);
                }
            } else if (!ctrlDown && shiftDown) {
                //only add to selection
                if (!isSelected) {
                    this.addItemToSelection(item);

                    //item.selected = true;
                    //item.setEditable(true);
                    //item.setDraggable(true);
                    //item.setOptions(shapeHovered ? layer.shapeSelectedHoveredStyle : layer.shapeSelectedStyle);
                    //viewModel.selection.push(item);
                }
            } else if (ctrlDown && shiftDown) {
                //only remove from selection
                if (isSelected) {
                    this.removeItemFromSelection(item);

                    //item.selected = false;
                    //item.setEditable(false);
                    //item.setDraggable(false);
                    //item.setOptions(shapeHovered ? layer.shapeHoveredStyle : layer.shapeStyle);
                    //viewModel.selection.remove(s => s.id === item.id);
                }
            } else {
                //select only this shape
                this.clearSelection();

                this.addItemToSelection(item);

                //item.selected = true;
                //item.setEditable(true);
                //item.setDraggable(true);
                //item.setOptions(shapeHovered ? layer.shapeSelectedHoveredStyle : layer.shapeSelectedStyle);
                //viewModel.selection.push(item);
            }
        }

        removeFromSelection(id: string) {
            if (id)
                this.viewModel.selection.remove(s => s.id === id).forEach(s => { s.selected = false; });
        }

        removeItemFromSelection(item: ISelectableItem) {
            if (item) {
                if (item.typeName) {
                    var layer = this.viewModel.layer.typeName[item.typeName];
                    if (layer)
                        layer.lastClickedIndex = -1;
                }
                this.removeFromSelection(item.id);
            }
        }

        addItemToSelection(item: ISelectableItem) {
            var selection = this.viewModel.selection;
            if (item && !item.selected && !item._isDisposed) {
                if (item.typeName) {
                    var layer = this.viewModel.layer.typeName[item.typeName];
                    if (layer)
                        layer.lastClickedIndex = -1;
                }
                item.selected = true;
                selection.push(item);
            }
        }

        addItemsToSelection(items: ISelectableItem[]) {
            var selection = this.viewModel.selection;
            if (items && items.length)
                items = items.filter(item => !item.selected && !item._isDisposed);
            if (items && items.length) {
                items.forEach(item => {
                    item.selected = true;
                    if (item.typeName) {
                        var layer = this.viewModel.layer.typeName[item.typeName];
                        if (layer)
                            layer.lastClickedIndex = -1;
                    }
                });
                selection.push.apply(selection, items);
            }
        }

        toggleEditImportedImage() {
            var viewModel = this.viewModel;
            if (viewModel.selectedToolName === 'ImportImageHelperTool') {
                this.selectTool(null);
            } else {
                this.selectTool('ImportImageHelperTool');
            }
        }

        revertImportedImage() {
            var viewModel = this.viewModel;
            if (viewModel.selectedToolName === 'ImportImageHelperTool') {
                this.selectTool(null);
                this.loadImportImages();
            }
        }

        deleteImportedImage() {

            var imgIds = this.viewModel.importedImages.map(img => img._id);

            if (!imgIds.length) {
                this.selectTool(null);
                return;
            }

            SolarProTool.Ajax("WebServices/MapServices.asmx").Call("DeleteImportedImages").Data({ ids: imgIds }).CallBack((result) => {
                this.selectTool(null);

                this.viewModel.currentImportedImageId = null;
                this.viewModel.importedImages.removeAll().forEach(im => {
                    im.setMap(null);
                });
            });
        }

        selectToolClick(toolName: string, subTool?: string) {

            (<any>window).lsSidebar.close('mapSettingsSlider');

            var viewModel = this.viewModel,
                tool = viewModel.toolIcons[toolName];

            if (tool && tool.disabled)
                return;

            let dropdownButton = $('#dropdownMenuToolbar' + toolName);
            let dropdownParent = dropdownButton.parent();
            let isLandscape = window.innerHeight <= window.innerWidth;

            let cssOrientation = isLandscape ? "dropright" : "dropdown";
            let cssOrientationOther = !isLandscape ? "dropright" : "dropdown";
            dropdownParent.removeClass(cssOrientationOther);
            dropdownParent.addClass(cssOrientation);

            if (!dropdownButton.data('init')) {
                dropdownButton.dropdown();
                dropdownButton.data('init', true);
                dropdownButton.dropdown('toggle');
            }

            if (subTool)
                dropdownButton.dropdown('toggle');

            this.selectTool(toolName, subTool);
        }

        selectTool(toolName: string, subTool?: string) {
            var viewModel = this.viewModel,
                oldTool = viewModel.currentTool,
                tool = viewModel.toolIcons[toolName];

            viewModel.cleanUpDragInfo();

            if (!tool || tool.disabled) {
                toolName = "SelectTool";
                tool = viewModel.toolIcons[toolName];
            }

            if (oldTool)
                oldTool.deselect(this);

            viewModel.currentTool = tool;

            if (toolName === "SelectTool" || toolName === "SelectRectTool" || toolName === "EraserTool") {
                this.getAllPolygons().forEach(poly => {
                    if (poly.userData) {
                        poly.userData.frozen = false;
                        poly.setOptions({ clickable: true });
                    }
                });
                this.viewModel.helperLines.forEach(hl => {
                    hl.frozen = false;
                });
            } else {
                this.getAllPolygons().forEach(poly => {
                    if (poly.userData) {
                        poly.userData.frozen = true;
                        poly.setOptions({ clickable: false });
                    }

                });
                this.viewModel.helperLines.forEach(hl => {
                    hl.frozen = true;
                });
            }

            if (tool)
                tool.select(this, oldTool, subTool || null);
        }

        fitBounds(polys: IGoogleMapsPolygon[]) {
            if (polys && polys.length) {
                this.map.fitBounds(this.getBoundsFromPolygons(polys));
                this.selectShapes(polys.map(poly => poly.userData as ShapeData), true);
            }
        }

        fromViewPointToLatLng(p: { x: number, y: number }) {
            var map = this.map,
                scale = this.getPixelFactor(),
                mapBounds = map.getBounds(),
                mapNE = mapBounds.getNorthEast(),
                mapSW = mapBounds.getSouthWest(),
                pNW = new LatLng(mapNE.lat(), mapSW.lng()).ToPoint2D(); // this.fromLatLngToPoint(new google.maps.LatLng(mapNE.lat(), mapSW.lng()));

            return LatLng.FromPoint2D(new Point2D(((pNW.x * scale) + p.x) / scale, ((pNW.y * scale) + p.y) / scale)); // this.fromPointToLatLng(new google.maps.Point(((pNW.x * scale) + p.x) / scale, ((pNW.y * scale) + p.y) / scale), false);
        }

        static getQuadtreeQuadrant(x, y, zoom) {
            var cx = Math.abs(+x);
            var cy = Math.abs(+y);
            var q = [];
            for (var z = zoom; z >= 1; z--) {
                var fx = cx % 2;
                var fy = cy % 2;
                q.unshift(fy * 2 + fx);
                cx = (cx - fx) / 2;
                cy = (cy - fy) / 2;
            }
            return q;
        }

        //static getTileDiv(map: google.maps.Map, coord: IPoint2D, zoom: number, ownerDocument: HTMLDocument, url: string): HTMLElement {
        //    var projection = map.getProjection(),
        //        tilesize = LatLng.TILE_SIZE,
        //        tilesizeHalf = LatLng.TILE_SIZE_HALF;

        //    //console.log(coord.x + " " + coord.y + " " + zoom);
        //    var cx = (coord.x + 0.5) * tilesize;
        //    var cy = (coord.y + 0.5) * tilesize;

        //    var nz = Math.min(21, zoom); //max zoom is 21
        //    var dz = 1 << (zoom - nz); //Math.pow(2, zoom - nz)
        //    var ts = dz * tilesize;
        //    var ts2 = ts * 0.5;
        //    var scale = (1 << zoom);

        //    var tx = Math.floor(cx / ts) * ts;
        //    var ty = Math.floor(cy / ts) * ts;

        //    var ix = tx + ts2;
        //    var iy = ty + ts2;

        //    var bgscale = dz * 100;

        //    var latlng = projection.fromPointToLatLng(new google.maps.Point(ix / scale, iy / scale), false);

        //    var div = ownerDocument.createElement('div');

        //    //var url = `../../handler/StaticImagesHandler.ashx?ID=GoogleHybrid&center=${latlng.lat()},${latlng.lng()}&zoom=${nz}&size=${tilesize}x${tilesize}&scale=1`;

        //    div.className = "md-tile-url";
        //    div.style.backgroundSize = `${bgscale}% ${bgscale}%`;
        //    div.style.backgroundPosition = `${(tx - cx + tilesizeHalf)}px ${(ty - cy + tilesizeHalf)}px`;
        //    div.style.backgroundImage = `url("${url}&center=${latlng.lat()},${latlng.lng()}&zoom=${nz}&size=${tilesize}x${tilesize}&scale=1")`;

        //    //$(div).attr("tile-coord", coord.x + "_" + coord.y + "_" + zoom);

        //    return div;


        //    //        var scale = (1 << zoom) / tilesize;
        //    //        var projection = map.getProjection();
        //    //        var latlng = projection.fromPointToLatLng(new google.maps.Point((coord.x + 0.5) / scale, (coord.y + 0.5) / scale), false);

        //    //        var url = url +"?ID=GoogleHybrid&center=" + latlng.lat() + "," + latlng.lng() + "&zoom=" + zoom + "&size=" + tilesize + "x" + tilesize + "&scale=1";

        //    //        return url;
        //}

        load(callBack?: () => void) {
            SolarProTool.Ajax("WebServices/MapServices.asmx").Call("GetDataForMapDrawer").Data().CallBack((result) => {
                this.clear();

                var bounds = new BoundsLatLng();

                if (!result)
                    result = {} as any;

                if (result.Shapes) {
                    let bds = this.importShapes(result.Shapes);
                    if (!bds.IsEmpty)
                        bounds.Expand(bds);
                }

                if (result.ImportedImages) {
                    let bds = this.importImages(result.ImportedImages);
                    if (!bds.IsEmpty && bds.Width < 1000000 && bds.Height < 1000000 && bds.Center.DistanceTo(bounds.Center) < 1000000)
                        bounds.Expand(bds);
                }

                if (this.ghostShapes && this.ghostShapes.length) {
                    this.ghostShapes.forEach(gp => {
                        gp.setMap(null);
                    });
                    this.ghostShapes = null;
                }

                if (result.GhostBorders && result.GhostBorders.length) {
                    var gPaths: google.maps.LatLng[][] = [];

                    result.GhostBorders.forEach(poly => {
                        gPaths.push(poly.points.map(p => new google.maps.LatLng(p.latitude, p.longitude)));
                    });

                    var gPoly = new google.maps.Polygon($.extend({}, {
                        map: this.map,
                        paths: gPaths
                    }, this.ghostShapeStyle) as google.maps.PolygonOptions);

                    this.ghostShapes = [gPoly as any];
                }

                this.autoSelectLayer();

                this.clearSelection();
                if (result.mapTypeId) {
                    if (this.mapTypeIds.some(m => m === result.mapTypeId)) {
                        this.map.setMapTypeId(result.mapTypeId);
                    } else if (result.mapTypeId.indexOf("Google") !== -1) {
                        this.map.setMapTypeId(this.mapTypeIds[1]);
                    }
                }

                if (!bounds.IsEmpty)
                    this.map.fitBounds(bounds.ToGMLatLngBounds());

                // select tool on startup
                var selectedToolName = this.options.isReadOnly ? 'HandTool' : 'DrawTool';
                this.selectTool(selectedToolName);

                if (callBack && typeof callBack === "function")
                    callBack();
            });
        }

        loadImportImages(callBack?: () => void) {
            SolarProTool.Ajax("WebServices/MapServices.asmx").Call("GetImportedImagesForMapDrawer").Data().CallBack((result) => {
                this.viewModel.importedImages.removeAll().forEach(im => {
                    im.setMap(null);
                });

                var bounds = new BoundsLatLng();

                if (!this.viewModel.bounds.IsEmpty)
                    bounds.Expand(this.viewModel.bounds);

                if (result.ImportedImages) {
                    let bds = this.importImages(result.ImportedImages);
                    if (!bds.IsEmpty && bds.Width < 1000000 && bds.Height < 1000000 && bds.Center.DistanceTo(bounds.Center) < 1000000)
                        bounds.Expand(bds);
                }

                if (!bounds.IsEmpty)
                    this.map.fitBounds(bounds.ToGMLatLngBounds());

                this.clearSelection();

                if (callBack && typeof callBack === "function")
                    callBack();
            });
        }

        autoSelectLayer() {
            var viewModel = this.viewModel,
                layers = viewModel.layer.all;

            for (var i = 0, j = layers.length; i < j; i++) {
                var layer = layers[i];
                if (!layer.locked) {
                    viewModel.selectedLayerTypeName = layer.typeName;
                    break;
                }
            }

            if ((viewModel.layer.roof && viewModel.layer.roof.polygons.length) ||
                (viewModel.layer.freeArea && viewModel.layer.freeArea.polygons.length))
                viewModel.detailsViewVisible = true;
        }

        testDepthCanvas() {
            let bd = new MapDrawing.BoundsLatLng();
            let llPolys: MapDrawing.LatLng[][] = [];
            //let gpolys: google.maps.Polygon[] = [];

            this.getAllPolygons().filter(poly => poly.userData && !poly._isDisposed).forEach(poly => {
                var shapeData = poly.userData as ShapeData;
                if (shapeData.typeName === window.RoofTypeName) {
                    llPolys.push(shapeData.latLngs[0]);
                    //let gpoly = new google.maps.Polygon({ paths: poly.getPaths() });
                    //gpolys.push(gpoly);
                    //shapeData.latLngs
                    bd.Expand(shapeData.bounds);
                }
            });

            bd = bd.Extend(5);

            let w = bd.Width,
                h = bd.Height;

            if (w > 0 && w < 1000 && h > 0 && h < 1000) {
                var ocr = this.ocrManager.ocr,
                    zoom: number,
                    pxSize = 256,
                    sizeFactor = Math.min(pxSize / w, pxSize / h),
                    maxSize = Math.max(w, h),
                    width = Math.round(w * sizeFactor),
                    height = Math.round(h * sizeFactor);

                if (maxSize > 500)
                    zoom = 18;
                else if (maxSize > 250)
                    zoom = 19;
                else
                    zoom = 23;

                ocr.request({
                    bounds: bd,
                    level: zoom,
                    renderWidth: width,
                    renderHeight: height,
                    depthMaterial: true,
                    drawFn: (canvas, gl) => {

                        var t0 = performance.now();

                        var depthCanvas = document.createElement("canvas");

                        depthCanvas.width = width;
                        depthCanvas.height = height;

                        depthCanvas.style.position = "absolute";
                        depthCanvas.style.top = "100px";
                        depthCanvas.style.left = "100px";
                        depthCanvas.style.width = width + "px";
                        depthCanvas.style.height = height + "px";
                        depthCanvas.style.zIndex = "1";
                        document.body.appendChild(depthCanvas);

                        var numPixels = width * height,
                            gldata = new Uint8Array(numPixels * 4),
                            x: number,
                            y: number,
                            d: number;

                        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, gldata);

                        var pts: THREE.Vector4[] = [];

                        var plys: number[][][] = [],
                            planes: THREE.Plane[] = [];

                        llPolys.forEach(llPoly => {
                            var positions: number[] = [],
                                minZ = Number.MAX_VALUE;

                            var poly = llPoly.map(ll => {
                                let v = bd.Min.DirectionTo(ll);
                                return [Math.round(v.x * width / w), Math.round(v.y * height / h)];
                            });

                            GMapsUtils.triangulationUtils.pointsInPolygon([poly], (x, y) => {
                                if (x > 0 && x < width && y > 0 && y < height) {
                                    let idx = y * (width * 4) + x * 4,
                                        r = gldata[idx],
                                        g = gldata[idx + 1],
                                        b = gldata[idx + 2],
                                        d = r / 256 + g + b * 256;

                                    positions.push(x / width * w, y / height * h, d);
                                    pts.push(new THREE.Vector4((x / width * w) * 1000, (y / height * h) * 1000, d * 1000, 4));
                                    if (d < minZ)
                                        minZ = d;
                                }
                            });

                            var plane = spt.ThreeJs.utils.approximatePlaneFromPositionArray(positions, minZ);

                            plys.push(poly);
                            planes.push(plane.clone());

                        });

                        var minDepth = Number.MAX_VALUE,
                            maxDepth = 0;

                        //calc min and max depth
                        for (y = 0; y < height; y++) {
                            for (x = 0; x < width; x++) {
                                const gidx = y * (width * 4) + x * 4,
                                    r = gldata[gidx],
                                    g = gldata[gidx + 1],
                                    b = gldata[gidx + 2];

                                d = r / 256 + g + b * 256;

                                if (d < minDepth)
                                    minDepth = d;
                                if (d > maxDepth)
                                    maxDepth = d;
                            }
                        }

                        var ctx = depthCanvas.getContext("2d"),
                            imageData = ctx.createImageData(canvas.width, canvas.height),
                            data = imageData.data,
                            deltaDepth = maxDepth - minDepth;

                        for (y = 0; y < height; y++) {
                            for (x = 0; x < width; x++) {
                                const idx = (height - 1 - y) * (width * 4) + x * 4,
                                    gidx = y * (width * 4) + x * 4,
                                    r = gldata[gidx],
                                    g = gldata[gidx + 1],
                                    b = gldata[gidx + 2];

                                d = r / 256 + g + b * 256;

                                d = Math.min(1, Math.max(0, (d - minDepth) / deltaDepth)) * 255;

                                data[idx] = d;
                                data[idx + 1] = d;
                                data[idx + 2] = d;
                                data[idx + 3] = 255;
                            }
                        }

                        plys.forEach((poly, i) => {
                            var plane = planes[i],
                                r = plane.normal.x * 127.5 + 127.5,
                                g = plane.normal.y * 127.5 + 127.5,
                                b = plane.normal.z * 127.5 + 127.5;

                            console.log(`plane normal x: ${plane.normal.x}, y: ${plane.normal.y}, z: ${plane.normal.z}, angle: ${plane.normal.angleTo(new THREE.Vector3(0, 0, 1)) / Math.PI * 180}`);

                            GMapsUtils.triangulationUtils.pointsInPolygon([poly], (x, y) => {
                                if (x > 0 && x < width && y > 0 && y < height) {
                                    let idx = (height - 1 - Math.round(y)) * (width * 4) + Math.round(x) * 4;
                                    imageData.data[idx] = r;
                                    imageData.data[idx + 1] = g;
                                    imageData.data[idx + 2] = b;
                                }
                            });

                        });

                        console.log(`buidling height: ${deltaDepth} m`);

                        spt.ThreeJs.utils.DebugPolygons(null, null, pts);

                        ctx.putImageData(imageData, 0, 0);

                        var t1 = performance.now();

                        console.log(`Call to plane approximation took ${(t1 - t0) * 0.001} seconds.`);
                    }
                });
            }
        }

        save(callback?: () => void) {
            if (LoaderManager.isLoading())
                return false;

            LoaderManager.addLoadingJob();

            let selectedToolName = this.options.isReadOnly ? 'HandTool' : 'SelectTool';
            this.selectTool(selectedToolName);

            ko.tasks.runEarly();

            setTimeout(this.saveIntern.bind(this, callback), 0);

        }

        private saveIntern(callback?: () => void) {
            LoaderManager.finishLoadingJob();

            var shapes = this.exportShapes(),
                mapType = "" + this.map.getMapTypeId(),
                isGoogleHD = mapType === "Google HD";

            var data: SolarProTool.MapDrawerData = {
                mapTypeId: mapType,
                Shapes: shapes,
                ImageId: null,
                ImageWidth: 0,
                ImageHeight: 0,
                ImageLatitude: 0,
                ImageLongitude: 0,
                ImageOrientation: 0,
                MapsImageData: null,
                MapsImageMinLatitude: 0,
                MapsImageMinLongitude: 0,
                MapsImageMaxLatitude: 0,
                MapsImageMaxLongitude: 0
            };

            var img = this.viewModel.currentImportedImage;

            if (img && img._bounds) {

                var bd = img._bounds;
                var c = bd.Center;

                data.ImageId = img._id;
                data.ImageWidth = bd.Width * 1000;
                data.ImageHeight = bd.Height * 1000;
                data.ImageLatitude = c.Latitude;
                data.ImageLongitude = c.Longitude;
                data.ImageOrientation = img._orientation;
            }

            if (isGoogleHD) {

                let bd = new MapDrawing.BoundsLatLng();

                this.getAllPolygons().filter(poly => poly.userData && !poly._isDisposed).forEach(poly => {
                    var shapeData = poly.userData as ShapeData;
                    bd.Expand(shapeData.bounds);
                });

                let w = bd.Width,
                    h = bd.Height;

                if (w > 0 && w < 1000 && h > 0 && h < 1000) {
                    var ocr = this.ocrManager.ocr,
                        canvas = document.createElement("canvas"),
                        zoom: number,
                        pxSize = 1024,
                        sizeFactor = Math.min(pxSize / w, pxSize / h),
                        maxSize = Math.max(w, h),
                        pxWidth = Math.round(w * sizeFactor),
                        pxHeight = Math.round(h * sizeFactor);

                    if (maxSize > 500)
                        zoom = 18;
                    else if (maxSize > 250)
                        zoom = 19;
                    else
                        zoom = 23;

                    canvas.width = pxWidth;
                    canvas.height = pxHeight;

                    //canvas.style.position = "absolute";
                    //canvas.style.top = "100px";
                    //canvas.style.left = "100px";
                    //canvas.style.width = pxWidth + "px";
                    //canvas.style.height = pxHeight + "px";
                    //canvas.style.zIndex = "1";
                    //var ctx = canvas.getContext("2d");
                    //ctx.fillStyle = "white";
                    //ctx.fillRect(0, 0, canvas.width, canvas.height);
                    //document.body.appendChild(canvas);

                    LoaderManager.addLoadingJob();

                    ocr.requestRender(bd, zoom, () => canvas, (success) => {
                        LoaderManager.finishLoadingJob();

                        if (success && canvas && typeof canvas.toDataURL == "function") {
                            data.MapsImageData = canvas.toDataURL("image/png");
                            data.MapsImageMinLatitude = bd.Min.Latitude;
                            data.MapsImageMinLongitude = bd.Min.Longitude;
                            data.MapsImageMaxLatitude = bd.Max.Latitude;
                            data.MapsImageMaxLongitude = bd.Max.Longitude;
                        }

                        this.saveData(data);
                    }, pxWidth, pxHeight);

                    //this.testDepthCanvas();

                    return;
                }

            }

            this.saveData(data, callback);
        }

        private saveData(data: SolarProTool.MapDrawerData, callback?: () => void) {
            SolarProTool.Ajax("WebServices/MapServices.asmx").Call("SaveMapDrawerData").Data({ data: data }).CallBack((result) => {
                if (!result)
                    result = {} as any;
                if (result.ErrorMessage)
                    DManager.showErrorMessage(result.ErrorMessage);
                else {
                    if (this.dialog)
                        DManager.hide(this.dialog);

                    var valueAdjustOpts = this.viewModel.valueAdjustOpts;

                    if ($('#Roof_Orientation').length) {
                        spt.Utils.SetFloatInInput('#Roof_Orientation', spt.Utils.GetFloatFromInputValue("" + result.RoofOrientation,
                            {
                                notImperial: true
                            }));
                        spt.Utils.SetFloatInInput('#Roof_RoofHeight', spt.Utils.GetFloatFromInputValue("" + result.RoofHeight,
                            {
                                notImperial: true,
                                from: "mm",
                                to: valueAdjustOpts.to
                            }));
                        spt.Utils.SetFloatInInput('#Roof_Angle', Math.round(spt.Utils.GetFloatFromInputValue("" + result.RoofAngle,
                            {
                                notImperial: true
                            }) * 100) / 100);

                        if (result.RoofName) {
                            if ($('#Roof_Title').length)
                                $('#Roof_Title').val(result.RoofName);
                            if ($('#prInfoRoofTitle').length)
                                $('#prInfoRoofTitle').text(result.RoofName);
                        }

                        if (result.IsMultiRoofBuilding !== undefined)
                            window.isMultiRoofBuilding = !!result.IsMultiRoofBuilding;

                        if (result.ProcessStep >= 5) {
                            window.ConfirmDialogSaveOnlyClick = function () {
                                DManager.hide('confirmation_dialog_saveOrReCreate');
                            };

                            window.ConfirmDialogSaveAndReCreateClick = function () {
                                AManager.AjaxWithLoading('AnordnungsService.asmx', 'GenerateAll', { multiRoof: window.isMultiRoofBuilding }, window.ConfirmDialogSaveOnlyClick);
                            };

                            DManager.show('confirmation_dialog_saveOrReCreate');
                        }
                    }
                }

                if (callback && typeof callback === "function")
                    callback();
            });
        }

        ghostShapeStyle: IShapeStyle = {
            strokeWeight: 2,
            fillColor: "#616161",
            fillOpacity: 0.5,
            strokeColor: "#262626",
            strokeOpacity: 0.8,
            clickable: false,
            editable: false,
            draggable: false,
            visible: true
        };
        //mouseEventCreation: boolean;
        viewModel: ViewModel;
        dialog: Element;
        view: Element;
        mapsViewWrapper: HTMLElement;
        mapsView: HTMLElement;
        map: google.maps.Map;
        ghostShapes: IGoogleMapsPolygon[];
        elevationService: google.maps.ElevationService;
        drawingManager: google.maps.drawing.DrawingManager;
        eraseMode = false;
        private drawingMode = false;
        rectSelectMode = false;
        zoomOnlyMode = false;
        shiftDown = false;
        ctrlDown = false;
        altDown = false;
        spaceDown = false;
        private _hasFocus = false;
        private _mapEventListener: google.maps.MapsEventListener[] = [];

        get hasFocus(): boolean {
            return this._hasFocus && (!this.viewModel || !this.viewModel.detailsContentHasFocus);
        }

        set hasFocus(v: boolean) {
            this._hasFocus = v;
        }

        setSeparationLinesFrozen(frozen: boolean) {
            var viewmodel = this.viewModel,
                buildingLayer = viewmodel.layer.building,
                buildingPolys = buildingLayer ? buildingLayer.polygons : null;
            if (buildingPolys != null)
                buildingPolys.forEach(poly => {
                    if (poly.userData && (poly.userData as ShapeData).separationPolylines)
                        (poly.userData as ShapeData).separationPolylines.forEach(sp => { sp.frozen = frozen; });
                });
        }

        mouseDown = false;
        options: IMapDrawerOptions;
        _projection: google.maps.Projection = null;
        center: LatLng = new LatLng();
        mapTypeIds = [MapDrawer.EmptyCanvas, "Google", "BING"/*, "hybrid"*/];
        tileManager: TileManager[];
        ocrManager: OCRTileManager;
        tempPolyData: PolyData[] = [];
        canvasOverlay: CanvasOverlay;
        osmBuildings: OsmBuildings;

        getPixelFactor() {
            if (this.map)
                return Math.pow(2, this.map.getZoom());
            return 0;
        }

        setDrawingMode(b: boolean) {
            this.drawingMode = !!b;
        }

        getPolygonsByPoint(viewPosition: google.maps.LatLng) {
            var pointInPolygon = google.maps.geometry.poly.containsLocation;
            return this.getAllPolygons().filter(shape => pointInPolygon(viewPosition, shape as any));
        }

        getAllPolygons(): IGoogleMapsPolygon[] {
            var result: IGoogleMapsPolygon[] = [];
            this.viewModel.layer.all.forEach(layer => {
                layer.polygons.forEach(p => result.push(p));
            });
            return result;
        }

        getByBoundsLatLng(bounds: BoundsLatLng, layers?: PolygonLayer[], noHelperlines?: boolean): ISelectableItem[] {
            var result: ISelectableItem[] = [];
            if (!layers)
                layers = this.viewModel.layer.all;
            for (var i = 0, j = layers.length; i < j; i++) {
                var layer = layers[i];
                if (layer && layer.bounds) {
                    if (!layer.bounds.Overlaps(bounds))
                        continue;
                    var polygons = layer.polygons;
                    for (var k = 0, l = polygons.length; k < l; k++) {
                        var poly = polygons[k],
                            data = poly.userData as ShapeData;
                        if (data && data.bounds) {
                            var b = data.bounds;
                            if (b.Overlaps(bounds))
                                result.push(data);
                        }
                    }
                }

            }
            if (!noHelperlines) {
                var helperLines = this.viewModel.helperLines;
                for (var m = 0, n = helperLines.length; m < n; m++) {
                    result.push(helperLines[m]);
                }
            }

            return result;
        }

        getByLatLng(latLng: LatLng, clickTolerance: number, layers?: PolygonLayer[], noHelperlines?: boolean): ISelectableItem[] {
            var pxFactor = Math.pow(2, this.map.getZoom()),
                offset = clickTolerance / pxFactor,
                p = latLng.ToPoint2D(),
                t = new Point2D(offset, offset),
                bounds = new BoundsLatLng(LatLng.FromPoint2D(p.sub(t)), LatLng.FromPoint2D(p.add(t)));

            return this.getByBoundsLatLng(bounds, layers, noHelperlines);
        }

        getSegmentByPoint(latLng: LatLng, clickTolerance: number, layers?: PolygonLayer[], noHelperlines?: boolean, noGhostShapes?: boolean): Segment2D {

            var result: Segment2D = null,
                minDist = Number.MAX_VALUE,
                pxFactor = Math.pow(2, this.map.getZoom()),
                p = latLng.ToPoint2D();

            this.getByLatLng(latLng, clickTolerance, layers, noHelperlines).map((item: ISelectableItem) => {
                item.getSegments().forEach(seg => {
                    var dist = seg.distanceToPoint(p) * pxFactor;
                    if (dist <= clickTolerance && dist < minDist) {
                        minDist = dist;
                        result = seg;
                    }
                });
            });

            if (!result && !noGhostShapes && this.ghostShapes && this.ghostShapes.length) {
                this.ghostShapes.forEach(sh => {

                    sh.getPaths().getArray().forEach(path => {
                        var points = LatLng.GMLatLngArrayToPoint2D(path.getArray()),
                            len = points.length;

                        for (var i = 0; i < len; i++) {
                            var p1 = points[i],
                                p2 = points[(i + 1) % len],
                                seg = new Segment2D(p1, p2);

                            var dist = seg.distanceToPoint(p) * pxFactor;
                            if (dist <= clickTolerance && dist < minDist) {
                                minDist = dist;
                                result = seg;
                            }
                        }
                    });
                });
            }

            return result;
        }

        getPointByPoint(latLng: LatLng, clickTolerance: number, helperLineIntersections: boolean, snapPolygons: boolean, layers?: PolygonLayer[], noGhostShapes?: boolean): Point2D {

            var result: Point2D = null,
                minDist = Number.MAX_VALUE,
                pxFactor = Math.pow(2, this.map.getZoom()),
                p = latLng.ToPoint2D(),
                helperLines = this.viewModel.helperLines;

            if (helperLineIntersections) {
                var hl: Helperline[],
                    len: number;

                if (helperLines.length > 1) {
                    hl = helperLines.filter(helperLine => (helperLine.distanceToPoint(p) * pxFactor) <= clickTolerance);
                    len = hl.length;
                    if (len > 1) {
                        for (let i = 0; i < len; i++) {
                            var hl1 = hl[i];
                            for (var j = i + 1; j < len; j++) {
                                let hl2 = hl[j],
                                    pt = hl1.getIntersection(hl2.Segment);

                                if (pt) {
                                    let dist = pt.DistanceTo(p) * pxFactor;
                                    if (dist <= clickTolerance && dist < minDist) {
                                        minDist = dist;
                                        result = pt;
                                    }
                                }
                            }
                        }
                    }
                }
                if (!result) {
                    var seg = this.getSegmentByPoint(latLng, clickTolerance, layers, true);
                    if (seg) {
                        if (!hl) {
                            hl = helperLines.filter(helperLine => (helperLine.distanceToPoint(p) * pxFactor) <= clickTolerance);
                            len = hl.length;
                        }
                        for (let i = 0; i < len; i++) {
                            let hl1 = hl[i],
                                pt = hl1.getIntersection(seg);
                            if (pt) {
                                let dist = pt.DistanceTo(p) * pxFactor;
                                if (dist <= clickTolerance && dist < minDist) {
                                    minDist = dist;
                                    result = pt;
                                }
                            }
                        }
                    }
                }
            }

            if (snapPolygons && !result) {
                var itms = this.getByLatLng(latLng, clickTolerance, layers, true);
                itms.forEach((item: ISelectableItem) => {
                    item.getPoints().forEach((pts) => {
                        pts.forEach(pt => {
                            var dist = pt.DistanceTo(p) * pxFactor;
                            if (dist <= clickTolerance && dist < minDist) {
                                minDist = dist;
                                result = pt;
                            }
                        });
                    });
                });
                if (!result) {
                    itms.forEach((item: ISelectableItem) => {
                        item.getSegments().forEach(seg => {
                            var mpt = seg.getCenter();
                            var dist = mpt.DistanceTo(p) * pxFactor;
                            if (dist <= clickTolerance && dist < minDist) {
                                minDist = dist;
                                result = mpt;
                            }
                        });
                    });
                }
            }

            if (!result && !noGhostShapes && this.ghostShapes && this.ghostShapes.length) {
                this.ghostShapes.forEach(sh => {

                    sh.getPaths().getArray().forEach(path => {
                        var points = LatLng.GMLatLngArrayToPoint2D(path.getArray()),
                            len = points.length;

                        for (var i = 0; i < len; i++) {
                            var pt = points[i],
                                dist = pt.DistanceTo(p) * pxFactor;
                            if (dist <= clickTolerance && dist < minDist) {
                                minDist = dist;
                                result = pt;
                            }
                        }
                    });
                });
            }

            return result;
        }

        closestPointByPoint(latLng: LatLng, clickTolerance: number): Point2D {

            var result: Point2D[] = [],
                pxFactor = Math.pow(2, this.map.getZoom()),
                p = latLng.ToPoint2D(),
                minDist = Number.MAX_VALUE,
                found: Point2D = null;

            this.getByLatLng(latLng, clickTolerance).forEach((item: ISelectableItem) => {
                item.getPoints().forEach((pts) => {
                    pts.forEach(pt => {
                        var dist = pt.DistanceTo(p) * pxFactor;
                        if (dist <= clickTolerance && dist < minDist) {
                            minDist = dist;
                            found = pt;
                        }
                    });
                });
            });

            return found;
        }

        updateDrawingManagerLayerStyles(layer?: IPolygonLayer) {
            var drawingManager = this.drawingManager;
            layer = layer || this.viewModel.currentLayer;

            if (layer && drawingManager) {
                drawingManager.setOptions({
                    circleOptions: layer.shapeSelectedStyle,
                    rectangleOptions: this.rectSelectMode ? this.rectSelectStyle : layer.shapeSelectedStyle,
                    polygonOptions: layer.shapeSelectedStyle
                });
            }
        }

        pathToMapPolygon(latLngs: LatLng[], orientationRad: number, origin: LatLng, depth: number, slopeDegree: number, useProjection: boolean, skipCheck?: boolean): SolarProTool.MapPolygon {
            //var latLngs: LatLng[] = [];

            //(<google.maps.LatLng[]>path).forEach((ll: google.maps.LatLng) => {

            //    latLngs.push(LatLng.FromGMLatLng(ll));
            //var latLng = LatLng.FromGMLatLng(ll);

            //bounds.Expand(latLng);

            //var pt = latLng.ToPoint2D(true);

            //points.push({
            //    latitude: latLng.Latitude,
            //    longitude: latLng.Longitude,
            //    altitude: 0,
            //    x: pt.x,
            //    y: pt.y,
            //    z: 0
            //});
            //});

            if (!skipCheck && latLngs.length < 3)
                return null;

            var boundsLatLng = BoundsLatLng.FromLatLngs(latLngs);
            if (!skipCheck && boundsLatLng.IsEmpty)
                return null;

            var xAxis = Point2D.FromOrientation(orientationRad);
            var yAxis = xAxis.rot90();
            var slopeFactor = useProjection ? 1 / Math.cos(slopeDegree / 180 * Math.PI) : 1;

            var points = latLngs.map(ll => {
                var p = origin.DirectionTo(ll).mul(1000);
                return new Point2D(xAxis.dot(p), yAxis.dot(p) * slopeFactor);
            });

            //if (orientation != 0) {
            //    var rot = Transformation.Rotation(-orientation);

            //    points = points.map(p => rot.transform(p));
            //}

            var bounds = Bounds2D.FromPoints(points);

            //var t = bounds.Min.neg();
            //points = points.map(p => p.add(t));
            //bounds.Offset(t);

            var isClockwise = Point2D.isClockwise(points);

            var resultPoints: SolarProTool.MapCoord[] = latLngs.map((latLng, i) => {
                return {
                    latitude: latLng.Latitude,
                    longitude: latLng.Longitude,
                    altitude: 0,
                    x: points[i].x,
                    y: points[i].y,
                    z: depth
                };
            });

            return {
                points: resultPoints,
                isClockwise: isClockwise,
                minLat: boundsLatLng.Min.Latitude,
                minLng: boundsLatLng.Min.Longitude,
                maxLat: boundsLatLng.Max.Latitude,
                maxLng: boundsLatLng.Max.Longitude,
                minx: Math.round(bounds.Min.x),
                miny: Math.round(bounds.Min.y),
                maxx: Math.round(bounds.Max.x),
                maxy: Math.round(bounds.Max.y),
                slope: slopeDegree,
                useProjection: useProjection,
                width: bounds.Delta.X,
                height: bounds.Delta.Y
            };

            //var sw = bounds.Min;
            //var ne = bounds.Max;

            //var mmScaleFactor = LatLng.MetersPerPixel * Math.cos(LatLng.Grad2Rad(bounds.Center.Latitude)) * 1000,
            //    pRef1 = this.fromLatLngToPoint(ref1),
            //    pRef2 = this.fromLatLngToPoint(ref2),
            //    dp = this.subPoints(pRef2, pRef1),
            //    lenSq = this.lengthSquared(dp);

            //if (lenSq <= 0)
            //    return null;

            //var len = Math.sqrt(lenSq),
            //    xAxis = new google.maps.Point(dp.x / len, dp.y / len),
            //    yAxis = new google.maps.Point(-xAxis.y, xAxis.x),
            //    minx = Number.MAX_VALUE,
            //    miny = Number.MAX_VALUE,
            //    maxx = -Number.MAX_VALUE,
            //    maxy = -Number.MAX_VALUE;

            //for (var i = 0, j = points.length; i < j; i++) {
            //    var pt = points[i],
            //        dpt = this.subPoints(pt, pRef1);

            //    pt.x = Math.round(this.dotProd(dpt, xAxis) * mmScaleFactor);
            //    pt.y = Math.round(this.dotProd(dpt, yAxis) * mmScaleFactor);

            //    minx = Math.min(pt.x, minx);
            //    miny = Math.min(pt.y, miny);
            //    maxx = Math.max(pt.x, maxx);
            //    maxy = Math.max(pt.y, maxy);
            //}

            //return {
            //    points: points,
            //    isClockwise: this.polygonIsClockwise(points),
            //    minLat: sw.lat(),
            //    minLng: sw.lng(),
            //    maxLat: ne.lat(),
            //    maxLng: ne.lng(),
            //    minx: minx,
            //    miny: miny,
            //    maxx: maxx,
            //    maxy: maxy
            //};
        }

        shapeSelectedHoveredStyle: IShapeStyle = {
            strokeWeight: 1.5,
            fillColor: '#bf4900',
            fillOpacity: 0.6,
            strokeColor: '#bf4900',
            strokeOpacity: 0.9
        };

        shapeSelectedStyle: IShapeStyle = {
            strokeWeight: 4,
            fillColor: '#bf4900',
            fillOpacity: 0.5,
            strokeColor: '#bf4900',
            strokeOpacity: 0.8
        };

        shapeHoveredStyle: IShapeStyle = {
            strokeWeight: 1,
            fillColor: '#13235B',
            fillOpacity: 0.5,
            strokeColor: '#A500AC',
            strokeOpacity: 0.8
        };

        shapeStyle: IShapeStyle = {
            strokeWeight: 1,
            fillColor: '#13235B',
            fillOpacity: 0.4,
            strokeColor: '#A500AC',
            strokeOpacity: 0.7
        };

        rectSelectStyle: IShapeStyle = {
            strokeWeight: 1,
            fillColor: '#2194ce',
            fillOpacity: 0.5,
            strokeColor: '#195799',
            strokeOpacity: 0.75,
            zIndex: 10
        };

        addListener(type: string, listener: (event: IEventManagerEvent) => void): EventManager { return this; }

        hasListener(type: string, listener: (event: IEventManagerEvent) => void): boolean { return false; }

        removeListener(type?: string, listener?: (event: IEventManagerEvent) => void): void { }

        dispatchEvent(event: IEventManagerEvent): void { }

        importImages(importedImages: SolarProTool.ImportedImageFileInfo[]): BoundsLatLng {
            var viewModel = this.viewModel;
            var bounds = new BoundsLatLng();
            importedImages.forEach(importedImage => {
                if (importedImage.Id) {

                    var center = new LatLng(importedImage.Latitude, importedImage.Longitude);

                    let bds = BoundsLatLng.FromCenterWidthHeight(center, importedImage.RealWidth / 1000, importedImage.RealHeight / 1000);
                    if (!bds.IsEmpty)
                        bounds.Expand(bds);

                    LoaderManager.addLoadingJob();
                    spt.Utils.LoadImage(`/handler/ImageHandler.ashx?ID=ImageImportPreview&ImgId=${importedImage.Id}&Guid=${spt.Utils.GenerateGuid()}`,
                        (img) => {
                            viewModel.importedImages.push(new ImageMapOverlay(importedImage.Id,
                                img.src,
                                bds,
                                this.map,
                                importedImage.Orientation,
                                importedImage.FileName));
                            LoaderManager.finishLoadingJob();
                        });
                }
            });
            return bounds;
        }

        saveImportedImage() {

            var img = this.viewModel.currentImportedImage;
            if (!img || !img._bounds) {
                this.selectTool(null);
                return;
            }
            var bd = img._bounds;
            var c = bd.Center;
            var data = {
                id: img._id,
                w: bd.Width * 1000,
                h: bd.Height * 1000,
                latitude: c.Latitude,
                longitude: c.Longitude,
                orientation: img._orientation
            };
            SolarProTool.Ajax("WebServices/MapServices.asmx").Call("EditImportedImage").Data(data).CallBack((result) => {
                this.selectTool(null);
            });
        }

        setMapTypeId(mapType: google.maps.MapTypeId | string) {
            var map = this.map;
            map.setMapTypeId(mapType);
        }

        createOverlayText(layer?: PolygonLayer) {
            if (!layer)
                layer = this.viewModel.currentLayer;

            var size = 16,
                margin = 6;

            return new OverlayText().set({
                size: size,
                fontStyle: "bold",
                fillPadding: 1,
                margin: margin,
                visible: false,
                textStyle: layer.shapeSelectedStyle.strokeColor,
                fillStyle: new Color().setStyle(layer.shapeStyle.fillColor).premultiplyAlpha(layer.shapeStyle.fillOpacity).toStringRgba(0.7)
            }).setCanvasOverlay(this.canvasOverlay);
        }

        setMeasureText(ot: OverlayText, ll1: LatLng, ll2: LatLng) {
            var valueAdjustOpts = this.viewModel.valueAdjustOpts;
            var imperial = window.UseImperialSystem;

            ot.set({
                text: spt.Utils.GetFloatFromInputValue("" + ll1.DistanceTo(ll2),
                    {
                        precision: valueAdjustOpts.precision,
                        isFeet: imperial,
                        notImperial: !imperial,
                        from: "m",
                        to: valueAdjustOpts.to
                    }) + " " + valueAdjustOpts.to,
                latLng: ll1.add(ll2).mul(0.5),
                visible: true,
                angle: ll1.AngleTo(ll2),
                maxWidth: ll1.ToPoint2D().DistanceTo(ll2.ToPoint2D()) * 0.8
            });
        }

        setAngleText(ot: OverlayText, left: LatLng, corner: LatLng, right: LatLng) {
            var l = left.ToPoint2D();
            var c = corner.ToPoint2D();
            var r = right.ToPoint2D();

            var tl = l.sub(c);
            var tr = r.sub(c);

            var lenl = tl.GetLength();
            var lenr = tr.GetLength();

            var nl = tl.divide(lenl);
            var nr = tr.divide(lenr);

            var ang = Math.acos(nl.dot(nr)) / Math.PI * 180;
            var angTxt = (Math.round(ang * 10) / 10) + " °";

            if ((<any>$).global.culture)
                angTxt.replace(".", (<any>$).global.culture.numberFormat["."]);

            var nc = nl.add(nr).GetNormalized();

            var tc = nc.rotr90();

            ot.set({
                text: angTxt,
                latLng: corner,
                visible: true,
                angle: Math.atan2(tc.y, tc.x),
                maxWidth: Math.min(lenl, lenr),
                alignUpper: Point2D.YAxis.dot(nc) > 0
            });
        }

        setCoordsText(ot: OverlayText, corner: LatLng) {
            var valueAdjustOpts = this.viewModel.valueAdjustOpts;
            var imperial = window.UseImperialSystem;

            var cord = this.viewModel.referenceLatLng.DirectionTo(corner);

            var xVal = Math.round(spt.Utils.GetFloatFromInputValue("" + cord.x,
                {
                    precision: valueAdjustOpts.precision,
                    isFeet: imperial,
                    notImperial: !imperial,
                    from: "m",
                    to: valueAdjustOpts.to
                }));

            var yVal = Math.round(spt.Utils.GetFloatFromInputValue("" + cord.y,
                {
                    precision: valueAdjustOpts.precision,
                    isFeet: imperial,
                    notImperial: !imperial,
                    from: "m",
                    to: valueAdjustOpts.to
                }));

            var coordsTxt = `(${xVal},${yVal})`;

            ot.set({
                text: coordsTxt,
                latLng: corner,
                visible: true,
                angle: 0,
                alignUpper: true
            });

        }

        setCoordNumberText(ot: OverlayText, corner: LatLng, num: number, hidden?: boolean) {
            ot.set({
                text: 'P ' + (num > 99 ? ('' + num) : ('0' + num).slice(-2)),
                latLng: corner,
                visible: !hidden,
                angle: 0,
                alignUpper: true
            });
        }

        keyDownHandler: (e: KeyboardEvent) => void;
        keyUpHandler: (e: KeyboardEvent) => void;
        mouseDownHandler: (e: MouseEvent) => void;
        mouseUpHandler: (e: MouseEvent) => void;
        mouseMoveHandler: (e: MouseEvent) => void;
        viewMouseDownHandler: (e: MouseEvent) => void;
        onDialogClose() {
            this.viewModel.currentTool.deselect(this);
            this.clear();
            this.unBindEvents();
            this.getAllPolygons().forEach(poly => {
                poly.setMap(null);
            });

            this.drawingManager.setMap(null);
            this.drawingManager = null;
            if (this.tileManager) {
                this.tileManager.forEach(tm => {
                    if (tm)
                        tm.abortAll();
                });
            }
            SetPaddingBack();
        }
    }

    EventManager.apply(MapDrawer.prototype);
}