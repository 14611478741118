module MapDrawing {

    export class ImageMapOverlay { //extends google.maps.OverlayView {
        static initializePrototype() {
            if (Object.setPrototypeOf) {
                Object.setPrototypeOf(ImageMapOverlay.prototype, google.maps.OverlayView.prototype);
            } else {
                var curproto = ImageMapOverlay.prototype;

                ImageMapOverlay.prototype = Object.create(google.maps.OverlayView.prototype as any);
                ImageMapOverlay.prototype.constructor = ImageMapOverlay;

                Object.keys(curproto).filter(k => k.toLowerCase() !== "constructor").forEach(k => {
                    ImageMapOverlay.prototype[k] = curproto[k];
                });
            }
        }

        constructor(id: string, src: string, bounds: BoundsLatLng, map: google.maps.Map, orientation?: number, name?: string) {
            google.maps.OverlayView.call(this); //super();
            this._bounds = bounds;
            this._src = src;
            this._id = id;
            this._orientation = orientation || 0;
            this._name = name;

            ko.track(this, ['_orientation', '_refLength', '_alpha', '_bounds']);

            if (map)
                this.setMap(map);
        }

        _refLength: number = 0;
        _orientation: number;
        _alpha: number = 1;
        _bounds: BoundsLatLng;
        _name: string;
        _id: string;
        _src: string;
        private _div: HTMLElement;
        private _img: HTMLImageElement;

        boundsChanged: () => void = null;

        onBoundsChanged() {
            if (this.boundsChanged) this.boundsChanged();
        }

        setMap: (map: google.maps.Map) => void;
        getMap: () => google.maps.Map;
        getPanes: () => google.maps.MapPanes;
        getProjection: () => google.maps.MapCanvasProjection;

        setBounds(bounds: BoundsLatLng, skipBoundsChangeEvent?: boolean) {
            this._bounds = bounds;
            this.draw();
            ko.valueHasMutated(this, '_bounds');
            if (!skipBoundsChangeEvent)
                this.onBoundsChanged();
        }

        getBounds(): BoundsLatLng {
            return this._bounds || null;
        }

        setOrientation(orientation: number) {
            this._orientation = orientation;
            this.draw();
        }

        setAlpha(alpha: number) {
            this._alpha = alpha;
            this.draw();
        }

        onAdd() {
            var div = this._div = document.createElement('div');
            div.style.borderStyle = 'none';
            div.style.borderWidth = '0';
            div.style.position = 'absolute';
            div.style.overflow = 'visible';

            // Create the img element and attach it to the div.
            var img = this._img = document.createElement('img') as HTMLImageElement;
            img.src = this._src;
            img.style.width = '100%';
            img.style.height = '100%';
            img.style.position = 'absolute';
            img.style.transform = 'rotate(' + this._orientation + 'deg)';
            img.style.opacity = '' + this._alpha;
            div.appendChild(img);

            // Add the element to the "overlayLayer" pane.
            var panes = this.getPanes();
            panes.overlayLayer.appendChild(div);

        }

        draw() {
            var overlayProjection = this.getProjection();

            var sw = overlayProjection.fromLatLngToDivPixel(this._bounds.Min.ToGMLatLng());
            var ne = overlayProjection.fromLatLngToDivPixel(this._bounds.Max.ToGMLatLng());

            var div = this._div;
            div.style.left = sw.x + 'px';
            div.style.top = ne.y + 'px';
            div.style.width = (ne.x - sw.x) + 'px';
            div.style.height = (sw.y - ne.y) + 'px';

            var img = this._img;
            img.style.transform = 'rotate(' + this._orientation + 'deg)';
            img.style.opacity = '' + this._alpha;
        }

        onRemove() {
            ko.untrack(this, ['_orientation', '_refLength', '_alpha']);
            this._div.parentNode.removeChild(this._div);
            this._div = null;
        }

        applyTransform(transformation: Transformation3D, mapDrawer: MapDrawer) {
            var pts = this._bounds.Corners.map(ll => ll.ToPoint2D());
            var c = Point2D.computeCenter(pts);
            //var tr = new Transformation().multiplyMatrices(transformation, Transformation.Rotation(this._orientation / 180 * Math.PI, c));
            var tr = Transformation3D.Rotation(-this._orientation / 180 * Math.PI, c);
            pts = pts.map(p => tr.transform(p));
            pts = pts.map(p => transformation.transform(p));
            c = Point2D.computeCenter(pts);

            var delta = pts[1].sub(pts[0]);
            var orientation = Math.atan2(delta.y, delta.x) || 0;
            var rtr = Transformation3D.Rotation(-orientation, c);

            var bounds = new BoundsLatLng();

            pts.forEach(p => {
                bounds.Expand(LatLng.FromPoint2D(rtr.transform(p)));
            });

            this._bounds = bounds;
            this._orientation = -orientation / Math.PI * 180;

            this.draw();
            ko.valueHasMutated(this, '_bounds');
        }
    }

    export class CanvasOverlayDrawable {
        protected _visible: boolean = true;
        canvasOverlay: CanvasOverlay;

        getVisible(ctx: CanvasRenderingContext2D, pxFactor: number) {
            return this._visible;
        }

        setVisible(value: boolean) {
            if (this._visible !== value) {
                this._visible = value;
                this.update();
            }
        }

        getBounds(ctx: CanvasRenderingContext2D, pxFactor: number): BoundsLatLng { return null; }

        setCanvasOverlay(canvasOverlay: CanvasOverlay) {
            if (canvasOverlay) {
                let index = canvasOverlay.drawables.indexOf(this);
                if (index < 0)
                    canvasOverlay.drawables.push(this);
                canvasOverlay.update();
            } else if (this.canvasOverlay) {
                let index = this.canvasOverlay.drawables.indexOf(this);
                if (index >= 0)
                    this.canvasOverlay.drawables.splice(index, 1);
                this.canvasOverlay.update();
            }
            this.canvasOverlay = canvasOverlay || null;
            return this;
        }

        draw(ctx: CanvasRenderingContext2D, pxFactor: number, boundsLatLng: BoundsLatLng, pixelBounds: Bounds2D) { }

        update() {
            if (this.canvasOverlay)
                this.canvasOverlay.update();
        }
    }

    export interface OverlayTextOptions {
         size?: number;
         margin?: number;
         angle?: number;
         text?: string;
         font?: string;
         fontStyle?: string;
         latLng?: LatLng;
         maxWidth?: number;
         fillStyle?: string | CanvasGradient | CanvasPattern;
         textStyle?: string | CanvasGradient | CanvasPattern;
         visible?: boolean;
         fillPadding?: number;
         alignUpper?: boolean;
    }

    export class OverlayText extends CanvasOverlayDrawable {

        size: number = 12;
        private textWidth: number;
        angle: number = 0;
        margin: number = 0;
        fillPadding: number = 0;
        maxWidth: number = 0;
        alignUpper: boolean = false;
        text: string = "";
        font: string = "Arial";
        fontStyle: string = "normal";
        latLng: LatLng = new LatLng();
        fillStyle: string | CanvasGradient | CanvasPattern = null;
        textStyle: string | CanvasGradient | CanvasPattern = "#000000";
        private textDeltaSize: number;

        set(opts: OverlayTextOptions) {
            if (opts) {
                Object.keys(opts).forEach(k => {
                    this[k] = opts[k];
                });
                this.onChanged();
            }
            return this;
        }

        getVisible(ctx: CanvasRenderingContext2D, pxFactor: number) {
            if (this._visible && this.maxWidth) {
                var textWidth = (this.textWidth || this.calcTextWidth(ctx)) / pxFactor;
                return textWidth * 0.5 <= this.maxWidth;
            }
            return this._visible;
        }

        private onChanged() {
            this.textWidth = 0;
            this.textDeltaSize = 0;
            this.update();
            return this;
        }

        private calcTextWidth(ctx: CanvasRenderingContext2D) {
            var textHeight = this.size;
            ctx.save();
            ctx.font = `${this.fontStyle} ${textHeight}px ${this.font}`;
            var textWidth = this.textWidth = ctx.measureText(this.text).width;
            ctx.restore();
            return textWidth;
        }

        private calcDeltaSize(ctx: CanvasRenderingContext2D) {
            var h = this.size + this.margin;
            var w = (this.textWidth || this.calcTextWidth(ctx)) * 0.5;
            return this.textDeltaSize = Math.sqrt(w * w + h * h);
        }

        getBounds(ctx: CanvasRenderingContext2D, pxFactor: number): BoundsLatLng {
            if (!this.getVisible(ctx, pxFactor))
                return null;

            var deltaSize = this.textDeltaSize || this.calcDeltaSize(ctx);

            var p = this.latLng.ToPoint2D().mul(pxFactor);
            var t = new Point2D(deltaSize, deltaSize);

            return new BoundsLatLng(LatLng.FromPoint2D(p.sub(t).divide(pxFactor)), LatLng.FromPoint2D(p.add(t).divide(pxFactor)));
        }

        draw(ctx: CanvasRenderingContext2D, pxFactor: number, boundsLatLng: BoundsLatLng, pixelBounds: Bounds2D) {
            var text = this.text;
            var textHeight = this.size;
            var textWidth = (this.textWidth || this.calcTextWidth(ctx));

            ctx.save();
            ctx.rotate(((Math.PI - this.angle - LatLng.PiHalf) % Math.PI + Math.PI) % Math.PI - LatLng.PiHalf);

            if (this.maxWidth && (textWidth / pxFactor) > this.maxWidth) {
                var sc = Math.max(0.5, this.maxWidth / (textWidth / pxFactor));
                ctx.scale(sc, sc);
            }

            var alignUpper = this.alignUpper;
            var margin = this.margin;
            var yOff = alignUpper ? textHeight + margin : -margin;

            ctx.strokeStyle = this.textStyle;
            ctx.lineWidth = 1;

            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.lineTo(0, alignUpper ? margin : -margin);
            ctx.stroke();

            ctx.translate(-textWidth * 0.5, yOff);

            if (this.fillStyle) {
                var fontDescent = this.size / 8;
                var fp = this.fillPadding;
                var fp2 = fp * 2;
                ctx.fillStyle = this.fillStyle;
                ctx.fillRect(-fp, fontDescent - textHeight - fp, textWidth + fp2, textHeight + fp2);
            }

            ctx.font = `${this.fontStyle} ${textHeight}px ${this.font}`;
            ctx.fillStyle = this.textStyle;

            ctx.fillText(text, 0, 0);

            ctx.restore();
        }

        dispose() {
            this.setCanvasOverlay(null);
        }
    }

    export class OverlayPolygon extends CanvasOverlayDrawable {
        latLngs: LatLng[][] = [];
        closed: boolean = true;
        style: CanvasDrawing.IStyle = null;

        getVisible(ctx: CanvasRenderingContext2D, pxFactor: number): boolean {
            return !!(this._visible && this.latLngs.length && this.style && (this.style.fillStyle || this.style.strokeStyle));
        }

        set(opts: { latLngs?: LatLng[][], closed?: boolean, style?: CanvasDrawing.IStyle }) {
            if (opts) {
                Object.keys(opts).forEach(k => {
                    this[k] = opts[k];
                });
                this.onChanged();
            }
            return this;
        }

        private onChanged() {
            this.update();
            return this;
        }

        getBounds(ctx: CanvasRenderingContext2D, pxFactor: number): BoundsLatLng {
            if (!this.getVisible(ctx, pxFactor))
                return null;

            return BoundsLatLng.FromLatLngs(this.latLngs);
        }

        draw(ctx: CanvasRenderingContext2D, pxFactor: number, boundsLatLng: BoundsLatLng, pixelBounds: Bounds2D) {

            ctx.save();

            let boundsCenter = boundsLatLng.Center.ToPoint2D(),
                boundsDelta = boundsLatLng.Max.ToPoint2D().sub(boundsLatLng.Min.ToPoint2D()),
                scale = new Point2D(pixelBounds.Delta.x / boundsDelta.x, -pixelBounds.Delta.y / boundsDelta.y),
                polys = this.latLngs.map(lls => lls.map(ll => ll.ToPoint2D().sub(boundsCenter).multiply(scale))),
                style = this.style;

            //ctx.lineWidth = 1;

            polys.forEach(pts => {
                ctx.beginPath();
                ctx.moveTo(pts[0].x, pts[0].y);
                for (var i = 1, l = pts.length; i < l; i++) {
                    let p = pts[i];
                    ctx.lineTo(p.x, p.y);
                }
                if (closed)
                    ctx.closePath();
            });

            style.applyToContext(ctx, pxFactor);

            if (style.fillStyle)
                ctx.fill();

            if (style.strokeStyle)
                ctx.stroke();

            ctx.restore();
        }

        dispose() {
            this.setCanvasOverlay(null);
        }
    }

    export class CanvasOverlay { //extends google.maps.OverlayView {
        static initializePrototype() {
            if (Object.setPrototypeOf) {
                Object.setPrototypeOf(CanvasOverlay.prototype, google.maps.OverlayView.prototype);
            } else {
                var curproto = CanvasOverlay.prototype;

                CanvasOverlay.prototype = Object.create(google.maps.OverlayView.prototype as any);
                CanvasOverlay.prototype.constructor = CanvasOverlay;

                Object.keys(curproto).filter(k => k.toLowerCase() !== "constructor").forEach(k => {
                    CanvasOverlay.prototype[k] = curproto[k];
                });
            }
        }

        constructor(mapDrawer: MapDrawer) {
            google.maps.OverlayView.call(this); //super();
            this.init(mapDrawer);
        }

        init(mapDrawer: MapDrawer) {
            this.mapDrawer = mapDrawer;
            if (mapDrawer && mapDrawer.map) {
                setTimeout(() => {
                    this.setMap(mapDrawer.map);
                    mapDrawer.map.addListener("idle", this.update.bind(this));
                    mapDrawer.map.addListener("bounds_changed", this.clearCanvas.bind(this));
                }, 0);
            }
        }

        draw() { }

        onAdd() {
            var div = this._div = document.createElement('div');
            div.style.borderStyle = 'none';
            div.style.borderWidth = '0';
            div.style.position = 'absolute';
            div.style.top = "0";
            div.style.left = "0";
            div.style.pointerEvents = "none";

            var isActive = false;

            var map = this.getMap() as google.maps.Map;

            var mapDiv = map.getDiv() as HTMLElement;

            var cr = mapDiv.getBoundingClientRect();

            var w = cr.width;
            var h = cr.height;

            var panes = this.getPanes();

            var p = panes.overlayLayer.parentElement;
            if (p) {
                p = p.parentElement;
                if (p) {
                    p = p.parentElement;
                    p.appendChild(div);
                    isActive = true;
                }
            }

            if (w < 10 || h < 10)
                isActive = false;

            var canvas = this.canvas = document.createElement('canvas') as HTMLCanvasElement;
            //canvas.style.pointerEvents = "none";
            canvas.className = "OverlayCanvas";

            this.isActive = isActive;

            if (!isActive)
                div.style.display = "none";

            div.appendChild(canvas);
            this.ctx = canvas.getContext("2d");

            this.setSize(w, h);

            this.update();
        }

        onResize() {
            var map = this.getMap() as google.maps.Map;
            if (!map)
                return;
            var mapDiv = map.getDiv() as HTMLElement;
            var cr = mapDiv.getBoundingClientRect();

            var w = cr.width;
            var h = cr.height;

            this.setSize(w, h);
        }

        setSize(w: number, h: number) {
            if (w > 32 && h > 32) {
                this._div.style.width = w + "px";
                this.canvas.width = w;
                
                this._div.style.height = h + "px";
                this.canvas.height = h;
            } else
                this.isActive = false;
        }

        onRemove() {
            //ko.untrack(this);
            this.mapDrawer = null;
            if (this._div && this._div.parentNode)
                this._div.parentNode.removeChild(this._div);
            this._div = null;
        }

        private clearCanvas() {
            var canvas = this.canvas;
            var ctx = this.ctx;
            if (!canvas || !ctx)
                return;

            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            this._needsUpdate = true;
        }

        private drawCanvas() {
            var mapDrawer = this.mapDrawer,
                map = mapDrawer.map,
                canvas = this.canvas,
                ctx = this.ctx;
            if (!canvas || !ctx)
                return;
            var pxFactor = mapDrawer.getPixelFactor();

            var mapBounds = BoundsLatLng.FromGMLatLngBounds(map.getBounds());
            var pxMapBounds = mapBounds.ToBounds2D(pxFactor);
            var mapBoundsHeight = Math.round(pxMapBounds.Delta.y);

            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            this.drawables.forEach(drawable => {
                if (!drawable.getVisible(ctx, pxFactor))
                    return;
                var bds = drawable.getBounds(ctx, pxFactor);
                if (bds && mapBounds.Overlaps(bds)) {
                    var pxBds = bds.ToBounds2D(pxFactor),
                        off = pxBds.Center.sub(pxMapBounds.Min);
                    ctx.setTransform(1, 0, 0, 1, off.x, mapBoundsHeight - off.y);

                    //ctx.save();
                    //ctx.strokeStyle = "#FF0000";
                    //ctx.strokeRect(pxBds.Delta.x * -0.5, pxBds.Delta.y * -0.5, pxBds.Delta.x, pxBds.Delta.y);
                    //ctx.restore();

                    drawable.draw(ctx, pxFactor, bds, pxBds);
                }
            });
        }

        update() {
            this._needsUpdate = true;
            this.checkUpdate();
        }

        clear() {
            this.drawables.slice().forEach(d => { d.setCanvasOverlay(null) });
        }

        private checkUpdate() {
            if (this._needsUpdate && !this._isUpdating) {

                this._needsUpdate = false;
                this._isUpdating = true;

                setTimeout(() => {
                    this.drawCanvas();

                    this._isUpdating = false;

                    this.checkUpdate();
                }, 0);
            }
        }

        static drawArrowHead(context: CanvasRenderingContext2D, from: Point2D, to: Point2D, angle?: number) {

            let d = to.sub(from),
                dLenSq = d.GetLengthSquared();

            if (!angle || angle <= 0)
                angle = Math.PI / 6;

            if (dLenSq <= 0)
                return;

            let n = d.GetNormalized().LeftHandNormal().mul(Math.tan(angle) * Math.sqrt(dLenSq));

            context.beginPath();
            context.moveTo(from.x + n.x, from.y + n.y);
            context.lineTo(to.x, to.y);
            context.lineTo(from.x - n.x, from.y - n.y);
            context.closePath();

        }

        drawables: CanvasOverlayDrawable[] = [];
        isActive: boolean;
        mapDrawer: MapDrawer;
        _div: HTMLElement;
        canvas: HTMLCanvasElement;
        ctx: CanvasRenderingContext2D;
        private _needsUpdate: boolean;
        private _isUpdating: boolean;

        setMap: (map: google.maps.Map) => void;
        getMap: () => google.maps.Map;
        getPanes: () => google.maps.MapPanes;
        getProjection: () => google.maps.MapCanvasProjection;
    }

    export class TileProvider {
        x: number;
        y: number;
        z: number;

        constructor(x: number, y: number, z: number, url: string, map: google.maps.Map) {
            this.x = x;
            this.y = y;
            this.url = url;

            var projection = map.getProjection(),
                tilesize = LatLng.TILE_SIZE,
                tilesizeHalf = LatLng.TILE_SIZE_HALF,
                zoom = z;


            var cx = (x + 0.5) * tilesize;
            var cy = (y + 0.5) * tilesize;

            var nz = this.z = Math.min(21, zoom); //max zoom is 21
            var dz = 1 << (zoom - nz); //Math.pow(2, zoom - nz)
            var ts = dz * tilesize;
            var ts2 = ts * 0.5;
            var scale = (1 << zoom);

            var tx = Math.floor(cx / ts) * ts;
            var ty = Math.floor(cy / ts) * ts;

            var ix = tx + ts2;
            var iy = ty + ts2;

            this.scale = dz;

            this.latLng = LatLng.FromGMLatLng(projection.fromPointToLatLng(new google.maps.Point(ix / scale, iy / scale), false));
            this.position = `${(tx - cx + tilesizeHalf)}px ${(ty - cy + tilesizeHalf)}px`;
        }

        loading: boolean = false;
        isLoaded: boolean = false;
        url: string;
        position: string;
        scale: number;
        latLng: LatLng;
        img: HTMLImageElement;
        references: string[] = [];

        get src(): string {

            var latlng = this.latLng,
                tilesize = LatLng.TILE_SIZE;

            return `${this.url}&center=${latlng.Latitude},${latlng.Longitude}&zoom=${this.z}&size=${tilesize}x${tilesize}&scale=1`;
        }

        getGmSrc() {
            //http://khms1.googleapis.com/kh?v=865&x=17687&y=11573&z=15
            return `https://khms${this.x % 2}.googleapis.com/kh?v=865&x=${this.x}&y=${this.y}&z=${this.z}`;
        }

        getBmSrc() {
            var q = MapDrawer.getQuadtreeQuadrant(this.x, this.y, this.z);
            //http://ak.dynamic.t2.tiles.virtualearth.net/comp/ch/12020312303120022?it=A,G,L&key=AtnOc3Jm2frbsoaRJ7zRC2-s9q9HQ45N5VPTV_8z08nxO27ZzVnDLoclvQWYZPc8
            return `http://ak.dynamic.t${q[q.length - 1]}.tiles.virtualearth.net/comp/ch/${q.join("")}?it=A,G,L&key=AtnOc3Jm2frbsoaRJ7zRC2-s9q9HQ45N5VPTV_8z08nxO27ZzVnDLoclvQWYZPc8`;
        }

        private listeners: ((tile: TileProvider) => void)[] = [];

        private beginLoad() {
            this.loading = true;
            //console.log("loading: " + this.x + " " + this.y + " " + this.z);
            this.img = spt.Utils.LoadImage(this.src, this.endLoad.bind(this));
        }

        private endLoad(img: HTMLImageElement) {
            this.isLoaded = true;
            this.loading = false;
            if (this.listeners.length) {
                this.listeners.forEach(fn => { fn(this); });
                this.listeners = [];
            }
            if (this.img)
                delete this.img;
        }

        load(fn: (tile: TileProvider) => void): void {
            if (this.isLoaded) {
                fn(this);
            } else {
                this.listeners.push(fn);
                if (!this.loading)
                    this.beginLoad();
            }
        }

        abort() {
            this.isLoaded = false;
            this.loading = false;
            if (this.img) {
                this.img.src = "";
                delete this.img;
            }
            if (this.listeners.length) {
                this.listeners = [];
            }
        }
    }

    export class TileManager {
        constructor(mapDrawer: MapDrawer, url: string, name: string) {
            this.mapDrawer = mapDrawer;
            this.url = url;
            this.name = name;
        }

        mapDrawer: MapDrawer;
        url: string;
        name: string;
        tiles: { [key: string]: TileProvider } = {};
        divs: { [id: string]: string } = {};

        getKey(x: number, y: number, zoom: number) {
            return `${x}_${y}_${zoom}`;
        }

        getTile(x: number, y: number, zoom: number, k?: string) {
            if (!k)
                k = this.getKey(x, y, zoom);
            return this.tiles[k] || (this.tiles[k] = new TileProvider(x, y, zoom, this.url, this.mapDrawer.map));
        }

        private setDivStyle(div: HTMLDivElement, tile: TileProvider, x: number, y: number, zoom: number, useGm?: boolean, useBm?: boolean) {
            if (div.getAttribute("tile-killed"))
                return;
            //var map = this.mapDrawer.map,
            //projection = map.getProjection(),
            var tilesize = LatLng.TILE_SIZE,
                tilesizeHalf = LatLng.TILE_SIZE_HALF;

            var cx = (x + 0.5) * tilesize;
            var cy = (y + 0.5) * tilesize;

            var nz = tile.z;
            var dz = 1 << (zoom - nz); //Math.pow(2, zoom - nz)
            var ts = dz * tilesize;
            //var ts2 = ts * 0.5;
            //var scale = (1 << zoom);

            var tx = Math.floor(cx / ts) * ts;
            var ty = Math.floor(cy / ts) * ts;

            //var ix = tx + ts2;
            //var iy = ty + ts2;

            var bgscale = dz * 100;

            //var latlng = projection.fromPointToLatLng(new google.maps.Point(ix / scale, iy / scale), false);

            div.style.backgroundImage = useGm ? `url("${tile.getGmSrc()}")` : (useBm ? `url("${tile.getBmSrc()}")` : `url("${tile.src}")`);
            //div.style.backgroundImage = `url("${this.url}&center=${latlng.lat()},${latlng.lng()}&zoom=${nz}&size=${tilesize}x${tilesize}&scale=1")`;
            div.style.backgroundSize = `${bgscale}% ${bgscale}%`;
            div.style.backgroundPosition = `${(tx - cx + tilesizeHalf)}px ${(ty - cy + tilesizeHalf)}px`;
            div.innerHTML = "";
        }

        getTileDiv(coord: IPoint2D, zoom: number, ownerDocument: HTMLDocument): HTMLElement {
            var x = coord.x,
                y = coord.y,
                sx = coord.x,
                sy = coord.y,
                sz = zoom,
                k = this.getKey(sx, sy, sz),
                id = `md-tile-${this.name}-${k}`;

            var maxZoom = Math.min(21, sz);
            //var minZoom = Math.max(1, maxZoom - 3);

            while (sz > maxZoom) {
                sx = Math.floor(sx / 2);
                sy = Math.floor(sy / 2);
                sz--;
            }

            var srcTile = this.getTile(sx, sy, sz, k);

            var div = ownerDocument.createElement('div');
            div.className = "md-tile-url";
            div.id = id;

            this.divs[id] = k;

            srcTile.references.push(id);

            if (!srcTile.isLoaded) {

                var tmpTile = this.getTile(sx, sy, sz);
                var useBm = this.name === "BING";
                var useGm = !useBm;
                while (sz > 19 && !tmpTile.isLoaded) {
                    sx = Math.floor(sx / 2);
                    sy = Math.floor(sy / 2);
                    sz--;
                    tmpTile = this.getTile(sx, sy, sz);
                }

                this.setDivStyle(div, tmpTile, x, y, zoom, !tmpTile.isLoaded && useGm, !tmpTile.isLoaded && useBm);

            }

            srcTile.load(t => {
                if (!this.divs[id])
                    return;
                this.setDivStyle(div, t, x, y, zoom);
            });

            return div;
        }

        releaseTile(div: HTMLElement) {
            if (div) {
                if (div.parentElement)
                    div.parentElement.removeChild(div);
                if (div.style && div.style.backgroundImage)
                    div.style.backgroundImage = "";
                var id = div.id;
                if (this.divs[id]) {
                    var k = this.divs[id];
                    var tile = this.tiles[k];
                    if (tile) {
                        var index = tile.references.indexOf(id);
                        if (index > -1)
                            tile.references.splice(index, 1);
                        if (tile.references.length <= 0)
                            tile.abort();
                    }
                    delete this.divs[id];
                }
            }
        }

        abortAll(): void {
            var tiles = this.tiles;
            Object.keys(tiles).map(k => tiles[k]).forEach(tile => {
                tile.abort();
            });
            this.tiles = {};
        }
    }

    export class OCRTileManager extends TileManager {
        constructor(mapDrawer: MapDrawer, googleUrl: string, name: string, size = 256) {
            super(mapDrawer, googleUrl, name);

            this.size = size;
            this.ocr = new GMapsUtils.OctantNodeRenderer(size, size);
        }

        size: number;
        ocr: GMapsUtils.OctantNodeRenderer;
        
        getTileDiv(coord: IPoint2D, zoom: number, ownerDocument: HTMLDocument): HTMLElement {
            if (zoom < 20)
                return super.getTileDiv(coord, zoom, ownerDocument);

            var x = coord.x,
                y = coord.y,
                k = this.getKey(x, y, zoom),
                id = `md-tile-${this.name}-${k}`,
                size = this.size;

            var canvas = ownerDocument.createElement('canvas');
            canvas.width = canvas.height = size;
            canvas.className = "md-canvas-url";
            canvas.id = id;

            this.divs[id] = k;

            //console.log("request tile at zoom " + zoom);

            this.ocr.requestRender(this.getBounds(x, y, zoom, this.mapDrawer.map), 21, () => {
                if (!canvas.parentElement || !this.divs[canvas.id])
                    return null;
                return canvas as HTMLCanvasElement;
            }, null, size, size);

            return canvas;
        }

        getBounds(x: number, y: number, zoom: number, map: google.maps.Map) {
            var projection = map.getProjection(),
                tilesize = this.size;
            
            var cx = (x + 0.5) * tilesize;
            var cy = (y + 0.5) * tilesize;

            //var nz = Math.min(21, zoom); //max zoom is 21
            //var dz = 1 << (zoom - zoom); //Math.pow(2, zoom - nz)
            var ts = tilesize;
            var ts2 = ts * 0.5;
            var scale = (1 << zoom);

            var tx = Math.floor(cx / ts) * ts;
            var ty = Math.floor(cy / ts) * ts;

            var ix = tx + ts2;
            var iy = ty + ts2;
            
            var latLng = LatLng.FromGMLatLng(projection.fromPointToLatLng(new google.maps.Point(ix / scale, iy / scale), false));

            return BoundsLatLng.FromCenterAndZoom(latLng, zoom, this.size, this.size);
        }

        //releaseTile(div: HTMLElement) {
        //    if (div) {
        //        if (div.parentElement)
        //            div.parentElement.removeChild(div);
        //        var id = div.id;
        //        if (this.divs[id])
        //            delete this.divs[id];
        //    }
        //}
    }

    export interface ITriangleDefinition {
        idx: number;
        tr: number[][];
        edge: Segment2D;
        n: Point3D;
        nearestNeighboorIdx: number;
        faceUpwards: boolean;
        slope: number;
        collectedIdx: number[];
    }
}
